import { AxiosResponse } from "axios";
import { APP_ROUTES, ENDPOINTS } from "const";
import { FormikHelpers } from "formik";
import {
  useActionGroup,
  useActionGroupSkills,
  useMe,
  useUpdateAttachments,
} from "hooks";
import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { FullActionGroupType, IActionGroupSkill } from "types";
import { showErrorMessage, showSuccessMessage } from "utils";
import { IManagementProps } from "./GroupForm.types";
import {
  createActionGroup,
  createActionGroupSkills,
  updateActionGroup,
  updateActionGroupSkills,
} from "./GroupForm.services";
import { ActionGroupPayload, InitActionGroup } from "./GroupForm.constants";

export function useInitialState() {
  const params = useParams<{ id: string }>();
  const { data: me } = useMe();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isOnActionGroupPage = location.pathname.includes(
    APP_ROUTES.ACTION_GROUP
  );
  const editGroupId = isOnActionGroupPage
    ? params.id
    : searchParams.get("groupId") || "";
  const { data: actionGroup } = useActionGroup({
    id: editGroupId,
    enabled: !!editGroupId,
    user: me?.data.id,
  });
  const { data: groupSkills } = useActionGroupSkills({
    id: actionGroup?.data.id,
    enabled: !!actionGroup,
  });

  return useMemo(() => {
    const init = new InitActionGroup(actionGroup?.data, groupSkills?.data);
    return init;
  }, [groupSkills?.data, actionGroup?.data]);
}

interface IActionGroupsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<FullActionGroupType>, any, any>
  > {
  id?: string | undefined;
}

interface IActionGroupsSkillsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IActionGroupSkill[]>, any, any>
  > {}

export function useCreateActionGroup({ ...rest }: IActionGroupsProps) {
  return useMutation<AxiosResponse<FullActionGroupType>, any, any>(
    (group: FullActionGroupType) => createActionGroup({ group }),
    {
      ...rest,
    }
  );
}

export function useUpdateActionGroup({
  id = "",
  ...rest
}: IActionGroupsProps & {
  id: string | undefined;
}) {
  return useMutation<AxiosResponse<FullActionGroupType>, any, any>(
    (group: FullActionGroupType) => updateActionGroup({ id, group }),
    {
      ...rest,
    }
  );
}

export function useCreateActionGroupSkill({
  ...rest
}: IActionGroupsSkillsProps) {
  return useMutation<AxiosResponse<IActionGroupSkill[]>, any, any>(
    (groupSkill: IActionGroupSkill[]) =>
      createActionGroupSkills({ groupSkill }),
    {
      ...rest,
    }
  );
}

export function useUpdateActionGroupSkill({
  ...rest
}: IActionGroupsSkillsProps) {
  return useMutation<AxiosResponse<IActionGroupSkill[]>, any, any>(
    (groupSkill: IActionGroupSkill[]) =>
      updateActionGroupSkills({ groupSkill }),
    {
      ...rest,
    }
  );
}

export function useSubmitHandler(
  success: () => void,
  manage: IManagementProps
) {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const isOnActionGroupPage = location.pathname.includes(
    APP_ROUTES.ACTION_GROUP
  );
  const { data: me } = useMe();
  const [searchParams] = useSearchParams();
  const editGroupId = isOnActionGroupPage
    ? params.id
    : searchParams.get("groupId") || "";
  const { mutateAsync: mutateCreateActionGroupAsync } = useCreateActionGroup(
    {}
  );
  const { mutateAsync: mutateUpdateActionGroupAsync } = useUpdateActionGroup({
    id: editGroupId,
  });

  const { mutateAsync: mutateCreateActionGroupSkillAsync } =
    useCreateActionGroupSkill({});
  const { mutateAsync: mutateUpdateActionGroupSkillAsync } =
    useUpdateActionGroupSkill({});

  const { data: actionGroupForEdit } = useActionGroup({
    id: editGroupId,
    enabled: !!editGroupId,
    user: me?.data.id,
  });

  const queryClient = useQueryClient();
  const { data: meData } = useMe({});
  const { mutateAsync: updateAttachments } = useUpdateAttachments();

  return useCallback(
    async (
      actionGroup: Partial<InitActionGroup>,
      formikHelpers: FormikHelpers<Partial<InitActionGroup>>
    ) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.resetQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS_SKILLS]);
      };
      let actionGroupId = params.id;
      try {
        if (params.id) {
          const payload = new ActionGroupPayload({
            user: meData?.data.id,
            attachmentsType: "actionGroups",
            ...actionGroup,
          });
          if (editGroupId) {
            if (manage.showIssueFields) {
              const updated = await mutateUpdateActionGroupAsync(
                payload.getActionGroup()
              );
              actionGroupId = updated.data.id;
              showSuccessMessage("Action group is updated!");
            }
            await mutateUpdateActionGroupSkillAsync({
              actionGroup: actionGroupForEdit?.data?.id,
              skills: payload.getSkills(),
            });
            showSuccessMessage("Action group skills are updated!");
          } else {
            if (manage.showIssueFields) {
              const created = await mutateCreateActionGroupAsync({
                ...payload.getActionGroup(),
                solutions: isOnActionGroupPage
                  ? payload.solutions
                  : [params.id],
              });
              actionGroupId = created.data.id;
              showSuccessMessage("Action group is created!");
              if (payload.getSkills().length) {
                await mutateCreateActionGroupSkillAsync({
                  actionGroup: created.data.id,
                  skills: payload.getSkills(),
                });
                showSuccessMessage("Action group skills are created!");
              }
            }
          }
          if (actionGroupId) {
            await updateAttachments(payload.getAttachments(actionGroupId));
            showSuccessMessage("Attachments are updated!");
          }
          success();
          invalidate();
        } else {
          formikHelpers.setSubmitting(false);
          showErrorMessage("No solution occurred! Check page!");
        }
      } catch (error: any) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(error.response.data);
        showErrorMessage("Error occurred! Check form!");
      }

      formikHelpers.setSubmitting(false);
    },
    [
      params.id,
      queryClient,
      meData?.data.id,
      editGroupId,
      manage.showIssueFields,
      mutateUpdateActionGroupSkillAsync,
      actionGroupForEdit?.data?.id,
      success,
      mutateUpdateActionGroupAsync,
      mutateCreateActionGroupAsync,
      isOnActionGroupPage,
      mutateCreateActionGroupSkillAsync,
      updateAttachments,
    ]
  );
}
