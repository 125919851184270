import { Formik } from "formik";
import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import validationSchema from "./CreateNewPasswordForm.schema";
import {
  useInitialState,
  useSubmitHandler,
} from "./CreateNewPasswordForm.hooks";
import CreateNewPasswordForm from "./CreateNewPasswordForm.component";

function CreateNewPassword() {
  const initialState = useInitialState();

  const { search } = useLocation();
  const [notification, setNotification] = useState<null | "passwordReset">(
    null
  );
  const [oobCode, setOobCode] = useState<null | string>(null);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.has("passwordReset")) {
      const value = query.get("passwordReset");
      if (value === "true") {
        setNotification("passwordReset");
      }
    }
    if (query.has("oobCode")) {
      const value = query.get("oobCode");
      setOobCode(value);
    }
  }, [search]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler(oobCode || "")}
    >
      {() => {
        return notification === "passwordReset" && <CreateNewPasswordForm />;
      }}
    </Formik>
  );
}

export default memo(CreateNewPassword);
