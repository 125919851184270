import { Formik } from "formik";
import { memo } from "react";
import validationSchema from "./ForgetPasswordForm.schema";
import { useInitialState, useSubmitHandler } from "./ForgetPasswordForm.hooks";
import ResetPasswordForm from "./ForgetPasswordForm.component";

function ForgetPassword() {
  const initialState = useInitialState();

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler()}
    >
      <ResetPasswordForm />
    </Formik>
  );
}

export default memo(ForgetPassword);
