import classnames from "classnames";
import { Loader } from "components";
import React, { Suspense } from "react";

export function LayoutWrp({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <section className={classnames("flex flex-col w-full h-full", className)}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </section>
  );
}

export default LayoutWrp;
