import classnames from "classnames";
import {
  AnalyticsComponent,
  BorderBadge,
  Button,
  IssueCardModal,
  IssueForm,
  ListMap,
  PromptModal,
} from "components";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as SquareLeft } from "assets/images/square-left.svg";
import { ReactComponent as SquareRight } from "assets/images/square-right.svg";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getRating } from "utils";
import { useResizeDetector } from "react-resize-detector";
import { IOutletAuth } from "types";
import { useWindowDimensions } from "hooks";
import { IssueCard } from "./IssueCard";
import { useIssueListFilterParams } from "./IssueList.hooks";
import JumboImage1 from "assets/images/jumbo-1.png";
import { SlideOver } from "components/SlideOver";
import { APP_ROUTES } from "const";

function IssueList() {
  const navigate = useNavigate();
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );
  const { width, height, ref: mapRef } = useResizeDetector();
  const [isIssueHovered, setIsIssueHovered] = useState(false);

  const [hoveredIssueId, setHoveredIssueId] = useState<
    string | undefined | string[]
  >(undefined);
  const [hoveredIssueType, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();

  const [issueHoveredProps, setIssueHoveredProps] = useState({
    lat: NaN,
    lng: NaN,
  });
  const dim = useWindowDimensions();
  const disabled = useMemo(() => {
    return !!(open && dim.width && dim.width <= 1023);
  }, [dim.width, open]);

  const { data, inViewRef } = useIssueListFilterParams(
    width || dim.width,
    disabled
  );

  const [openFormReport, setOpenFormReport] = useState(false);
  const [showReportPromptModal, setShowReportPromptModal] = useState(false);
  const [createdIssueId, setCreatedIssueId] = useState<string | undefined>(
    undefined
  );
  const [showSuccessReportPromptModal, setShowSuccessReportPromptModal] =
    useState(false);
  useEffect(() => {
    if (createdIssueId) {
      setShowSuccessReportPromptModal(true);
    }
  }, [createdIssueId]);

  return (
    <div className={classnames("flex flex-col   relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
          // { "ml-[5%] mr-[5%] ": !open },
          // { "lg:ml-[5%]": open }
        )}
      >
        <div
          className={classnames(
            "  one-fourth-step three-fourth-step ten-fourth-step eleven-third-step grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear",
            {
              "sm:grid-cols-2 lg:grid-cols-3 lg:col-span-3": !open,
              "hidden lg:grid max-w-[500px]": open,
            }
          )}
        >
          <div
            className={classnames(
              " relative    flex-1 max-h-[300px]  w-full  overflow-hidden",
              {
                "flex col-span-1 sm:col-span-2  lg:col-span-3": !open,
              },
              {
                hidden: open,
              }
            )}
          >
            <div className=" flex justify-center flex-1 w-full h-full">
              <img
                style={{ flexBasis: "100%" }}
                className="  select-none pointer-events-none min-h-[180px] flex-1 w-full h-full object-cover sm:object-contain"
                alt="jumbo"
                src={authContext.backgroundImages || JumboImage1}
              />
              <Button
                isReverse
                className="   !absolute h-[42px] font-semibold   bottom-[10%]   z-50 justify-center flex  shrink-0"
                onClick={() => {
                  if (!!authContext?.isAuth) {
                    setShowReportPromptModal(true);
                  } else {
                    setShowGoSignInModal(true);
                  }
                }}
              >
                Report issue
              </Button>
            </div>
          </div>
          {data?.pages.map((page, pagesIndex) => {
            return page.data.items.length < 1 ? (
              <div className="flex min-h-[400px] items-start justify-center col-span-6">
                <p className=" max-w-[700px] text-center text-xl">
                  There are no issues found for the chosen location, please
                  adjust your search parameters or
                  <span
                    onClick={() => {
                      if (!!authContext?.isAuth) {
                        setShowReportPromptModal(true);
                      }
                    }}
                    className="font-bold px-1 hover:text-white cursor-pointer"
                  >
                    report an issue
                  </span>
                </p>
              </div>
            ) : (
              page.data.items.map((issue, issueIndex) => {
                const isFormattedAddress = !!issue?.location?.formattedAddress;
                const isCountry = !!issue?.location?.country;
                const isCity = !!issue?.location?.city;
                const isStreetNumber = !!issue?.location?.streetNumber;
                const isRoute = !!issue?.location?.route;
                const location =
                  typeof issue?.location === "object"
                    ? isFormattedAddress
                      ? `${issue?.location?.formattedAddress}`
                      : isCountry && isCity && isStreetNumber && isRoute
                      ? `${issue?.location?.route} ${issue?.location?.streetNumber}, ${issue?.location?.city}, ${issue?.location?.country}`
                      : isCountry && isCity
                      ? ` ${issue?.location?.city}, ${issue?.location?.country}`
                      : isCountry
                      ? `${issue?.location?.country}`
                      : "No location"
                    : "No location";
                const geoLocation = {
                  lat: issue?.location?.lat,
                  lng: issue?.location?.lng,
                };
                const isLast =
                  data?.pages.length === pagesIndex + 1 &&
                  page.data.items.length === issueIndex + 1;

                const issueType =
                  issue?.location?.administrativeArea ||
                  issue?.location?.route ||
                  issue?.location?.streetNumber
                    ? "regular"
                    : issue?.location?.city
                    ? "city"
                    : "country";

                return (
                  <div
                    className={classnames(
                      "relative cursor-pointer   maxmd:flex  ",
                      {
                        " first-card-to-click ": issueIndex === 0,
                      }
                    )}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      issue && setOpenedCardId(issue.id);
                      issue && setOpenCardModal(true);
                    }}
                    key={issue.id}
                  >
                    {issue.analyticsData && (
                      <AnalyticsComponent analyticsData={issue.analyticsData} />
                    )}
                    <IssueCard
                      id={issue.user?.id}
                      issueType={issueType}
                      setHoveredIssueType={setHoveredIssueType}
                      forceHoverIssue={hoveredIssueId?.includes(issue.id || "")}
                      setIsIssueHovered={setIsIssueHovered}
                      setIssueHoveredProps={setIssueHoveredProps}
                      geoLocation={geoLocation}
                      innerRef={isLast ? inViewRef : undefined}
                      title={issue.title}
                      //details={issue.details}
                      location={location}
                      profileImage={issue?.user?.avatar}
                      firstName={issue.user?.firstName}
                      lastName={issue.user?.lastName}
                      createdAt={issue.createdAt}
                      rating={getRating(issue.rating)}
                      primaryClassification={issue.primaryClassification}
                      secondaryClassification={issue.secondaryClassification}
                      email={issue?.user?.email}
                      score={issue.user?.reputationScore}
                      tags={issue?.tags}
                      isSoftDeleted={!!issue.deletedAt}
                    />
                  </div>
                );
              })
            );
          })}
        </div>
        <div
          className={classnames(
            "  relative lg:rounded-lg lg:overflow-hidden ",
            {
              "hidden lg:flex lg:flex-col  row-span-2 row-start-1 lg:col-start-4":
                !open,
              "lg:col-start-2 lg:col-end-[-1] ": open,
            }
          )}
        >
          <ListMap
            setOpenMap={setOpen}
            hoveredIssueType={hoveredIssueType}
            setHoveredIssueId={setHoveredIssueId}
            issueCardHovered={isIssueHovered}
            issueCardProps={issueHoveredProps}
            innerRef={mapRef}
            mapHeight={height || dim.height}
            mapWidth={width || dim.width}
          />

          <div
            className="flex  flex-col absolute top-7 left-5 cursor-pointer"
            onClick={() => setOpen((open) => !open)}
          >
            {!open && <SquareLeft />}
            {open && <SquareRight />}
          </div>
        </div>
      </div>

      <BorderBadge
        className="  bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 fixed bottom-14 right-[50%] translate-x-1/2 cursor-pointer block lg:hidden z-10"
        onClick={() => setOpen((open) => !open)}
        classNames={{
          text: "!text-sm",
          contend: "px-4 py-2 ",
        }}
      >
        {open ? "Show issue list" : "Show map"}
      </BorderBadge>
      {openCardModal && openedCardId && (
        <IssueCardModal id={openedCardId} setShow={setOpenCardModal} />
      )}
      {showReportPromptModal && (
        <PromptModal
          setShow={setShowReportPromptModal}
          header="Report Issue"
          content="Before adding a new issue, please ensure a similar issue for this location does not exist already."
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowReportPromptModal(false);
                  setOpenFormReport(true);
                }}
              >
                Continue, I have checked
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowReportPromptModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        />
      )}
      {!!showSuccessReportPromptModal && createdIssueId && (
        <PromptModal
          setShow={setShowSuccessReportPromptModal}
          header="Report Issue"
          content="Will you like to propose a solution for this issue?"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessReportPromptModal(false);
                  navigate(APP_ROUTES.ISSUE + "/" + createdIssueId);
                }}
              >
                Yes
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessReportPromptModal(false);
                  setCreatedIssueId(undefined);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
      <SlideOver
        open={openFormReport}
        close={useCallback(() => {
          setCreatedIssueId(undefined);
          setOpenFormReport(false);
        }, [setOpenFormReport, setCreatedIssueId])}
        title="Report Issue"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <IssueForm
          onSuccess={useCallback(
            (id?: string) => {
              setCreatedIssueId(id);
              setOpenFormReport(false);
            },
            [setOpenFormReport, setCreatedIssueId]
          )}
          onClose={useCallback(() => {
            setCreatedIssueId(undefined);
            setOpenFormReport(false);
          }, [setOpenFormReport, setCreatedIssueId])}
        />
      </SlideOver>
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(IssueList);
