import classnames from "classnames";

import { memo } from "react";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { IOutletAuth } from "types";
import { useMe } from "hooks";

import { APP_ROUTES } from "const";

import { useMotivationalList } from "modules/MotivationalList/MotivationalList.hooks";
import { MotivationalCard } from "modules/MotivationalList/MotivationalCard";
interface IProps {
  profileOwner?: boolean;
}

function ProfileMotivationals({ profileOwner }: IProps) {
  const navigate = useNavigate();
  const authContext = useOutletContext<IOutletAuth>();
  const { data: meData } = useMe({ enabled: !!authContext?.isAuth });
  const params = useParams<{ userId: string }>();

  const { data: motivationalsData } = useMotivationalList({
    user: meData?.data && profileOwner ? meData?.data.id! : params.userId!,
    skills: [],
    tags: [],
    category: [],
    zoom: 1,
    lat: "",
    lng: "",
    suspense: false,
  });

  return (
    <div className={classnames("flex flex-col   relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr_1fr_1fr_1fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-3  mb-12 overflow-auto "
        )}
      >
        <div
          className={classnames(
            "grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll sm:grid-cols-2 sm:col-span-2  md:grid-cols-3 md:col-span-3   lg:grid-cols-4 lg:col-span-4 pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear"
          )}
        >
          {motivationalsData?.pages.map((page) => {
            return page.data.items.length < 1 ? (
              <div className="flex min-h-[400px] items-start justify-center col-span-6">
                <p className=" max-w-[700px] text-center text-xl">
                  {profileOwner
                    ? "You have not created any motivational content"
                    : "There are no motivational contents created by this user"}
                </p>
              </div>
            ) : (
              page.data.items.map((motivational) => {
                return (
                  <div
                    className=" cursor-pointer z-10  shadow-issueCard hover:shadow-issueCardStrong  bg-white rounded-md  "
                    onClick={() => {
                      motivational.id &&
                        navigate(
                          APP_ROUTES.MOTIVATIONAL + "/" + motivational.id
                        );
                    }}
                    key={motivational.id}
                  >
                    <MotivationalCard
                      id={motivational.id}
                      image={motivational.image}
                      videoLink={motivational.videoLink}
                      thumbnailLink={motivational.thumbnailLink}
                      title={motivational.title}
                      firstName={motivational.user?.firstName}
                      lastName={motivational.user?.lastName}
                      createdAt={motivational.createdAt}
                      likes={motivational.likes}
                      dislikes={motivational.dislikes}
                      rate={motivational.rates && motivational.rates[0]}
                      views={motivational.views}
                    />
                  </div>
                );
              })
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default memo(ProfileMotivationals);
