import classnames from "classnames";
import { memo, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

function Star({
  id,
  ratingValue,
  displayOnly = false,
  itemHovered = NaN,
  size = "normal",
  setItemHovered,
  onClick,
}: {
  id: number;
  ratingValue: number;
  displayOnly?: boolean;
  size?: "small" | "normal" | "large";
  itemHovered?: number;
  setItemHovered?: React.Dispatch<React.SetStateAction<number>>;
  onClick: () => void;
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const rate = ratingValue ? Math.abs(ratingValue) : 0;
  const decimal = rate - Math.floor(rate);
  const shouldFillOnAction = !displayOnly && (isMouseOver || itemHovered > id);
  const shouldFillOnValue = id <= ratingValue;
  const shouldFillOnDecimal =
    decimal !== 0 && ratingValue < id && ratingValue > id - 1;
  const uniqId = useMemo(() => {
    return uuidv4();
  }, []);
  const starSize = size === "small" ? 20 : size === "normal" ? 22 : 24;
  return (
    <div
      className={classnames("", {
        "cursor-pointer": !displayOnly,
      })}
      onMouseOver={() => {
        if (!displayOnly) {
          setIsMouseOver(true);
          setItemHovered && setItemHovered(id);
        }
      }}
      onMouseOut={() => {
        if (!displayOnly) {
          setIsMouseOver(false);
          setItemHovered && setItemHovered(NaN);
        }
      }}
      onClick={onClick}
    >
      <svg
        width={starSize}
        height={starSize}
        viewBox="0 0 22 19"
        fill="#6A6B6A"
        xmlns="http://www.w3.org/2000/svg"
      >
        {shouldFillOnDecimal ? (
          <linearGradient id={"partGradient" + uniqId}>
            <stop offset="-8.22%" stopColor="#6A6B6A"></stop>
            <stop
              offset={
                decimal < 0.5
                  ? "20%"
                  : decimal === 0.5
                  ? "30.6%"
                  : decimal > 0.5
                  ? "47%"
                  : "0%"
              }
              stopColor="#6A6B6A"
            ></stop>
            <stop
              offset={
                decimal < 0.5
                  ? "35%"
                  : decimal === 0.5
                  ? "51%"
                  : decimal > 0.5
                  ? "65%"
                  : "0%"
              }
              stopColor="#6A6B6A"
            ></stop>
            <stop
              offset={
                decimal < 0.5
                  ? "35%"
                  : decimal === 0.5
                  ? "52%"
                  : decimal > 0.5
                  ? "66%"
                  : "0%"
              }
              stopColor="#6A6B6A"
            ></stop>
            <stop offset="100%" stopColor="#6A6B6A"></stop>
          </linearGradient>
        ) : (
          <linearGradient id={"gradient" + uniqId}>
            <stop offset="-8.22%" stopColor="#6A6B6A"></stop>
            <stop offset="46.6%" stopColor="#6A6B6A"></stop>
            <stop offset="110.24%" stopColor="#6A6B6A"></stop>
          </linearGradient>
        )}

        <path
          d="M9.87777 0.695722L7.32731 5.86696L1.62098 6.69889C0.597669 6.84731 0.187563 8.10887 0.929659 8.83144L5.05805 12.8544L4.08161 18.5373C3.90585 19.5645 4.98775 20.3339 5.89389 19.8535L10.9987 17.1702L16.1036 19.8535C17.0097 20.33 18.0916 19.5645 17.9158 18.5373L16.9394 12.8544L21.0678 8.83144C21.8099 8.10887 21.3998 6.84731 20.3765 6.69889L14.6701 5.86696L12.1197 0.695722C11.6627 -0.226039 10.3387 -0.237757 9.87777 0.695722Z"
          fill={
            shouldFillOnValue || shouldFillOnDecimal
              ? shouldFillOnDecimal
                ? `url(#partGradient${uniqId})`
                : `url(#gradient${uniqId})`
              : "#E5E9F2"
          }
          stroke={`#6A6B6A`}
          strokeWidth={shouldFillOnAction ? 1 : 0}
        />
      </svg>
    </div>
  );
}

export default memo(Star);
