import classnames from "classnames";
import { CustomToolTip } from "components";
import { CATEGORIES, CATEGORIES_COLORS } from "const";
import { memo, ReactNode, useMemo, useState } from "react";

interface IDotProps {
  onClick?: () => void;
  className?: string;
  styleLess?: boolean;
  children: ReactNode;
  withNotification?: boolean;
  notificationContent?: string;
  classNames?: {
    span?: string;
    text?: string;
    textContainer?: string;
  };
  classificationName?: string;
}

function BorderBadgeRound({
  className,
  children,
  classNames,
  withNotification,
  notificationContent,
  styleLess = false,
  classificationName = "",
}: IDotProps) {
  const [showNotification, setShowNotification] = useState(false);

  const badgeColor = useMemo(() => {
    if (CATEGORIES[classificationName]) {
      return CATEGORIES[classificationName];
    }

    const badgeColors = JSON.parse(localStorage.getItem("badgeColors") || "{}");
    const storedColor = badgeColors[classificationName];
    if (storedColor) {
      return storedColor;
    } else {
      const randomColor =
        CATEGORIES_COLORS[Math.floor(Math.random() * CATEGORIES_COLORS.length)];

      const updatedBadgeColors = {
        ...badgeColors,
        [classificationName]: randomColor,
      };
      localStorage.setItem("badgeColors", JSON.stringify(updatedBadgeColors));
      return randomColor;
    }
  }, [classificationName]);

  return (
    <div
      onMouseOver={() => {
        setShowNotification(true);
      }}
      onMouseOut={() => {
        setShowNotification(false);
      }}
      className={classnames(
        "relative",
        {
          " rounded  p-[1px]": !styleLess,
        },
        className,
        { "bg-gradient-to-r ": badgeColor },
        badgeColor
      )}
    >
      <div
        className={classnames(
          "  flex flex-row gap-2 items-center rounded-xl px-2",
          classNames?.textContainer
        )}
      >
        <p
          className={classnames(
            { "leading-6 text-sm font-semibold  text-[#606060]": !styleLess },

            classNames?.text
          )}
        >
          {children}
        </p>
      </div>
      {withNotification && notificationContent && (
        <CustomToolTip
          className="!bg-white !border-black !border !text-black !whitespace-nowrap"
          position="top"
          showTooltip={showNotification}
          tooltipText={notificationContent}
        />
      )}
    </div>
  );
}
export default memo(BorderBadgeRound);
