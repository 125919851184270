import { APP_ROUTES } from "const";
import { Step } from "react-joyride";
import { IProductTour } from "types";

export const firstScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        search reported issues.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the issues page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".one-first-step",
  },
  {
    content:
      "In the search bar, enter the location of interest to find reported issues. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".one-second-step",
  },
  {
    content: "You can select one or more issue categories",
    placement: "bottom",
    target: ".one-third-step",
  },
  {
    content:
      "Issues in the location and categories you specified will be available here. Click on any card to see more details.",
    placement: "right",
    target: ".one-fourth-step",
  },
];

export const secondScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        report issues.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the issues page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".two-first-step",
  },
  {
    content: "Click the Report button on the Issue page",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".two-second-step",
  },
  {
    content:
      "Enter information about the issue you want to report and click save.",
    placement: "bottom",
    target: ".two-third-step",
  },
];

export const thirdScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        Help to ensure that attention is paid to the most relevant issues,
        solutions and action groups by reviewing and up-voting or down-voting.
        Here is how to quickly up-vote or down-vote a reported issue.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the issues page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".three-first-step",
  },
  {
    content:
      "In the search bar, enter the location of interest to find reported issues. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".three-second-step",
  },
  {
    content: "You can select one or more issue categories",
    placement: "bottom",
    target: ".three-third-step",
  },
  {
    content:
      "Issues in the location and categories you specified will be available here. Click on any issue card of interest to see more details.",
    placement: "right",
    target: ".three-fourth-step",
  },
  {
    content: "In the following screen, click See full details.",
    placement: "right",
    target: ".three-fifth-step",
  },
  {
    content: "Click rate.",
    placement: "right",
    target: ".three-sixth-step",
  },
  {
    content:
      "Vote up or down by clicking to assign 1-5 stars for any relevant category. Then click save.",
    placement: "right",
    target: ".three-seventh-step",
  },
];

export const fourthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        search proposed solutions.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the Solutions page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".four-first-step",
  },
  {
    content:
      "Use the search bar to search for any location to find proposed solutions. To see only solutions that you proposed, click on the “My solutions” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".four-second-step",
  },
  {
    content:
      "You can select one or more skills to see solutions that require such skills.",
    placement: "bottom",
    target: ".four-third-step",
  },
  {
    content:
      "Solutions in the location you specified will be available here. Click on any card to see more details.",
    placement: "right",
    target: ".four-fourth-step",
  },
];

export const fifthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        search proposed solutions for a specific issue.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>
        First, we need to select the relevant issue that we will propose the
        solution for. To select the relevant issue, go to the menu and navigate
        to the Issues page.
      </h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".five-first-step",
  },
  {
    content:
      "In the search bar, enter the location of interest to find reported issues. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".five-second-step",
  },
  {
    content: "You can select one or more issue categories",
    placement: "bottom",
    target: ".five-third-step",
  },
  {
    content: (
      <h2>
        Scroll down to see the list of reported issues. Click the issue of your
        interest. If the issue is not listed, you can report the issue. To
        report the issue, please follow the guidelines at this link: link to
        product tour for reporting issue.
      </h2>
    ),
    placement: "right",
    target: ".five-fourth-step",
  },

  {
    content: "Click See full details",
    placement: "right",
    target: ".five-fifth-step",
  },
  {
    content: "Scroll down to see proposed solutions for the selected issues.",
    placement: "right",
    target: ".five-sixth-step",
  },
];

export const sixthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        propose a solution.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>
        First, we need to select the relevant issue that we will propose the
        solution for. To select the relevant issue, go to the menu and navigate
        to the Issues page.
      </h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".six-first-step",
  },
  {
    content:
      "In the search bar, enter the location of interest to find reported issues. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".six-second-step",
  },
  {
    content: (
      <h2>
        Scroll down to see the list of reported issues. Click the issue you want
        to propose a solution for. If the issue is not listed, you can report
        the issue. To report the issue, please follow the guidelines at this
        link: link to product tour for reporting issue.
      </h2>
    ),
    placement: "bottom",
    target: ".six-third-step",
  },
  {
    content: "Click “See full details”",
    placement: "right",
    target: ".six-fourth-step",
  },
  {
    content:
      "Scroll down to see solutions for the selected issues. Click +Add Solution.",
    placement: "right",
    target: ".six-fifth-step",
  },
  {
    content:
      "Enter information about the solution you want to propose and click save.",
    placement: "right",
    target: ".six-sixth-step",
  },
];

export const seventhScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        Help to ensure that attention is paid to the most relevant issues,
        solutions and action groups by reviewing and up-voting or down-voting.
        Here is how to quickly up-vote or down-vote a proposed solution.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the Solutions page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".seven-first-step",
  },
  {
    content:
      "Use the search bar to search for any location to find proposed solutions. To see only solutions that you proposed, click on the “My solutions” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".seven-second-step",
  },
  {
    content:
      "You can select one or more skills to see solutions that require such skills.",
    placement: "bottom",
    target: ".seven-third-step",
  },
  {
    content:
      "Solutions in the location you specified will be available here. Click on any solution card of interest to see more details and then up-vote or down-vote.",
    placement: "right",
    target: ".seven-fourth-step",
  },
  {
    content: "In the following screen, click See full details.",
    placement: "right",
    target: ".seven-fifth-step",
  },
  {
    content:
      "Click on Rating display in order to insert your rating for this soluton",
    placement: "right",
    target: ".seven-sixth-step",
  },
];

export const eighthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        search available action groups.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the Action Groups page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".eight-first-step",
  },
  {
    content:
      "Use the search bar to search for any location to find available action groups. To see only action groups that you created, click on the “My groups” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".eight-second-step",
  },
  {
    content:
      "You can select one or more skills to see action groups that require such skills.",
    placement: "bottom",
    target: ".eight-third-step",
  },
  {
    content:
      "Action groups in the location you specified will be available here. Click on any card to see more details.",
    placement: "right",
    target: ".eight-fourth-step",
  },
];

export const ninethScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        Help to ensure that attention is paid to the most relevant issues,
        solutions and action groups by reviewing and up-voting or down-voting.
        Here is how to quickly up-vote or down-vote an action group.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the Action Groups page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".nine-first-step",
  },
  {
    content:
      "Use the search bar to search for any location to find available action groups. To see only action groups that you created, click on the “My groups” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".nine-second-step",
  },
  {
    content:
      "You can select one or more skills to see action groups that require such skills.",
    placement: "bottom",
    target: ".nine-third-step",
  },
  {
    content:
      "Action groups in the location you specified will be available here. Click on any card to see more details and up-vote or down-vote.",
    placement: "right",
    target: ".nine-fourth-step",
  },
  {
    content:
      "In the following screen, click thumbs-up to give a positive review or thumbs-down to down-vote.",
    placement: "right",
    spotlightPadding: 15,
    target: ".nine-fifth-step",
  },
];

export const tenthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        search reported issues.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>
        First, we need to select the relevant issue. To select the relevant
        issue, go to the menu and navigate to the Issues page.
      </h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".ten-first-step",
  },
  {
    content:
      "Use the search bar to search for the location of the issue that you want to see available action groups for. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".ten-second-step",
  },
  {
    content: "You can select one or more issue categories",
    placement: "bottom",
    target: ".ten-third-step",
  },
  {
    content: (
      <h2>
        Scroll down to see the list of reported issues. Click the issue of your
        interest. If the issue is not listed, you can report the issue. To
        report the issue, please follow the guidelines at this link: link to
        product tour for reporting issue.
      </h2>
    ),
    placement: "right",
    target: ".ten-fourth-step",
  },
  {
    content: "Click “See full details”",
    placement: "right",
    target: ".ten-fifth-step",
  },
  {
    content: (
      <h2>
        Scroll down to see available solutions. Click on the solution for which
        you want to see available action groups. If the solution is not listed,
        you can propose the solution. To propose a solution, please follow the
        guidelines at this link: link to product tour for proposing solutions.
      </h2>
    ),
    placement: "right",
    target: ".ten-sixth-step",
  },
  {
    content:
      "You are now on the solutions page for this particular issue and solution. Scroll down to see available action groups. Click on show more to see more details.",
    placement: "right",
    target: ".ten-seventh-step",
  },
];

export const eleventhScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        WEattitude enables anyone to report issues, propose solutions for
        issues, and create groups to implement solutions. Let us see how to
        create an action group.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>
        First, we need to select the relevant issue. To select the relevant
        issue, go to the menu and navigate to the Issues page.
      </h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".eleven-first-step",
  },
  {
    content:
      "Use the search bar to search for the location of the issue that you want to create an action group for. To see only issues that you reported, click on the “My issues” button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".eleven-second-step",
  },
  {
    content: (
      <h2>
        Scroll down to see the list of reported issues. Click the issue of your
        interest. If the issue is not listed, you can report the issue. To
        report the issue, please follow the guidelines at this link: link to
        product tour for reporting issue.
      </h2>
    ),
    placement: "bottom",
    target: ".eleven-third-step",
  },
  {
    content: "Click “See full details”",
    placement: "right",
    target: ".eleven-fourth-step",
  },
  {
    content: (
      <h2>
        Scroll down to see available solutions. Click on the solution for which
        you want to create an action group for. If the solution is not listed,
        you can propose the solution. To propose a solution, please follow the
        guidelines at this link: link to product tour for proposing solutions.
      </h2>
    ),
    placement: "right",
    target: ".eleven-fifth-step",
  },
  {
    content:
      "You are now on the solutions page for this particular issue and solution. Scroll down and click +Add Group.",
    placement: "right",
    target: ".eleven-sixth-step",
  },
  {
    content:
      "Enter information about the action group you want to create and click save.",
    placement: "right",
    target: ".eleven-seventh-step",
  },
];

export const twelfthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        Learn how to generate a sheet showing multiple helpful resources in any
        community.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: <h2>Go to the menu and navigate to the Handout Sheets page.</h2>,
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".twelve-first-step",
  },
  {
    content:
      "In the search bar, enter the location of interest to find available resources then click the Find resources button.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".twelve-second-step",
  },
];

export const thirteenthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        View and share motivational videos and essays for most life situations.
        Here is how to search for motivational content.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>Go to the menu and navigate to the Motivational Contents page.</h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".thirteen-first-step",
  },
  {
    content:
      "You can select one or more topic categories to view relevant motivational content.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".thirteen-second-step",
  },
  {
    content:
      "To see only motivational contents that you created, click on My motivationals.",
    placement: "bottom",
    target: ".thirteen-third-step",
  },
  {
    content:
      "Motivational contents in the categories you selected will be available here. Click on any card to see more details.",
    placement: "bottom",
    target: ".thirteen-fourth-step",
  },
];

export const fourteenthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        View and share motivational videos and essays for most life situations.
        Here is how to create motivational content.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>Go to the menu and navigate to the Motivational Contents page.</h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".fourteen-first-step",
  },
  {
    content: "Click Create",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".fourteen-second-step",
  },
  {
    content:
      "Select one or more relevant categories for the motivational content you want to create.",
    placement: "bottom",
    target: ".fourteen-third-step",
  },
  {
    content:
      "Add an image for your essay or for videos, select a youtube link for videos. If you need to create the video from scratch or upload it to youtube, please see the tutorial at this page: https://www.youtube.com/watch?v=WkgOvUr5Alc. For your privacy, consider selecting to keep videos private on youtube.",
    placement: "right",
    target: ".fourteen-fourth-step",
  },
  {
    content: "Enter the title of your motivational content.",
    placement: "bottom",
    target: ".fourteen-fifth-step",
  },
  {
    content:
      "If you are creating a motivational essay, type your essay in this section and click publish.",
    placement: "bottom",
    target: ".fourteen-sixth-step",
  },
];

export const fifteenthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        View and share motivational videos and essays for most life situations.
        Here is how to edit a motivational content that you created.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>Go to the menu and navigate to the Motivational Contents page.</h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".fifteen-first-step",
  },
  {
    content:
      "You can select one or more topic categories to view relevant motivational content.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".fifteen-second-step",
  },
  {
    content:
      "To see motivational contents that you created, click on My motivationals. You can only edit content that you created.",
    placement: "bottom",
    target: ".fifteen-third-step",
  },
  {
    content: "Click on the motivational content that you want to edit.",
    placement: "bottom",
    target: ".fifteen-fourth-step",
  },
  {
    content:
      "If it is a motivational content that you created, you will see the Edit button. Click the Edit button to modify the content.",
    placement: "right",
    target: ".fifteen-fifth-step",
  },
];

export const sixteenthScenarioSteps: Step[] = [
  {
    content: (
      <h2>
        View and share motivational videos and essays for most life situations.
        Here is how to up-vote or down-vote a motivational content.
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">skip</strong> },
    placement: "center",
    target: "header",
  },
  {
    content: (
      <h2>Go to the menu and navigate to the Motivational Contents page.</h2>
    ),
    floaterProps: {
      disableAnimation: true,
    },

    spotlightPadding: 5,
    target: ".sixteen-first-step",
  },
  {
    content:
      "You can select one or more topic categories to view relevant motivational content.",
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".sixteen-second-step",
  },
  {
    content:
      "Once you identify the motivational content that you want to vote on, click thumbs up or thumbs down to vote-up or vote-down the content.",
    placement: "bottom",
    target: ".sixteen-third-step",
  },
];

export const fullProductTour: IProductTour[] = [
  {
    orderNumber: 1,
    title: "Search reported issues",
    scenarioStep: firstScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 2,
    title: "Report an Issue",
    scenarioStep: secondScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 3,
    title: "Up-vote or down-vote an issue",
    scenarioStep: thirdScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 4,
    title: "Search proposed solutions",
    scenarioStep: fourthScenarioSteps,
    startLocationPathname: APP_ROUTES.SOLUTION_LIST,
  },
  {
    orderNumber: 5,
    title: "Search proposed solutions for a specific issue.",
    scenarioStep: fifthScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 6,
    title: "Propose a solution",
    scenarioStep: sixthScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 7,
    title: "Up-vote or down-vote a solution",
    scenarioStep: seventhScenarioSteps,
    startLocationPathname: APP_ROUTES.SOLUTION_LIST,
  },
  {
    orderNumber: 8,
    title: "Search Action Group",
    scenarioStep: eighthScenarioSteps,
    startLocationPathname: APP_ROUTES.ACTION_GROUP_LIST,
  },
  {
    orderNumber: 9,
    title: "Up-vote or down-vote an action group",
    scenarioStep: ninethScenarioSteps,
    startLocationPathname: APP_ROUTES.ACTION_GROUP_LIST,
  },
  {
    orderNumber: 10,
    title: "Search available action groups for a specific issue and solution",
    scenarioStep: tenthScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 11,
    title: "Create Action Group",
    scenarioStep: eleventhScenarioSteps,
    startLocationPathname: APP_ROUTES.ISSUES,
  },
  {
    orderNumber: 12,
    title: "Handout Sheet",
    scenarioStep: twelfthScenarioSteps,
    startLocationPathname: APP_ROUTES.HANDOUTS,
  },
  {
    orderNumber: 13,
    title: "Motivational Content",
    scenarioStep: thirteenthScenarioSteps,
    startLocationPathname: APP_ROUTES.MOTIVATIONAL_LIST,
  },
  {
    orderNumber: 14,
    title: "Create motivational content.",
    scenarioStep: fourteenthScenarioSteps,
    startLocationPathname: APP_ROUTES.MOTIVATIONAL_LIST,
  },
  {
    orderNumber: 15,
    title: "Edit motivational content.",
    scenarioStep: fifteenthScenarioSteps,
    startLocationPathname: APP_ROUTES.MOTIVATIONAL_LIST,
  },
  {
    orderNumber: 16,
    title: "Up-vote or down-vote motivational content.",
    scenarioStep: sixteenthScenarioSteps,
    startLocationPathname: APP_ROUTES.MOTIVATIONAL_LIST,
  },
];
