import { memo } from "react";

interface IProps {
  title: string;
  body: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: React.Dispatch<
    React.SetStateAction<{
      title: string;
      body: string;
    }>
  >;
}

function PushNotificationContainer({
  title,
  body,
  setShow,
  setNotification,
}: IProps) {
  return (
    <div className="absolute top-[10px] left-[10px] min-w-[300px] flex flex-col p-4 rounded-lg gap-4 z-[200] bg-white shadow-md ">
      <div className="flex flex-row justify-between items-center">
        <strong>{title}</strong>
        <span
          className="cursor-pointer hover:text-gray-400"
          onClick={() => {
            setShow(false);
            setNotification({ title: "", body: "" });
          }}
        >
          x
        </span>
      </div>

      <p>{body}</p>
    </div>
  );
}

export default memo(PushNotificationContainer);
