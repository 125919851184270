import { APP_ROUTES } from "const";
import { useResetPasswordApi } from "hooks";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FIELD_NAMES } from "./CreateNewPasswordForm.constants";
import { ValuesType } from "./CreateNewPasswordForm.types";

export function useInitialState() {
  return useMemo(() => {
    return {
      [FIELD_NAMES.PASSWORD]: "",
      [FIELD_NAMES.NEW_PASSWORD]: "",
    };
  }, []);
}
export function useSubmitHandler(oobCode: string) {
  const { mutateAsync } = useResetPasswordApi({});
  const navigate = useNavigate();
  return useCallback(
    async (props: ValuesType, formikHelpers) => {
      try {
        const result = await mutateAsync({
          oobCode: oobCode,
          new_password: props.new_password,
        });
        if (result.status === 201) {
          navigate(`${APP_ROUTES.CONFIRM_EMAIL}?passwordConfirmed=true`);
        }
      } catch (error: any) {
        formikHelpers.setErrors(error.response.data);
      }
    },
    [mutateAsync, navigate, oobCode]
  );
}
