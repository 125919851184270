export enum APP_ROUTES {
  HOME = "/",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  CHANGE_PASSWORD = "/change-password",
  CONFIRM_EMAIL = "/confirm-email",
  CONFIRM_PASSWORD = "/confirm-password",
  FORGET_PASSWORD = "/forget-password",
  ISSUES = "/issues",
  MOTIVATIONAL_LIST = "/motivationals",
  MOTIVATIONAL = "/motivational",
  MOTIVATIONAL_CREATE = "/create-motivational",
  MOTIVATIONAL_EDIT = "/edit-motivational",
  ISSUE = "/issue",
  SOLUTION = "/solution",
  SOLUTION_LIST = "/solutions",
  ACTION_GROUP_LIST = "/action-groups",
  ACTION_GROUP = "/action-group",
  PROFILE = "/profile",
  DASHBOARD = "/dashboard",
  NOTFOUND = "/404",
  BLANK = "/blank",
  ERROR = "/error",
  HANDOUTS = "/handouts",
  ADMIN = "/admin",
}

export default APP_ROUTES;
