import { useMe, useQueryOptionsSetter } from "hooks";
import { getGeocode } from "use-places-autocomplete";
import { useIssueList } from "modules/IssueList/IssueList.hooks";
import { useSolutionList } from "modules/SolutionList/SolutionList.hooks";
import { DashboardActivitiesCard } from "./DashboardActivitiesCard";
import { DashboardIssuesCard } from "./DashboardIssuesCard";
import { DashboardSolutionsCard } from "./DashboardSolutionsCard";
import { useGeolocated } from "react-geolocated";
import { useEffect, useRef, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { IOutletAuth } from "types";
import { ReputationScore } from "components/ReputationScore/ReputationScore";

function Dashboard() {
  const authContext = useOutletContext<IOutletAuth>();
  const { data: meData } = useMe();
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
      suppressLocationOnMount: true,
    });

  const [userLoactionCoordinates, setUserLocationCoordinates] = useState({
    lat: "",
    lng: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  useQueryOptionsSetter(searchParams, setSearchParams);
  const placeId = searchParams.get("placeId") || "";

  const positionIsFetched = useRef(false);

  useEffect(() => {
    if (
      !positionIsFetched.current &&
      (!meData?.data?.lat || !meData?.data?.lng)
    ) {
      if (isGeolocationAvailable) {
        positionIsFetched.current = true;
        getPosition();
      }
    }
  }, [
    meData?.data?.lng,
    meData?.data?.lat,
    isGeolocationAvailable,
    getPosition,
  ]);

  useEffect(() => {
    if (
      isGeolocationAvailable &&
      isGeolocationEnabled &&
      coords &&
      (!meData?.data?.lat || !meData?.data?.lng) &&
      !placeId
    ) {
      setUserLocationCoordinates({
        lat: String(coords.latitude),
        lng: String(coords.longitude),
      });
    }
  }, [
    coords,
    placeId,
    isGeolocationAvailable,
    isGeolocationEnabled,
    meData?.data?.lng,
    meData?.data?.lat,
  ]);

  useEffect(() => {
    if (!placeId && meData?.data?.lat && meData?.data?.lng) {
      setUserLocationCoordinates({
        lat: meData?.data?.lat,
        lng: meData?.data?.lng,
      });
    }
  }, [placeId, meData?.data?.lat, meData?.data?.lng]);

  const { data: solutionsData } = useSolutionList(
    {
      user: "",
      skills: [],
      category: [],
      tags: [],
      zoom: 9,
      lat: userLoactionCoordinates.lat || "",
      lng: userLoactionCoordinates.lng || "",
    },
    1045,
    3
  );
  const { data: issuesData } = useIssueList(
    {
      user: "",
      skills: [],
      tags: [],
      category: [],
      zoom: 9,
      lat: userLoactionCoordinates.lat || "",
      lng: userLoactionCoordinates.lng || "",
    },
    1045,

    3
  );

  useEffect(() => {
    if (placeId) {
      getGeocode({ placeId }).then((result) => {
        const latlng = result[0].geometry.location;
        const lat = String(latlng?.lat());
        const lng = String(latlng?.lng());
        setUserLocationCoordinates({
          lat: lat,
          lng: lng,
        });
      });
    }
  }, [placeId]);

  useEffect(() => {
    if (
      !authContext.isAuth &&
      (!placeId || placeId.length === 0) &&
      !isGeolocationEnabled
    ) {
      setUserLocationCoordinates({
        lat: "",
        lng: "",
      });
    }
  }, [placeId, authContext.isAuth, isGeolocationEnabled]);

  return (
    <div className="  pt-12 pb-4 max-w-[1600px]">
      <p className="font-bold">Your WEattitude.org Dashboard</p>
      {/* TODO - ADD MORE ICONS */}
      <ReputationScore
        score={meData?.data.reputationScore}
        classNames={{ icon: " w-10 max-h-10 " }}
        place="right"
        className="pt-1"
      />
      <div className="grid pt-3 lg:grid-cols-3 gap-6 maxSm:px-1">
        <DashboardActivitiesCard />
        <DashboardSolutionsCard
          params={{
            lat: userLoactionCoordinates.lat || "",
            lng: userLoactionCoordinates.lng || "",
          }}
          solutions={solutionsData?.pages[0].data.items || []}
        />
        <DashboardIssuesCard
          params={{
            lat: userLoactionCoordinates.lat || "",
            lng: userLoactionCoordinates.lng || "",
          }}
          issues={issuesData?.pages[0].data.items || []}
        />
      </div>
    </div>
  );
}
export default Dashboard;
