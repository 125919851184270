import { memo, useState } from "react";
import FirstLevelMarkerRegular from "assets/images/markerDefault.svg";

import ThirdLevelMarkerRegular from "assets/images/markerHighlight.svg";
import classnames from "classnames";
export interface IMapProps {
  lat: number;
  lng: number;
  label?: string;
  onTop?: boolean;
  numberOfItems?: number;
  issue?: string | string[];
  setHoveredIssueId?: React.Dispatch<
    React.SetStateAction<string | undefined | string[]>
  >;
  onClick?: () => void;
}

export function MapMarker({
  numberOfItems = 1,
  onTop = false,
  issue,
  onClick,
  setHoveredIssueId,
}: IMapProps) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onClick={onClick}
      style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
      onMouseOver={() => {
        setIsHovered(true);
        setHoveredIssueId && setHoveredIssueId(issue);
      }}
      onMouseOut={() => {
        setIsHovered(false);
        setHoveredIssueId && setHoveredIssueId(undefined);
      }}
      className={classnames(
        "flex flex-col justify-center w-[30px] h-[30px] rounded-full",
        {
          "z-30": !!onTop,
        }
      )}
    >
      <div className="relative">
        <img
          className=" w-[30px] h-[30px] opacity-100 bg-white rounded-full"
          alt="marker "
          src={
            isHovered || !!onTop
              ? ThirdLevelMarkerRegular
              : FirstLevelMarkerRegular
          }
        />
        {!!numberOfItems && (
          <span
            className={classnames("absolute  text-white font-black z-10", {
              "top-[26%] left-[19%]": numberOfItems.toString().length > 2,
              "top-[13%] left-[17%]": numberOfItems.toString().length === 2,
              "top-[13%] left-[34%]": numberOfItems.toString().length <= 1,
              "text-md": numberOfItems.toString().length > 2,
              "text-[1rem] leading-normal":
                numberOfItems.toString().length <= 2,
            })}
          >
            {numberOfItems < 100 ? numberOfItems : "99+"}
          </span>
        )}
      </div>
    </div>
  );
}
export default memo(MapMarker);
