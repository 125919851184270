import React from "react";
import classnames from "classnames";
import { ColorBorderWrapper } from "components/ColorBorderWrapper";

export interface IProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  isError?: boolean;
  classNames?: {
    wrapper?: string;
  };
}

function Textarea({
  className,
  customRef,
  isError,
  classNames,
  ...rest
}: IProps) {
  const classes = classnames(
    className,
    "w-full h-full focus:placeholder-opacity-0 rounded-[8px] border-0 block   ",
    {
      " select-none  resize-none bg-gray-300": rest.disabled,
    },
    {
      "!bg-transparent !placeholder-gray-700 !font-normal select-none":
        rest.readOnly,
    }
  );
  return (
    <ColorBorderWrapper
      isError={!!isError}
      noAnimation
      className={classnames(
        "h-full box !overflow-y-auto",
        classNames?.wrapper,
        {
          "!bg-transparent": rest.readOnly,
        }
      )}
    >
      <textarea
        ref={customRef}
        {...rest}
        className={classes}
        value={rest.value || ""}
      />
    </ColorBorderWrapper>
  );
}

export default Textarea;
