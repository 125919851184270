import React, { useState } from "react";
import classnames from "classnames";
import "./InputElement.styles.css";
import { ColorBorderWrapper } from "components";
import { twMerge } from "tailwind-merge";

export interface InputElementProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isError?: boolean;
  forUrlInput?: boolean;
  startElement?: React.ReactNode;
  classNames?: {
    mainContainer?: string;
    inputContainer?: string;
  };
}

function InputElement({
  className,
  classNames,
  startElement,
  isError,
  ...rest
}: InputElementProps) {
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);

  return (
    <div className={twMerge(classnames("relative", classNames?.mainContainer))}>
      <ColorBorderWrapper
        noAnimation={rest.forUrlInput}
        isError={!!isError}
        className={classnames(
          classNames?.inputContainer,
          "h-full flex flex-row items-center",
          {
            "bg-gray-300": rest.forUrlInput && !rest.readOnly,
            "!bg-transparent": rest.readOnly,
          }
        )}
      >
        <div className="absolute translate-y-1/2 bottom-[50%] left-4">
          {startElement}
        </div>
        {rest.forUrlInput && (
          <div className="z-10 block  bg-[#F9FAFB] h-full rounded-r-none rounded-md border-l-0 border-t-0 border-b-0 border border-r-[1px] focus:outline-none border-gray-300 pointer-events-none select-none">
            <p className="px-3 py-[9.3px] h-full text-[#6B7280] text-sm">
              https://
            </p>
          </div>
        )}
        <input
          className={twMerge(
            classnames(
              "h-full",
              className,
              {
                "appearance-none block w-full px-3 py-2.5  placeholder-gray-400  text-sm  rounded-[6px] border-0":
                  !className,
                "!bg-transparent !placeholder-gray-700": rest.readOnly,
                "pr-10": rest.type === "password",
                "rounded-l-none": rest.forUrlInput,
              },
              { "!px-0": rest.readOnly },
              "focus:outline-none  "
            )
          )}
          {...rest}
          type={!showPasswordIcon ? "text" : rest.type}
          placeholder={!rest.disabled ? rest.placeholder : "No data"}
        />
      </ColorBorderWrapper>
      <div
        className={classnames(
          "absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ",
          {
            hidden: rest.type !== "password",
          }
        )}
      >
        <label
          onClick={() => {
            setShowPasswordIcon((val) => !val);
          }}
          className={classnames(
            "bg-[#EFEFEF]   rounded px-2 py-1 text-sm text-[#606060] font-mono cursor-pointer js-password-label"
          )}
          htmlFor="toggle"
        >
          {showPasswordIcon ? "show" : "hide"}
        </label>
      </div>
    </div>
  );
}

export default InputElement;
