import { APP_ROUTES, ENDPOINTS } from "const";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { FullActionGroupType, IActionGroupUsers, IUser } from "types";

import {
  Card,
  ButtonRound,
  MembersPopup,
  WarningModal,
  Loader,
} from "components";

import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";

import { ReactComponent as Approve } from "assets/images/button-icon-approve.svg";
import { ReactComponent as Decline } from "assets/images/button-icon-decline.svg";
import { ReactComponent as Pending } from "assets/images/button-icon-pending.svg";

import classnames from "classnames";
import { useActionGroupUsers, useMe, useQueryOptionsSetter } from "hooks";

import { useQueryClient } from "react-query";

import { showErrorMessage, showSuccessMessage } from "utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useActionGroupUsersList,
  useDeleteActionGroupUser,
  useUpdateActionGroupUser,
} from "../ActionGroup.hooks";
import { useCreateActionGroupUser } from "modules/ProfileForm/ProfileTabs/ProfileActionGroups";

interface IProps {
  actionGroup: FullActionGroupType | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  allowEdit?: boolean;
  isSoftDeleted?: boolean;
}

interface MembershipSelectProps {
  defaultMembership: string | undefined;

  onChange: (role: IActionGroupUsers["role"]) => void;
}

function MembershipSelect({
  defaultMembership,
  onChange,
}: MembershipSelectProps) {
  const [localMembership, setLocalMembership] = useState(
    defaultMembership || "member"
  );
  return (
    <select
      className="py-0 pl-1 pr-7 rounded-md custom-select-icon  "
      value={localMembership}
      onChange={(e) => {
        setLocalMembership(e.target.value);
        onChange(e.target.value as IActionGroupUsers["role"]);
      }}
    >
      <option value="admin">Admin</option>
      <option value="member">Member</option>
    </select>
  );
}

function UsersCard({ allowEdit = false, actionGroup, isSoftDeleted }: IProps) {
  const { data: me } = useMe();

  const { data: isMemberList } = useActionGroupUsers({
    actionGroup: actionGroup?.id,
    user: me?.data.id,
    suspense: true,
  });
  const isGroupOwner =
    !!me &&
    actionGroup?.actionGroupUsers?.find(
      (aUser) =>
        aUser.user?.id === me.data.id &&
        (aUser.role === "admin" || aUser.role === "creator")
    );
  const { data: actionGroupUsers, inViewRef } = useActionGroupUsersList({
    actionGroup: actionGroup?.id,
    suspense: true,
    status: !isGroupOwner ? "approved" : undefined,
  });

  const isMemberOfTheGroup = isMemberList?.data.items?.[0];
  const navigate = useNavigate();
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useQueryOptionsSetter(searchParams, setSearchParams);

  const queryClient = useQueryClient();

  const { mutateAsync: mutateCreateActionGroupUserAsync } =
    useCreateActionGroupUser({});
  const { mutateAsync: mutateUpdateActionGroupUserAsync } =
    useUpdateActionGroupUser();
  const { mutateAsync: mutateDeleteActionGroupUserAsync } =
    useDeleteActionGroupUser({});
  const [actionPending, setActionPending] = useState(false);

  const [showMembersPopup, setShowMembersPopup] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState<null | Partial<
    IActionGroupUsers<any, any>
  >>(null);
  const [currentUserToDecline, setCurrentUserToDecline] = useState<
    Partial<IActionGroupUsers<Partial<IUser>>> | undefined
  >(undefined);
  const updateUser = useCallback(
    (user: Partial<IActionGroupUsers<Partial<IUser>, any>> | undefined) => {
      mutateUpdateActionGroupUserAsync(user)
        .then((res) => {
          queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
          queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUP_USERS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          showSuccessMessage(
            `Action group user ${res?.data.user?.firstName} ${res.data?.user?.lastName} has been updated!`
          );
          setCurrentUserToDecline(undefined);
          setOpenWarningModal(null);
        })
        .catch(() => {
          setCurrentUserToDecline(undefined);
          setOpenWarningModal(null);
          showErrorMessage("Error while declining a user!");
        });
    },
    [mutateUpdateActionGroupUserAsync, queryClient]
  );

  useEffect(() => {
    if (!openWarningModal) {
      setCurrentUserToDecline(undefined);
    }
  }, [openWarningModal]);

  const membersList = useMemo(() => {
    const list = actionGroupUsers?.pages.reduce((acc, page) => {
      return [...acc, ...page.data.items];
    }, [] as IActionGroupUsers<IUser<any>>[]);
    if (isMemberOfTheGroup) {
      const filtered = list?.filter((i) => i.id !== isMemberOfTheGroup.id); // put member on top
      filtered?.unshift(isMemberOfTheGroup);
      return filtered;
    }

    return list;
  }, [actionGroupUsers?.pages, isMemberOfTheGroup]);
  const membersCount = actionGroupUsers?.pages[0].data?.meta?.totalItems;
  return (
    <Card
      className={classnames("bg-white !gap-0", {
        "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
      })}
      shouldHover={false}
      collapse={descriptionCollapsed}
      header={
        <div className="flex  justify-between items-center pb-1">
          <div className="grid grid-flow-col gap-x-2 items-center justify-between flex-1 h-[46px] shrink-0">
            <div className="flex flex-row flex-wrap gap-x-1 sm:gap-2">
              <img
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": descriptionCollapsed,
                })}
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">Members</span>
              {membersCount !== undefined && (
                <div className=" relative self-start">
                  <p
                    onTouchStart={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (Number(membersCount) > 0) setShowMembersPopup(true);
                    }}
                    onClick={() => {
                      if (Number(membersCount) > 0) setShowMembersPopup(true);
                    }}
                    className="  font-normal text-[#999999] hover:text-black cursor-pointer line-clamp-2"
                  >{`(${membersCount} ${
                    membersCount === 1 ? "member" : "members"
                  })`}</p>
                  {showMembersPopup && (
                    <MembersPopup
                      membersCount={String(membersCount)}
                      membersList={membersList || []}
                      setShow={setShowMembersPopup}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-row justify-between items-center w-full">
              {!isGroupOwner && me?.data.id && (
                <>
                  {!!isMemberOfTheGroup ? (
                    <Button
                      disabled={actionPending}
                      inGroupStyle={false}
                      type="button"
                      className="maxmxs:!px-1.5 maxmxs:!py-1.5"
                      onTouchStart={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (me?.data.id) {
                          setActionPending(true);
                          mutateDeleteActionGroupUserAsync(
                            isMemberOfTheGroup.id
                          )
                            .then(() => {
                              queryClient.invalidateQueries([
                                ENDPOINTS.ACTION_GROUPS,
                              ]);

                              queryClient.invalidateQueries([
                                ENDPOINTS.ACTION_GROUP_USERS,
                              ]);

                              queryClient.invalidateQueries([
                                ENDPOINTS.SOLUTIONS,
                              ]);

                              showSuccessMessage(
                                `You have left the group ${actionGroup?.title}!`
                              );
                              setActionPending(false);
                            })
                            .catch(() => {
                              showErrorMessage("Error while joining group!");
                              setActionPending(false);
                            });
                        }
                      }}
                      onClick={() => {
                        if (me?.data.id) {
                          setActionPending(true);
                          mutateDeleteActionGroupUserAsync(
                            isMemberOfTheGroup.id
                          )
                            .then(() => {
                              queryClient.invalidateQueries([
                                ENDPOINTS.ACTION_GROUPS,
                                { exact: false },
                              ]);

                              queryClient.invalidateQueries(
                                [ENDPOINTS.ACTION_GROUP_USERS],
                                { exact: false }
                              );

                              queryClient.invalidateQueries([
                                ENDPOINTS.SOLUTIONS,
                                { exact: false },
                              ]);

                              showSuccessMessage(
                                `You have left the group ${actionGroup?.title}!`
                              );
                              setActionPending(false);
                            })
                            .catch(() => {
                              showErrorMessage("Error while joining group!");
                              setActionPending(false);
                            });
                        }
                      }}
                    >
                      Leave Group
                    </Button>
                  ) : (
                    <Button
                      disabled={actionPending}
                      inGroupStyle={false}
                      isStyleless
                      isReverse
                      className="text-[black] border hover:border-black border-gray-500 maxmxs:!px-1.5 maxmxs:!py-1.5"
                      type="button"
                      onTouchStart={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActionPending(true);
                        mutateCreateActionGroupUserAsync({
                          actionGroup: actionGroup?.id,
                          user: me?.data.id,
                          status: "pending",
                        })
                          .then(() => {
                            queryClient.invalidateQueries([
                              ENDPOINTS.ACTION_GROUPS,
                              { exact: false },
                            ]);

                            queryClient.invalidateQueries([
                              ENDPOINTS.ACTION_GROUP_USERS,
                              { exact: false },
                            ]);

                            queryClient.invalidateQueries([
                              ENDPOINTS.SOLUTIONS,
                              { exact: false },
                            ]);

                            showSuccessMessage(
                              `You have joined the group ${actionGroup?.title}!`
                            );
                            setActionPending(false);
                          })
                          .catch(() => {
                            showErrorMessage("Error while joining group!");
                            setActionPending(false);
                          });
                      }}
                      onClick={() => {
                        setActionPending(true);
                        mutateCreateActionGroupUserAsync({
                          actionGroup: actionGroup?.id,
                          user: me?.data.id,
                          status: "pending",
                        })
                          .then(() => {
                            queryClient.invalidateQueries(
                              [ENDPOINTS.ACTION_GROUPS],
                              {
                                exact: false,
                              }
                            );
                            queryClient.invalidateQueries(
                              [ENDPOINTS.ACTION_GROUP_USERS],
                              {
                                exact: false,
                              }
                            );
                            queryClient.invalidateQueries(
                              [ENDPOINTS.SOLUTIONS],
                              {
                                exact: false,
                              }
                            );

                            showSuccessMessage(
                              `You have joined the group ${actionGroup?.title}!`
                            );
                            setActionPending(false);
                          })
                          .catch(() => {
                            showErrorMessage("Error while joining group!");
                            setActionPending(false);
                          });
                      }}
                    >
                      Join Group
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      }
      classNames={{
        header: "px-6 sm:px-16 ",
        content: "!px-0 flex-1",
        footer: "px-6 sm:px-16 h-[60px]",
      }}
      // const isLast =
      //             data?.pages.length === pagesIndex + 1 &&
      //             page.data.items.length === issueIndex + 1;
      showFooterDivider={false}
      content={
        <div className="max-h-[400px] overflow-y-auto overflow-x-hidden  ">
          {isGroupOwner && (
            <div className="flex flex-col   ">
              {membersList && membersList.length > 0 ? (
                membersList.map((user, index) => {
                  const isLast = membersList.length === index + 1;
                  return (
                    <div
                      ref={isLast ? inViewRef : undefined}
                      key={user.id}
                      className={classnames(
                        " px-6 py-2 sm:px-16 flex flex-row justify-between gap-2 items-center w-full border border-gray-200 hover:bg-gray-100",
                        { "border-t-0": index === 0 }
                      )}
                    >
                      <div className="flex flex-row gap-2 sm:gap-3 lg:gap-8 xl:gap-14 items-center">
                        <div className="relative h-[40px] w-[40px] sm:h-[80px] sm:w-[80px] rounded-full">
                          <img
                            alt="avatar"
                            className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px] rounded-full object-cover"
                            src={user?.user?.avatar}
                          />
                          {user.status === "pending" && (
                            <Pending className="absolute bg-white bottom-[-5px] right-[-5px] h-[16px] w-[16px] sm:h-[32px] sm:w-[32px] rounded-full" />
                          )}
                          {user.status === "approved" && (
                            <Approve className="absolute bg-white bottom-[-5px] right-[-5px] h-[16px] w-[16px] sm:h-[32px] sm:w-[32px] rounded-full" />
                          )}
                        </div>
                        <div
                          onClick={() => {
                            navigate(APP_ROUTES.PROFILE + "/" + user?.user?.id);
                          }}
                          className="flex flex-row flex-wrap gap-2 cursor-pointer hover:text-black"
                        >
                          <div>{user?.user?.firstName}</div>
                          <div>{user?.user?.lastName}</div>
                        </div>
                      </div>

                      {user?.role && !allowEdit && (
                        <div className="flex flex-row flex-wrap justify-end gap-4 capitalize text-gray-500 border border-gray-500 py-1 px-2 rounded">
                          Group {user?.role}
                        </div>
                      )}
                      {allowEdit && (
                        <>
                          {user?.user?.id !== me?.data.id && (
                            <div className="flex flex-row flex-wrap justify-end gap-4">
                              {user?.user?.id && actionGroup?.id && (
                                <MembershipSelect
                                  defaultMembership={user?.role}
                                  onChange={(role) => {
                                    updateUser({
                                      id: user.id,
                                      role,
                                    });
                                  }}
                                />
                              )}
                              {user.status === "pending" && (
                                <ButtonRound
                                  type="button"
                                  onClick={() => {
                                    if (user?.user?.id)
                                      updateUser({
                                        id: user.id,
                                        status: "approved",
                                      });
                                  }}
                                >
                                  <Approve className="opacity-80 hover:opacity-100" />
                                </ButtonRound>
                              )}

                              <ButtonRound
                                type="button"
                                onClick={() => {
                                  if (user?.user?.id) {
                                    setOpenWarningModal(user);
                                    setCurrentUserToDecline(user);
                                    // updateUser(user.user.id, user, "declined");
                                  }
                                }}
                              >
                                <Decline className="opacity-80 hover:opacity-100" />
                              </ButtonRound>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center px-16 py-6 w-full">
                  <span className="text-black font-bold text-lg">
                    No users yet
                  </span>
                </div>
              )}
            </div>
          )}
          {!isGroupOwner && (
            <div className="flex flex-col   ">
              {membersList && membersList.length > 0 ? (
                membersList.map((user, index) => {
                  const isLast = membersList.length === index + 1;
                  return (
                    <div
                      ref={isLast ? inViewRef : undefined}
                      key={user.id}
                      className={classnames(
                        " px-6 py-2 sm:px-16 flex flex-row justify-between gap-2 items-center w-full border border-gray-200 hover:bg-gray-100",
                        {
                          "border-t-0": index === 0,
                        }
                      )}
                    >
                      <div className="flex flex-row gap-2 sm:gap-3 lg:gap-8 xl:gap-14 items-center">
                        <div className="relative h-[40px] w-[40px] sm:h-[80px] sm:w-[80px] rounded-full">
                          <img
                            alt="avatar"
                            className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px] rounded-full object-cover"
                            src={user?.user?.avatar}
                          />

                          {user?.user?.id === me?.data.id &&
                            user.status === "pending" && (
                              <Pending className="absolute bg-white bottom-[-5px] right-[-5px] h-[16px] w-[16px] sm:h-[32px] sm:w-[32px]  rounded-full" />
                            )}
                          {user?.user?.id === me?.data.id &&
                            user.status === "approved" && (
                              <Approve className="absolute bg-white bottom-[-5px] right-[-5px] h-[16px] w-[16px] sm:h-[32px] sm:w-[32px]  rounded-full" />
                            )}
                        </div>
                        <div
                          onClick={() => {
                            navigate(APP_ROUTES.PROFILE + "/" + user?.user?.id);
                          }}
                          className="flex flex-row flex-wrap gap-2 cursor-pointer hover:text-black"
                        >
                          <div>{user?.user?.firstName}</div>
                          <div>{user?.user?.lastName}</div>
                        </div>
                      </div>
                      {user?.role && !allowEdit && (
                        <div className="flex flex-row flex-wrap justify-end gap-4 capitalize text-gray-500 border border-gray-500 py-1 px-2 rounded">
                          Group {user?.role}
                        </div>
                      )}
                      {allowEdit && (
                        <>
                          {user?.user?.id !== me?.data.id && (
                            <ButtonRound
                              type="button"
                              onClick={() => {
                                if (me?.data.id) {
                                  setOpenWarningModal(user);
                                  setCurrentUserToDecline(user);
                                }
                              }}
                            >
                              <Decline />
                            </ButtonRound>
                          )}
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center px-16 py-6 w-full">
                  <span className="text-black font-bold text-lg">
                    No users yet
                  </span>
                </div>
              )}
            </div>
          )}
          {!!openWarningModal && (
            <WarningModal
              setShow={(show: boolean) => {
                if (!show) {
                  setOpenWarningModal(null);
                }
              }}
              actionCallback={() => {
                if (currentUserToDecline && currentUserToDecline.id) {
                  updateUser({ id: openWarningModal.id, status: "declined" });
                }
              }}
            />
          )}
        </div>
      }
    />
  );
}

const suspended = (props: IProps) => (
  <Suspense fallback={<Loader />}>
    <UsersCard {...props} />
  </Suspense>
);

export default suspended;
