import {
  IIssueRatingCategory,
  ISelectOption,
  IUser,
  IUserSkill,
  ImageType,
} from "types";
import { buildCategories, buildSkills } from "./ProfileForm.utills";

export enum FIELD_NAMES {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ABOUT = "about",
  IS_PRIVATE = "isPrivate",
  EMAIL = "email",
  SKILLS = "skills",
  AVATAR = "avatar",
  PRIMARY_LOCATION = "primaryLocation",
  MEDIA_LINKS = "mediaLinks",
  CATEGORIES = "issueRatingCategories",
  REPUTATION_SCORE = "reputationScore",
}

export class ProfileFormInitial {
  [FIELD_NAMES.AVATAR]: ImageType | null | string = null; ///
  [FIELD_NAMES.EMAIL] = ""; //
  [FIELD_NAMES.PRIMARY_LOCATION]: ISelectOption | null = null;
  [FIELD_NAMES.FIRST_NAME]: string = "";
  [FIELD_NAMES.LAST_NAME]: string = "";
  [FIELD_NAMES.ABOUT]: string = "";
  [FIELD_NAMES.IS_PRIVATE]: boolean = false;
  [FIELD_NAMES.SKILLS]: (ISelectOption<
    string | undefined,
    string | undefined
  > & { description: string | undefined })[] = [];
  [FIELD_NAMES.MEDIA_LINKS]: string[] = [""];
  [FIELD_NAMES.CATEGORIES]: (ISelectOption<
    string | undefined,
    string | undefined
  > & { description: string | undefined })[] = [];
  [FIELD_NAMES.REPUTATION_SCORE]: number | undefined = undefined;
  constructor(
    user: Partial<IUser> | undefined,
    userSkills: Partial<IUserSkill>[] | undefined
  ) {
    this.addAvatar(user?.avatar);
    this.addEmail(user?.email);
    this.addLocation(user?.formattedAddress, user?.placeId);
    this.addFirstName(user?.firstName);
    this.addLastName(user?.lastName);
    this.addIsPrivate(user?.isPrivate);
    this.addAbout(user?.about);
    this.addSkills(userSkills);
    this.addMediaLinks(user?.mediaLinks);
    this.addCategories(user?.issueRatingCategories);
    this.addScore(user?.reputationScore);
  }

  addLocation(
    formattedAddress: string | undefined,
    placeId: string | undefined
  ) {
    if (formattedAddress && placeId) {
      this[FIELD_NAMES.PRIMARY_LOCATION] = {
        label: formattedAddress,
        value: placeId,
      };
    }
  }
  addMediaLinks(mediaLinks: string[] | undefined) {
    if (mediaLinks?.length) {
      this[FIELD_NAMES.MEDIA_LINKS] = mediaLinks;
    }
  }
  addAvatar(avatar: null | string | undefined) {
    if (avatar) {
      this[FIELD_NAMES.AVATAR] = avatar;
    }
  }
  addFirstName(firstName: string | undefined) {
    if (firstName) {
      this[FIELD_NAMES.FIRST_NAME] = firstName;
    }
  }
  addLastName(lastName: string | undefined) {
    if (lastName) {
      this[FIELD_NAMES.LAST_NAME] = lastName;
    }
  }
  addAbout(about: string | undefined) {
    if (about) {
      this[FIELD_NAMES.ABOUT] = about;
    }
  }
  addIsPrivate(isPrivate: boolean | undefined) {
    if (isPrivate) {
      this[FIELD_NAMES.IS_PRIVATE] = isPrivate;
    }
  }
  addEmail(email: string | undefined) {
    if (email) {
      this[FIELD_NAMES.EMAIL] = email;
    }
  }
  addSkills(userSkills: Partial<IUserSkill>[] | undefined) {
    if (userSkills?.length) {
      this[FIELD_NAMES.SKILLS] = buildSkills(userSkills);
    }
  }
  addCategories(categories: IIssueRatingCategory[] | undefined) {
    if (categories?.length) {
      this[FIELD_NAMES.CATEGORIES] = buildCategories(categories);
    }
  }
  addScore(score: number | undefined) {
    if (score) {
      this[FIELD_NAMES.REPUTATION_SCORE] = score;
    }
  }
}

export class ProfileFormPayload {
  [FIELD_NAMES.AVATAR]: File | null | undefined | string = null; ///
  [FIELD_NAMES.EMAIL] = ""; //
  [FIELD_NAMES.PRIMARY_LOCATION]: ISelectOption | null = null;
  [FIELD_NAMES.FIRST_NAME]: string = "";
  [FIELD_NAMES.LAST_NAME]: string = "";
  [FIELD_NAMES.ABOUT]: string = "";
  [FIELD_NAMES.IS_PRIVATE]: boolean = false;
  [FIELD_NAMES.SKILLS]: string[] = [];
  [FIELD_NAMES.MEDIA_LINKS]: string[] = [""];
  [FIELD_NAMES.CATEGORIES]: string[] = [];
  placeId: string | undefined = "";

  constructor({
    avatar,
    skills,
    primaryLocation,
    issueRatingCategories,
    ...data
  }: ProfileFormInitial) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
    this.addAvatar(avatar);
    this.addSkills(skills);
    this.addPlaceId(primaryLocation);
    this.addCategories(issueRatingCategories);
  }

  addAvatar(avatar: null | string | undefined | ImageType) {
    if (avatar) {
      this[FIELD_NAMES.AVATAR] = avatar;
    }
  }
  addSkills(skills: ProfileFormInitial["skills"]) {
    if (skills.length) {
      this[FIELD_NAMES.SKILLS] = skills
        .map((i) => i.value)
        .filter((i) => i) as string[];
    }
  }
  addPlaceId(primaryLocation: ISelectOption | null) {
    if (primaryLocation) {
      this.placeId = primaryLocation.value as string;
    }
  }
  getUser() {
    const user = {};
    Object.entries(this).forEach(([key, value]) => {
      user[key] = value;
    });
    delete user[FIELD_NAMES.SKILLS];
    //@ts-ignore
    if (user?.avatar === "") {
      //@ts-ignore
      delete user?.avatar;
    }
    return user;
  }
  getSkills() {
    return [...this[FIELD_NAMES.SKILLS]];
  }
  addCategories(categories: ProfileFormInitial["issueRatingCategories"]) {
    if (categories?.length) {
      this[FIELD_NAMES.CATEGORIES] = categories.map((i) => i.value || "");
    }
  }
}
