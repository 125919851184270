import { memo } from "react";
import classnames from "classnames";

interface IProps {
  createdAt: string | undefined;

  showCreatedAt?: boolean;
  customContent?: React.ReactNode;
  className?: string;
  classNames?: { avatar?: string; name?: string; dotSeparator?: string };
}

function ActionGroupProfileBlock({
  createdAt,
  className,
  classNames,
  customContent,
  showCreatedAt = true,
}: IProps) {
  return (
    <div
      className={classnames(
        "flex items-center justify-start gap-1 flex-1 w-full",
        className
      )}
    >
      <div
        className={classnames(
          " min-w-0 flex flex-row gap-4 justify-between   flex-1",
          classNames?.name
        )}
      >
        {!!customContent && <div className=" relative ">{customContent}</div>}
        <span
          className={classnames(
            "rounded-full mt-2.5 h-1 w-1 bg-[#666666]  ",
            classNames?.dotSeparator
          )}
        />
        {showCreatedAt && (
          <span className="text-sm sekf-start  text-gray-400 group-hover:text-gray-700 font-light leading-5 overflow-ellipsis overflow-hidden whitespace-nowrap ">
            {createdAt}
          </span>
        )}
      </div>
    </div>
  );
}

export default memo(ActionGroupProfileBlock);
