import classnames from "classnames";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useElementScroll, useScrollOnDrag, useTags } from "hooks";

interface IMotivationalTagsProps {
  className?: string;
  setValue?: (value: any) => void;
  tags: string[] | undefined;
  error?: string;

  classNames?: {
    error: string;
  };
}
const defaultTags: string[] = [];

export function MotivationalTags({
  className,
  classNames,
  tags = defaultTags,
  error,
  setValue,
}: IMotivationalTagsProps) {
  const formValues = tags;

  const { data } = useTags({
    suspense: false,
    keepPreviousData: true,
  });
  const preparedData = data?.data;

  const options = useMemo(() => {
    return _.compact(
      preparedData?.map((item) => ({
        label: item.name,
        value: item.id || "",
      }))
    );
  }, [preparedData]);
  const filteredTags = useMemo(() => {
    return tags?.filter((i) => {
      return i;
    });
  }, [tags]);

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { scrollable, scrollableToLeft, scrollableToRight } =
    useElementScroll(element);
  useScrollOnDrag(element);
  useEffect(() => {
    if (scrollable) {
      element?.classList.toggle("is-left-overflowing", scrollableToLeft);
      element?.classList.toggle("is-right-overflowing", scrollableToRight);
    }
  }, [scrollable, element, scrollableToLeft, scrollableToRight]);
  return (
    <div className="flex flex-col ">
      <div
        ref={(el) => {
          setElement(el);
        }}
        className={classnames(
          " dragging-group fourteen-third-step flex flex-row gap-x-5 gap-y-1  px-5 pb-[16px] pt-2 hide-scroll cursor-pointer fade ",
          className,
          {
            "pb-7": !error,
          }
        )}
      >
        <div className="relative flex gap-2 flex-wrap mt-4 w-full">
          <div className="flex flex-row items-start justify-between gap-4 w-full">
            {options.map((value, optionIndex) => {
              return (
                <div
                  key={value.value + optionIndex}
                  onClick={() => {
                    if (!setValue) {
                      return;
                    }
                    if (filteredTags.length < 1) {
                      setValue([value.value]);
                    } else {
                      if (filteredTags.includes(value.value)) {
                        setValue([
                          ...formValues?.filter((item) => item !== value.value),
                        ]);
                      } else {
                        setValue([
                          ...formValues,
                          (formValues[optionIndex] = value.value),
                        ]);
                      }
                    }
                  }}
                  className={classnames(
                    " rounded-md p-1 px-2 font-semibold text-sm cursor-pointer select-none shadow-md whitespace-nowrap ",
                    {
                      "bg-black  hover:bg-gray-800  text-white  ":
                        filteredTags.includes(value.value),
                    },
                    {
                      "bg-white  hover:bg-gray-100  text-black  ":
                        !filteredTags.includes(value.value),
                    },
                    {
                      " pointer-events-none ": false,
                    }
                  )}
                >
                  {value.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {error && (
        <div
          className={classnames(
            classNames?.error,
            " pb-1 px-5  text-red-500  font-light text-sm"
          )}
        >
          {error}
        </div>
      )}
    </div>
  );
}
