import classnames from "classnames";
import { Button, PromptModal } from "components";
import { memo, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

import { useResizeDetector } from "react-resize-detector";
import { IOutletAuth } from "types";
import { useWindowDimensions } from "hooks";

import { MotivationalCard } from "./MotivationalCard";

import { useMotivationalListFilterParams } from "./MotivationalList.hooks";
import JumboImage1 from "assets/images/jumbo-1.png";
import { APP_ROUTES } from "const";

function MotivationalList() {
  const navigate = useNavigate();
  const authContext = useOutletContext<IOutletAuth>();

  const { width } = useResizeDetector();

  const dim = useWindowDimensions();

  const { data, inViewRef } = useMotivationalListFilterParams(
    width || dim.width,
    false
  );

  const [showGoSignInModal, setShowGoSignInModal] = useState(false);

  return (
    <div className={classnames("flex flex-col   relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr_1fr_1fr_1fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-3  mb-12  "
        )}
      >
        <div
          className={classnames(
            "thirteen-fourth-step fifteen-fourth-step flex flex-col sm:grid grid-cols-1 auto-rows-max gap-3 lg:overflow-scroll sm:grid-cols-2 sm:col-span-2  md:grid-cols-3 md:col-span-3   lg:grid-cols-4 lg:col-span-4 pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear"
          )}
        >
          <div
            className={classnames(
              "relative flex flex-1 max-h-[300px] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full overflow-hidden mb-4"
            )}
          >
            <div className=" flex justify-center flex-1 w-full h-full">
              <img
                style={{ flexBasis: "100%" }}
                className="select-none pointer-events-none min-h-[180px] flex-1 w-full h-full object-cover sm:object-contain"
                alt="jumbo"
                src={authContext.backgroundImages || JumboImage1}
              />
              <Button
                isReverse
                className=" !absolute h-[42px] font-semibold  bottom-[10%]  z-50 justify-center flex  shrink-0"
                onClick={() => {
                  if (!!authContext?.isAuth) {
                    navigate(APP_ROUTES.MOTIVATIONAL_CREATE);
                  } else {
                    setShowGoSignInModal(true);
                  }
                }}
              >
                Create motivational content
              </Button>
            </div>
          </div>
          <h1 className="text-[18px] flex justify-center max-h-[300px] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full mb-2 font-semibold">
            WE-losophy
          </h1>
          {data?.pages.map((page, pagesIndex) => {
            return page.data.items.length < 1 ? (
              <div className="flex min-h-[400px] items-start justify-center col-span-6">
                <p className=" max-w-[700px] text-center text-xl">
                  There are no motivational contents found for the chosen
                  category, please select another category or create
                  motivational content
                </p>
              </div>
            ) : (
              page.data.items.map((motivational, issueIndex) => {
                const isLast =
                  data?.pages.length === pagesIndex + 1 &&
                  page.data.items.length === issueIndex + 1;

                return (
                  <div
                    className={classnames(
                      " cursor-pointer z-10  shadow-issueCard hover:shadow-issueCardStrong  bg-white rounded-md  ",
                      {
                        " motivational-card-to-click ": issueIndex === 0,
                      }
                    )}
                    onClick={() => {
                      motivational.id &&
                        navigate(
                          APP_ROUTES.MOTIVATIONAL + "/" + motivational.id
                        );
                    }}
                    key={motivational.id}
                  >
                    <MotivationalCard
                      index={issueIndex}
                      id={motivational.id}
                      image={motivational.image}
                      videoLink={motivational.videoLink}
                      thumbnailLink={motivational.thumbnailLink}
                      innerRef={isLast ? inViewRef : undefined}
                      title={motivational.title}
                      firstName={motivational.user?.firstName}
                      lastName={motivational.user?.lastName}
                      createdAt={motivational.createdAt}
                      likes={motivational.likes}
                      dislikes={motivational.dislikes}
                      rate={motivational.rates && motivational.rates[0]}
                      views={motivational.views}
                    />
                  </div>
                );
              })
            );
          })}
        </div>
      </div>
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(MotivationalList);
