import {
  AttachmentsInitial,
  AttachmentsPayload,
  IAttachmentPayloadArguments,
} from "modules/Attachments/Attachments.types";
import { IAsset, IAttachment, ISolution } from "types";

export enum FIELD_NAMES {
  TITLE = "title",
  DESCRIPTION = "description",
  EFFECTIVENESS = "effectiveness",
  ATTACHMENTS = "attachments",
  TAGS = "tags",
}

export class SolutionInitial extends AttachmentsInitial {
  [FIELD_NAMES.TITLE] = "";
  [FIELD_NAMES.DESCRIPTION] = "";
  [FIELD_NAMES.EFFECTIVENESS] = "";
  validator?: any;
  [FIELD_NAMES.TAGS]: string[] = [];

  constructor(
    solution: ISolution<any, any, any, IAttachment<IAsset>> | undefined
  ) {
    super(solution?.attachments);
    if (solution) {
      this.addTitle(solution.title);
      this.addDescription(solution.description);
      this.addEffectiveness(solution.effectiveness);
      this.addTags(solution.tags);
    }
  }
  addTitle(title: ISolution["title"]) {
    if (title) {
      this[FIELD_NAMES.TITLE] = title;
    }
  }
  addDescription(description: ISolution["description"]) {
    if (description) {
      this[FIELD_NAMES.DESCRIPTION] = description;
    }
  }
  addEffectiveness(effectiveness: ISolution["effectiveness"]) {
    if (effectiveness) {
      this[FIELD_NAMES.EFFECTIVENESS] = effectiveness;
    }
  }
  addTags(tags: ISolution["tags"]) {
    if (tags) {
      this[FIELD_NAMES.TAGS] = tags;
    }
  }
}

export class SolutionPayload extends AttachmentsPayload {
  [FIELD_NAMES.TITLE] = "";
  [FIELD_NAMES.DESCRIPTION] = "";
  [FIELD_NAMES.EFFECTIVENESS] = "";
  [FIELD_NAMES.TAGS]: string[] = [];
  issue?: string;

  constructor(
    {
      attachments,
      attachmentsInput,
      attachmentsQuill,
      attachmentsTitle,
      attachmentsType,
      ...rest
    }: Partial<SolutionInitial> & IAttachmentPayloadArguments,
    issueId?: string
  ) {
    super({
      attachmentsType,
      attachments,
      attachmentsInput,
      attachmentsQuill,
      attachmentsTitle,
    });
    this.addTitle(rest.title);
    this.addDescription(rest.description);
    this.addEffectiveness(rest.effectiveness);
    this.addIssueId(issueId);
    this.addTags(rest.tags);
  }
  addIssueId(issueId?: string) {
    if (issueId) {
      this.issue = issueId;
    }
  }
  addTitle(title?: ISolution["title"]) {
    if (title) {
      this[FIELD_NAMES.TITLE] = title;
    }
  }
  addDescription(description?: ISolution["description"]) {
    if (description) {
      this[FIELD_NAMES.DESCRIPTION] = description;
    }
  }
  addEffectiveness(effectiveness?: ISolution["effectiveness"]) {
    if (effectiveness) {
      this[FIELD_NAMES.EFFECTIVENESS] = effectiveness;
    }
  }
  getSolution() {
    return {
      title: this[FIELD_NAMES.TITLE],
      description: this[FIELD_NAMES.DESCRIPTION],
      effectiveness: this[FIELD_NAMES.EFFECTIVENESS],
      issue: this.issue,
      tags: this[FIELD_NAMES.TAGS],
    };
  }
  addTags(tags?: ISolution["tags"]) {
    if (tags) {
      this[FIELD_NAMES.TAGS] = tags;
    }
  }
}
