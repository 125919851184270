import { memo, useState } from "react";
import {
  FullActionGroupType,
  IActionGroupRatings,
  IActionGroupUsers,
  IUser,
} from "types";

import { GroupCardModal } from "components";

import classnames from "classnames";
import { useMe, useQueryOptionsSetter } from "hooks";

import { useMutation, UseMutationOptions } from "react-query";
import { AxiosResponse } from "axios";
import { createActionGroupUser } from "services";

import { useParams, useSearchParams } from "react-router-dom";

import { useActionGroupsList } from "modules/GroupList/GroupList.hooks";
import { GroupCard } from "modules/GroupList/GroupCard";

interface IProps {
  profileOwner?: boolean;
}

interface IActionGroupUsersProps
  extends Partial<
    UseMutationOptions<
      AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
      any,
      any
    >
  > {}

export function useCreateActionGroupUser({ ...rest }: IActionGroupUsersProps) {
  return useMutation<
    AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
    any,
    any
  >(
    (actionUser: IActionGroupUsers<IUser, FullActionGroupType>) =>
      createActionGroupUser({ actionUser }),
    {
      ...rest,
    }
  );
}

function ActionGroupsCard({ profileOwner }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  useQueryOptionsSetter(searchParams, setSearchParams);

  const { data: meData } = useMe();

  const params = useParams<{ userId: string }>();
  const { data: actionGroupData } = useActionGroupsList({
    user: meData?.data && profileOwner ? meData?.data.id! : params.userId!,
    skills: [],
    tags: [],
    category: [],
    zoom: 1,
    lat: "",
    lng: "",
    suspense: false,
    withDeleted: true,
  });

  const [openCardModal, setOpenCardModal] = useState(false);

  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );

  const [, setIsIssueHovered] = useState(false);

  const [hoveredIssueId] = useState<string | undefined | string[]>(undefined);
  const [, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();
  return (
    <div
      className={classnames(
        "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
      )}
    >
      <div
        className={classnames(
          "grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear sm:grid-cols-2 lg:grid-cols-4 lg:col-span-4"
        )}
      >
        {actionGroupData?.pages.map((page, pagesIndex) => {
          return page.data.items.length < 1 ? (
            <div className="flex   justify-center col-span-6" key={pagesIndex}>
              <p className=" max-w-[700px] text-center text-xl">
                {profileOwner
                  ? "You have not created any action groups"
                  : "There are no action groups created by this user"}
              </p>
            </div>
          ) : (
            page.data.items.map((actionGroup) => {
              const solution =
                actionGroup?.solutions && actionGroup?.solutions[0];
              const geoLocation = {
                lat: solution?.issue?.location?.lat,
                lng: solution?.issue?.location?.lng,
              };

              const groupId = actionGroup.id!;
              let rate: IActionGroupRatings | null = null;
              if (actionGroup && actionGroup?.rates && actionGroup?.rates[0]) {
                rate = actionGroup.rates[0];
              }
              return (
                <div
                  className=" z-10 maxmd:flex cursor-pointer"
                  key={actionGroup?.id}
                  onClick={() => {
                    setOpenedCardId(actionGroup?.id);
                  }}
                >
                  <GroupCard
                    setOpenCardModal={setOpenCardModal}
                    groupId={groupId}
                    forceHoverIssue={hoveredIssueId?.includes(
                      actionGroup.id || ""
                    )}
                    membersNumber={String(actionGroup.membersCount)}
                    skills={actionGroup.skills}
                    rate={rate}
                    setIsIssueHovered={setIsIssueHovered}
                    setHoveredIssueType={setHoveredIssueType}
                    geoLocation={geoLocation}
                    title={actionGroup?.title || ""}
                    profileImage={actionGroup?.user?.avatar}
                    groupImage={actionGroup.logo}
                    createdAt={actionGroup?.createdAt}
                    likes={actionGroup?.likes || 0}
                    dislikes={actionGroup?.dislikes || 0}
                    isSoftDeleted={!!actionGroup?.deletedAt}
                  />
                </div>
              );
            })
          );
        })}
      </div>
      {openCardModal && openedCardId && (
        <GroupCardModal id={openedCardId} setShow={setOpenCardModal} />
      )}
    </div>
  );
}

export default memo(ActionGroupsCard);
