import { FormikProps, Form } from "formik";
import classnames from "classnames";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "const";
import { Button, FormikInput, AuthPanel } from "components";

import { ValuesType } from "./LoginForm.types";
import { FIELD_NAMES, MAX_FORM_FIELDS_LENGTH } from "./LoginForm.constants";
import { ReactComponent as WeLogo } from "assets/images/we-logo.svg";

interface AuthenticationPanelProps
  extends FormikProps<ValuesType>,
    React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function LoginForm(props: AuthenticationPanelProps) {
  return (
    <AuthPanel>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <WeLogo />
        </div>
        <h2 className="mt-8 text-3xl font-bold text-gray-900 text-center">
          Sign in to your account
        </h2>

        <div className=" flex flex-row justify-center text-center items-center w-full h-full text-base font-normal mt-4">
          <p className="text-sm text-gray-600 mr-1 text-center">Or</p>
          <Link to={APP_ROUTES.SIGN_UP}>
            <p className="opacity-100 font-semibold text-black text-gradient">
              Sign up
            </p>
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <Form className={classnames("")}>
          <div className="mt-7">
            <FormikInput
              autoComplete="nope"
              label="Email address"
              name={FIELD_NAMES.EMAIL}
              placeholder="E-Mail Address"
            />
          </div>

          <div className="mt-7">
            <FormikInput
              label="Password"
              autoComplete="nope"
              maxLength={MAX_FORM_FIELDS_LENGTH}
              name={FIELD_NAMES.PASSWORD}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="flex items-center justify-end mt-8">
            <div className="text-sm">
              <Link
                to={APP_ROUTES.FORGET_PASSWORD}
                className="opacity-100 font-bold text-black text-gradient"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div className="mt-8">
            <Button
              type="submit"
              className="w-full justify-center"
              disabled={props.isSubmitting}
            >
              Sign in
            </Button>
          </div>
        </Form>
      </div>
    </AuthPanel>
  );
}

export default memo(LoginForm);
