import {
  IMotivational,
  IMotivationalTag,
  ITag,
  IUser,
} from "../../types/entities";

export enum FIELD_NAMES {
  TITLE = "title",
  DESCRIPTION = "description",
  VIDEO_LINK = "videoLink",
  THUMBNAIL_LINK = "thumbnailLink",
  IMAGE = "image",
  TAGS = "tags",
}

export class InitialMotivational {
  [FIELD_NAMES.TITLE] = "";
  [FIELD_NAMES.DESCRIPTION] = "";
  [FIELD_NAMES.VIDEO_LINK] = "";
  [FIELD_NAMES.THUMBNAIL_LINK] = "";
  [FIELD_NAMES.IMAGE]: null | string = "";
  [FIELD_NAMES.TAGS]: string[] = [];
  constructor(
    data: IMotivational<IUser, IMotivationalTag<any, ITag>> | undefined
  ) {
    this.addTitle(data?.title);
    this.addDescription(data?.description);
    this.addVideoLink(data?.videoLink);
    this.addThumbnailLink(data?.thumbnailLink);
    this.addTags(data?.tags);
    this.addImage(data?.image);
  }
  addTitle(title: string | undefined) {
    if (title) {
      this[FIELD_NAMES.TITLE] = title;
    }
  }
  addDescription(description: string | undefined) {
    if (description) {
      this[FIELD_NAMES.DESCRIPTION] = description;
    }
  }
  addVideoLink(videoLink: string | undefined) {
    if (videoLink) {
      this[FIELD_NAMES.VIDEO_LINK] = videoLink;
    }
  }
  addThumbnailLink(thumbnailLink: string | undefined) {
    if (thumbnailLink) {
      this[FIELD_NAMES.THUMBNAIL_LINK] = thumbnailLink;
    }
  }
  addTags(tags: IMotivationalTag<any, ITag>[] | undefined) {
    if (tags && tags.length > 0) {
      this[FIELD_NAMES.TAGS] = tags.map((tag) => {
        return tag.tag.id || "";
      });
    }
  }
  addImage(image: string | undefined) {
    if (image) {
      this[FIELD_NAMES.IMAGE] = image;
    }
  }
}

export class PayloadMotivational {
  [FIELD_NAMES.TITLE] = "";
  [FIELD_NAMES.DESCRIPTION] = "";
  [FIELD_NAMES.VIDEO_LINK] = "";
  [FIELD_NAMES.IMAGE]: null | undefined | string = null; ///
  [FIELD_NAMES.THUMBNAIL_LINK] = "";
  [FIELD_NAMES.TAGS]: {
    tag: string;
  }[] = [];

  constructor(data: InitialMotivational) {
    this.addImage(data[FIELD_NAMES.IMAGE]);
    this.addThumbnailLink(data[FIELD_NAMES.THUMBNAIL_LINK]);
    this.addTags(data[FIELD_NAMES.TAGS]);
    this.addTitle(data[FIELD_NAMES.TITLE]);
    this.addDescription(data[FIELD_NAMES.DESCRIPTION]);
    this.addVideoLink(data[FIELD_NAMES.VIDEO_LINK]);
  }
  async addImage(image: null | string | undefined) {
    this[FIELD_NAMES.IMAGE] = image;
  }
  addThumbnailLink(thumbnailLink: string | undefined) {
    if (thumbnailLink) {
      this[FIELD_NAMES.THUMBNAIL_LINK] = thumbnailLink;
    }
  }
  addTags(tags: string[] | undefined) {
    if (tags) {
      this[FIELD_NAMES.TAGS] = tags.map((tag) => {
        return {
          tag,
        };
      });
    }
  }
  addTitle(title: string | undefined) {
    if (title) {
      this[FIELD_NAMES.TITLE] = title;
    }
  }
  addDescription(description: string) {
    if (description) {
      this[FIELD_NAMES.DESCRIPTION] = description;
    }
  }
  addVideoLink(videoLink: string | undefined) {
    if (videoLink) {
      this[FIELD_NAMES.VIDEO_LINK] = videoLink;
    }
  }
  getPayload() {
    return {
      title: this[FIELD_NAMES.TITLE],
      description: this[FIELD_NAMES.DESCRIPTION],
      videoLink: this[FIELD_NAMES.VIDEO_LINK],
      image: this[FIELD_NAMES.IMAGE],
      thumbnailLink: this[FIELD_NAMES.THUMBNAIL_LINK],
      //tags: this[FIELD_NAMES.TAGS],
    };
  }
  getTags() {
    return this[FIELD_NAMES.TAGS];
  }
}
