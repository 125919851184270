import axios, { AxiosResponse } from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  getMe,
  resetPassword,
  signInVerify,
  sendResetPasswordRequest,
  findSkills,
  findUserSkills,
  findIssues,
  findIssue,
  findAverageIssueRating,
  IIssuesProps,
  findIssueRatings,
  findMapLocations,
  ILocationParams,
  ISolutionsProps,
  findSolution,
  findSolutions,
  findSolutionRatings,
  findMapSolutionLocations,
  findActionGroups,
  findMapActionGroupsLocations,
  findActionGroup,
  IActionGroupsProps,
  findActionGroupsList,
  findActionGroupsSkills,
  findActionGroupUsers,
  getCommentsConfig,
  createGroupRating,
  updateGroupRating,
  findActivities,
  updateActivities,
  findMotivationals,
  findMotivational,
  getBackgroundImages,
  findMotivationalTags,
  findTags,
  IMotivationalsProps,
  updateMotivationalRating,
  createMotivationalRating,
  updateMotivationalView,
  createHandouts,
  findUser,
  createFirebasePushToken,
  findUserDeviceTokens,
  findIssueRatingCategories,
  findActionGroupsHandouts,
  IFindActivitiesProps,
  findUnreadActivitiesAmount,
  setActivitiesAsSeen,
  updateAttachments,
  IUpdateAttachmentsProps,
  getTextAttachment,
  getMandatoryFiles,
  getMandatoryAttachments,
  deleteUser,
  findUsers,
  IFindUsersProps,
  softDeleteIssue,
  restoreIssue,
  softDeleteSolution,
  restoreSolution,
  restoreActionGroup,
  softDeleteActionGroup,
  IFindActionGroupUsersProps,
} from "services";
import {
  IActionGroupRatings,
  IMotivationalRatings,
  IActionGroupSkill,
  IActionGroupUsers,
  IActivity,
  IAverageIssueRating,
  IBackgroundImage,
  IIssueRating,
  IMapLocation,
  IMotivational,
  IMotivationalTag,
  IOutletAuth,
  IResetPasswordData,
  IResetPasswordEmail,
  ISignInFormData,
  ISignUpFormData,
  ISkill,
  ISolutionRating,
  ITag,
  IUser,
  IUserSkill,
  IUserDeviceTokens,
  FullIssueType,
  IIssueRatingCategory,
  IActionGroupHangout,
  IAttachment,
  IAsset,
  FullSolutionType,
  FullActionGroupType,
  IMandatoryFile,
  IMandatoryAttachments,
  IIssue,
  ISolution,
  IActionGroup,
} from "types";
import { signUp } from "services";
import { APP_ROUTES, ENDPOINTS } from "const";
import { BufferType, IAxiosResponseWithPagination } from "types/requests";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { FastCommentsSSO } from "fastcomments-typescript";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

interface IResetPasswordDataProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IResetPasswordData>, any, any>
  > {}

export function useResetPasswordApi({ ...rest }: IResetPasswordDataProps) {
  return useMutation<AxiosResponse<IResetPasswordData>, any, any>(
    resetPassword,
    {
      ...rest,
    }
  );
}

interface IResetPasswordEmailProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IResetPasswordEmail>, any, any>
  > {}

export function useSendResetPasswordRequestApi({
  ...rest
}: IResetPasswordEmailProps) {
  return useMutation<AxiosResponse<IResetPasswordEmail>, any, any>(
    sendResetPasswordRequest,
    {
      ...rest,
    }
  );
}

interface ISignInQueryProps
  extends Partial<
    UseMutationOptions<AxiosResponse<ISignInFormData>, any, any>
  > {}

export function useSignInApi({ ...rest }: ISignInQueryProps) {
  return useMutation<AxiosResponse<ISignInFormData>, any, any>(signInVerify, {
    ...rest,
  });
}

interface ISignUpQueryProps
  extends Partial<
    UseMutationOptions<AxiosResponse<ISignUpFormData>, any, any>
  > {}

export function useSignUpApi({ ...rest }: ISignUpQueryProps) {
  return useMutation<AxiosResponse<ISignUpFormData>, any, any>(signUp, {
    ...rest,
  });
}

interface IMeByIdQueryProps
  extends Partial<UseQueryOptions<AxiosResponse<IUser>>> {}

export function useMe({ ...rest }: IMeByIdQueryProps = {}) {
  const authContext = useOutletContext<IOutletAuth>();

  return useQuery<AxiosResponse<IUser>, any, AxiosResponse<IUser>>(
    [ENDPOINTS.ME],
    () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return getMe(source);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
      suspense: true,
      enabled: !!authContext?.isAuth,
      ...rest,
    }
  );
}

interface IQuerySkillsProps
  extends Partial<UseQueryOptions<AxiosResponse<ISkill[]>>> {
  search: string;
}

export function useSkills({ search, ...rest }: IQuerySkillsProps) {
  return useQuery<AxiosResponse<ISkill[]>>(
    [ENDPOINTS.SKILLS, search],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findSkills({ source, limit: 10, search });
    },
    { ...rest } // set up default settings
  );
}

interface IQueryUserSkillsProps
  extends Partial<UseQueryOptions<AxiosResponse<IUserSkill[]>>> {
  id?: string;
}

export function useUserSkills({ id, ...rest }: IQueryUserSkillsProps) {
  return useQuery<AxiosResponse<IUserSkill[]>>(
    [ENDPOINTS.USER_SKILLS, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findUserSkills({ source, params: { user: id } });
    },
    { ...rest, enabled: !!id } // set up default settings
  );
}

interface IQueryActionGroupSkillsProps
  extends Partial<UseQueryOptions<AxiosResponse<IActionGroupSkill<ISkill>[]>>> {
  id?: string;
}

export function useActionGroupSkills({
  id,
  ...rest
}: IQueryActionGroupSkillsProps) {
  return useQuery<AxiosResponse<IActionGroupSkill<ISkill>[]>>(
    [ENDPOINTS.ACTION_GROUPS_SKILLS, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroupsSkills({
        source,
        params: { actionGroup: id },
      });
    },
    { ...rest, enabled: !!id } // set up default settings
  );
}

////
interface IInfiniteQueryActivitiesProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<IActivity<IUser>>>
    >,
    IFindActivitiesProps {}

function useInfiniteActivitiesQuery({
  limit,
  representEntityUser,
  page,
  ...rest
}: IInfiniteQueryActivitiesProps = {}) {
  const params = _.pickBy({ page, limit, representEntityUser }, _.identity);

  return useInfiniteQuery<IAxiosResponseWithPagination<IActivity<IUser>>>(
    [ENDPOINTS.ACTIVITIES, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return await findActivities({
        source,
        ...params,
        page: pageParam,
      });
    },
    { ...rest }
  );
}
////

export function useInfiniteActivities({
  representEntityUser,
}: {
  representEntityUser: string | undefined;
}) {
  const { ref, inView } = useInView();

  const { data, fetchNextPage, ...rest } = useInfiniteActivitiesQuery({
    representEntityUser,
    suspense: false,
    enabled: !!representEntityUser,
    refetchOnWindowFocus: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);
  return { ref, inView, data, fetchNextPage, ...rest };
}

interface IUpdateActivitiesProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IActivity<IUser>>, any, any>
  > {}

export function useUpdateActivities({ ...rest }: IUpdateActivitiesProps) {
  return useMutation<AxiosResponse<IActivity<IUser>>, any, any>(
    () => updateActivities(),
    {
      ...rest,
    }
  );
}
//user={{USER_ID}}&lat={{LAT}}&lng={{LNG}}&range=1000&page=1&limit=10

export type SolutionParamsTypes = Partial<ISolutionsProps["params"]>;
interface IQuerySolutionsProps
  extends Partial<
      UseQueryOptions<IAxiosResponseWithPagination<FullSolutionType>>
    >,
    SolutionParamsTypes {}

export function useSolutions({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  issue,
  actionGroup,
  ...rest
}: IQuerySolutionsProps = {}) {
  const params = _.pickBy(
    { lat, limit, lng, range, page, issue, actionGroup },
    _.identity
  );
  return useQuery<IAxiosResponseWithPagination<FullSolutionType>>(
    [ENDPOINTS.SOLUTIONS, ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findSolutions({
        source,
        params,
      });
    },
    { refetchOnWindowFocus: false, ...rest } // set up default settings
  );
}

interface IQuerySolutionProps
  extends Partial<UseQueryOptions<AxiosResponse<FullSolutionType>>> {
  id: string | undefined;
}

export function useSolution({ id = "", ...rest }: IQuerySolutionProps) {
  return useQuery<AxiosResponse<FullSolutionType>>(
    [ENDPOINTS.SOLUTIONS, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findSolution({
        source,
        id,
      }).catch((err) => {
        if (err.response.status === 404 || err.response.status === 400) {
          window.location.assign(APP_ROUTES.NOTFOUND);
        }
        return err;
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

export type IssueParamsTypes = Partial<IIssuesProps["params"]>;
export type MotivationalParamsTypes = Partial<IMotivationalsProps["params"]>;
export type ActionGroupParamsTypes = Partial<IActionGroupsProps["params"]>;
interface IQueryIssuesProps
  extends Partial<UseQueryOptions<IAxiosResponseWithPagination<FullIssueType>>>,
    IssueParamsTypes {}

export function useIssues({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  actionGroup,
  ...rest
}: IQueryIssuesProps = {}) {
  const params = _.pickBy(
    { lat, limit, lng, range, page, actionGroup },
    _.identity
  );
  return useQuery<IAxiosResponseWithPagination<FullIssueType>>(
    [ENDPOINTS.ISSUES, ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findIssues({
        source,
        params,
      });
    },
    { ...rest } // set up default settings
  );
}

interface IInfiniteQuerySolutionsProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullSolutionType>>
    >,
    SolutionParamsTypes {}

export function useInfinitySolutions({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  skills,
  actionGroup,
  tags,
  withDeleted,
  ...rest
}: IInfiniteQuerySolutionsProps = {}) {
  const params = _.pickBy(
    {
      lat,
      limit,
      lng,
      range,
      page,
      user,
      skills,
      actionGroup,
      tags,
      withDeleted,
    },
    _.identity
  );

  return useInfiniteQuery<IAxiosResponseWithPagination<FullSolutionType>>(
    [ENDPOINTS.SOLUTIONS, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return await findSolutions({
        source,
        params: {
          ...params,
          page: pageParam,
        },
      });
    },
    { ...rest }
  );
}

interface IInfiniteQueryIssuesProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullIssueType>>
    >,
    IssueParamsTypes {}

export function useInfinityIssues({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  category,
  actionGroup,
  tags,
  withDeleted,
  ...rest
}: IInfiniteQueryIssuesProps = {}) {
  const params = _.pickBy(
    {
      lat,
      limit,
      lng,
      range,
      page,
      user,
      category,
      actionGroup,
      tags,
      withDeleted,
    },
    _.identity
  );
  return useInfiniteQuery<IAxiosResponseWithPagination<FullIssueType>>(
    [ENDPOINTS.ISSUES, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findIssues({
        source,
        params: { ...params, page: pageParam },
      });
    },
    { ...rest }
  );
}

interface IQueryActionGroupsListProps
  extends Partial<
      UseQueryOptions<IAxiosResponseWithPagination<FullActionGroupType>>
    >,
    ActionGroupParamsTypes {}

export function useActionGroups({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  solution,
  title,
  ...rest
}: IQueryActionGroupsListProps = {}) {
  const params = { lat, limit, lng, range, page, solution, title, user };
  return useQuery<IAxiosResponseWithPagination<FullActionGroupType>>(
    [ENDPOINTS.ACTION_GROUPS, ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroupsList({
        source,
        params,
      });
    },
    { ...rest } // set up default settings
  );
}

interface IInfiniteQueryActionGroupsProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullActionGroupType>>
    >,
    ActionGroupParamsTypes {}

export function useInfinityActionGroups({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  skills,
  tags,
  withDeleted,
  ...rest
}: IInfiniteQueryActionGroupsProps = {}) {
  const params = _.pickBy(
    { lat, limit, lng, range, page, user, skills, tags, withDeleted },
    _.identity
  );
  return useInfiniteQuery<IAxiosResponseWithPagination<FullActionGroupType>>(
    [ENDPOINTS.ACTION_GROUPS, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroups({
        source,
        params: { ...params, page: pageParam },
      });
    },
    { ...rest }
  );
}

interface IQueryIssueProps
  extends Partial<UseQueryOptions<AxiosResponse<FullIssueType>>> {
  id: string | undefined;
}

export function useIssue({ id = "", ...rest }: IQueryIssueProps) {
  return useQuery<AxiosResponse<FullIssueType>>(
    [ENDPOINTS.ISSUE, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findIssue({
        source,
        id,
      }).catch((err) => {
        if (err.response.status === 404 || err.response.status === 400) {
          window.location.assign(APP_ROUTES.NOTFOUND);
        }
        return err;
      });
    },
    { enabled: !!id, refetchOnWindowFocus: false, ...rest } // set up default settings
  );
}
interface IQueryActionGroupsProps
  extends Partial<UseQueryOptions<AxiosResponse<FullActionGroupType>>>,
    Partial<ILocationParams> {
  id: string | undefined;
}

export function useActionGroup({
  id = "",
  lat,
  lng,
  range,
  user,
  ...rest
}: IQueryActionGroupsProps) {
  return useQuery<AxiosResponse<FullActionGroupType>>(
    [ENDPOINTS.ACTION_GROUPS, id, lat, lng, range, user],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroup({
        source,
        id,
        lat,
        lng,
        range,
        user,
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

interface IQueryAverageIssueRatingsProps
  extends Partial<UseQueryOptions<AxiosResponse<IAverageIssueRating>>> {
  id: string | undefined;
}

export function useAverageIssueRatings({
  id = "",
  ...rest
}: IQueryAverageIssueRatingsProps) {
  return useQuery<AxiosResponse<IAverageIssueRating>>(
    [ENDPOINTS.ISSUE_RATING, id, "average"],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findAverageIssueRating({
        source,
        id,
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

interface IQueryIssueRatingsProps
  extends Partial<
    UseQueryOptions<
      IAxiosResponseWithPagination<IIssueRating<IIssueRatingCategory>>
    >
  > {
  userId: string | undefined;
  issueId: string | undefined;
}

export function useIssueRatings({
  issueId,
  userId,
  ...rest
}: IQueryIssueRatingsProps) {
  return useQuery<
    IAxiosResponseWithPagination<IIssueRating<IIssueRatingCategory>>
  >(
    [ENDPOINTS.ISSUE_RATING, userId, issueId],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findIssueRatings({
        source,
        issueId,
        userId,
      });
    },
    { refetchOnWindowFocus: false, ...rest } // set up default settings
  );
}
interface IQueryActionGroupUsersProps
  extends Partial<
      UseQueryOptions<IAxiosResponseWithPagination<IActionGroupUsers<IUser>>>
    >,
    Partial<IFindActionGroupUsersProps> {}

export function useActionGroupUsers({
  actionGroup,
  user,
  status,
  role,
  page,
  limit,
  ...rest
}: IQueryActionGroupUsersProps) {
  const params = _.pickBy(
    { actionGroup, user, status, role, page, limit },
    _.identity
  );
  return useQuery<IAxiosResponseWithPagination<IActionGroupUsers<IUser>>>(
    [ENDPOINTS.ACTION_GROUP_USERS, ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroupUsers({
        source,
        ...params,
      });
    },
    { ...rest } // set up default settings
  );
}
////
export interface IInfiniteQueryActionGroupUsersProps
  extends Partial<
      UseInfiniteQueryOptions<
        IAxiosResponseWithPagination<IActionGroupUsers<IUser>>
      >
    >,
    Partial<IFindActionGroupUsersProps> {}

export function useInfinityActionGroupUsers({
  user,
  actionGroup,
  page,
  status,
  role,
  limit,
  ...rest
}: IInfiniteQueryActionGroupUsersProps = {}) {
  const params = _.pickBy(
    { actionGroup, user, status, role, page, limit },
    _.identity
  );
  return useInfiniteQuery<
    IAxiosResponseWithPagination<IActionGroupUsers<IUser>>
  >(
    [ENDPOINTS.ACTION_GROUPS, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroupUsers({
        source,
        ...params,
        page: pageParam,
      });
    },
    { ...rest }
  );
}

///

interface IQuerySolutionRatingProps
  extends Partial<
    UseQueryOptions<IAxiosResponseWithPagination<ISolutionRating>>
  > {
  userId: string | undefined;
  solutionId: string | undefined;
}
export function useSolutionRatings({
  solutionId,
  userId,
  ...rest
}: IQuerySolutionRatingProps) {
  return useQuery<IAxiosResponseWithPagination<ISolutionRating>>(
    [ENDPOINTS.SOLUTION_RATING, userId, solutionId],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findSolutionRatings({
        source,
        solutionId,
        userId,
      });
    },
    { ...rest } // set up default settings
  );
}
interface IQueryMapLocationProps
  extends Partial<UseQueryOptions<AxiosResponse<IMapLocation[]>>>,
    ILocationParams {
  eps?: number;
  path?: "solution" | "issue" | "groups";
  actionGroup?: string | undefined;
}
export function useMapLocations({
  lat,
  lng,
  category,
  user,
  range,
  eps,
  skills,
  tags,
  path = "issue",
  actionGroup,
  ...rest
}: IQueryMapLocationProps) {
  const params = _.pickBy(
    { lat, lng, category, user, range, eps, skills, actionGroup, tags },
    _.identity
  );

  return useQuery<AxiosResponse<IMapLocation[]>>(
    [ENDPOINTS.MAP_LOCATIONS, path, ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return path === "issue"
        ? await findMapLocations({
            source,
            params,
          })
        : path === "solution"
        ? await findMapSolutionLocations({
            source,
            params,
          })
        : await findMapActionGroupsLocations({
            source,
            params,
          });
    },
    { ...rest } // set up default settings
  );
}
interface ICommentsProps
  extends Partial<UseQueryOptions<AxiosResponse<FastCommentsSSO>>> {}
export function useCommentConfig({
  enabled = true,
  suspense = false,
}: ICommentsProps = {}) {
  return useQuery<AxiosResponse<FastCommentsSSO>>(
    [ENDPOINTS.COMMENTS_CONFIG],
    async () => {
      return await getCommentsConfig();
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      keepPreviousData: true,
      suspense,
    } // set up default settings
  );
}

interface IActionGroupRatingsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IActionGroupRatings>, any, any>
  > {
  groupId?: string | undefined;
}

export function useCreateGroupRating(props?: IActionGroupRatingsProps) {
  return useMutation<AxiosResponse<IActionGroupRatings>, any, any>(
    (group: IActionGroupRatings) => createGroupRating({ group }),
    {
      ...props,
    }
  );
}

export function useUpdateGroupRating({
  actionGroupRatingId = "",
  ...rest
}: IActionGroupRatingsProps & {
  actionGroupRatingId?: string | undefined;
} = {}) {
  return useMutation<AxiosResponse<IActionGroupRatings>, any, any>(
    (group: IActionGroupRatings) =>
      updateGroupRating({ group, actionGroupRatingId }),
    {
      ...rest,
    }
  );
}

interface IQueryMotivationalProps
  extends Partial<
    UseQueryOptions<
      AxiosResponse<IMotivational<IUser, IMotivationalTag<any, ITag>>>
    >
  > {
  id: string | undefined;
}

export function useMotivational({ id = "", ...rest }: IQueryMotivationalProps) {
  return useQuery<
    AxiosResponse<IMotivational<IUser, IMotivationalTag<any, ITag>>>
  >(
    [ENDPOINTS.MOTIVATIONAL, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findMotivational({
        source,
        id,
      }).catch((err) => {
        if (err.response.status === 404 || err.response.status === 400) {
          window.location.assign(APP_ROUTES.NOTFOUND);
        }
        return err;
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

interface IInfiniteQueryMotivationalsProps
  extends Partial<
      UseInfiniteQueryOptions<
        IAxiosResponseWithPagination<IMotivational<IUser, IMotivationalTag>>
      >
    >,
    MotivationalParamsTypes {}

export function useInfinityMotivationals({
  user,
  lat,
  limit,
  lng,
  range,
  page,
  category,
  tags,
  ...rest
}: IInfiniteQueryMotivationalsProps = {}) {
  const params = _.pickBy(
    { lat, limit, lng, range, page, user, category, tags },
    _.identity
  );

  return useInfiniteQuery<
    IAxiosResponseWithPagination<IMotivational<IUser, IMotivationalTag>>
  >(
    [ENDPOINTS.MOTIVATIONAL, ...Object.values(params)],
    async ({ pageParam = 1 }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findMotivationals({
        source,
        params: { ...params, page: pageParam },
      });
    },
    { ...rest }
  );
}

interface IQueryBackgroundImagesProps
  extends Partial<UseQueryOptions<AxiosResponse<IBackgroundImage[]>>> {}
export function useBackgroundImages({ ...rest }: IQueryBackgroundImagesProps) {
  return useQuery<AxiosResponse<IBackgroundImage[]>>(
    [ENDPOINTS.BACKGROUNDS],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await getBackgroundImages({
        source,
      });
    },
    { ...rest } // set up default settings
  );
}

interface IQueryMotivationalTagsProps
  extends Partial<
    UseQueryOptions<AxiosResponse<IMotivationalTag<IMotivational, ITag>[]>>
  > {
  id?: string;
}

export function useMotivationalTags({
  id,
  ...rest
}: IQueryMotivationalTagsProps) {
  return useQuery<AxiosResponse<IMotivationalTag<IMotivational, ITag>[]>>(
    [ENDPOINTS.MOTIVATIONAL_TAGS, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findMotivationalTags({
        source,
        params: { motivational: id },
      });
    },
    { ...rest, enabled: !!id } // set up default settings
  );
}

interface IQueryTagsProps
  extends Partial<UseQueryOptions<AxiosResponse<ITag[]>>> {}

export function useTags({ ...rest }: IQueryTagsProps) {
  return useQuery<AxiosResponse<ITag[]>>(
    [ENDPOINTS.TAGS],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findTags({ source, limit: 10 });
    },
    { ...rest } // set up default settings
  );
}

interface IMotivationalRatingsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IMotivationalRatings>, any, any>
  > {
  motivationalId?: string | undefined;
}

export function useCreateMotivationalRating(props?: IMotivationalRatingsProps) {
  return useMutation<AxiosResponse<IMotivationalRatings>, any, any>(
    (motivational: IMotivationalRatings) =>
      createMotivationalRating({ motivational }),
    {
      ...props,
    }
  );
}

export function useUpdateMotivationalRating({
  motivationalRatingId = "",
  ...rest
}: IMotivationalRatingsProps & {
  motivationalRatingId: string | undefined;
}) {
  return useMutation<AxiosResponse<IMotivationalRatings>, any, any>(
    (motivational: IMotivationalRatings) =>
      updateMotivationalRating({ motivational, motivationalRatingId }),
    {
      ...rest,
    }
  );
}

export function useUpdateMotivationalViews(motivationalId: string | undefined) {
  return useMutation<AxiosResponse<IMotivational>, any, any>(() =>
    updateMotivationalView(motivationalId)
  );
}

interface IHandoutsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<FullActionGroupType[]>, any, any>
  > {}

export function useCreateHandout(props?: IHandoutsProps) {
  return useMutation<AxiosResponse<FullActionGroupType[]>, any, any>(
    (actionGroups: FullActionGroupType[]) => createHandouts(actionGroups),
    {
      ...props,
    }
  );
}

interface IQueryUserProps
  extends Partial<UseQueryOptions<AxiosResponse<IUser>>> {
  id: string | undefined;
}

export function useUser({ id = "", ...rest }: IQueryUserProps) {
  return useQuery<AxiosResponse<IUser>>(
    [ENDPOINTS.USERS, id],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findUser({
        source,
        id,
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

interface ICreateUserDeviceTokenProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IUserDeviceTokens<string>>, any, any>
  > {}

export function useCreateUserDeviceToken({
  ...rest
}: ICreateUserDeviceTokenProps = {}) {
  return useMutation<
    AxiosResponse<IUserDeviceTokens<string>>,
    any,
    Partial<IUserDeviceTokens>
  >(
    ({ token, user }) =>
      createFirebasePushToken({
        token,
        user,
      }),
    {
      ...rest,
    }
  );
}

interface IQueryUserDeviceTokenProps
  extends Partial<UseQueryOptions<AxiosResponse<IUserDeviceTokens<IUser>[]>>> {
  user?: string;
}

export function useUserDeviceToken({
  user,
  ...rest
}: IQueryUserDeviceTokenProps) {
  return useQuery<AxiosResponse<IUserDeviceTokens<IUser>[]>>(
    [ENDPOINTS.USER_TOKEN_DEVICE, user],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findUserDeviceTokens({
        source,
        params: { user },
      });
    },
    { refetchOnWindowFocus: false, ...rest, enabled: !!user } // set up default settings
  );
}
///
interface IQueryIssueRatingCategoriesProps
  extends Partial<UseQueryOptions<AxiosResponse<IIssueRatingCategory[]>>> {}

export function useIssueRatingCategories({
  ...rest
}: IQueryIssueRatingCategoriesProps = {}) {
  return useQuery<AxiosResponse<IIssueRatingCategory[]>>(
    [ENDPOINTS.ISSUE_RATING_CATEGORIES],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findIssueRatingCategories({
        source,
      });
    },
    { refetchOnWindowFocus: false, ...rest } // set up default settings
  );
}
interface IActionGroupHandoutsProps
  extends Partial<UseQueryOptions<AxiosResponse<IActionGroupHangout[]>>>,
    ActionGroupParamsTypes {}
////
export function useActionGroupsHandouts({
  lat,
  limit,
  lng,
  range,
  page,
  ...rest
}: IActionGroupHandoutsProps = {}) {
  const params = _.pickBy({ lat, limit, lng, range, page }, _.identity);
  return useQuery<AxiosResponse<IActionGroupHangout[]>>(
    [ENDPOINTS.ACTION_GROUPS + "/handouts", ...Object.values(params)],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findActionGroupsHandouts({
        source,
        params: { ...params },
      });
    },
    { ...rest }
  );
}
///
interface IFindUnreadProps
  extends Partial<UseQueryOptions<AxiosResponse<number>>> {}
export function useFindUnreadActivitiesAmount({
  suspense = true,
  ...rest
}: IFindUnreadProps = {}) {
  return useQuery<AxiosResponse<number>>(
    [ENDPOINTS.ACTIVITIES + "/unread-amount"],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findUnreadActivitiesAmount(source);
    },
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      suspense,
      ...rest,
    } // set up default settings
  );
}

interface ISetActivitiesAsSeenProps
  extends Partial<UseMutationOptions<AxiosResponse<string[]>, any, any>> {}
//
export function useSetActivitiesAsSeenQuery({
  ...rest
}: ISetActivitiesAsSeenProps = {}) {
  return useMutation<AxiosResponse<string[]>, any, any>(
    (ids: string[]) => setActivitiesAsSeen(ids),
    {
      ...rest,
    }
  );
}
//////
interface IUpdateAttachmentsQueryProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IAttachment<IAsset, IUser>>, any, any>
  > {}

export function useUpdateAttachments({
  ...rest
}: IUpdateAttachmentsQueryProps = {}) {
  return useMutation<AxiosResponse<IAttachment<IAsset, IUser>>, any, any>(
    (data: IUpdateAttachmentsProps) => updateAttachments(data),
    {
      ...rest,
    }
  );
}
//
interface IQueryDownloadTextAttachmentProps
  extends Partial<UseQueryOptions<AxiosResponse<BufferType>>> {
  id: string | undefined;
  type?: "pdf" | "docx";
}

export function useDownloadTextAttachment({
  id = "",
  type = "pdf",
  ...rest
}: IQueryDownloadTextAttachmentProps) {
  return useQuery<AxiosResponse<BufferType>>(
    [ENDPOINTS.ATTACHMENTS_DOWNLOAD, id, type],
    async () => {
      const source = axios.CancelToken.source();
      return await getTextAttachment({
        source,
        id,
        type,
      });
    },
    { enabled: !!id, ...rest } // set up default settings
  );
}

interface IQueryMandatoryFilesProps
  extends Partial<UseQueryOptions<AxiosResponse<IMandatoryFile[]>>> {
  directory: "issues" | "solutions" | "action-groups";
}

export function useMandatoryFiles({
  directory,
  ...rest
}: IQueryMandatoryFilesProps) {
  return useQuery<AxiosResponse<IMandatoryFile[]>>(
    [ENDPOINTS.MANDATORY_FILES, directory],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await getMandatoryFiles({
        source,
        params: { directory: directory },
      });
    },
    { ...rest, enabled: !!directory }
  );
}

interface IQueryMandatoryAttachmentsProps
  extends Partial<UseQueryOptions<AxiosResponse<IMandatoryAttachments>>> {
  commonFor: "issues" | "solutions" | "actionGroups";
}

export function useMandatoryAttachments({
  commonFor,
  ...rest
}: IQueryMandatoryAttachmentsProps) {
  return useQuery<AxiosResponse<IMandatoryAttachments>>(
    [ENDPOINTS.ATTACHMENTS, commonFor],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await getMandatoryAttachments({
        source,
        params: { commonFor: commonFor },
      });
    },
    { ...rest, enabled: !!commonFor }
  );
}

///
interface IDeleteUserQueryProps
  extends Partial<UseMutationOptions<AxiosResponse<IUser>, any, any>> {}

export function useDeleteUser({ ...rest }: IDeleteUserQueryProps = {}) {
  return useMutation<AxiosResponse<IUser>, any, any>(
    (id: string) => deleteUser({ user: id }),
    {
      ...rest,
    }
  );
}
///
interface IQueryUsersProps
  extends Partial<UseQueryOptions<IAxiosResponseWithPagination<IUser>>>,
    IFindUsersProps {}
//
export function useUsers({ page, limit, ...rest }: IQueryUsersProps) {
  return useQuery<IAxiosResponseWithPagination<IUser>>(
    [ENDPOINTS.USERS, page, limit],
    async () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return await findUsers({
        page,
        limit,
        source,
      });
    },
    { ...rest } // set up default settings
  );
}

// Soft delete issues

interface ISoftDeleteIssueQueryProps
  extends Partial<UseMutationOptions<AxiosResponse<IIssue>, any, any>> {}

export function useSoftDeleteIssue({
  ...rest
}: ISoftDeleteIssueQueryProps = {}) {
  return useMutation<AxiosResponse<IIssue>, any, any>(
    (id: string) => softDeleteIssue({ issue: id }),
    {
      ...rest,
    }
  );
}

interface IRestoreIssueQueryProps
  extends Partial<UseQueryOptions<AxiosResponse<IIssue>>> {
  id: string | undefined;
}

export function useRestoreIssue({ id = "", ...rest }: IRestoreIssueQueryProps) {
  return useQuery<AxiosResponse<IIssue>>(
    [ENDPOINTS.ISSUE + "/soft", id],
    async () => {
      return await restoreIssue({
        issue: id,
      });
    },
    {
      enabled: false,
    }
  );
}

// Soft delete solutions

interface ISoftDeleteSolutionQueryProps
  extends Partial<UseMutationOptions<AxiosResponse<ISolution>, any, any>> {}

export function useSoftDeleteSolution({
  ...rest
}: ISoftDeleteSolutionQueryProps = {}) {
  return useMutation<AxiosResponse<ISolution>, any, any>(
    (id: string) => softDeleteSolution({ solution: id }),
    {
      ...rest,
    }
  );
}

interface IRestoreSolutionQueryProps
  extends Partial<UseQueryOptions<AxiosResponse<ISolution>>> {
  id: string | undefined;
}

export function useRestoreSolution({
  id = "",
  ...rest
}: IRestoreSolutionQueryProps) {
  return useQuery<AxiosResponse<ISolution>>(
    [ENDPOINTS.SOLUTIONS + "/soft", id],
    async () => {
      return await restoreSolution({
        solution: id,
      });
    },
    {
      enabled: false,
    }
  );
}

// Soft delete action groups

interface ISoftDeleteActionGroupQueryProps
  extends Partial<UseMutationOptions<AxiosResponse<IActionGroup>, any, any>> {}

export function useSoftDeleteActionGroup({
  ...rest
}: ISoftDeleteActionGroupQueryProps = {}) {
  return useMutation<AxiosResponse<IActionGroup>, any, any>(
    (id: string) => softDeleteActionGroup({ actionGroup: id }),
    {
      ...rest,
    }
  );
}

interface IRestoreActionGroupQueryProps
  extends Partial<UseQueryOptions<AxiosResponse<IActionGroup>>> {
  id: string | undefined;
}

export function useRestoreActionGroup({
  id = "",
  ...rest
}: IRestoreActionGroupQueryProps) {
  return useQuery<AxiosResponse<IActionGroup>>(
    [ENDPOINTS.ACTION_GROUPS + "/soft", id],
    async () => {
      return await restoreActionGroup({
        actionGroup: id,
      });
    },
    {
      enabled: false,
    }
  );
}
////
