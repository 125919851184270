import { useIssue, useMe, useSolutions } from "hooks";
import { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  CommentsCard,
  IssueForm,
  PromptModal,
  SolutionForm,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getRating } from "utils";
import { ReactComponent as Location } from "assets/images/location.svg";
import { IssueDetailCard } from "./IssueDetailCard";
import { SlideOver } from "components/SlideOver";
import Heading from "components/Heading/Heading";
import { SolutionsCard } from "./SolutionsCard";
import { APP_ROUTES } from "const";
import { ImpactRankingCard } from "./ImpactRankingCard";

function Issue() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { data: solutionsData } = useSolutions({
    limit: 9999,
    issue: params.id,
  });
  const { data: me } = useMe();
  const { data } = useIssue({ id: params.id, suspense: true });
  //
  const [ratingsForm, setRatingsForm] = useState(false);
  const [issuesForm, setIssuesForm] = useState(false);
  const [solutionsForm, setSolutionsForm] = useState(false);
  const [createdSolutionId, setCreatedSolutionId] = useState<
    string | undefined
  >(undefined);
  const [showSolutionPromptModal, setShowSolutionPromptModal] = useState(false);
  const [showSuccessSolutionPromptModal, setShowSuccessSolutionPromptModal] =
    useState(false);

  const onEditRatings = useCallback(() => {
    if (data?.data.user?.id && me?.data.id) {
      setRatingsForm(true);
    }
  }, [data?.data.user?.id, me?.data.id]);

  const onEditIssues = useCallback(() => {
    if (data?.data.user?.id === me?.data.id) {
      setIssuesForm(true);
    }
  }, [data?.data.user?.id, me?.data.id]);

  const onAddSolutions = useCallback(() => {
    if (me?.data.id) {
      setShowSolutionPromptModal(true);
    }
  }, [me?.data.id]);
  useEffect(() => {
    if (createdSolutionId) {
      setShowSuccessSolutionPromptModal(true);
    }
  }, [createdSolutionId]);

  const formattedAddress = data?.data?.location?.formattedAddress;
  const isSoftDeleted = !!data?.data.deletedAt;

  return (
    <div className=" sm:mx-[5%] pt-8 pb-4 max-w-[1600px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap">
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUES);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issues
        </span>
        <span className="text-[#606060] font-normal text-md cursor-pointer">
          &gt; Issue: {data?.data.title}
        </span>
      </div>
      <Heading type="h1">
        {data?.data.title} {isSoftDeleted && "(This issue was deleted)"}
      </Heading>
      <div className="flex gap-2 mt-2 items-center">
        <Location />
        {!!data?.data?.location && (
          <span className="text-black text-md">
            {formattedAddress || "No location"}
          </span>
        )}
      </div>
      <div className="grid pt-12 lg:grid-cols-2 gap-6">
        <IssueDetailCard
          id={data?.data?.user?.id}
          details={data?.data.details || ""}
          importance={data?.data.importance || ""}
          origin={data?.data.origin || ""}
          profileImage={data?.data?.user?.avatar}
          firstName={data?.data?.user?.firstName}
          lastName={data?.data?.user?.lastName}
          createdAt={data?.data.createdAt}
          rating={getRating(data?.data.rating)}
          primaryClassification={data?.data.primaryClassification}
          secondaryClassification={data?.data.secondaryClassification}
          onEdit={onEditIssues}
          allowEdit={data?.data.user?.id === me?.data.id}
          email={data?.data.user?.email}
          score={data?.data.user?.reputationScore}
          attachments={data?.data.attachments}
          tags={data?.data.tags}
          issueId={data?.data.id}
          isSoftDeleted={isSoftDeleted}
        />

        <ImpactRankingCard
          issue={data?.data.id}
          onEdit={onEditRatings}
          allowEdit={!!data?.data.user?.id && !!me?.data.id}
          isSoftDeleted={isSoftDeleted}
        />
        <SolutionsCard
          solutions={solutionsData?.data.items}
          details={data?.data.details || ""}
          importance={data?.data.importance || ""}
          origin={data?.data.origin || ""}
          profileImage={data?.data?.user?.avatar}
          firstName={data?.data?.user?.firstName}
          lastName={data?.data?.user?.lastName}
          createdAt={data?.data.createdAt}
          primaryClassification={data?.data.primaryClassification}
          secondaryClassification={data?.data.secondaryClassification}
          onEdit={onAddSolutions}
          allowEdit={!!me?.data.id}
          email={data?.data.user?.email}
          score={data?.data.user?.reputationScore}
          isSoftDeleted={isSoftDeleted}
        />
        <CommentsCard
          entityOwnerId={data?.data.user?.id}
          isSoftDeleted={isSoftDeleted}
        />
      </div>
      <SlideOver
        open={!!issuesForm}
        close={useCallback(() => {
          setIssuesForm(false);
        }, [])}
        title="Edit issue"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <IssueForm
          onSuccess={useCallback(() => {
            setIssuesForm(false);
          }, [])}
          onClose={useCallback(() => {
            setIssuesForm(false);
          }, [])}
          showIssueFields
          showRatings={false}
        />
      </SlideOver>
      <SlideOver
        open={!!solutionsForm}
        close={useCallback(() => {
          setSolutionsForm(false);
        }, [])}
        title="Propose Solution"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <SolutionForm
          onSuccess={useCallback(
            (id?: string) => {
              setCreatedSolutionId(id);
              setSolutionsForm(false);
            },
            [setCreatedSolutionId, setSolutionsForm]
          )}
          onClose={useCallback(() => {
            setSolutionsForm(false);
          }, [setSolutionsForm])}
          showSolutionFields
          showSolutionRatings={false}
        />
      </SlideOver>
      <SlideOver
        open={!!ratingsForm}
        close={useCallback(() => {
          setRatingsForm(false);
        }, [])}
        title="Rate issue"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <IssueForm
          onSuccess={useCallback(() => {
            setRatingsForm(false);
          }, [])}
          onClose={useCallback(() => {
            setRatingsForm(false);
          }, [])}
          showIssueFields={false}
          showRatings={true}
        />
      </SlideOver>
      {showSolutionPromptModal && (
        <PromptModal
          setShow={setShowSolutionPromptModal}
          header="Propose Solution"
          content="Before adding a new solution, please ensure a
          similar solution for this issue does not exist already"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSolutionPromptModal(false);
                  setSolutionsForm(true);
                }}
              >
                Continue, I have checked
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSolutionPromptModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        />
      )}
      {showSuccessSolutionPromptModal && createdSolutionId && (
        <PromptModal
          setShow={setShowSuccessSolutionPromptModal}
          header="Propose Solution"
          content="Will you like to create an action group to implement
          this solution?"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessSolutionPromptModal(false);
                  navigate(APP_ROUTES.SOLUTION + "/" + createdSolutionId);
                }}
              >
                Yes
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessSolutionPromptModal(false);
                  setCreatedSolutionId(undefined);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(Issue);
