import { Formik } from "formik";
import { Suspense, memo } from "react";

import AddActionGroupForm from "./AddActionGroup.component";
import {
  IValues,
  validationSchema,
  useInitialState,
  useSubmitHandler,
} from "./AddActionGroup.hooks";
import { Loader } from "components";

interface IProps {
  solution: string | undefined;
}
function AddActionGroupModalContainer({ solution }: IProps) {
  return (
    <Formik<IValues>
      enableReinitialize
      initialValues={useInitialState(solution)}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler(solution)}
    >
      <AddActionGroupForm />
    </Formik>
  );
}

export default memo((props: IProps) => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col h-full flex-1 w-full items-center justify-center">
          <Loader globalLoader={false} type="rings" size="small" />
        </div>
      }
    >
      <AddActionGroupModalContainer {...props} />
    </Suspense>
  );
});
