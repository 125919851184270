import { useMandatoryAttachments, useMandatoryFiles } from "hooks";
import {
  AttachmentFiletItem,
  AttachmentLinkItem,
} from "../AttachmentItem/AttachmentItem";
import { IAsset, IAttachment, IMandatoryFile } from "types";

type MandatoryAttachmentsType = {
  commonFor: "issues" | "solutions" | "actionGroups";
  isLinks?: boolean;
};

export const MandatoryAttachments = ({
  commonFor,
  isLinks = false,
}: MandatoryAttachmentsType) => {
  const { data: mandatoryAttachments } = useMandatoryAttachments({
    commonFor: commonFor,
  });

  return (
    <>
      {isLinks
        ? mandatoryAttachments?.data?.items
            ?.filter((i) => i?.type === "link")
            .map((attachment, index) => (
              <AttachmentLinkItem
                readOnly={true}
                key={index}
                value={attachment}
              />
            ))
        : mandatoryAttachments?.data?.items
            ?.filter((i) => i?.type === "file")
            .map((attachment, index) => (
              <AttachmentFiletItem
                key={index}
                // @ts-ignore
                attachment={attachment}
                readOnly={true}
                error={undefined}
              />
            ))}
    </>
  );
};

type MandatoryFilesType = {
  directory: "issues" | "solutions" | "action-groups";
};

export const MandatoryFiles = ({ directory }: MandatoryFilesType) => {
  const { data: mandatoryFiles } = useMandatoryFiles({ directory });
  return (
    <>
      {mandatoryFiles?.data?.map((file, index) => (
        <AttachmentFiletItem
          key={index}
          attachment={transformAttachmentData(file)}
          readOnly={true}
          error={undefined}
        />
      ))}
    </>
  );
};

function transformAttachmentData(
  data: IMandatoryFile
): Partial<IAttachment<IAsset, string>> {
  const { url, mimeType } = data;

  const originalName = new URL(url).pathname.split("/").pop() ?? "unknown";

  let assetType: "image" | "document";
  if (mimeType.startsWith("image/")) {
    assetType = "image";
  } else {
    assetType = "document";
  }

  const file = new File([], originalName, { type: mimeType });

  const image: Partial<IAttachment<IAsset, string>> = {
    id: originalName,
    type: "file",
    data: null,
    title: originalName,
    asset: {
      url: url,
      type: assetType,
      originalName: originalName,
      mimetype: mimeType,
      path: url,
      eTag: originalName,
      createdAt: String(file.lastModified),
      updatedAt: String(file.lastModified),
    },
  };

  return image;
}
