import { Outlet, useOutletContext } from "react-router-dom";
import { LayoutWrp } from "components";
import "./layouts.css";
import { IOutletAuth } from "types";
import { memo } from "react";

export function PublicLayout() {
  return (
    <LayoutWrp className="mxs:grid grid-rows-12 layout-width">
      <Outlet context={useOutletContext<IOutletAuth>()} />
    </LayoutWrp>
  );
}

export default memo(PublicLayout);
