import { APP_ROUTES } from "const";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { IMotivational, IMotivationalTag, IUser } from "types";
import { ToggleButtonMotivationalTagsGroup } from "components";

import classnames from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MotivationalCard } from "modules/MotivationalList/MotivationalCard";

import { IAxiosResponseWithPagination } from "types/requests";
import { useQueryOptionsSetter, useTags } from "hooks";
import _ from "lodash";

interface IProps {
  motivationals:
    | IAxiosResponseWithPagination<
        IMotivational<IUser, IMotivationalTag<string, string>>
      >[]
    | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  className?: string;
}

function MotivationalsCard({ innerRef, className, motivationals }: IProps) {
  const { data } = useTags({});

  const preparedData = data?.data.map((tag) => tag);

  const options = useMemo(() => {
    return _.compact(
      preparedData?.map((item) => ({
        label: item.name,
        value: item.id || "",
      }))
    );
  }, [preparedData]);
  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const [currentSelectedTags, setCurrentSelectedTags] = useState(options);
  const useEffectRef = useRef<boolean>(false);
  useEffect(() => {
    if (
      options.find((item) => {
        return item.label;
      }) &&
      !useEffectRef.current
    ) {
      useEffectRef.current = true;

      setCurrentSelectedTags(options);
    }
  }, [options]);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col overflow-hidden">
      <ToggleButtonMotivationalTagsGroup
        setCurrentSelectedTags={setCurrentSelectedTags}
        setQueryOptions={setQueryOptions}
        tags={currentSelectedTags}
      />
      <div
        className={classnames(
          className,
          "lg:h-[calc(100vh-218px)] max-h-[100vh] overflow-y-auto overflow-x-hidden p-4 "
        )}
      >
        <div className="flex flex-col  gap-y-4  ">
          {motivationals?.map((page, pagesIndex) => {
            return page.data.items.length < 1 ? (
              <div className="flex min-h-[400px] items-start justify-center col-span-6">
                <p className=" max-w-[700px] text-center text-xl">
                  There are no motivational contents found, please adjust your
                  search parameters or
                  <span
                    onClick={() => {
                      navigate(APP_ROUTES.MOTIVATIONAL_CREATE);
                    }}
                    className="font-bold px-1 hover:text-white cursor-pointer"
                  >
                    create a motivational content
                  </span>
                </p>
              </div>
            ) : (
              page.data.items.map((motivational, issueIndex) => {
                const isLast =
                  motivationals.length === pagesIndex + 1 &&
                  page.data.items.length === issueIndex + 1;

                return (
                  <div
                    className=" cursor-pointer z-10  shadow-issueCard hover:shadow-issueCardStrong  bg-white rounded-md  "
                    onClick={() => {
                      motivational.id &&
                        navigate(
                          APP_ROUTES.MOTIVATIONAL + "/" + motivational.id
                        );
                    }}
                    key={motivational.id}
                  >
                    <MotivationalCard
                      detailsMode
                      id={motivational.id}
                      image={motivational.image}
                      videoLink={motivational.videoLink}
                      thumbnailLink={motivational.thumbnailLink}
                      innerRef={isLast ? innerRef : undefined}
                      title={motivational.title}
                      firstName={motivational.user?.firstName}
                      lastName={motivational.user?.lastName}
                      createdAt={motivational.createdAt}
                      rate={motivational.rates && motivational.rates[0]}
                      likes={motivational.likes}
                      dislikes={motivational.dislikes}
                      views={motivational.views}
                    />
                  </div>
                );
              })
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default memo(MotivationalsCard);
