import { ENDPOINTS } from "const";
import { axios } from "services";
import { IUser, IUserSkill } from "types";
import { toFormData } from "utils";

interface IUpdateUserProps {
  id: string;
  user: IUser;
}

export function updateUser({ id, user }: IUpdateUserProps) {
  return axios.patch<IUser>(
    ENDPOINTS.USERS + "/" + id,
    toFormData(user, { indices: true })
  );
}

interface IUpdateUserSkillsProps {
  user: string;
  skills: string[];
}

export function updateUserSkills(data: IUpdateUserSkillsProps) {
  return axios.put<IUserSkill>(ENDPOINTS.USER_SKILLS + "/bulk", data);
}
