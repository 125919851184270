import { memo } from "react";
import { useField } from "formik";

import classnames from "classnames";
import { StarRatingItem } from "components";

interface IStarRatingField {
  name: string;
  title: string;
  disabled?: boolean;
  label?: string;
  className?: string;

  displayOnly?: boolean;
  ref?: React.MutableRefObject<any>;
  classNames?: {
    message?: string;
    label?: string;
    error?: string;
    input?: string;
    inputLabel?: string;
  };
}

function FormikRating({
  name,
  className,
  label,
  title,
  displayOnly = false,
  classNames,
  ...rest
}: IStarRatingField) {
  const [field, meta, { setValue }] = useField(name);
  const error = (!!meta.touched && meta.error) || "";
  const showError = !!error;

  return (
    <div
      className={classnames(className, {
        " pointer-events-none opacity-70 ": rest.disabled,
      })}
    >
      {label && (
        <label
          className={classnames(
            classNames?.label,
            " text-base mb-[13px] text-darkBlue05 font-bold"
          )}
        >
          {label}
        </label>
      )}
      <StarRatingItem
        {...field}
        {...rest}
        displayOnly={displayOnly}
        title={title}
        ratingValue={Number(field.value)}
        setRatingValue={setValue}
        classNames={{
          input: classNames?.input,
          label: classNames?.inputLabel,
        }}
      />
      {showError && (
        <div
          className={classnames(classNames?.error, " text-right ", {
            " text-warn mt-[10px] ": showError,
            " text-base mt-[10px] ": showError,
          })}
        >
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default memo(FormikRating);
