import classnames from "classnames";
import { Button, Loader } from "components";
import { memo, useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { IOutletAuth } from "types";

import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { saveAs } from "file-saver";

function Handouts() {
  const authContext = useOutletContext<IOutletAuth>();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setIsDocumentReady(true);
    setNumPages(numPages);
  }

  const startDownload = useCallback(async () => {
    const url = authContext.pdfUrl;
    if (url) {
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const res = await fetch(url);
      const blob = await res.blob();
      saveAs(blob, fileName);
    }
  }, [authContext.pdfUrl]);

  useEffect(() => {
    if (!authContext.pdfUrl) {
      setIsDocumentReady(false);
    }
  }, [setIsDocumentReady, authContext.pdfUrl]);
  return (
    <div className={classnames("flex flex-col  relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-3  mb-12 overflow-auto "
        )}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col h-[95%] max-h-[95%]">
            {isDocumentReady && authContext.pdfUrl && (
              <div className="flex flex-row justify-start sm:justify-end py-2 gap-3 flex-wrap">
                <Button className="px-2" onClick={startDownload}>
                  Download
                </Button>
                <Button
                  className="flex"
                  onClick={() => {
                    authContext.setPdfUrl(undefined);
                  }}
                >
                  Discard PDF
                </Button>
              </div>
            )}
            {authContext.pdfUrl && (
              <>
                <Document
                  loading={<Loader />}
                  file={authContext.pdfUrl}
                  className="h-full overflow-hidden select-none"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    scale={1.65}
                    className="h-[95%] "
                    style={{
                      height: "90%",
                      maxHeight: "95vw",
                      overflow: "hidden",
                    }}
                    pageNumber={pageNumber}
                  />
                </Document>
                <div className="flex flex-col gap-2 justify-center">
                  <div className="flex flex-row gap-2 justify-center">
                    <button
                      onClick={() => {
                        if (pageNumber > 1) {
                          setPageNumber((prevVal) => prevVal - 1);
                        }
                      }}
                    >
                      &larr;
                    </button>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                    <button
                      onClick={() => {
                        if (pageNumber < numPages) {
                          setPageNumber((prevVal) => prevVal + 1);
                        }
                      }}
                    >
                      &rarr;
                    </button>
                  </div>
                </div>
              </>
            )}
            {!authContext.pdfUrl &&
              authContext.handoutsNoGroupsFound === "NoSearch" && (
                <div className="flex flex-col text-center max-w-[260px] pt-14">
                  Please enter a location to find available resources
                </div>
              )}
            {!authContext.pdfUrl &&
              authContext.handoutsNoGroupsFound === "NoGroups" && (
                <div className="flex flex-col text-center max-w-[260px] pt-14">
                  No Action Groups found
                </div>
              )}
            {!authContext.pdfUrl &&
              authContext.handoutsNoGroupsFound === "Groups" && (
                <div className="flex flex-col text-center max-w-[260px] pt-14">
                  Please click the Find resources button to generate a document
                  of available resources.
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Handouts);
