import { ImageType, isFileType, isImageFileType, isImageType } from "types";
import * as yup from "yup";

import { MixedSchema } from "yup/lib/mixed";
export const ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".PNG",
  ".JPG",
  ".gif",
  "image/png",
];

async function uploadImage(dataUrl: string) {
  let image = new Image();
  image.src = dataUrl;

  return image;
}

/**
 * Check size of file that is tried to upload Check the size of the file that is being uploaded
 * @param fileDimension - max file dimension in bytes
 */
export function checkFileDimension(fileDimension: number) {
  return async (value: any) => {
    if (isImageType(value)) {
      const image = await uploadImage(value.dataUrl || "");

      return (
        !!value &&
        !!image.height &&
        !!image.width &&
        image.height <= fileDimension &&
        image.width <= fileDimension
      );
    }
    return true;
  };
}

/**
 * Check size of file that is tried to upload Check the size of the file that is being uploaded
 * @param fileSize - max file size in bytes
 */
export function checkFileSize(fileSize: number) {
  return (value: any) => {
    if (isFileType(value)) {
      return !!value && value?.size <= fileSize;
    }
    return true;
  };
}

/**
 *
 * @param cbs - array of cbs that pass to test method
 * @returns - yup schema
 */
export function mapTestRules<T>(
  cbs: {
    cb: yup.TestFunction<T>;
    id: string;
    message: string;
  }[]
): MixedSchema<any, Record<string, any>, any> {
  const result: MixedSchema<any, Record<string, any>, any> = yup.mixed();
  return cbs.reduce(
    (schema, { cb, id, message }) => schema.test(id, message, cb),
    result
  );
}

/**
 * compare type from file or file extension with allowed types
 * @param file - file
 * @param allowedType - mapped string from allowed types array
 */
function compareTypes(file: File | undefined) {
  return (allowedType: string) => {
    if (!file) {
      return false;
    }
    if (allowedType === file.type) return true;
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    if (fileExtension === allowedType) return true;
    return false;
  };
}
/**
 * Check the number of files that being uploaded
 * @param allowedNumbers - allowed numbers in array
 */
export function checkFileNumber(maxNumber: number) {
  return (_value: any, context: yup.TestContext) => {
    if (Array.isArray(context?.parent)) {
      return maxNumber >= context.parent.length;
    }
    return true;
  };
}

/**
 * Check the type of file that being uploaded
 * @param allowedTypes - allowed types in array
 */
export function checkFileType(allowedTypes: string[]) {
  return (value: ImageType) => {
    if (isImageFileType(value)) {
      return !!allowedTypes.find(compareTypes(value));
    }
    return true;
  };
}
