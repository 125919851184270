import React from "react";
import classnames from "classnames";

import "react-quill/dist/quill.bubble.css";

export interface IProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  isError?: boolean;

  classNames?: {
    wrapper?: string;
  };
}

function MotivationalTextArea({
  className,
  customRef,
  isError,
  classNames,

  ...rest
}: IProps) {
  const classes = classnames(className, " ");

  return <textarea ref={customRef} {...rest} className={classes} />;
}

export default MotivationalTextArea;
