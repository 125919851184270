/* eslint-disable no-useless-escape */
import { detect } from "detect-browser";
import { FullActionGroupType, IActivity, IUser } from "types";

import { Options, serialize } from "object-to-formdata";
import { APP_ROUTES, ENTITY_TYPES } from "const";

export function toFormData<T>(data: T, options?: Options) {
  return serialize(data, options);
}

type BrowsersType = "chrome" | "firefox" | "edge" | "safari";

export function checkBrowser(browser: BrowsersType): boolean {
  const detected = detect();
  return detected?.name === browser;
}

export function getRating(rating: number | undefined | null) {
  if (rating === 0 || !rating) {
    return 0;
  }
  return Math.round(rating * 10) / 10;
}
export function getSearchParams(searchParams: globalThis.URLSearchParams) {
  return {
    user: searchParams.get("user") || "",
    skills: searchParams.getAll("skills") || "",
    tags: searchParams.getAll("tags") || "",
    category: searchParams.getAll("category") || "",
    lat: searchParams.get("lat") || "",
    lng: searchParams.get("lng") || "",
    zoom: Number(searchParams.get("zoom")),
    actionGroup: searchParams.get("actionGroup") || "",
  };
}

export function getPlaceIdParams(searchParams: globalThis.URLSearchParams) {
  return searchParams.getAll("placeId") || "";
}

export function getBounds(viewport: google.maps.LatLngBounds) {
  const ne = viewport.getNorthEast().toJSON();
  const sw = viewport.getSouthWest().toJSON();
  return { ne, sw };
}

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

export function getFirstLocationInActionGroupEntity(
  actionGroups?: FullActionGroupType | undefined | null
) {
  const firstSolution = actionGroups?.solutions && actionGroups?.solutions[0];
  if (firstSolution) {
    return firstSolution.issue?.location;
  }
  return null;
}
///
function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // returns distance in kilometers
}

export function computeCenterAndRadius(viewport: google.maps.LatLngBounds) {
  // Calculate center
  const centerLat =
    (viewport.getNorthEast().lat() + viewport.getSouthWest().lat()) / 2;
  const centerLng =
    (viewport.getNorthEast().lng() + viewport.getSouthWest().lng()) / 2;

  // Calculate radius (half of diagonal distance)
  const diagonalDistance = haversineDistance(
    viewport.getNorthEast().lat(),
    viewport.getNorthEast().lng(),
    viewport.getSouthWest().lat(),
    viewport.getSouthWest().lng()
  );

  const radius = diagonalDistance / 2;

  return {
    center: { lat: centerLat, lng: centerLng },
    radius: radius,
  };
}
//
export function getYouTubeVideoId(
  url: string | undefined | null
): string | null {
  // Regular expression pattern to extract YouTube video ID
  const youtubeRegex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Regular expression pattern to extract YouTube Shorts video ID
  const shortsRegex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:shorts\/)|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Execute the regular expression on the URL
  const match = url?.match(youtubeRegex) || url?.match(shortsRegex);

  // If there's a match, return the video ID (group 1), otherwise return null
  return match ? match[1] : null;
}
export function matchYoutubeUrl(url: string | undefined | null) {
  // Regular expression pattern to match YouTube video URLs
  const youtubeRegex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:shorts\/)?|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Test if the URL matches the pattern
  return youtubeRegex.test(url || "");
}

export function getNotificationDescription(notification: IActivity<any>) {
  return notification.content;
}

export function getActivityNavigation(entityType: ENTITY_TYPES, id: string) {
  let link = "";
  if (
    entityType === ENTITY_TYPES.ISSUE ||
    entityType === ENTITY_TYPES.ISSUE_RATING
  ) {
    link = APP_ROUTES.ISSUE + "/" + id;
  }
  if (
    entityType === ENTITY_TYPES.SOLUTION ||
    entityType === ENTITY_TYPES.SOLUTION_RATING
  ) {
    link = APP_ROUTES.SOLUTION + "/" + id;
  }
  if (
    entityType === ENTITY_TYPES.ACTION_GROUP ||
    entityType === ENTITY_TYPES.ACTION_GROUP_RATING ||
    entityType === ENTITY_TYPES.ACTION_GROUP_USER
  ) {
    link = APP_ROUTES.ACTION_GROUP + "/" + id;
  }
  if (
    entityType === ENTITY_TYPES.MOTIVATIONAL ||
    entityType === ENTITY_TYPES.MOTIVATIONAL_RATING
  ) {
    link = APP_ROUTES.MOTIVATIONAL + "/" + id;
  }
  return link;
}

//
export function getUserName(user: IUser<any> | undefined | null) {
  let name: string | undefined = "";
  if (user?.firstName) {
    name += capitalizeFirstLetter(user.firstName);
  }
  if (user?.lastName) {
    name += " " + capitalizeFirstLetter(user.lastName);
  }
  if (!name) {
    name = user?.email?.toLowerCase();
  }

  return name || "Unknown user";
}
function capitalizeFirstLetter(string: string) {
  return string.trim().charAt(0).toUpperCase() + string.slice(1);
}
