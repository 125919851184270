import { Formik } from "formik";
import { memo } from "react";

import validationSchema from "./MotivationalForm.schema";
import { useInitialState, useSubmitHandler } from "./MotivationalForm.hooks";
import MotivationalForm from "./MotivationalForm.component";
import { IManagementProps } from "./MotivationalForm.types";
import { InitialMotivational } from "./MotivationalForm.constants";

interface IProps extends IManagementProps {
  className?: string;
  onSuccess: () => void;
}

function MotivationalFormContainer({
  className,
  onSuccess,
  showIssueFields = true,
  showRatings = true,
}: IProps) {
  return (
    <Formik<InitialMotivational>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler(onSuccess)}
    >
      <MotivationalForm
        className={className}
        onClose={onSuccess}
        showIssueFields={showIssueFields}
        showRatings={showRatings}
      />
    </Formik>
  );
}

export default memo(MotivationalFormContainer);
