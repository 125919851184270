import classnames from "classnames";
import { Card } from "components/Card";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ChevronDown from "assets/images/chevron-down.svg";
import { useCommentConfig, useMe } from "hooks";
import { FastCommentsCommentWidget } from "fastcomments-react";
import {
  useLocation,
  useMatch,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Loader from "components/Loader";

import { ACTIVITY_TYPES, APP_ROUTES, ENTITY_TYPES } from "const";
import { useCreateActivities } from "./CommentsCard.hooks";
import {
  ActionGroupUserMembership,
  ActionGroupUserStatus,
  IOutletAuth,
} from "types";
import { getUserName } from "utils";

export interface IFastComment {
  _id: string;
  userId: string;
  anonUserId?: string;
  commenterName: string;
  commentHTML: string;
  parentId?: string;
  date: Date;
  votes: number;
  votesUp: number;
  votesDown: number;
  verified: boolean;
  avatarSrc: string;
  hasImages: boolean;
  displayLabel?: any;
  badges: any[];
  children?: any[];
  isByAdmin: boolean;
  isByModerator: boolean;
}
interface IProps {
  entityOwnerId: string | undefined;
  isPrivate?: boolean;
  membership?: undefined | ActionGroupUserMembership;
  status?: undefined | ActionGroupUserStatus;
  ruleSet?: "Solution" | "ActionGroup" | "Issue" | "Motivational" | "Default";
  isSoftDeleted?: boolean;
}

function CommentsCard({
  entityOwnerId,
  isPrivate = false,
  membership = undefined,
  status = undefined,
  ruleSet = "Default",
  isSoftDeleted = false,
}: IProps) {
  const authContext = useOutletContext<IOutletAuth>();
  const mountRef = useRef(0);
  useEffect(() => {
    if (mountRef.current < 1) {
      mountRef.current = mountRef.current + 1;
    }
  }, []);

  const [cardCollapsed, setDescriptionCollapsed] = useState(false);
  const params = useParams();
  const urlId = params.id;
  const me = useMe();
  const meId = me?.data?.data.id;

  const config = useCommentConfig({ enabled: mountRef.current === 0 });
  const memoizedConfig = useMemo(() => {
    return config?.data?.data;
  }, [config]);
  const [isLoading, setIsLoading] = useState(true);
  const [replySuccess, setReplySuccess] = useState(false);
  const ref = useRef();
  const location = useLocation();
  const memoizedLocation = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);
  const { mutateAsync } = useCreateActivities();
  useEffect(() => {
    if (replySuccess) {
      setIsLoading(true);
    }
  }, [replySuccess]);

  useEffect(() => {
    if (replySuccess && isLoading) {
      setIsLoading(false);
      setReplySuccess(false);
    }
  }, [replySuccess, isLoading]);

  let entityType = "" as ENTITY_TYPES;

  const isMotivational = useMatch({
    path: APP_ROUTES.MOTIVATIONAL,
    end: false,
  });
  if (isMotivational) {
    entityType = ENTITY_TYPES.MOTIVATIONAL;
  }
  const isIssue = useMatch({
    path: APP_ROUTES.ISSUE,
    end: false,
  });
  if (isIssue) {
    entityType = ENTITY_TYPES.ISSUE;
  }
  const isSolution = useMatch({
    path: APP_ROUTES.SOLUTION,
    end: false,
  });
  if (isSolution) {
    entityType = ENTITY_TYPES.SOLUTION;
  }
  const isActionGroup = useMatch({
    path: APP_ROUTES.ACTION_GROUP,
    end: false,
  });
  if (isActionGroup) {
    entityType = ENTITY_TYPES.ACTION_GROUP;
  }

  const privateCustomCss = `
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600&display=swap');
    * {
        font-family: Manrope;
        color: #606060;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
    }
    .top-area {
      display: ${meId ? "block" : "none"}
    }
    .footer {
      display: none
    }
    .downvote, .upvote {
      pointer-events: ${meId ? "default" : "none"}
    }
    .comment-toolbar-reply {
      display: ${meId ? "block" : "none"}
    }
    .menu {
      display: ${meId ? "block" : "none !important"}
    }
    .avatar-wrapper {
      pointer-events: ${meId ? "default" : "none"};
      margin-top: 15px
    }
    .icon.up {
      background: url(https://app.weattitude.org/like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .icon.up.active, .icon.up:hover {
      background: url(https://app.weattitude.org/active-like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    
    .divider {
      display:none;
    }
    .comment-bottom-toolbar {
      display:flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
    .comment-toolbar-reply {
      position: relative !important;
    }
    .comment .avatar-wrapper {
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
    .children .comment .avatar-wrapper {
      width: 35px;
      height: 35px;
    }
    .comment > .inner > .comment-bottom {
      margin-top: 0px;
    }
    #comment-area {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    .comment > .inner {
      padding: 0
    }
    .select-dir-wrapper {
      border-bottom: 0;
    }
    .menu.empty, .menu.dropdown, .icon.bubble {
      display: none !important;
    }
    .comment > .inner > .comment-content .comment-text {
      color: #606060;
      line-height: 25.5px;
      font-weight: 400;
    }         
    b {
      color: black
    }
    .comment {
      margin-top: 10px;
    }
    .comment > .inner > .comment-bottom .reply-form-wrapper {
      padding: 0
    }
    .comment > .toggle-replies {
      margin-top: 5px;
    }
    .comment-reply textarea {
      border-radius: 7px;
      border-bottom: 1px solid #bfbfbf;
    }
    .comment-reply.root {
      padding: 15px 0 0 0;
    }
    .horizontal-border {
      display: none !important;
    }
    .comment .comment-reply textarea {
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
    }
    .fast-comments-reply, .comment .fast-comments-reply, .edit-cancel, .edit-save {
      border: 0px; 
      border-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      transition-property: opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background: linear-gradient( 89.55deg, #4d3dd5 -13.7%, #7a6ff0 6.72%, #a39dfd 21.77%, #a4a5fb 42.04%, #59cbe4 74.84%, rgba(29, 127, 243, 0.886708) 93.78%, rgba(36, 77, 221, 0.79) 109.94% );
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      background-size: 400% 400%;
      animation: gradient 10s ease infinite;
      font-weight: 600;

    }
    .fast-comments-reply:before, .comment .fast-comments-reply:before, .edit-cancel:before, .edit-save:before {
      content: "Submit Reply";
      display: flex;
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .fast-comments-reply:hover:before, .edit-cancel:hover:before, .edit-save:hover:before  {
      background-color: #11ffee00;
    }
    .notification-bell {
      display: none !important;
    }
    .edit-cancel:before {
      content: "Cancel"
    }
    .edit-save:before {
      content: "Save"
    }
    .edit-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    .comment-edit textarea {
      border-radius: 11px
    }
    .pagination {
      margin-top: 6px;
      padding-bottom: 16px;
    }
`;

  const publicCustomCss = `
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600&display=swap');
    * {
        font-family: Manrope;
        color: #606060;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
    }
    .top-area {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none"
          : meId
          ? "block"
          : "none"
      };
    }
    .footer {
      display: none
    }
    .downvote, .upvote {
      pointer-events: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "default"
            : "none"
          : meId
          ? "default"
          : "none"
      };
    }
    .comment-toolbar-reply {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none"
          : meId
          ? "block"
          : "none"
      };
    }
    .menu {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none !important"
          : meId
          ? "block"
          : "none !important"
      };
    }
    .avatar-wrapper {
      pointer-events: ${meId ? "default" : "none"};
      margin-top: 15px
    }
    .icon.up {
      background: url(https://app.weattitude.org/like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
      margin-right: 10px;
    }
    .icon.up.active, .icon.up:hover {
      background: url(https://app.weattitude.org/active-like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
      margin-right: 10px;
    }
    .icon.down {
      background: url(https://app.weattitude.org/dislike.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .icon.down.active, .icon.down:hover {
      background: url(https://app.weattitude.org/active-dislike.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
     
    .divider {
      display:none;
    }
    .comment-bottom-toolbar {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "flex"
            : "none"
          : meId
          ? "flex"
          : "none"
      };
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
    .comment-toolbar-reply {
      position: relative !important;
    }
    .comment .avatar-wrapper {
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
    .children .comment .avatar-wrapper {
      width: 35px;
      height: 35px;
    }
    .comment > .inner > .comment-bottom {
      margin-top: 0px;
    }
    #comment-area {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    .comment > .inner {
      padding: 0
    }
    .select-dir-wrapper {
      border-bottom: 0;
    }
    .menu.empty, .menu.dropdown, .icon.bubble {
      display: none !important;
    }
    .comment > .inner > .comment-content .comment-text {
      color: #606060;
      line-height: 25.5px;
      font-weight: 400;
    }         
    b {
      color: black
    }
    .comment {
      margin-top: 10px;
    }
    .comment > .inner > .comment-bottom .reply-form-wrapper {
      padding: 0
    }
    .comment > .toggle-replies {
      margin-top: 5px;
    }
    .comment-reply textarea {
      border-radius: 7px;
      border-bottom: 1px solid #bfbfbf;
    }
    .comment-reply.root {
      padding: 15px 0 0 0;
    }
    .horizontal-border {
      display: none !important;
    }
    .comment .comment-reply textarea {
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
    }
    .fast-comments-reply, .comment .fast-comments-reply, .edit-cancel, .edit-save {
      border: 0px; 
      border-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      transition-property: opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background: linear-gradient( 89.55deg, #4d3dd5 -13.7%, #7a6ff0 6.72%, #a39dfd 21.77%, #a4a5fb 42.04%, #59cbe4 74.84%, rgba(29, 127, 243, 0.886708) 93.78%, rgba(36, 77, 221, 0.79) 109.94% );
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      background-size: 400% 400%;
      animation: gradient 10s ease infinite;
      font-weight: 600;

    }
    .fast-comments-reply:before, .comment .fast-comments-reply:before, .edit-cancel:before, .edit-save:before {
      content: "Submit Reply";
      display: flex;
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .fast-comments-reply:hover:before, .edit-cancel:hover:before, .edit-save:hover:before  {
      background-color: #11ffee00;
    }
    .notification-bell {
      display: none !important;
    }
    .edit-cancel:before {
      content: "Cancel"
    }
    .edit-save:before {
      content: "Save"
    }
    .edit-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    .comment-edit textarea {
      border-radius: 11px
    }
    .pagination {
      margin-top: 6px;
      padding-bottom: 16px;
    }
`;
  const [commentsList, setCommentsList] = useState<undefined | IFastComment[]>(
    undefined
  );
  useEffect(() => {
    const eventHandler = (e: any) => {
      if (!e.data) {
        return;
      }
      try {
        const data = JSON.parse(e.data);
        if (data.type === "on-comments-rendered") {
          if (commentsList === undefined) {
            setCommentsList(data.comments);
          }
        }
      } catch (err) {}
    };
    window.addEventListener("message", eventHandler, false);
    return () => {
      window.removeEventListener("message", eventHandler, false);
    };
  }, [commentsList, setCommentsList]);

  return (
    <div className="flex flex-col relative">
      <Card
        className={classnames("bg-white flex-1", {
          "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
        })}
        shouldHover={false}
        collapse={cardCollapsed}
        header={
          <div
            // onTouchStart={() => {
            //   setDescriptionCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1"
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
              <img
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": cardCollapsed,
                })}
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">
                {location.pathname.includes(APP_ROUTES.ACTION_GROUP)
                  ? "Group Discussions"
                  : "Comments"}
              </span>
            </div>
          </div>
        }
        classNames={{
          header: "px-6 sm:px-16 ",
          content: "!px-0 flex-1",
          footer: "px-6 sm:px-16 h-[60px]",
        }}
        content={
          <div
            className="flex flex-col max-h-[450px] overflow-y-auto overflow-x-hidden"
            //@ts-ignore
            ref={ref}
          >
            {location.pathname.includes(APP_ROUTES.ACTION_GROUP) && (
              <>
                <div
                  className={classnames(
                    "flex justify-center items-center py-2 px-8",
                    {
                      hidden:
                        (!isPrivate && !!membership) ||
                        (!isPrivate && !authContext.isAuth) ||
                        (!isPrivate && authContext.isAuth) ||
                        (isPrivate && !!membership),
                    }
                  )}
                >
                  <p>You can only view comments until you join the group</p>
                </div>
                <div
                  className={classnames(
                    "flex justify-center items-center py-2 px-8",
                    {
                      hidden:
                        commentsList?.length !== undefined &&
                        commentsList.length > 0,
                    }
                  )}
                >
                  {commentsList?.length === 0 && (
                    <p>There are no comments yet</p>
                  )}
                </div>
              </>
            )}
            {(location.pathname.includes(APP_ROUTES.ISSUE) ||
              location.pathname.includes(APP_ROUTES.SOLUTION) ||
              location.pathname.includes(APP_ROUTES.MOTIVATIONAL)) && (
              <div
                className={classnames(
                  "flex justify-center items-center py-2 px-8",
                  {
                    hidden:
                      commentsList?.length !== undefined &&
                      commentsList.length > 0,
                  }
                )}
              >
                {commentsList?.length === 0 && <p>There are no comments yet</p>}
              </div>
            )}
            {isLoading && ref.current && (
              <Loader type="rings" portalNode={ref.current} />
            )}
            <FastCommentsCommentWidget
              allowAnon={false}
              allowAnonVotes={false}
              disableEmailInputs
              disableImageRedirect
              disableUnverifiedLabel
              disableSuccessMessage
              tenantId={process.env.REACT_APP_FAST_COMMENT_TENANT_ID + ""}
              sso={memoizedConfig}
              collapseReplies
              disableLiveCommenting
              onRender={useCallback(() => {
                setIsLoading(false);
              }, [])}
              //@ts-ignore
              onCommentsRendered={(...rest) => {
                //console.log("E", rest);
              }}
              onReplySuccess={useCallback(
                (comment) => {
                  let title = "";

                  if (entityType === ENTITY_TYPES.ISSUE) {
                    title = `New comment on your issue!`;
                  }
                  if (entityType === ENTITY_TYPES.SOLUTION) {
                    title = `New comment on your solution!`;
                  }
                  if (entityType === ENTITY_TYPES.ACTION_GROUP) {
                    title = `New comment on your group!`;
                  }
                  if (entityType === ENTITY_TYPES.MOTIVATIONAL) {
                    title = `New comment on your motivational!`;
                  }

                  if (comment.parentId) {
                    const aParentToReply = commentsList?.find(
                      (item) => item._id === comment.parentId
                    );

                    setReplySuccess(true);
                    if (aParentToReply) {
                      const parentIdToSendNotification =
                        aParentToReply.userId.substring(
                          aParentToReply.userId.indexOf(":") + 1
                        );
                      return mutateAsync({
                        activityType: ACTIVITY_TYPES.REPLY,
                        activityUser: meId,
                        entityUser: parentIdToSendNotification,
                        entity: urlId,
                        representEntityType: entityType,
                        representEntityId: urlId,
                        representEntityUser: parentIdToSendNotification,
                        entityType: entityType,
                        title,
                        content: `${getUserName(
                          me.data?.data
                        )} has reply to your comment: "${comment.comment}"`,
                      });
                    } else {
                      return;
                    }
                  }
                  if (entityOwnerId && entityOwnerId === meId) {
                    setReplySuccess(true);
                    return;
                  }
                  setReplySuccess(true);
                  return mutateAsync({
                    activityType: ACTIVITY_TYPES.COMMENT,
                    activityUser: meId,
                    entity: urlId,
                    entityType,
                    representEntityId: urlId,
                    representEntityType: entityType,
                    representEntityUser: entityOwnerId,
                    title,
                    content: `${getUserName(
                      me.data?.data
                    )} has left comment: "${comment.comment}".`, // comment.comment,
                  });
                },
                [
                  commentsList,
                  entityOwnerId,
                  entityType,
                  me.data?.data,
                  meId,
                  mutateAsync,
                  urlId,
                ]
              )}
              onVoteSuccess={useCallback(
                (vote, ...rest) => {
                  const voteUser = vote.userId?.split(":")[1];
                  if (rest[1] === "up" && voteUser !== meId) {
                    debugger;
                    return mutateAsync({
                      activityType: ACTIVITY_TYPES.COMMENT_LIKE,
                      activityUser: meId,
                      entity: urlId,
                      representEntityId: urlId,
                      representEntityType: entityType,
                      representEntityUser: voteUser,
                      entityType: entityType,
                      title: "New like on your comment!",
                      content: `${getUserName(
                        me.data?.data
                      )} has liked your comment.`,
                      entityUser: vote.userId?.split(":")[1],
                    });
                  }
                },
                [entityType, me.data?.data, meId, mutateAsync, urlId]
              )}
              locale="en"
              url={process.env.REACT_APP_HOST + "" + memoizedLocation}
              urlId={urlId}
              //noStyles
              //@ts-ignore
              customCSS={isPrivate ? privateCustomCss : publicCustomCss}
            />
          </div>
        }
      />
    </div>
  );
}

export default memo(CommentsCard);
