import { APP_ROUTES } from "const";
import { FormikHelpers } from "formik";
import { useSendResetPasswordRequestApi } from "hooks";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FIELD_NAMES } from "./ForgetPasswordForm.constants";
import { ValuesType } from "./ForgetPasswordForm.types";

export function useInitialState() {
  return useMemo(() => {
    return {
      [FIELD_NAMES.EMAIL]: "",
    };
  }, []);
}
export function useSubmitHandler() {
  const { mutateAsync } = useSendResetPasswordRequestApi({});
  const navigate = useNavigate();
  return useCallback(
    async (props: ValuesType, formikHelpers: FormikHelpers<ValuesType>) => {
      await mutateAsync({ email: props.email.toLowerCase() })
        .then(() => {
          navigate(`${APP_ROUTES.CONFIRM_EMAIL}?passwordReset=true`);
        })
        .catch((error: any) => {
          formikHelpers.setErrors(error.response.data);
        });
    },
    [mutateAsync, navigate]
  );
}
