import React, { memo } from "react";
import classnames from "classnames";
import "./ButtonRound.css";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  approveStyle?: boolean;
  declineStyle?: boolean;
  warningStyle?: boolean;
  regularStyle?: boolean;
  onClick?: (params: any) => void;

  innerRef?: (node?: Element | null | undefined) => void;
  classNames?: {
    content?: string;
  };
}

function ButtonRound({
  className,
  children,
  approveStyle,
  declineStyle,
  warningStyle,
  regularStyle = true,
  classNames,

  ...rest
}: IProps) {
  const classes = classnames(
    className,
    "relative flex outline-none focus:outline-none items-center text-sm rounded-full group",
    { "   ": regularStyle },
    { "border border-yellow-400  ": warningStyle },
    { "border border-alert  ": declineStyle },
    { "border border-green-400  ": approveStyle },
    { "cursor-not-allowed ": rest.disabled }
  );

  return (
    <button className={classes} {...rest} ref={rest.innerRef}>
      {children}
    </button>
  );
}

export default memo(ButtonRound);
