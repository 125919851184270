import classnames from "classnames";
import { memo } from "react";

export interface IProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  options: Array<any> | null;

  classNames?: {
    input?: string | undefined;
  };
}

function Select({ id, options, classNames, ...rest }: IProps) {
  const classes = classnames("select", rest.className);

  return (
    <div className={classnames(classes, " flex flex-col ", {})}>
      <select
        {...rest}
        disabled={rest.disabled}
        className={classnames(classNames?.input)}
      ></select>
    </div>
  );
}

Select.defaultProps = { options: [] };

export default memo(Select);
