import { Formik } from "formik";
import { memo, useMemo, useState } from "react";

import validationSchema from "./ProfileForm.schema";
import { useInitialState, useSubmitHandler } from "./ProfileForm.hooks";
import ProfileForm from "./ProfileForm.component";
import { useParams } from "react-router-dom";
import { useMe } from "hooks";
import { CommentsProfile } from "components";
import classnames from "classnames";
import { ProfileFormInitial } from "./ProfileForm.constants";

interface IProps {
  className?: string;
}

function ProfileFormContainer({ className }: IProps) {
  const [tabSelected, setTabSelected] = useState<
    "Profile" | "ActionGroups" | "Issues" | "Solutions" | "Motivationals"
  >("Profile");
  const params = useParams<{ userId: string }>();
  const { data } = useMe({ keepPreviousData: false }); ///
  const initial = data?.data;
  const viewAUser = useMemo(() => {
    return params.userId ? params.userId !== initial?.id : false;
  }, [params.userId, initial?.id]);

  return (
    <Formik<ProfileFormInitial>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler()}
    >
      {(props) => (
        <>
          <div
            className={classnames(
              "flex flex-wrap flex-col sm:flex-row gap-3 items-center sm:items-start sm:gap-5 pt-2 pb-6 px-2 ",
              {
                hidden: viewAUser && props.values.isPrivate,
              }
            )}
          >
            <span
              onClick={() => {
                setTabSelected("Profile");
              }}
              className={classnames(
                "cursor-pointer bg-white rounded-md shadow-md px-2 py-1 w-[161px] sm:w-auto items-center text-center ",
                {
                  " !bg-black text-white ": tabSelected === "Profile",
                }
              )}
            >
              {viewAUser ? "Profile" : "My profile"}
            </span>
            <span
              onClick={() => {
                setTabSelected("Issues");
              }}
              className={classnames(
                "cursor-pointer bg-white rounded-md shadow-md px-2 py-1 w-[161px] sm:w-auto items-center text-center ",
                {
                  " !bg-black text-white": tabSelected === "Issues",
                }
              )}
            >
              {viewAUser ? "Issues" : "My issues"}
            </span>
            <span
              onClick={() => {
                setTabSelected("Solutions");
              }}
              className={classnames(
                "cursor-pointer bg-white rounded-md shadow-md px-2 py-1 w-[161px] sm:w-auto items-center text-center ",
                {
                  " !bg-black text-white": tabSelected === "Solutions",
                }
              )}
            >
              {viewAUser ? "Solutions" : "My solutions"}
            </span>
            <span
              onClick={() => {
                setTabSelected("ActionGroups");
              }}
              className={classnames(
                "cursor-pointer bg-white rounded-md shadow-md px-2 py-1 w-[161px] sm:w-auto items-center text-center ",
                {
                  " !bg-black text-white": tabSelected === "ActionGroups",
                }
              )}
            >
              {viewAUser ? "Action Groups" : "My action groups"}
            </span>
            <span
              onClick={() => {
                setTabSelected("Motivationals");
              }}
              className={classnames(
                "cursor-pointer bg-white rounded-md shadow-md px-2 py-1 w-[161px] sm:w-auto items-center text-center ",
                {
                  " !bg-black text-white": tabSelected === "Motivationals",
                }
              )}
            >
              {viewAUser ? "Motivational contents" : "My motivational contents"}
            </span>
            {!viewAUser && <CommentsProfile />}
          </div>
          <ProfileForm
            {...props}
            className={className}
            tabSelected={tabSelected}
            readOnly={viewAUser}
            reputationScore={data?.data.reputationScore}
          />
        </>
      )}
    </Formik>
  );
}

export default memo(ProfileFormContainer);
