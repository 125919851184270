const getMapBounds = (maps: any, places: any) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place: any) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

const bindResizeListener = (map: any, maps: any, bounds: any) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

export const apiIsLoaded = (map: any, maps: any, places: any) => {
  // Get bounds by our places
  const bounds = getMapBounds(maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

export function getRange(
  zoom: number | undefined,
  lat: number,
  width: number = 600
) {
  //https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
  // const hypotenuse = Math.sqrt(
  //   Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
  // );
  //const radius = (meterPerPx * hypotenuse) / 1000;
  const meterPerPx =
    (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom || 1);
  const radius = (meterPerPx * width) / 1000 / 2;
  return Math.round(radius > 1 ? radius : 1); //transform to km/px and then get half for radius and multiply by 1.3
}

export function calculateEps(range: number) {
  if (range <= 3) return 0.0001;

  return ((1 / 111) * range) / 20; //eps -define cluster area
}
