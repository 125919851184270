import { memo, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Loader } from "components";
import { APP_ROUTES } from "const";
import { ProfileBlock, Card } from "components";
import { BorderBadge } from "components/BorderBadge";
import { getRating } from "utils";
import classnames from "classnames";
import { useIssue, useWindowDimensions } from "hooks";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";

interface ICard {
  id: string | undefined;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
}

interface IRatingBlockProps {
  rating: number;
  showRatingTooltip: boolean;
}

function RatingBlock({ rating, showRatingTooltip }: IRatingBlockProps) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (showRatingTooltip) {
          setOpenPopup(true);
        }
      }}
      onMouseOut={() => {
        setOpenPopup(false);
      }}
      className={classnames(
        " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start justify-center px-2 text-center  ",
        {
          " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
            openPopup,
        }
      )}
    >
      {getRating(rating) || 0}
      <RatingStar height={8} width={8} fill="black" />
      {showRatingTooltip && openPopup && (
        <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[150%] text-sm font-normal after:tooltiptoparrow">
          <div className="flex flex-col relative text-xs bg-white p-2">
            <p>How relevant is this issue? </p>
            <p className="hidden sm:inline">
              Please click "See Full Details" to rate
            </p>
            <p className="inline sm:hidden">Please click "Details" to rate</p>
          </div>
          <span className="absolute border-[8px] top-[-17px] right-[9%] xx:right-[25%] lg:right-[31%] border-t-transparent border-b-[#8159D7] border-r-transparent border-l-transparent tooltiptoparrow" />
        </div>
      )}
    </span>
  );
}

function Item({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {content}
      </span>
    </>
  );
}

function IssueCardModal({ id, className, setShow }: ICard, ref) {
  const navigate = useNavigate();
  const { data, isLoading } = useIssue({ id: id, suspense: false });
  const date =
    data?.data.createdAt &&
    new Date(data?.data.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

  const formattedAddress = data?.data?.location?.formattedAddress;

  const dimensions = useWindowDimensions();

  const isSoftDeleted = !!data?.data.deletedAt;

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classnames(
        className,
        "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
      )}
    >
      {isLoading ? (
        <Loader type="rings" />
      ) : (
        <Card
          shouldHover={false}
          className={classnames(
            "flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40",
            { "bg-gray-100 pointer-events-none select-none": isSoftDeleted }
          )}
          header={
            <div className="flex justify-between items-center pb-1">
              <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                <span className="font-bold leading-6 text-lg line-clamp-2">
                  {data?.data.title}
                </span>
                <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                  {formattedAddress}
                </span>
              </div>
              <XIcon
                onClick={() => {
                  setShow(false);
                }}
                className="!pointer-events-auto h-6 w-6 shrink-0 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          }
          classNames={{
            header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            content: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            footer: "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
          }}
          content={
            <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
              <Item title="Details" content={data?.data.details} />
              <Item title="Origin" content={data?.data.origin} />
              <Item title="Importance" content={data?.data.importance} />
              <Item
                title="Category"
                content={
                  <div className="flex items-start flex-wrap  gap-4 py-1">
                    {!!data?.data.primaryClassification && (
                      <BorderBadge
                        classificationName={
                          data?.data?.primaryClassification?.category
                        }
                        classificationDescription={
                          data?.data?.primaryClassification?.description
                        }
                        tooltipId={id}
                      >
                        {data?.data.primaryClassification?.title}
                      </BorderBadge>
                    )}
                    {!!data?.data.secondaryClassification && (
                      <BorderBadge
                        classificationName={
                          data?.data.secondaryClassification.category
                        }
                        classificationDescription={
                          data?.data.secondaryClassification.description
                        }
                        tooltipId={id}
                      >
                        {data?.data.secondaryClassification.title}
                      </BorderBadge>
                    )}
                  </div>
                }
              />
              {data?.data?.tags && data?.data?.tags?.length > 0 && (
                <Item
                  title="Labels"
                  content={
                    <div className="flex items-center flex-wrap gap-2 py-1">
                      {data?.data?.tags?.map((tag) => (
                        <BorderBadge
                          key={tag}
                          classificationName={tag}
                          styleLess
                          classNames={{
                            text: "text-gray-500 text-xs underline",
                            contend: "flex",
                          }}
                        >
                          {tag}
                        </BorderBadge>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          }
          footer={
            <>
              <div className="flex flex-row items-center gap-2 sm:gap-14 pr-1 ">
                <ProfileBlock
                  userId={data?.data?.user?.id}
                  isModalVariant
                  firstName={data?.data?.user?.firstName}
                  lastName={data?.data?.user?.lastName}
                  createdAt={date}
                  profileImage={data?.data?.user?.avatar}
                  email={data?.data?.user?.email}
                  score={data?.data?.user?.reputationScore}
                />
                <div className="flex flex-row gap-1 h-full items-center  rounded shrink-0">
                  {(!!data?.data?.rating || data?.data?.rating === 0) && (
                    <RatingBlock
                      rating={data?.data?.rating}
                      showRatingTooltip
                    />
                  )}
                </div>
              </div>

              {dimensions.width < 641 && (
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    id && navigate(APP_ROUTES.ISSUE + "/" + id);
                  }}
                >
                  Details
                </Button>
              )}
              {dimensions.width > 640 && (
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  !px-2 maxSm:hidden text-[black] border hover:border-black border-gray-500  "
                  onClick={() => {
                    id && navigate(APP_ROUTES.ISSUE + "/" + id);
                  }}
                >
                  See full details
                </Button>
              )}
            </>
          }
        />
      )}
    </div>,
    document.body
  );
}

const IssueCardModalWithRef = React.forwardRef(IssueCardModal);

export default memo(IssueCardModalWithRef);
