export enum PRIMARY_COLORS {
  DARK_GRAY = "#3B3B3B",
  GHOST_WHITE = "#F5FCFF",
  PATTENS_BLUE = "#D5E2EA",
  CERULEAN = "#00649C",
  GREY_CHATEAU = "#98A0A7",
}
//category constants
export const CATEGORIES = {
  climate: "bg-[#C7D2FE]",
  employment: "bg-[#FECACA]",
  education: "bg-[#FBCFE8]",
  addiction: "bg-lime-200",
  povertyAndHunger: "bg-green-200",
  insecurityAndInstability: "bg-[#DDD6FE]",
  health: "bg-[#99F6E4]",
  injusticeAndDiscrimination: "bg-[#FED7AA]",
  corruption: "bg-[#BAE6FD]",
  disunityAndDivision: "bg-[#FDE68A]",
};

export const CATEGORIES_COLORS = [
  "bg-yellow-200",
  "bg-blue-200",
  "bg-red-200",
  "bg-cyan-200",
  "bg-pink-200",
  "bg-indigo-200",
  "bg-teal-200",
  "bg-amber-200",
  "bg-orange-200",
  "bg-gray-200",
  "bg-emerald-200",
  "bg-violet-200",
];

export enum CATEGORIES_GRADIENTS {
  povertyAndHunger = "from-[#FBC12B] to-[#F26437]",
  health = "from-[#DC88DE] via-[#8159D7] to-[#9BB6FB]",
  insecurityAndInstability = "from-[#9EDE88] via-[#80C866] to-[#1B8B09]",
  education = "from-[#E29AE2] to-[#D059AC]",
  disunityAndDivision = "from-[#ED8B8B] via-[#EC7676] to-[#A92A19]",
  climate = "from-[#379835] to-[#368535]",
  employment = "from-[#06A0AA] to-[#64D4DB]",
  addiction = "from-[#4DE007] to-[#E4E832]",
  injusticeAndDiscrimination = "from-[#A4BDFC] to-[#96AFF0]",
  corruption = "from-[#FEE382] to-[#F3BF81]",
}

export const BADGE_COLORS = [
  "from-[#FBC12B] to-[#F26437]",
  "from-[#DC88DE] via-[#8159D7] to-[#9BB6FB]",
  "from-[#9EDE88] via-[#80C866] to-[#1B8B09]",
  "from-[#E29AE2] to-[#D059AC]",
  "from-[#ED8B8B] via-[#EC7676] to-[#A92A19]",
  "from-[#379835] to-[#368535]",
  "from-[#06A0AA] to-[#64D4DB]",
  "from-[#4DE007] to-[#E4E832]",
  "from-[#A4BDFC] to-[#96AFF0]",
  "from-[#FEE382] to-[#F3BF81]",
];

export enum SOLUTION_CATEGORIES_GRADIENTS {
  money = "from-[#DC88DE] via-[#8159D7] to-[#9BB6FB]",
  teaching = "from-[#FBC12B]  to-[#F26437]",
  studying = "from-[#9EDE88] via-[#80C866] to-[#1B8B09]",
}

export enum LOADER_SIZES {
  tiny = 50,
  small = 100,
  medium = 300,
  large = 600,
  huge = 900,
}

export enum ACTIVITY_TYPES {
  COMMENT_LIKE = "commentLike",
  LIKE = "like",
  DISLIKE = "dislike",
  CREATE_SOLUTION = "createSolution",
  CREATE_ACTION_GROUP = "createActionGroup",
  REQUEST_JOIN_GROUP = "requestJoinGroup",
  APPROVE_JOIN_GROUP = "approveJoinGroup",
  DECLINE_JOIN_GROUP = "declineJoinGroup",
  LEAVE_ACTION_GROUP = "leaveActionGroup",
  DELETE_ACTION_GROUP = "deleteActionGroup",
  RATE_ACTION_GROUP = "rateActionGroup",
  RATE_ISSUE = "rateIssue",
  DELETE_ISSUE_RATE = "deleteIssueRate",
  COMMENT = "comment",
  REPLY = "reply",
  CASCADE_SOFT_DELETE_ISSUE = "cascadeSoftDeleteIssue",
  CASCADE_SOFT_RESTORE_ISSUE = "cascadeSoftRestoreIssue",
  RATE_MOTIVATIONAL = "rateMotivational",
  RATE_SOLUTION = "rateSolution",
}

export enum ENTITY_TYPES {
  ISSUE = "issue",
  SOLUTION = "solution",
  ACTION_GROUP = "actionGroup",
  ACTION_GROUP_USER = "actionGroupUser",
  COMMENT = "comment",
  ACTION_GROUP_RATING = "rating",
  ISSUE_RATING = "issueRating",
  REPLY = "reply",
  MOTIVATIONAL = "motivational",
  MOTIVATIONAL_RATING = "motivationalRating",
  USER = "user",
  SOLUTION_RATING = "solutionRating",
}
export const SKILLS_BADGE_COLORS = [
  "text-red-500  focus:bg-red-500",
  "text-yellow-500  focus:bg-yellow-500",
  "text-green-500  focus:bg-green-500",
  "text-blue-500  focus:bg-blue-500",
  "text-indigo-500  focus:bg-indigo-500",
];
