import { Fragment, memo } from "react";
import { IIssueRatingCategory } from "types";
import { ProfileBlock, Card } from "components";
import { BorderBadge } from "components/BorderBadge";
import classnames from "classnames";

interface IProps {
  title: string;
  location?: string;
  profileImage: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  id: string | undefined;
  rating: number;
  email: string | undefined;
  forceHoverIssue?: boolean;
  issueType: "regular" | "city" | "country";
  setHoveredIssueType: React.Dispatch<
    React.SetStateAction<"regular" | "city" | "country" | undefined>
  >;
  setIsIssueHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  setIssueHoveredProps?: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  geoLocation: {
    lat: number | undefined;
    lng: number | undefined;
  };
  primaryClassification: Partial<IIssueRatingCategory> | undefined;
  secondaryClassification: Partial<IIssueRatingCategory> | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  score: number | undefined;
  tags?: string[] | undefined;
  isSoftDeleted?: boolean;
}

function IssueCard({
  title,
  location,
  profileImage,
  firstName = "",
  lastName = "",
  createdAt = "",
  rating,
  id,
  issueType,
  setHoveredIssueType,
  setIsIssueHovered,
  setIssueHoveredProps,
  forceHoverIssue,
  geoLocation,
  primaryClassification,
  secondaryClassification,
  email,
  score,
  innerRef,
  tags,
  isSoftDeleted,
}: IProps) {
  const date =
    createdAt &&
    new Date(createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

  return (
    <Card
      className={classnames("maxmd:flex-1 !gap-1 !pt-3 !pb-2 h-full", {
        "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
      })}
      issueType={issueType}
      setHoveredIssueType={setHoveredIssueType}
      forceHoverIssue={forceHoverIssue}
      setIsIssueHovered={setIsIssueHovered}
      setIssueHoveredProps={setIssueHoveredProps}
      geoLocation={geoLocation}
      showHeaderDivider={false}
      showSubHeaderDivider={false}
      showContent={false}
      header={
        <Fragment>
          <span
            className="text-black font-semibold leading-6 line-clamp-2"
            ref={innerRef}
          >
            {title}
          </span>
        </Fragment>
      }
      subHeader={
        <Fragment>
          <div className="flex flex-wrap    gap-x-2 gap-y-2 md:gap-x-4  ">
            {primaryClassification && (
              <BorderBadge
                className="flex"
                classificationName={primaryClassification.category}
                classificationDescription={primaryClassification.description}
                tooltipId={id}
              >
                {primaryClassification.title}
              </BorderBadge>
            )}
            {secondaryClassification && (
              <BorderBadge
                className="flex"
                classificationName={secondaryClassification.category}
                classificationDescription={secondaryClassification.description}
                tooltipId={id}
              >
                {secondaryClassification.title}
              </BorderBadge>
            )}

            {!primaryClassification && !secondaryClassification && (
              <div>No classifications yet</div>
            )}
            {/* <ScoreTooltip
              content={primaryClassification?.description}
              id={"issue-card-primary-classification " + id}
            />
            <ScoreTooltip
              content={secondaryClassification?.description}
              id={"issue-card-secondary-classification " + id}
            /> */}
          </div>
          {/* <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
            {location}
          </span> */}
        </Fragment>
      }
      classNames={{
        header: "max-w-[460px] sm:max-w-[360px] !justify-center  !gap-0 !pt-1",
        subHeader:
          "max-w-[460px] sm:max-w-[360px] !justify-start !gap-0 min-h-[28px] !pt-1",
        content: "max-w-[460px] sm:max-w-[360px] h-full flex-1",
        footer: "max-w-[460px] sm:max-w-[360px] pt-2 pb-1 !shrink ",
      }}
      content={
        // <span className="line-clamp-3 leading-6 h-[75px]">{details}</span>
        <></>
      }
      footer={
        <>
          <ProfileBlock
            userId={id}
            firstName={firstName}
            lastName={lastName}
            location={location}
            rating={rating}
            showIssueRatingTooltip
            createdAt={date}
            profileImage={profileImage}
            email={email}
            classNames={{ name: " " }}
            score={score}
          />
        </>
      }
      tags={
        tags &&
        tags?.length > 0 && (
          <div className="flex items-start flex-wrap gap-x-2 justify-start px-6">
            {tags?.map((tag) => (
              <BorderBadge
                key={tag}
                classificationName={tag}
                styleLess
                classNames={{
                  text: "text-gray-500 text-xs underline",
                }}
              >
                {tag}
              </BorderBadge>
            ))}
          </div>
        )
      }
    />
  );
}

export default memo(IssueCard);
