import { memo } from "react";

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  isSelected: boolean;
}

function CustomCheckbox(props: IProps) {
  return (
    <input
      style={{
        appearance: "none",
      }}
      type="checkbox"
      className="h-4 w-4 text-transparent   border-gray-300 rounded"
      checked={props.isSelected}
      onChange={() => null}
    />
  );
}
export default memo(CustomCheckbox);
