import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function PublicLayoutError() {
  const { search } = useLocation();
  const [message, setMessage] = useState<null | string>(null);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.has("message")) {
      const value = query.get("message");
      if (value) {
        setMessage(value);
      }
    }
  }, [search]);
  return (
    <div className="flex flex-col justify-center items-center text-red-500">
      {message && <div>{message}</div>}
      {!message && <div>Some error occurred</div>}
    </div>
  );
}
export default memo(PublicLayoutError);
