import { memo, useState } from "react";

import { IssueCardModal } from "components";

import classnames from "classnames";
import { useParams } from "react-router-dom";
import { getRating } from "utils";

import { useIssueList } from "modules/IssueList/IssueList.hooks";
import { useMe } from "hooks";
import { IssueCard } from "modules/IssueList/IssueCard";

interface IProps {
  profileOwner?: boolean;
}

function ProfileSolutionsCard({ profileOwner }: IProps) {
  const { data: meData } = useMe();
  const params = useParams<{ userId: string }>();
  const { data: issuesData } = useIssueList({
    user: meData?.data && profileOwner ? meData?.data.id! : params.userId!,
    skills: [],
    tags: [],
    category: [],
    zoom: 1,
    lat: "",
    lng: "",
    suspense: false,
    actionGroup: "",
    withDeleted: true,
  });
  const issues = issuesData?.pages[0].data.items;

  const [openCardModal, setOpenCardModal] = useState(false);
  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );

  const [, setIsIssueHovered] = useState(false);

  const [hoveredIssueId] = useState<string | undefined | string[]>(undefined);
  const [, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();

  return (
    <div
      className={classnames(
        "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
      )}
    >
      <div
        className={classnames(
          "grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear sm:grid-cols-2 lg:grid-cols-4 lg:col-span-4"
        )}
      >
        {issuesData?.pages.map((page) => {
          return page.data.items.length < 1 ? (
            <div className="flex min-h-[400px] items-start justify-center col-span-6">
              <p className=" max-w-[700px] text-center text-xl">
                {profileOwner
                  ? "You have not reported any issue"
                  : "There are no issues reported by this user"}
              </p>
            </div>
          ) : (
            issues?.map((issue) => {
              const isFormattedAddress = !!issue?.location?.formattedAddress;
              const isCountry = !!issue?.location?.country;
              const isCity = !!issue?.location?.city;
              const isStreetNumber = !!issue?.location?.streetNumber;
              const isRoute = !!issue?.location?.route;
              const location =
                typeof issue?.location === "object"
                  ? isFormattedAddress
                    ? `${issue?.location?.formattedAddress}`
                    : isCountry && isCity && isStreetNumber && isRoute
                    ? `${issue?.location?.route} ${issue?.location?.streetNumber}, ${issue?.location?.city}, ${issue?.location?.country}`
                    : isCountry && isCity
                    ? ` ${issue?.location?.city}, ${issue?.location?.country}`
                    : isCountry
                    ? `${issue?.location?.country}`
                    : "No location"
                  : "No location";
              const geoLocation = {
                lat: issue.location.lat,
                lng: issue.location.lng,
              };

              const issueType =
                issue?.location?.administrativeArea ||
                issue?.location?.route ||
                issue?.location?.streetNumber
                  ? "regular"
                  : issue?.location?.city
                  ? "city"
                  : "country";
              return (
                <div
                  className=" cursor-pointer z-10  maxmd:flex  "
                  onClick={() => {
                    window.scrollTo(0, 0);
                    issue && setOpenedCardId(issue.id);
                    issue && setOpenCardModal(true);
                  }}
                  key={issue.id}
                >
                  <IssueCard
                    id={issue.user?.id}
                    issueType={issueType}
                    setHoveredIssueType={setHoveredIssueType}
                    forceHoverIssue={hoveredIssueId?.includes(issue.id || "")}
                    setIsIssueHovered={setIsIssueHovered}
                    geoLocation={geoLocation}
                    title={issue.title}
                    //details={issue.details}
                    location={location}
                    profileImage={issue?.user?.avatar}
                    firstName={issue.user?.firstName}
                    lastName={issue.user?.lastName}
                    createdAt={issue.createdAt}
                    rating={getRating(issue.rating)}
                    primaryClassification={issue.primaryClassification}
                    secondaryClassification={issue.secondaryClassification}
                    email={issue?.user?.email}
                    score={issue?.user?.reputationScore}
                    isSoftDeleted={!!issue?.deletedAt}
                  />
                </div>
              );
            })
          );
        })}
      </div>
      {openCardModal && openedCardId && (
        <IssueCardModal id={openedCardId} setShow={setOpenCardModal} />
      )}
    </div>
  );
}

export default memo(ProfileSolutionsCard);
