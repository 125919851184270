import { memo, useEffect, useMemo, useState } from "react";
import {
  IActionGroupRatings,
  IActionGroupSkill,
  IOutletAuth,
  ISkill,
} from "types";
import {
  Card,
  BorderBadgeRound,
  PromptModal,
  Button,
  BorderBadge,
} from "components";
import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import { isNil } from "lodash";
import classnames from "classnames";
import { useQueryClient } from "react-query";
import { APP_ROUTES, ENDPOINTS } from "const";
import { useClickOnLikeDislikeActionGroup, useUpdateGroupRating } from "hooks";
import { ActionGroupProfileBlockSmallCard } from "components/ActionGroupProfileBlockSmallCard";
import AvatarPlaceholder from "assets/images/avatar.png";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Tooltip } from "react-tooltip";

interface IProps {
  title: string;
  skills?: Partial<IActionGroupSkill<Partial<ISkill>>>[] | undefined;
  profileImage: string | undefined;
  groupImage: string | undefined;
  createdAt: string | undefined;
  likes: number;
  dislikes: number;
  location?: string;
  forceHoverIssue?: boolean;
  membersNumber?: string;
  rate?: IActionGroupRatings | undefined | null;
  groupId: string;
  setOpenCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setHoveredIssueType: React.Dispatch<
    React.SetStateAction<"regular" | "city" | "country" | undefined>
  >;
  setIsIssueHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  setIssueHoveredProps?: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  geoLocation: {
    lat: number | undefined;
    lng: number | undefined;
  };

  innerRef?: (node?: Element | null | undefined) => void;
  tags?: string[] | undefined;
  allLocations?: (string | null | undefined)[];
  isSoftDeleted?: boolean;
}

function GroupCard({
  title,
  profileImage,
  groupImage,
  createdAt = "",
  likes,
  membersNumber,
  dislikes,
  groupId,
  setOpenCardModal,
  setHoveredIssueType,
  setIsIssueHovered,
  setIssueHoveredProps,
  forceHoverIssue,
  geoLocation,
  rate,
  skills,
  location,
  innerRef,
  tags,
  allLocations,
  isSoftDeleted,
}: IProps) {
  const date = useMemo(() => {
    return (
      createdAt &&
      new Date(createdAt).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      })
    );
  }, [createdAt]);
  const isLikesDislikes = useMemo(() => {
    return !isNil(likes) && !isNil(dislikes);
  }, [likes, dislikes]);

  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
  };
  const { mutateAsync: mutateUpdateSolutionAsync, isLoading: updateLoading } =
    useUpdateGroupRating();
  const isLoading = updateLoading;
  const [rates, setRates] = useState({
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });
  useEffect(() => {
    if (likes) {
      setRates((val) => ({ ...val, likes: likes }));
    }
    if (dislikes) {
      setRates((val) => ({ ...val, dislikes: dislikes }));
    }
    if (rate?.rate === 1) {
      setRates((val) => ({ ...val, liked: true, disliked: false }));
    }
    if (rate?.rate === 0) {
      setRates((val) => ({ ...val, liked: false, disliked: true }));
    }
  }, [likes, dislikes, rate]);
  const like = useClickOnLikeDislikeActionGroup(
    groupId,
    rate?.rate,
    mutateUpdateSolutionAsync,
    invalidate
  );
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();

  const uniqueLocations = useMemo(() => {
    return [...new Set(allLocations)];
  }, [allLocations]);
  return (
    <>
      <Card
        customClickEventsOnCardAreas={{
          headerBlock: () => {
            setOpenCardModal(true);
          },
          dividersBlock: () => {
            setOpenCardModal(true);
          },
          contentBlock: () => {
            setOpenCardModal(true);
          },
        }}
        shouldHover={true}
        className={classnames("maxmd:flex-1 !gap-1.5 !pt-3 !pb-2 h-full", {
          "bg-gray-100 select-none": isSoftDeleted,
        })}
        setHoveredIssueType={setHoveredIssueType}
        forceHoverIssue={forceHoverIssue}
        setIsIssueHovered={setIsIssueHovered}
        setIssueHoveredProps={setIssueHoveredProps}
        geoLocation={geoLocation}
        showHeaderDivider={false}
        header={
          // fixed: added lg:flex-col xl:flex-row, because between these breakpoints tags and titles extend outside container

          <div className="relative flex flex-row lg:flex-col xl:flex-row gap-2 justify-between">
            <div
              className={classnames(
                "relative rounded-full py-3 pr-3 overflow-hidden flex justify-center items-center shrink-0 ",
                {
                  "pointer-events-none": isSoftDeleted,
                }
              )}
            >
              <img
                decoding="async"
                height={80}
                width={80}
                src={!!groupImage ? groupImage : AvatarPlaceholder}
                alt="avatar"
                className={classnames(
                  " object-cover rounded-full h-20 w-20 border"
                )}
              />
              <img
                decoding="async"
                height={32}
                width={32}
                src={!!profileImage ? profileImage : AvatarPlaceholder}
                alt="avatar"
                className={classnames(
                  "absolute object-cover ml-14 mt-12 rounded-full border border-white h-8 w-8"
                )}
              />
            </div>
            <div className="flex flex-col gap-3 items-start w-full">
              <span
                className="text-black font-bold leading-6 line-clamp-2"
                ref={innerRef}
              >
                {title}
              </span>
              <span
                className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light"
                data-tooltip-id={
                  !!uniqueLocations && uniqueLocations?.length > 1
                    ? "locations_for_" + groupId
                    : ""
                }
              >
                {location}{" "}
                {!!uniqueLocations &&
                  uniqueLocations?.length > 1 &&
                  "+" + (uniqueLocations?.length - 1)}
              </span>
              <Tooltip
                id={"locations_for_" + groupId}
                place="top"
                className="white-tooltip font-semibold !rounded-md !p-2 z-30 max-w-[260px]"
              >
                <ul className="list-disc pl-4">
                  {uniqueLocations?.map((location, index) => (
                    <li key={index}>{location}</li>
                  ))}
                </ul>
              </Tooltip>
              <div className="flex flex-col relative w-full ">
                <div className="flex flex-wrap  min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4  ">
                  {skills?.slice(0, 2).map((skill, index) => {
                    return (
                      <BorderBadgeRound
                        key={skill.id}
                        className={classnames("flex w-full", {
                          "pointer-events-none": isSoftDeleted,
                        })}
                        classNames={{
                          text: index > 0 ? "  line-clamp-1 absolute " : "",
                          textContainer: index > 0 ? "h-[26px] " : "",
                        }}
                        withNotification
                        notificationContent={`${skill.timeRequired || 0} ${
                          Number(skill.timeRequired) === 1 ? "hour" : "hours"
                        } per week`}
                        classificationName={skill?.skill?.name}
                      >
                        {skill?.skill?.name}
                      </BorderBadgeRound>
                    );
                  })}
                  {skills && skills?.length > 2 && (
                    <div>+{skills.length - 2}</div>
                  )}
                  {skills && skills.length < 1 && <div>No skills assigned</div>}
                </div>
              </div>
            </div>
          </div>
        }
        classNames={{
          header:
            "max-w-[460px] sm:max-w-[360px] min-h-[50px] !justify-center !py-2 !gap-0 !pt-1",
          subHeader:
            "max-w-[460px] sm:max-w-[360px]   !justify-start !gap-0  !pt-1",
          content: "max-w-[460px] sm:max-w-[360px] h-full flex-1",
          footer: "max-w-[460px] sm:max-w-[360px]  max-h-[64px] pt-4 pb-2",
        }}
        content={
          // <span className="line-clamp-3 leading-6 h-[75px]">{details}</span>
          <></>
        }
        footer={
          <>
            <div
              onClick={() => {
                setOpenCardModal(true);
              }}
              className="flex flex-col justify-start gap-1 w-full"
            >
              <ActionGroupProfileBlockSmallCard
                customContent={
                  <div
                    className={classnames("flex flex-col gap-1", {
                      "pointer-events-none": isSoftDeleted,
                    })}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      className="flex flex-row gap-2 items-center"
                    >
                      {isLikesDislikes && (
                        <>
                          <div className="flex flex-row gap-1 items-center">
                            <span
                              onClick={() => {
                                if (authContext.isAuth) {
                                  if (!isLoading) {
                                    if (rates.liked) {
                                      setRates((val) => ({
                                        ...val,
                                        liked: false,
                                        likes: val.likes - 1,
                                      }));
                                    } else {
                                      setRates((val) => ({
                                        ...val,
                                        liked: true,
                                        likes: val.likes + 1,
                                        dislikes:
                                          val.dislikes > 0
                                            ? val.dislikes - 1
                                            : val.dislikes,
                                        disliked: false,
                                      }));
                                    }
                                    like(true);
                                  }
                                } else {
                                  setShowGoSignInModal(true);
                                }
                              }}
                              className={classnames(
                                "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm  p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                                {
                                  " bg-[#EFEFEF]": rates.liked === false,
                                  " bg-[#606060]": rates.liked === true,
                                }
                              )}
                            >
                              <Like
                                stroke={
                                  rates.liked === true ? "white" : "#606060"
                                }
                              />
                            </span>
                            <span className="text-center text-[#606060] text-xs ">
                              {rates.likes}
                            </span>
                          </div>

                          <div className="flex flex-row gap-1 items-center ">
                            <span
                              onClick={() => {
                                if (authContext.isAuth) {
                                  if (!isLoading) {
                                    if (rates.disliked) {
                                      setRates((val) => ({
                                        ...val,
                                        disliked: false,
                                        dislikes: val.dislikes - 1,
                                      }));
                                    } else {
                                      setRates((val) => ({
                                        ...val,
                                        liked: false,
                                        likes:
                                          val.likes > 0
                                            ? val.likes - 1
                                            : val.likes,
                                        dislikes: val.dislikes + 1,
                                        disliked: true,
                                      }));
                                    }
                                    like(false); // dislike
                                  }
                                } else {
                                  setShowGoSignInModal(true);
                                }
                              }}
                              className={classnames(
                                "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm   p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                                {
                                  " bg-[#EFEFEF]": rates.disliked === false,
                                  " bg-[#606060]": rates.disliked === true,
                                }
                              )}
                            >
                              <Dislike
                                stroke={
                                  rates.disliked === true ? "white" : "#606060"
                                }
                              />
                            </span>
                            <span className="text-center text-[#606060] text-xs ">
                              {rates.dislikes}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    {membersNumber !== undefined && (
                      <div
                        onClick={() => {
                          setOpenCardModal(true);
                        }}
                        className=" relative self-start"
                      >
                        <p className=" text-sm font-normal text-[#999999] whitespace-nowrap overflow-hidden text-ellipsis ">{`(${membersNumber} ${
                          Number(membersNumber) === 1 ? "member" : "members"
                        })`}</p>
                      </div>
                    )}
                  </div>
                }
                createdAt={date}
                classNames={{ name: " " }}
              />
            </div>
          </>
        }
        tags={
          tags &&
          tags?.length > 0 && (
            <div className="flex items-start flex-wrap gap-x-2 justify-start px-6">
              {tags?.map((tag) => (
                <BorderBadge
                  key={tag}
                  classificationName={tag}
                  styleLess
                  classNames={{
                    text: "text-gray-500 text-xs underline",
                  }}
                >
                  {tag}
                </BorderBadge>
              ))}
            </div>
          )
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(GroupCard);
