import classnames from "classnames";
import {
  AnalyticsComponent,
  BorderBadge,
  Button,
  SolutionCardModal,
  WarningModal,
} from "components";
import { useMemo, useState } from "react";
import { ReactComponent as SquareLeft } from "assets/images/square-left.svg";
import { ReactComponent as SquareRight } from "assets/images/square-right.svg";

import { useOutletContext } from "react-router-dom";
import { getRating } from "utils";

import { SolutionCard } from "./SolutionCard";

import { ListMap } from "components";
import { useSolutionListFilterParams } from "./SolutionList.hooks";
import { useResizeDetector } from "react-resize-detector";

import { useWindowDimensions } from "hooks";
import JumboImage1 from "assets/images/jumbo-1.png";
import { IOutletAuth } from "types";

function SolutionList() {
  const authContext = useOutletContext<IOutletAuth>();

  const [open, setOpen] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );
  const { width, height, ref: mapRef } = useResizeDetector();
  const [isIssueHovered, setIsIssueHovered] = useState(false);
  const dim = useWindowDimensions();

  const disabled = useMemo(() => {
    return !!(open && dim.width && dim.width <= 1023);
  }, [dim.width, open]);

  const [hoveredIssueId, setHoveredIssueId] = useState<
    string | undefined | string[]
  >(undefined);
  const [hoveredIssueType, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();

  const [issueHoveredProps, setIssueHoveredProps] = useState({
    lat: NaN,
    lng: NaN,
  });

  const { data, inViewRef } = useSolutionListFilterParams(
    width || dim.width,
    disabled
  );

  const [openWarningModal, setOpenWarningModal] = useState(false);
  let noSolutionsFound = true;
  if (!data?.pages?.length) {
    noSolutionsFound = true;
  }
  if (data?.pages?.length) {
    if (!data.pages[0].data?.items?.length) {
      noSolutionsFound = true;
    }
  }

  return (
    <div className={classnames("flex flex-col   relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
        )}
      >
        <div
          className={classnames(
            " four-fourth-step five-fourth-step six-third-step seven-fourth-step grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear",
            {
              "sm:grid-cols-2 lg:grid-cols-3 lg:col-span-3": !open,
              "hidden lg:grid max-w-[500px]": open,
            }
          )}
        >
          <div
            className={classnames(
              "  relative   flex-1 max-h-[300px]  w-full  overflow-hidden",
              {
                " flex  col-span-1 sm:col-span-2  lg:col-span-3": !open,
              },
              {
                hidden: open,
              }
            )}
          >
            <div className=" flex justify-center flex-1 w-full h-full">
              <img
                style={{ flexBasis: "100%" }}
                className="select-none pointer-events-none min-h-[180px] flex-1 w-full h-full object-cover sm:object-contain"
                alt="jumbo"
                src={authContext.backgroundImages || JumboImage1}
              />
              <Button
                isReverse
                className="!absolute  h-[42px] font-semibold  px-2  bottom-[10%] z-50 justify-center flex  shrink-0"
                onClick={() => {
                  setOpenWarningModal(true);
                }}
              >
                Propose solution
              </Button>
            </div>
          </div>
          {data?.pages.map((page, pagesIndex) => {
            return page.data.items.map((solution, solutionIndex) => {
              const isFormattedAddress =
                !!solution?.issue.location?.formattedAddress;
              const isCountry = !!solution?.issue?.location?.country;
              const isCity = !!solution?.issue?.location?.city;
              const isStreetNumber = !!solution?.issue?.location?.streetNumber;
              const isRoute = !!solution?.issue?.location?.route;
              const location =
                typeof solution?.issue?.location === "object"
                  ? isFormattedAddress
                    ? `${solution?.issue?.location?.formattedAddress}`
                    : isCountry && isCity && isStreetNumber && isRoute
                    ? `${solution?.issue?.location?.route} ${solution?.issue?.location?.streetNumber}, ${solution?.issue?.location?.city}, ${solution?.issue?.location?.country}`
                    : isCountry && isCity
                    ? ` ${solution?.issue?.location?.city}, ${solution?.issue?.location?.country}`
                    : isCountry
                    ? `${solution?.issue?.location?.country}`
                    : "No location"
                  : "No location";
              const geoLocation = {
                lat: solution?.issue.location.lat,
                lng: solution?.issue.location.lng,
              };
              const isLast =
                data?.pages.length === pagesIndex + 1 &&
                page.data.items.length === solutionIndex + 1;

              const issueType =
                solution?.issue?.location?.administrativeArea ||
                solution?.issue?.location?.route ||
                solution?.issue?.location?.streetNumber
                  ? "regular"
                  : solution?.issue?.location?.city
                  ? "city"
                  : "country";

              return (
                <div
                  className={classnames(
                    `relative solution-card cursor-pointer z-[${
                      solutionIndex + 10
                    }]  maxmd:flex`,
                    {
                      " first-card-to-click ": solutionIndex === 0,
                    }
                  )}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    solution && setOpenedCardId(solution.id);
                    solution && setOpenCardModal(true);
                  }}
                  key={solution.id}
                >
                  {solution.analyticsData && (
                    <AnalyticsComponent
                      analyticsData={solution.analyticsData}
                    />
                  )}
                  <SolutionCard
                    solutionId={solution.id}
                    id={solution?.user.id}
                    skills={solution?.primaryActionGroup?.skills}
                    issueType={issueType}
                    setHoveredIssueType={setHoveredIssueType}
                    forceHoverIssue={hoveredIssueId?.includes(
                      solution.id || ""
                    )}
                    setIsIssueHovered={setIsIssueHovered}
                    setIssueHoveredProps={setIssueHoveredProps}
                    geoLocation={geoLocation}
                    innerRef={isLast ? inViewRef : undefined}
                    title={solution.title}
                    location={location}

                    profileImage={solution?.user?.avatar}
                    firstName={solution.user?.firstName}
                    lastName={solution.user?.lastName}
                    createdAt={solution.createdAt}
                    rating={
                      solution.impactRanking
                        ? getRating(solution.impactRanking)
                        : 0
                    }
                    primaryClassification="climate"
                    secondaryClassification="climate"
                    email={solution?.user?.email}
                    score={solution?.user.reputationScore}
                    tags={solution?.tags}
                    isSoftDeleted={!!solution.deletedAt}
                  />
                </div>
              );
            });
          })}
          {!noSolutionsFound && (
            <div className="flex   justify-center col-span-6">
              <p className=" max-w-[700px] text-center text-xl">
                There are no solutions found for the chosen location, please
                adjust your search parameters or
                <span
                  onClick={() => {
                    setOpenWarningModal(true);
                  }}
                  className="font-bold px-1 hover:text-white cursor-pointer"
                >
                  create a solution
                </span>
              </p>
            </div>
          )}
        </div>
        <div
          className={classnames("relative lg:rounded-lg lg:overflow-hidden ", {
            "hidden lg:flex lg:flex-col row-start-1 lg:col-start-4": !open,
            "lg:col-start-2 lg:col-end-[-1] ": open,
          })}
        >
          <ListMap
            setOpenMap={setOpen}
            hoveredIssueType={hoveredIssueType}
            setHoveredIssueId={setHoveredIssueId}
            issueCardHovered={isIssueHovered}
            issueCardProps={issueHoveredProps}
            innerRef={mapRef}
            mapHeight={height || dim.height}
            mapWidth={width || dim.width}
          />
          <div
            className="flex flex-col absolute top-7 left-5 cursor-pointer"
            onClick={() => setOpen((open) => !open)}
          >
            {!open && <SquareLeft />}
            {open && <SquareRight />}
          </div>
        </div>
      </div>

      <BorderBadge
        className="bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 fixed bottom-14 right-[50%] translate-x-1/2 cursor-pointer block lg:hidden z-10"
        onClick={() => setOpen((open) => !open)}
        classNames={{
          text: "!text-sm",
          contend: "px-4 py-2 ",
        }}
      >
        {open ? "Show issue list" : "Show map"}
      </BorderBadge>
      {openCardModal && openedCardId && (
        <SolutionCardModal id={openedCardId} setShow={setOpenCardModal} />
      )}
      {openWarningModal && <WarningModal setShow={setOpenWarningModal} />}
    </div>
  );
}

export default SolutionList;
