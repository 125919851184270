import * as yup from "yup";

import {
  FIELD_NAMES,
  MIN_PASSWORD_LENGTH,
} from "./CreateNewPasswordForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.PASSWORD]: yup
      .string()
      .min(MIN_PASSWORD_LENGTH)
      .required("This field is required"),
    [FIELD_NAMES.NEW_PASSWORD]: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match.")
      .required("This field is required"),
  });
}
