import React from "react";
import classnames from "classnames";
import "./MotivationalInputElement.styles.css";

export interface MotivationalInputElementProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isError?: boolean;
  classNames?: {
    mainContainer?: string;
    inputContainer?: string;
  };
}

function MotivationalInputElement({
  className,
  classNames,

  isError,
  ...rest
}: MotivationalInputElementProps) {
  return (
    <input
      className={classnames(
        " ",
        className

        // {
        //   "outline outline-1 outline-red-500 focus:outline  focus:outline-1 focus:outline-red-500 ":
        //     isError,
        // }
      )}
      {...rest}
      type={rest.type}
    />
  );
}

export default MotivationalInputElement;
