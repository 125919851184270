import { useInfinityIssues, useQueryOptionsSetter } from "hooks";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions } from "react-query";
import { useSearchParams } from "react-router-dom";
import { IIssuesProps } from "services";
import { FullIssueType } from "types";
import { IAxiosResponseWithPagination } from "types/requests";

import { getRange, getSearchParams } from "utils";

type IssueParamsTypes = Partial<IIssuesProps["params"]>;
interface IInfiniteQueryIssuesProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullIssueType>>
    >,
    IssueParamsTypes {}
export function useIssueList(
  params: Partial<
    ReturnType<typeof getSearchParams> & IInfiniteQueryIssuesProps
  >,
  mapWidth?: number,
  limit?: number,
  disabled = false
) {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();
  const { data, fetchNextPage, ...rest } = useInfinityIssues({
    limit: limit || 20,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },
    //
    range: getRange(params.zoom, Number(params.lat), mapWidth),
    keepPreviousData: true,
    suspense: !isMount,
    enabled: !disabled,
    withDeleted: !!params.user,
    ...params,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}

export function useIssueListFilterParams(
  mapWidth: number,
  disabled = false,
  limit?: number
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, inViewRef } = useIssueList(
    getSearchParams(searchParams),
    mapWidth,
    limit,
    disabled
  );
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  return { data, inViewRef, setQueryOptions, searchParams };
}
