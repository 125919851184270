import classnames from "classnames";
import { memo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import AvatarEditor from "react-avatar-editor";
import { ImageType } from "types";
import { Button } from "components";
import { useWindowDimensions } from "hooks";

interface IProps {
  src: string;
  fieldName: string;
  className?: string;
  userName?: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setFormikValue: (
    value: string | ImageType | null,
    shouldValidate?: boolean | undefined
  ) => void;
}
function dataURItoBlob(dataURI: string) {
  var byteString = window.atob(dataURI.split(",")[1]);

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //New Code
  return new Blob([ab], { type: mimeString });
}

function AvatarEditorModal({
  className,
  src,
  userName,
  setFormikValue,
  setShow,
}: IProps) {
  const editorRef = useRef<any>(null);

  const handleSave = () => {
    if (editorRef.current) {
      const canvasScaled =
        editorRef.current.getImageScaledToCanvas() as HTMLCanvasElement;

      const croppedImg = canvasScaled.toDataURL();
      const type = croppedImg.substring(
        croppedImg.indexOf(":") + 1,
        croppedImg.indexOf(";")
      );
      const blob = dataURItoBlob(croppedImg);
      const file = new File(
        [blob],
        `${userName?.replace(" ", "_").concat(".png")}`,
        { type: type, lastModified: new Date().getTime() }
      );

      setFormikValue(Object.assign(file, { dataUrl: croppedImg }));
    }
  };

  const [scaleValue, setScaleValue] = useState(1.1);
  const [radiusValue] = useState(300);
  const { height } = useWindowDimensions();

  return ReactDOM.createPortal(
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
      className={classnames(
        className,
        "z-40 top-0  fixed  left-0 w-full bg-black bg-opacity-50 h-full outline-none flex justify-center items-center overflow-y-hidden"
      )}
    >
      {src && (
        <div className="flex flex-col bg-white border rounded-lg max-h-[640px] max-w-[492px] ">
          <div className="px-6 py-8">
            <p className=" text-2xl font-bold ">Upload photo</p>
          </div>

          <span className="border-b border-gray-200" />
          <div className="flex flex-col px-12 sm:px-24 pt-8 pb-14  ">
            <AvatarEditor
              className="rounded-lg"
              crossOrigin="anonymous"
              disableBoundaryChecks
              ref={editorRef}
              image={src}
              width={height * 0.15 > 290 ? 190 : height * 0.15}
              height={height * 0.15 > 290 ? 190 : height * 0.15}
              border={50}
              borderRadius={radiusValue}
              color={[196, 196, 196, 0.3]} // RGBA
              scale={scaleValue}
              rotate={0}
            />
            <div className="flex flex-col justify-center items-center pt-11">
              <input
                type="range"
                className=" w-full "
                min={0.1}
                step={0.05}
                max={3.5}
                value={scaleValue}
                onChange={(e) => {
                  setScaleValue(Number(e.target.value));
                }}
              />
            </div>
          </div>
          <span className="border-b border-gray-200" />
          <div className="flex flex-col">
            <span className="border-b border-gray-200" />
            {/* <input
            type="range"
            min={0}
            step={10}
            max={300}
            value={radiusValue}
            onChange={(e) => {
              setRadiusValue(Number(e.target.value));
            }}
          /> */}
            <div className="flex flex-row justify-end gap-5 py-3 px-6">
              <Button
                type="button"
                isReverse
                className="border "
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleSave();
                  setShow(false);
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>,
    document.body
  );
}

export default memo(AvatarEditorModal);
