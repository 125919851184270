/* This example requires Tailwind CSS v2.0+ */
import { memo, useEffect, useState } from "react";
import {} from "@headlessui/react";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useElementScroll, useScrollOnDrag } from "hooks";

interface IQueryOptions {
  placeId?: string;
  category?: string[] | null;
  user?: string | null;
  lat?: string | null;
  skills?: string[] | null;
  lng?: string | null;
  zoom?: string | null;
  tags?: string[] | null;
}

interface IProps {
  setQueryOptions: (cb: (val: IQueryOptions) => IQueryOptions) => void;
  setCurrentSelectedTags: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
        label: string;
      }[]
    >
  >;
  tags: {
    value: string;
    label: string;
  }[];
}

function ToggleButtonMotivationalTagsGroup({
  tags,
  setQueryOptions,
  setCurrentSelectedTags,
}: IProps) {
  const [searchParams] = useSearchParams();
  const mainValues = {
    tags: searchParams.getAll("tags"),
  };

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { scrollable, scrollableToLeft, scrollableToRight } =
    useElementScroll(element);

  useScrollOnDrag(element);

  useEffect(() => {
    if (scrollable) {
      element?.classList.toggle("is-left-overflowing", scrollableToLeft);
      element?.classList.toggle("is-right-overflowing", scrollableToRight);
    }
  }, [scrollable, element, scrollableToLeft, scrollableToRight]);

  return (
    <div
      ref={(el) => {
        setElement(el);
      }}
      className="dragging-group thirteen-second-step fifteen-second-step sixteen-second-step  flex flex-row gap-x-5 gap-y-1 overflow-auto px-5 pb-[16px] pt-2 hide-scroll cursor-pointer fade "
    >
      {tags.map((tag, index) => {
        return (
          <div
            key={tag.value + index}
            onClick={() => {
              if (!mainValues.tags.includes(tag.value)) {
                setQueryOptions((values: IQueryOptions) => {
                  const val: IQueryOptions = {
                    ...values,
                    tags: [...mainValues.tags, tag.value as string] || "",
                  };
                  return val;
                });
                setCurrentSelectedTags(() => [
                  ...tags.map((item) => {
                    return item;
                  }),
                ]);
              }
              if (mainValues.tags.includes(tag.value)) {
                setQueryOptions((values: IQueryOptions) => {
                  const val: IQueryOptions = {
                    ...values,
                    tags: [
                      ...mainValues.tags.filter((item) => item !== tag.value),
                    ],
                  };
                  setCurrentSelectedTags(() => [
                    ...tags.map((item) => {
                      return item;
                    }),
                  ]);
                  return val;
                });
              }
            }}
            className={classnames(
              " rounded-md p-1 px-1.5 text-sm cursor-pointer select-none shadow-md whitespace-nowrap transform transition ease-in-out duration-200",
              {
                "bg-black  hover:bg-gray-800  text-white  ":
                  mainValues.tags.includes(tag.value),
              },
              {
                "bg-white  hover:bg-gray-100  text-black  ":
                  !mainValues.tags.includes(tag.value),
              }
            )}
          >
            {tag.label}
          </div>
        );
      })}
    </div>
  );
}

export default memo(ToggleButtonMotivationalTagsGroup);
