import { checkFileSize, mapTestRules } from "utils/validation";
import * as yup from "yup";

const MAX_FILE_SIZE = 10000000; // file size in kilobyte
const ATTACHMENTS_COUNT = 10;

export function checkAttachmentsCount(count: number) {
  return function (value, form) {
    //@ts-ignore
    const lastElementIndex = form?.parent?.length - 1;
    const lastItem = form.from[0].value?.attachments[lastElementIndex];
    console.log(lastItem, value);
    if (form?.parent?.length > count && lastItem === value) {
      return false;
    }
    return true;
  };
}

const attachmentSchema = mapTestRules([
  {
    cb: checkFileSize(MAX_FILE_SIZE),
    id: "fileSize",
    message: "This file is too big",
  },
  {
    cb: checkAttachmentsCount(ATTACHMENTS_COUNT),
    id: "attachment-count",
    message: "To many attachments",
  },
]);

export const ATTACHMENTS_MAX_TITLE_LENGTH = 120;
export const ATTACHMENTS_MAX_TEXT_LENGTH = 2000;

export const attachmentValidation = {
  attachments: yup.array().of(attachmentSchema),
  attachmentsInput: yup
    .string()
    //check if title is not empty
    .test(
      "title-is-empty",
      "is required if name is not empty",
      function (value) {
        if (this.parent.attachmentsTitle && !value) {
          return false;
        }
        return true;
      }
    )
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-z]{2,}([/][a-zA-Z0-9-_.]+)*(\?[a-zA-Z0-9-_%&=]+)?$/,
      "Enter correct url!"
    ),
  attachmentsTitle: yup
    .string()
    //check if input is not empty
    .test(
      "input-is-empty",
      "is required if link is not empty",
      function (value) {
        if (this.parent.attachmentsInput && !value) {
          return false;
        }
        return true;
      }
    )
    .when("isQuillModalOpen", {
      is: true,
      then: yup.string().required("Title is required "),
    })
    .max(ATTACHMENTS_MAX_TITLE_LENGTH),
  attachmentsQuill: yup
    .string()
    .when("isQuillModalOpen", {
      is: true,
      then: yup.string().required("Some text is required "),
    })
    .max(
      ATTACHMENTS_MAX_TEXT_LENGTH,
      "Content must me less than 2000 characters (including formatting)"
    ),
};
