import { memo, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  ActionGroupProfileBlockModal,
  BorderBadge,
  BorderBadgeRound,
  Button,
  Loader,
  PromptModal,
} from "components";
import { APP_ROUTES, ENDPOINTS } from "const";
import { Card } from "components";
import classnames from "classnames";
import {
  useActionGroup,
  useClickOnLikeDislikeActionGroup,
  useMe,
  useUpdateGroupRating,
} from "hooks";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { isNil } from "lodash";
import { useQueryClient } from "react-query";
import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import { getRange, getSearchParams } from "utils";

import { IOutletAuth } from "types";
import { getDetails } from "use-places-autocomplete";
import { getFirstLocationInActionGroupEntity } from "utils";
import { Tooltip } from "react-tooltip";

interface ICard {
  id: string | undefined;
  mapWidth?: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
  allLocations?: (string | null | undefined)[];
}

function Item({
  title,
  content,
  isLink = false,
}: {
  title: string;
  content: React.ReactNode;
  isLink?: boolean;
}) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {isLink ? (
          <a href={"https://" + content} target="_blank" rel="noreferrer">
            {content}
          </a>
        ) : (
          content
        )}
      </span>
    </>
  );
}

function GroupCardModal(
  { id, className, setShow, mapWidth, allLocations }: ICard,
  ref
) {
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = getSearchParams(searchParams);
  const range = getRange(params?.zoom, Number(params.lat), mapWidth);
  const user = me?.data?.id;
  const { data, isLoading } = useActionGroup({
    id,
    suspense: false,
    ...params,
    range,
    user,
  });
  console.log(data, "====");
  const group = data?.data;
  const date =
    group?.createdAt &&
    new Date(group?.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

  const isLikesDislikes = !isNil(group?.likes) && !isNil(group?.dislikes);
  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
  };
  const rate = group?.rates && group?.rates[0];

  const [placeName, setPlaceName] = useState<string | null>(null);
  const firstLocation = getFirstLocationInActionGroupEntity(group);
  const placeId = firstLocation?.placeId;

  const prevPlaceId = useRef<string | null>(null);
  useEffect(() => {
    if (placeId && placeId !== prevPlaceId.current) {
      prevPlaceId.current = placeId;
      getDetails({ placeId }).then((place) => {
        if (typeof place !== "string") {
          setPlaceName(place?.name);
        }
      });
    }
  }, [placeId]);

  const { mutateAsync: mutateUpdateSolutionAsync, isLoading: updateLoading } =
    useUpdateGroupRating({
      actionGroupRatingId: rate?.id,
    });
  const isLoadingGroupRating = updateLoading;

  const [rates, setRates] = useState({
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });

  useEffect(() => {
    if (group?.likes) {
      setRates((val) => ({ ...val, likes: group?.likes || 0 }));
    }
    if (group?.dislikes) {
      setRates((val) => ({ ...val, dislikes: group?.dislikes || 0 }));
    }
    if (rate?.rate === 1) {
      setRates((val) => ({ ...val, liked: true, disliked: false }));
    }
    if (rate?.rate === 0) {
      setRates((val) => ({ ...val, liked: false, disliked: true }));
    }
  }, [group, rate]);
  const like = useClickOnLikeDislikeActionGroup(
    data?.data.id,
    rate?.rate,
    mutateUpdateSolutionAsync,
    invalidate
  );

  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);

  const isFormattedAddress = !!firstLocation?.formattedAddress;
  const isCountry = !!firstLocation?.country;
  const isCity = !!firstLocation?.city;
  const isStreetNumber = !!firstLocation?.streetNumber;
  const isRoute = !!firstLocation?.route;

  const formattedAddress = firstLocation?.formattedAddress;
  const country = firstLocation?.country;
  const city = firstLocation?.city;

  let fullAddress = formattedAddress;
  if (
    placeName &&
    placeName !== country &&
    placeName !== city &&
    placeName !== formattedAddress
  ) {
    fullAddress = placeName + ", " + formattedAddress;
  }

  const location =
    typeof firstLocation === "object"
      ? isFormattedAddress
        ? `${fullAddress}`
        : isCountry && isCity && isStreetNumber && isRoute
        ? `${firstLocation?.route} ${firstLocation?.streetNumber}, ${firstLocation?.city}, ${firstLocation?.country}`
        : isCountry && isCity
        ? ` ${firstLocation?.city}, ${firstLocation?.country}`
        : isCountry
        ? `${firstLocation?.country}`
        : "No location"
      : "No location";

  const uniqueLocations = useMemo(() => {
    return [...new Set(allLocations)];
  }, [allLocations]);

  const isSoftDeleted = !!data?.data.deletedAt;

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classnames(
        className,
        "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden"
      )}
    >
      {isLoading ? (
        <Loader type="rings" />
      ) : (
        <Card
          shouldHover={false}
          className={classnames("flex-1  w-full max-w-[616px] mx-4 z-40", {
            "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
          })}
          header={
            <div className="flex justify-between items-center pb-1">
              <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                <span className="font-bold leading-6 text-lg line-clamp-2">
                  {data?.data.title}
                </span>
                <span
                  className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light"
                  data-tooltip-id={
                    !!uniqueLocations && uniqueLocations?.length > 1
                      ? "modal_locations_for_" + id
                      : ""
                  }
                >
                  {!!formattedAddress ? formattedAddress : location}{" "}
                  {!!uniqueLocations &&
                    uniqueLocations?.length > 1 &&
                    "+" + (uniqueLocations?.length - 1)}
                </span>
                <Tooltip
                  id={"modal_locations_for_" + id}
                  place="top"
                  className="white-tooltip font-semibold !rounded-md !p-2 z-30 max-w-[260px]"
                >
                  <ul className="list-disc pl-4">
                    {uniqueLocations?.map((location, index) => (
                      <li key={index}>{location}</li>
                    ))}
                  </ul>
                </Tooltip>
              </div>
              <XIcon
                onClick={() => {
                  setShow(false);
                }}
                className="!pointer-events-auto h-6 w-6 shrink-0 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          }
          classNames={{
            header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            content: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            footer: "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
          }}
          content={
            <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
              <Item title="Goals" content={data?.data.description} />
              <Item title="Schedule" content={data?.data.schedule} />
              <Item title="Contact" content={data?.data.address} />
              <Item title="Website" content={data?.data.contactUrl} isLink />
              <Item
                title="Skills Required"
                content={
                  <>
                    {data?.data?.skills && data?.data?.skills.length < 1 ? (
                      <div>No skills assigned</div>
                    ) : (
                      <div className="flex items-start flex-wrap  gap-4 pt-1">
                        {data?.data?.skills?.map((skill) => {
                          return (
                            <BorderBadgeRound
                              key={skill.id}
                              className="flex"
                              withNotification
                              notificationContent={`${
                                skill?.timeRequired || 0
                              } ${
                                Number(skill.timeRequired) === 1
                                  ? "hour"
                                  : "hours"
                              } per week`}
                              classificationName={skill?.skill?.name}
                            >
                              {skill?.skill?.name}
                            </BorderBadgeRound>
                          );
                        })}
                      </div>
                    )}
                  </>
                }
              />
              {data?.data?.tags && data?.data?.tags?.length > 0 && (
                <Item
                  title="Labels"
                  content={
                    <div className="flex items-center flex-wrap gap-2 py-1">
                      {data?.data?.tags?.map((tag) => (
                        <BorderBadge
                          key={tag}
                          classificationName={tag}
                          styleLess
                          classNames={{
                            text: "text-gray-500 text-xs underline",
                            contend: "flex",
                          }}
                        >
                          {tag}
                        </BorderBadge>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          }
          footer={
            <>
              <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                <ActionGroupProfileBlockModal
                  isModalVariant
                  showMembers={false}
                  showCreatedAt={false}
                  //@ts-ignore
                  actionGroupUsers={data?.data?.actionGroupUsers}
                  membersCount={String(data?.data.membersCount)}
                  createdAt={date}
                  profileImage={group?.user?.avatar}
                  groupImage={data?.data?.logo}
                />
                <div className="flex flex-row maxSm:flex-wrap gap-2 items-center nine-fifth-step ">
                  {isLikesDislikes && (
                    <>
                      <div className="flex flex-col gap-0.5">
                        <span
                          onClick={() => {
                            if (authContext.isAuth) {
                              if (!isLoadingGroupRating) {
                                if (rates.liked) {
                                  setRates((val) => ({
                                    ...val,
                                    liked: false,
                                    likes: val.likes - 1,
                                  }));
                                } else {
                                  setRates((val) => ({
                                    ...val,
                                    liked: true,
                                    likes: val.likes + 1,
                                    dislikes:
                                      val.dislikes > 0
                                        ? val.dislikes - 1
                                        : val.dislikes,
                                    disliked: false,
                                  }));
                                }
                                like(true); // it means like
                              }
                            } else {
                              setShowGoSignInModal(true);
                            }
                          }}
                          className={classnames(
                            "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm  p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                            {
                              " bg-[#EFEFEF]": rates.liked === false,
                              " bg-[#606060]": rates.liked === true,
                            }
                          )}
                        >
                          <Like
                            stroke={rates.liked === true ? "white" : "#606060"}
                          />
                        </span>
                        <span className="text-center text-[#606060] text-xs max-h-[5px]">
                          {rates.likes}
                        </span>
                      </div>

                      <div className="flex flex-col gap-0.5">
                        <span
                          onClick={() => {
                            if (authContext.isAuth) {
                              if (!isLoadingGroupRating) {
                                if (rates.disliked) {
                                  setRates((val) => ({
                                    ...val,
                                    disliked: false,
                                    dislikes: val.dislikes - 1,
                                  }));
                                } else {
                                  setRates((val) => ({
                                    ...val,
                                    liked: false,
                                    likes:
                                      val.likes > 0 ? val.likes - 1 : val.likes,
                                    dislikes: val.dislikes + 1,
                                    disliked: true,
                                  }));
                                }
                                like(false); // it means dislike
                              }
                            } else {
                              setShowGoSignInModal(true);
                            }
                          }}
                          className={classnames(
                            "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm   p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                            {
                              " bg-[#EFEFEF]": rates.disliked === false,
                              " bg-[#606060]": rates.disliked === true,
                            }
                          )}
                        >
                          <Dislike
                            stroke={
                              rates.disliked === true ? "white" : "#606060"
                            }
                          />
                        </span>
                        <span className="text-center text-[#606060] text-xs max-h-[5px]">
                          {rates.dislikes}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {
                <Button
                  inGroupStyle={false}
                  type="button"
                  onClick={() => {
                    navigate(APP_ROUTES.ACTION_GROUP + "/" + data?.data.id);
                  }}
                  className="!pointer-events-auto"
                >
                  View Group
                </Button>
              }
            </>
          }
        />
      )}
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>,
    document.body
  );
}

const IssueCardModalWithRef = React.forwardRef(GroupCardModal);

export default memo(IssueCardModalWithRef);
