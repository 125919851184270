import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import level1 from "assets/images/level-1-horizontal.png";
import level2 from "assets/images/level-2-horizontal.png";
import level3 from "assets/images/level-3-horizontal.png";
import level4 from "assets/images/level-4-horizontal.png";
import level5 from "assets/images/level-5-horizontal.png";
import { useMemo } from "react";

import { ScoreTooltip } from "components/ScoreTooltip/ScoreTooltip";

interface IReputationScoreProps {
  score: number | undefined;
  className?: string;
  place?: "top" | "bottom" | "left" | "right";
  classNames?: {
    icon?: string;
  };
  tooltipId?: string;
}

// Level Icon	Value Range	Title	Description	Hover Text
// Level 5	4.5 to 5	Powerhouse Contributor	Fully Energized and Leading the Charge	This member is a Powerhouse Contributor: Fully Energized and Leading the Charge
// Level 4	3.5 to 4.4	High Voltage Creator	Electrifying the Community with Passion	This member is a High Voltage Creator: Electrifying the Community with Passion
// Level 3	2.5 to 3.4	Active Energizer	Sparking Conversations and Connections	This member is an Active Energizer: Sparking Conversations and Connections
// Level 2	1.5 to 2.4	Rising Reactor	Building Momentum and Gaining Charge	This member is a Rising Reactor: Building Momentum and Gaining Charge
// Level 1	0 to 1.4	Starter Spark	Igniting a Journey of Impact	This member is a Starter Spark: Igniting a Journey of Impact

function getScoreIcon(score: number | undefined, icons = {}) {
  if (!score) {
    // if there is no score, return the first icon
    return icons[0];
  }
  if (score >= 0 && score <= 1.4) {
    return icons[0];
  }
  if (score > 1.4 && score <= 2.4) {
    return icons[1];
  }
  if (score > 2.4 && score <= 3.4) {
    return icons[2];
  }
  if (score > 3.4 && score <= 4.4) {
    return icons[3];
  }
  return icons[4];
}

//
export function ReputationScore({
  score,
  className,
  classNames,
  place,
  tooltipId,
}: IReputationScoreProps) {
  const icons = useMemo(() => {
    return {
      //here the trick - we don't have one image for one of levels
      0: (
        <>
          <img
            data-tooltip-id={"level-1" + tooltipId}
            src={level1}
            alt="level 1"
            className={twMerge(classnames("w-4", classNames?.icon))}
          />
          <ScoreTooltip
            content="This member is a Starter Spark: Igniting a Journey of Impact"
            id={"level-1" + tooltipId}
            place={place} //seems like left is not working
          />
        </>
      ),
      1: (
        <>
          <img
            data-tooltip-id={"level-2" + tooltipId}
            src={level2}
            alt="level 2"
            className={twMerge(classnames("w-4", classNames?.icon))}
          />
          <ScoreTooltip
            content="This member is a Rising Reactor: Building Momentum and Gaining Charge"
            id={"level-2" + tooltipId}
            place={place} //seems like left is not working
          />
        </>
      ),
      2: (
        <>
          <img
            data-tooltip-id={"level-3" + tooltipId}
            src={level3}
            alt="level 3"
            className={twMerge(classnames("w-4", classNames?.icon))}
          />
          <ScoreTooltip
            content="This member is an Active Energizer: Sparking Conversations and Connections"
            id={"level-3" + tooltipId}
            place={place} //seems like left is not working
          />
        </>
      ),
      3: (
        <>
          <img
            data-tooltip-id={"level-4" + tooltipId}
            src={level4}
            alt="level 4"
            className={twMerge(classnames("w-4", classNames?.icon))}
          />
          <ScoreTooltip
            content="This member is a High Voltage Creator: Electrifying the Community with Passion"
            id={"level-4" + tooltipId}
            place={place} //seems like left is not working
          />
        </>
      ),
      4: (
        <>
          <img
            data-tooltip-id={"level-5" + tooltipId}
            src={level5}
            alt="level 5"
            className={twMerge(classnames("w-4", classNames?.icon))}
          />
          <ScoreTooltip
            content="This member is a Powerhouse Contributor: Fully Energized and Leading the Charge"
            id={"level-5" + tooltipId}
            place={place} //seems like left is not working
          />
        </>
      ),
    };
  }, [classNames?.icon, place, tooltipId]);
  return (
    <div className={twMerge(classnames("flex flex-col", className))}>
      {getScoreIcon(score, icons)}
    </div>
  );
}
