import { ENDPOINTS } from "const";
import { axios } from "services";
import { IIssueRating, ISolution } from "types";

interface ISolutionProps {
  solution: ISolution;
}

export function createSolution({ solution }: ISolutionProps) {
  return axios.post<ISolution>(ENDPOINTS.SOLUTIONS, solution);
}

export function updateSolution({
  solutionId,
  solution,
}: ISolutionProps & { solutionId: string }) {
  return axios.patch<ISolution>(
    ENDPOINTS.SOLUTIONS + "/" + solutionId,
    solution
  );
}

export function createSolutionRating(issue: IIssueRating) {
  return axios.post<IIssueRating>(ENDPOINTS.SOLUTION_RATINGS, issue);
}
export function updateSolutionRating(issue: IIssueRating) {
  return axios.patch<IIssueRating>(
    ENDPOINTS.SOLUTION_RATINGS + "/" + issue.id,
    issue
  );
}
