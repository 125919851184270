import classnames from "classnames";
import { useMemo, useState, useCallback } from "react";
import _ from "lodash";

import {
  FormikFieldArray,
  Badge,
  FormikAdvanceSelect,
  SearchableSelectType,
} from "components";

import { FIELD_NAMES } from "./ProfileForm.constants";
import { ISelectOption } from "types";
import {
  useDebouncedHandler,
  useIssueRatingCategories,
  useSkills,
} from "hooks";
import { OptionProps, components } from "react-select";
import { useField } from "formik";
import { SKILLS_BADGE_COLORS } from "const";

interface ISkillsSelect extends ISelectOption {
  description: string;
}

function Option(props: OptionProps<ISkillsSelect>) {
  return (
    <components.Option {...props}>
      <div
        className={classnames(
          "flex flex-col  text-sm leading-6 flex-1 pr-3 pl-8 border-transparent border-l-2 border-solid pt-2 pb-2",
          {
            " !border-l-link-water hover-list-item-background":
              props.isFocused && !props.isSelected,
            " !border-l-black select-list-item-background": props.isSelected,
          }
        )}
      >
        <span className="  font-semibold">{props.data.label}</span>
        <span className="font-normal mt-1 line-clamp-2 text-gray-500">
          {props.data.description}
        </span>
      </div>
    </components.Option>
  );
}

interface IMultiSelectProps {
  className?: string;
  readOnly?: boolean;
}

export function FormikMultiSelectWithBadgesSkills({
  className,
  readOnly,
}: IMultiSelectProps) {
  const [, form] = useField(FIELD_NAMES.SKILLS);
  const error = form.touched && form.error;
  const showError = !!error;
  const [inputValue, setInputValue] = useState("");
  const { data, isLoading } = useSkills({ search: inputValue });
  const options = useMemo(() => {
    return _.compact(
      data?.data.map((item) => ({
        label: item.name,
        value: item.id || "",
        description: item.description,
      }))
    );
  }, [data]);
  const onInputChange = useDebouncedHandler((value: string) => {
    setInputValue(value);
  });

  let message = "";
  if (!readOnly) {
    message = showError ? error : "You can add up to 5 skills";
  }

  return (
    <div
      className={classnames("flex flex-col ", className, {
        "w-full  ": readOnly,
      })}
    >
      <FormikAdvanceSelect<SearchableSelectType>
        readOnly={readOnly}
        label={"Skills"}
        controlShouldRenderValue={false}
        placeholder="Search skills..."
        filterOption={useCallback(() => true, [])} //disable to filter options
        name={FIELD_NAMES.SKILLS}
        options={options}
        isMulti
        isSearchable
        isLoading={isLoading}
        makeSearch={onInputChange}
        styles={{
          indicatorsContainer: () => ({
            display: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            padding: "0px",
          }),
        }}
        className={classnames("z-10 multiselect", {
          "!bg-transparent pointer-events-none select-none": readOnly,
        })}
        //@ts-ignore
        components={{ Option }}
        message={message}
        classNames={{
          message: showError ? "text-red-500" : " text-gray-500",
          input: readOnly ? "!bg-transparent " : "",
          inputContainer: readOnly ? " hidden " : "",
        }}
        closeMenuOnSelect
        onMenuClose={useCallback(() => {
          setInputValue("");
        }, [])}
      />

      <div className="flex gap-4 flex-wrap mt-4 mb-6">
        <FormikFieldArray<ISelectOption> name={FIELD_NAMES.SKILLS}>
          {({ remove, index, ...rest }) => {
            //text-indigo-400  hover:text-indigo-500  focus:bg-indigo-500
            return (
              <Badge
                showRemove={!readOnly}
                onRemove={() => {
                  remove(index);
                }}
                classNames={{
                  button: SKILLS_BADGE_COLORS[index] || "",
                }}
              >
                {rest.value.label}
              </Badge>
            );
          }}
        </FormikFieldArray>
      </div>
    </div>
  );
}

export function FormikMultiSelectWithBadgesCategories({
  className,
  readOnly,
}: IMultiSelectProps) {
  const [, form] = useField(FIELD_NAMES.CATEGORIES);
  const error = form.touched && form.error;
  const showError = !!error;
  const { data, isLoading } = useIssueRatingCategories();
  const options = useMemo(() => {
    return _.compact(
      data?.data.map((item) => ({
        label: item.title,
        value: item.id || "",
        description: item.description,
      }))
    );
  }, [data]);

  let message = "";
  if (!readOnly) {
    message = showError ? error : "You can add up to 3 categories";
  }

  return (
    <div
      className={classnames("flex flex-col ", className, {
        "w-full  ": readOnly,
      })}
    >
      <FormikAdvanceSelect<SearchableSelectType>
        readOnly={readOnly}
        label={"Topics You Care About"}
        controlShouldRenderValue={false}
        placeholder="Select categories"
        name={FIELD_NAMES.CATEGORIES}
        options={options}
        isMulti
        isLoading={isLoading}
        styles={{
          indicatorsContainer: () => ({
            display: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            padding: "0px",
          }),
        }}
        className={classnames("multiselect", {
          "!bg-transparent pointer-events-none select-none": readOnly,
        })}
        //@ts-ignore
        components={{ Option }}
        message={message}
        classNames={{
          message: showError ? "text-red-500" : " text-gray-500",
          input: readOnly ? "!bg-transparent " : "",
          inputContainer: readOnly ? " hidden " : "",
        }}
        closeMenuOnSelect
      />

      <div className="flex gap-4 flex-wrap mt-4">
        <FormikFieldArray<ISelectOption> name={FIELD_NAMES.CATEGORIES}>
          {({ remove, index, ...rest }) => {
            return (
              <Badge
                showRemove={!readOnly}
                onRemove={() => {
                  remove(index);
                }}
                classNames={{
                  button: SKILLS_BADGE_COLORS[index] || "",
                }}
              >
                {rest.value.label}
              </Badge>
            );
          }}
        </FormikFieldArray>
      </div>
    </div>
  );
}
