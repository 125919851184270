import { APP_ROUTES } from "const";
import React, { memo, useState } from "react";
import { IActivity, IUser } from "types";
import { Card, CustomToolTip } from "components";
import AvatarPlaceholder from "assets/images/avatar.png";

import ChevronDown from "assets/images/chevron-down.svg";

import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { differenceInMinutes } from "date-fns";
import { useInfiniteActivities, useMe, useSetActivitiesAsSeen } from "hooks";
import { useInView } from "react-intersection-observer";
import { getActivityNavigation, getNotificationDescription } from "utils";

interface IItemProps {
  notification: IActivity<IUser>;
  className?: string;
  isLast?: boolean;
  innerRef?: any;
  seen?: boolean;
  setAsViewed?: (id: string) => void;
  id: string;
}

//////////////
function NotificationItem({
  notification,
  isLast,
  innerRef,
  seen,
  setAsViewed,
  id,
}: IItemProps) {
  const navigate = useNavigate();
  const notificationDescription = getNotificationDescription(notification); //
  const timeDifference = Math.abs(
    differenceInMinutes(new Date(notification.createdAt), new Date())
  );
  const notificationTime =
    timeDifference < 60
      ? `${timeDifference}m`
      : timeDifference > 59 && timeDifference < 1440
      ? `${Math.ceil(timeDifference / 60)}h`
      : `${Math.ceil(timeDifference / 1440)}d`;

  const [isHovered, setIsHovered] = useState(false);

  const { ref } = useInView({
    skip: seen,
    triggerOnce: true,
    delay: 2000,
    onChange: (inView) => {
      if (inView) {
        setAsViewed && setAsViewed(id);
      }
    },
  });

  return (
    <div className="flex flex-col w-full" ref={innerRef}>
      <div
        ref={ref}
        onClick={() => {
          navigate(
            getActivityNavigation(
              notification.representEntityType,
              notification.representEntityId
            )
          );
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseOut={() => {
          setIsHovered(false);
        }}
        className={classnames(
          "flex flex-row gap-2 justify-between text-sm leading-6 flex-1 px-16 maxSm:px-6  py-4 cursor-pointer w-full",
          { "hover:bg-gray-100": isHovered }
        )}
      >
        <div className="flex flex-row gap-2">
          <div className="flex items-center rounded-full shrink-0">
            <img
              alt="avatar"
              className="h-6 w-6 rounded-full"
              src={notification.activityUser.avatar || AvatarPlaceholder}
            />
          </div>
          <div className="relative flex flex-col">
            <span className="  font-semibold">{notification.title}</span>
            <span className="font-normal mt-1 line-clamp-2 text-gray-500">
              {notificationDescription}
            </span>
            {notification.content && (
              <CustomToolTip
                className="z-[150] line-clamp-3 !bg-black !min-w-[120%] !max-w-[240%]"
                position={isLast ? "top" : "bottom"}
                badgeStyle={false}
                showTooltip={isHovered}
                tooltipText={notification.content}
              />
            )}
          </div>
        </div>
        <div className="flex items-center text-gray-500">
          {notificationTime}
        </div>
      </div>
      <div className={classnames("border-b border-[#E5E7EB] w-full h-[1px]")} />
    </div>
  );
}

function DashboardActivitiesCard() {
  const { data: me } = useMe();

  const { data, ref } = useInfiniteActivities({
    representEntityUser: me?.data.id || "",
  });
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);
  const navigate = useNavigate();
  const setAsViewed = useSetActivitiesAsSeen();

  return (
    <Card
      className="bg-white !py-2 !gap-0"
      shouldHover={false}
      collapse={descriptionCollapsed}
      header={
        <div
          // onTouchStart={() => {
          //   setDescriptionCollapsed((val) => !val);
          // }}
          // onTouchEnd={(e) => {
          //   e.preventDefault();
          // }}
          className={classnames("flex justify-between items-center py-2  ", {
            "pb-4": !descriptionCollapsed,
            "pb-2": descriptionCollapsed,
          })}
        >
          <div className="grid grid-flow-col gap-x-2 items-center sm:h-[40px] ">
            <img
              alt="chevron-down"
              className={classnames("cursor-pointer lg:hidden", {
                "rotate-180": descriptionCollapsed,
              })}
              onClick={() => {
                setDescriptionCollapsed((val) => !val);
              }}
              src={ChevronDown}
            />
            <span className="font-bold leading-6 text-lg">Activity</span>
          </div>
        </div>
      }
      classNames={{
        header: "px-10 ",
        content: "!px-0 flex-1 ",
        footer: "px-16 h-[60px]",
      }}
      showFooterDivider={false}
      content={
        <div className="max-h-[600px] overflow-y-auto overflow-x-hidden  p-[1px] ">
          {data?.pages.map((page, pageKey) => {
            const lastPage = data.pages.length - 1 === pageKey;

            return (
              <React.Fragment key={pageKey}>
                {page.data.items?.map((notification, itemKey) => {
                  const lastItem = page.data.items.length - 1 === itemKey;
                  const isLast = lastItem && lastPage;

                  return (
                    <React.Fragment key={notification.id}>
                      <NotificationItem
                        key={notification.id + notification.createdAt}
                        notification={notification}
                        isLast={isLast}
                        innerRef={isLast ? ref : null}
                        id={notification.id || ""}
                        setAsViewed={setAsViewed}
                        seen={notification.seen}
                      />
                      {isLast && (
                        <div className="px-16 py-16 flex items-center justify-center text-gray-500">
                          <span className="text-black font-normal text-md">
                            <p className="  text-center ">
                              No more activities found, please
                              <span
                                onClick={() => {
                                  navigate(APP_ROUTES.ISSUES);
                                }}
                                className="  px-1 font-bold   cursor-pointer"
                              >
                                browse an issue
                              </span>
                            </p>
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      }
      footer={<div></div>}
    />
  );
}

export default memo(DashboardActivitiesCard);
