import { useInfinityMotivationals, useQueryOptionsSetter } from "hooks";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions } from "react-query";
import { useSearchParams } from "react-router-dom";
import { IMotivationalsProps } from "services";
import { IMotivational, IMotivationalTag, IUser } from "types";
import { IAxiosResponseWithPagination } from "types/requests";

import { getRange, getSearchParams } from "utils";

type MotivationalParamsTypes = Partial<IMotivationalsProps["params"]>;
interface IInfiniteQueryMotivationalsProps
  extends Partial<
      UseInfiniteQueryOptions<
        IAxiosResponseWithPagination<IMotivational<IUser, IMotivationalTag>>
      >
    >,
    MotivationalParamsTypes {}
export function useMotivationalList(
  params: Partial<
    ReturnType<typeof getSearchParams> & IInfiniteQueryMotivationalsProps
  >,
  mapWidth?: number,
  limit?: number,
  disabled = false
) {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();
  const { data, fetchNextPage, ...rest } = useInfinityMotivationals({
    limit: limit || 20,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },

    range: getRange(params.zoom, Number(params.lat), mapWidth),
    keepPreviousData: true,
    suspense: !isMount,
    enabled: !disabled,
    ...params,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}

export function useMotivationalListFilterParams(
  mapWidth: number,
  disabled = false,
  limit?: number
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, inViewRef } = useMotivationalList(
    getSearchParams(searchParams),
    mapWidth,
    limit,
    disabled
  );
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  return { data, inViewRef, setQueryOptions, searchParams };
}
