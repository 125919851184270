import { Form, useFormikContext } from "formik";
import { memo } from "react";

import { Button, FormikInput, FormikRating, FormikTextarea } from "components";
import { ISSUE_FIELD_NAMES } from "./IssueForm.constants";
import classnames from "classnames";
import { MAX_TEXT_LENGTH, MAX_TITLE_TEXT_LENGTH } from "./IssueForm.schema";
import FormikAutoCompleteSelectField from "components/FormikAutoCompleteSelectField";
import { IManagementProps, IValues } from "./IssueForm.types";
import { IIssueRatingCategory } from "types";

import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";

export interface IIssueFormProps extends IManagementProps {
  categories: IIssueRatingCategory[] | undefined;
  showIssueFields: boolean;
  showRatings: boolean;
  className?: string;
  onClose: (() => void) | undefined;
}

function IssueForm(props: IIssueFormProps) {
  const { errors, values, isSubmitting } = useFormikContext<IValues>();
  return (
    <Form className=" two-third-step flex flex-col flex-1 ">
      <div className="  px-4 sm:px-20 flex-1 flex flex-col gap-4">
        {props.showIssueFields && (
          <>
            <FormikInput
              autoComplete="nope"
              label="Title"
              name={ISSUE_FIELD_NAMES.TITLE}
              placeholder="Title"
              showOnlyCustomMessage={errors?.title === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Please add a descriptive title</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.title === "max",
                    })}
                  >{`${values?.title?.length}/${MAX_TITLE_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikAutoCompleteSelectField
              allowAutoFill={true}
              name={ISSUE_FIELD_NAMES.LOCATION}
              label="Location"
              //className="mt-6"
            />
            <FormikTextarea
              autoComplete="nope"
              label="Details"
              name={ISSUE_FIELD_NAMES.DETAILS}
              placeholder="Details"
              showOnlyCustomMessage={errors?.details === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Please add some more details</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.details === "max",
                    })}
                  >{`${values?.details?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikTextarea
              autoComplete="nope"
              label="Origin"
              name={ISSUE_FIELD_NAMES.ORIGIN}
              placeholder="Origin"
              showOnlyCustomMessage={errors?.origin === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>How did this issue start?</span>{" "}
                  <span
                    className={classnames({
                      "text-red-400": errors?.origin === "max",
                    })}
                  >{`${values?.origin?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikTextarea
              autoComplete="nope"
              label="Importance"
              name={ISSUE_FIELD_NAMES.IMPORTANCE}
              placeholder="Importance"
              showOnlyCustomMessage={errors?.importance === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Why is this issue important?</span>{" "}
                  <span
                    className={classnames({
                      "text-red-400": errors?.importance === "max",
                    })}
                  >{`${values?.importance?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
          </>
        )}

        {props.showRatings && (
          <>
            {props.showIssueFields && (
              <>
                <div className="  leading-6 font-semibold pt-8">
                  Rate your issue
                </div>
              </>
            )}
            <div className="three-seventh-step grid grid-cols-2 mt-5 gap-4 flex-1 auto-rows-min">
              {props.categories?.map((category) => (
                <FormikRating
                  key={category.id}
                  name={category.category}
                  title={category.title}
                  classNames={{ input: "mt-2 !gap-3" }}
                />
              ))}
            </div>

            {!!errors?.validator! && (
              <div className="w-full">
                <p className=" text-red-400 text-sm font-normal">
                  {errors?.validator as string}
                </p>
              </div>
            )}
          </>
        )}
        {props.showIssueFields && (
          <>
            <Attachments />
            <FormikTags
              fieldName={ISSUE_FIELD_NAMES.TAGS}
              label="Labels (Optional)"
            />
          </>
        )}
      </div>

      <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
      <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
        <Button
          isReverse
          type="button"
          className=" justify-center border"
          disabled={isSubmitting}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="justify-center"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default memo(IssueForm);
