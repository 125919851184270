/* This example requires Tailwind CSS v2.0+ */
import React, { memo, useCallback, useRef, useState } from "react";

import classnames from "classnames";
import { IActivity, IUser } from "types";
import { differenceInMinutes } from "date-fns";
import {
  useInfiniteActivities,
  useMe,
  useOnClickOutside,
  useSetActivitiesAsSeen,
  useUserSkills,
} from "hooks";
import { APP_ROUTES } from "const";
import { Link, useNavigate } from "react-router-dom";
import AvatarPlaceholder from "assets/images/avatar.png";
import { CustomToolTip } from "components/CustomToolTip";
import { useInView } from "react-intersection-observer";
import { getActivityNavigation, getNotificationDescription } from "utils";

interface IProps {
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  mobileVersion?: boolean;
  className?: string;
}

interface IItemProps {
  notification: IActivity<IUser>;
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  isLast?: boolean;
  innerRef?: any;
  seen?: boolean;
  setAsViewed?: (id: string) => void;
  id: string;
}

function NotificationItem({
  id,
  notification,
  setOpenNotifications,
  innerRef,
  isLast = false,
  seen,
  setAsViewed,
}: IItemProps) {
  const navigate = useNavigate();
  const notificationDescription = getNotificationDescription(notification); //
  const timeDifference = Math.abs(
    differenceInMinutes(new Date(notification.createdAt), new Date())
  );
  const notificationTime =
    timeDifference < 60
      ? `${timeDifference}m`
      : timeDifference > 59 && timeDifference < 1440
      ? `${Math.ceil(timeDifference / 60)}h`
      : `${Math.ceil(timeDifference / 1440)}d`;
  const [isHovered, setIsHovered] = useState(false);

  const { ref } = useInView({
    skip: seen,
    triggerOnce: true,
    delay: 2000,
    onChange: (inView) => {
      if (inView) {
        setAsViewed && setAsViewed(id);
      }
    },
  });

  return (
    <div className="flex flex-col w-full" ref={innerRef}>
      <div
        ref={ref}
        onClick={() => {
          setOpenNotifications(false);
          navigate(
            getActivityNavigation(
              notification.representEntityType,
              notification.representEntityId
            )
          );
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseOut={() => {
          setIsHovered(false);
        }}
        className={classnames(
          "flex flex-row gap-2 justify-between text-sm leading-6 flex-1 px-16  pt-2 pb-2 cursor-pointer",
          { "bg-[#F8F8F8]": isHovered }
        )}
      >
        <div className="flex flex-row gap-2">
          <div className="flex items-center rounded-full shrink-0">
            <img
              alt="avatar"
              className="h-6 w-6 rounded-full"
              src={notification.activityUser.avatar || AvatarPlaceholder}
            />
          </div>
          <div className=" relative flex flex-col">
            <span className="  font-semibold">{`${
              notification.activityUser.firstName ||
              notification.activityUser.email
            } ${notification.activityUser.lastName || ""}`}</span>
            <span className="font-normal mt-1 line-clamp-2 text-gray-500">
              {notificationDescription}
            </span>
            {notification.content && (
              <CustomToolTip
                className="z-[150] line-clamp-3 !bg-black !min-w-[120%] !max-w-[240%]"
                position={isLast ? "top" : "bottom"}
                badgeStyle={false}
                showTooltip={isHovered}
                tooltipText={notification.content}
              />
            )}
          </div>
        </div>
        <div className="flex items-center text-gray-500">
          {notificationTime}
        </div>
      </div>
      <div className={classnames("border-b border-[#E5E7EB] w-full h-[1px]")} />
    </div>
  );
}

function NotificationPopup({
  className,
  mobileVersion = false,
  setOpenNotifications,
}: IProps) {
  const { data: meData } = useMe();
  const { data: userSkillsData } = useUserSkills({
    id: meData?.data.id,
  });
  const { data: activitiesData, ref } = useInfiniteActivities({
    representEntityUser: meData?.data.id,
  });
  const mainContainer = useRef<HTMLDivElement | null>(null);
  useOnClickOutside({
    ref: mainContainer,
    handler: useCallback(() => {
      setOpenNotifications(false);
    }, [setOpenNotifications]),
    truthfulCondition: true,
  });

  const notEnoughPersonalData =
    (!meData?.data.firstName && !meData?.data.firstName) ||
    !userSkillsData?.data ||
    userSkillsData?.data.length < 1;
  const setAsViewed = useSetActivitiesAsSeen();
  return (
    <div
      ref={mainContainer}
      className={classnames(
        className,
        `  bg-white rounded-md flex flex-col  shadow-md`,
        {
          " absolute min-w-[500px] top-[100%] z-[100] right-[100%] ":
            !mobileVersion,
          "relative w-full flex-1  ": mobileVersion,
        }
      )}
    >
      <div className="px-16 py-4 flex flex-row justify-start font-bold text-black">
        Notifications
      </div>
      <div className={classnames("border-b border-[#D1D5DB] w-full h-[1px]")} />
      <div
        className={classnames("  flex flex-col pb-2  overflow-auto", {
          " max-h-[535px] ": !mobileVersion,
          " max-h-screen ": mobileVersion,
        })}
      >
        {notEnoughPersonalData && (
          <>
            <div className="px-16 py-6 flex flex-row items-center justify-center text-gray-500 ">
              <p>
                Please go to your{" "}
                <Link className="font-bold" to={APP_ROUTES.PROFILE}>
                  profile page{" "}
                </Link>
                to fill out more required information, so we can assist you get
                the best out of this app
              </p>
            </div>

            <div
              className={classnames("border-b border-[#E5E7EB] w-full h-[1px]")}
            />
          </>
        )}
        {activitiesData?.pages.map((page, pageKey) => {
          return (
            <React.Fragment key={pageKey}>
              {page.data.items.map((notification, itemKey) => {
                const lastPage = activitiesData.pages.length - 1 === pageKey;
                const lastItem = page.data.items.length - 1 === itemKey;
                const isLast = lastItem && lastPage;
                return (
                  <React.Fragment key={itemKey}>
                    <NotificationItem
                      notification={notification}
                      setOpenNotifications={setOpenNotifications}
                      isLast={isLast}
                      innerRef={isLast ? ref : null}
                      id={notification.id || ""}
                      setAsViewed={setAsViewed}
                      seen={notification.seen}
                    />
                    {isLast && (
                      <div className="px-16 py-16 flex items-center justify-center text-gray-500">
                        No more notifications
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>

      {!mobileVersion && <div className="px-4 py-8"></div>}
    </div>
  );
}

export default memo(NotificationPopup);
