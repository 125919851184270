import {
  Badge,
  Button,
  FormikAdvanceSelect,
  FormikFieldArray,
  Option,
  SearchableSelectType,
} from "components";
import { SKILLS_BADGE_COLORS } from "const";
import { Form } from "formik";
import { useActionGroups, useDebouncedHandler, useMe } from "hooks";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FIELD_NAMES } from "./AddActionGroup.hooks";
import classnames from "classnames";
import { ISelectOption } from "types";
import { Rings } from "react-loader-spinner";

export default function AddActionGroupForm() {
  const [title, setTitle] = useState("");
  const { data: me } = useMe();
  const {
    data: actionGroups,
    isFetching,
    isLoading,
  } = useActionGroups({
    user: me?.data.id,
    title,
  });

  const options = useMemo(() => {
    return _.compact(
      actionGroups?.data.items.map((item) => ({
        label: item.title || "",
        value: item.id || "",
        description: item.description || "",
      }))
    );
  }, [actionGroups?.data.items]);
  const onInputChange = useDebouncedHandler((value: string) => {
    setTitle(value);
  });
  const loading = isFetching || isLoading;
  return (
    <Form className="flex flex-col flex-1 w-full">
      <FormikAdvanceSelect<SearchableSelectType>
        label="Action groups"
        controlShouldRenderValue={false}
        placeholder="Search action groups..."
        filterOption={useCallback(() => true, [])} //disable to filter options
        name={FIELD_NAMES.ACTION_GROUPS}
        options={options}
        isMulti
        isSearchable
        isLoading={isFetching || isLoading}
        makeSearch={onInputChange}
        styles={{
          indicatorsContainer: () => ({
            display: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            padding: "0px",
          }),
        }}
        components={{ Option }}
        classNames={{
          message: " text-gray-500",
        }}
        className={classnames("z-10 multiselect px-4 sm:px-20")}
        closeMenuOnSelect
        onMenuClose={useCallback(() => {
          setTitle("");
        }, [])}
      />
      <div className="flex gap-4 flex-wrap px-4 sm:px-20">
        <FormikFieldArray<ISelectOption> name={FIELD_NAMES.ACTION_GROUPS}>
          {({ remove, index, ...rest }) => {
            return (
              <Badge
                className="pl-0 badge"
                onRemove={() => {
                  remove(index);
                }}
                classNames={{
                  button: SKILLS_BADGE_COLORS[index] || "",
                }}
              >
                {rest.value.label}
              </Badge>
            );
          }}
        </FormikFieldArray>
      </div>
      <div className="flex flex-row justify-center w-full pt-2">
        <Button
          isReverse
          isStyleless
          inGroupStyle={false}
          disabled={loading}
          className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
          type="submit"
        >
          {loading ? <Rings color="#DA7D78" height={20} width={20} /> : "Save"}
        </Button>
      </div>
    </Form>
  );
}
