import classnames from "classnames";
import { Star } from "components";

import { memo, useState } from "react";
import Line from "./Line";
import { ScoreTooltip } from "components/ScoreTooltip/ScoreTooltip";

interface IProps {
  title: string;
  ratingValue: number;
  setRatingValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  onClick?: (ratingValue: number) => void;
  starSize?: "small" | "normal" | "large";
  ratingStyle?: "star" | "line";
  displayOnly?: boolean;
  classNames?: {
    label?: string;
    input?: string;
  };
  description?: string;
  category?: string;
}

function StarRatingItem({
  title,
  ratingValue,
  setRatingValue,
  onClick,
  starSize = "normal",
  ratingStyle = "star",
  displayOnly = false,
  classNames,
  description,
  category,
}: IProps) {
  const [itemHovered, setItemHovered] = useState(NaN);

  return (
    <div className="flex flex-col relative">
      <div className="flex flex-col w-full gap-y-2">
        <div className="relative">
          <span
            className={classnames(
              " font-light leading-[30px] text-sm text-gray-700 line-clamp-1",
              classNames?.label
            )}
          >
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap relative ">
              <span data-tooltip-id={category + "-tooltip-title"}>{title}</span>
            </p>
          </span>
        </div>

        {ratingStyle === "star" ? (
          <span
            className={classnames(
              "grid grid-flow-col justify-start gap-1 items-center font-normal leading-[30px]",
              classNames?.input
            )}
          >
            {[...Array(5)].map((_n, i) => (
              <Star
                size={starSize}
                key={i}
                id={i + 1}
                ratingValue={ratingValue}
                displayOnly={displayOnly}
                itemHovered={itemHovered}
                setItemHovered={setItemHovered}
                onClick={() => {
                  if (setRatingValue) {
                    if (ratingValue === i + 1) {
                      setRatingValue(0);
                      if (onClick) {
                        onClick(0);
                      }
                    } else {
                      setRatingValue(i + 1);
                      if (onClick) {
                        onClick(i + 1);
                      }
                    }
                  }
                }}
              />
            ))}
          </span>
        ) : (
          <div className="flex items-center max-h-[10px]  gap-2">
            <span
              className={classnames(
                " flex h-[6px] bg-[#DDDDDC] rounded-md w-full",
                classNames?.input
              )}
              data-tooltip-id={category + "-tooltip-indicator"}
            >
              <Line ratingValue={ratingValue} />
            </span>
            <span className="text-[#212221E0] font-semibold text-sm flex flex-row items-start">
              {ratingValue}
            </span>
          </div>
        )}
      </div>
      <ScoreTooltip content={description} id={category + "-tooltip-title"} />
      <ScoreTooltip
        content={description}
        id={category + "-tooltip-indicator"}
      />
    </div>
  );
}

export default memo(StarRatingItem);
