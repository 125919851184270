import { APP_ROUTES } from "const";
import { useSignUpApi } from "hooks";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FIELD_NAMES } from "./SignUpForm.constants";

export function useInitialState() {
  return useMemo(() => {
    return {
      [FIELD_NAMES.EMAIL]: "",
      [FIELD_NAMES.PASSWORD]: "",
    };
  }, []);
}

export function useSubmitHandler() {
  const { mutateAsync } = useSignUpApi({});
  const navigate = useNavigate();
  return useCallback(
    async (props, formikHelpers) => {
      try {
        const result = await mutateAsync({
          ...props,
          email: props.email.toLowerCase(),
        });
        if (result.status === 201) {
          navigate(`${APP_ROUTES.CONFIRM_EMAIL}?registered=true`);
        }
      } catch (error: any) {
        formikHelpers.setErrors(error.response.data);
      }
    },
    [mutateAsync, navigate]
  );
}
