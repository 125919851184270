import { StylesConfig } from "react-select";

import { PRIMARY_COLORS } from "const";
import resolveConfig from "tailwindcss/resolveConfig";
import { ISelectOption } from "types";
import { queryClient } from "../index";

const defaultConfig = resolveConfig();

export function getStylesForReactSelectInputs(
  config: StylesConfig<ISelectOption> = {}
) {
  const customStyles: StylesConfig<ISelectOption> = {
    valueContainer: (provided) => ({ ...provided, flexWrap: "nowrap" }),
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        color: "black",
        fontSize: defaultConfig.theme.fontSize.xs,
        width: "15px",
      };
    },
    indicatorSeparator: () => ({}),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: defaultConfig.theme.fontSize.sm,
      textWrap: "nowrap",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: PRIMARY_COLORS.GHOST_WHITE,
      borderColor: defaultConfig.theme.colors.gray["200"],
      height: "40px",
    }),
    option: () => ({ display: "flex", alignItems: "center" }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "8px",
      paddingBottom: "12px",
      paddingLeft: "24px",
      paddingRight: "24px",
    }),
    ...config,
  };
  return customStyles;
}

export function logout() {
  queryClient.removeQueries();
  window.location.reload();
}
