import classnames from "classnames";
import { Label } from "components/Label";
import { useField } from "formik";
import { useCallback } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { twMerge } from "tailwind-merge";
import { ImageType } from "types";

interface IProps extends DropzoneOptions {
  label?: string;
  className?: string;
  fileTypes?: string[];
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
    text?: string;
  };
}
function isFileImage(file: File) {
  return file && file["type"].split("/")[0] === "image";
}
export function FormikFileDnd({
  className,
  label,
  classNames,
  ...rest
}: IProps) {
  const name = "attachments";
  const [{ value }, { touched }, { setValue, setTouched }] =
    useField<(File | ImageType)[]>(name);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    ...rest,
    accept: {
      "image/*": [],
      "application/pdf": [],
      "text/plain": [],
      //accept doc and docs
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      //accept xls and xlsx
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    onDragOver: () => {
      if (!touched) {
        return setTouched(true);
      }
    },
    //add preview url to file https://react-dropzone.js.org/#section-previews
    onDrop: (acceptedFiles: File[]) => {
      const modified = acceptedFiles.map((file) => {
        if (isFileImage(file)) {
          return Object.assign(file, { dataUrl: URL.createObjectURL(file) }); //{ file,  };
        }
        return file;
      });
      setValue([...value, ...modified]);
    },
  });
  return (
    <div
      onBlur={useCallback(() => {
        setTouched(true);
      }, [setTouched])}
      className={twMerge(
        classnames(
          "flex flex-col w-full h-48 border-2 border-dashed hover:border-green-300 hover:bg-green-100 border-gray-300 cursor-pointer group",
          {
            "border-green-300 border-2 border-dashed bg-green-100 ":
              isDragAccept,
          },
          className
        )
      )}
    >
      {label && <Label name={label} className={classNames?.label} />}
      <div
        {...getRootProps()}
        className={classnames(
          "h-full w-full flex items-center justify-center p-4 text-center",
          classNames?.container
        )}
      >
        <input
          {...getInputProps()}
          onBlur={useCallback(() => {
            setTouched(true);
          }, [setTouched])}
          className={classnames(classNames?.input)}
        />
        {!isDragAccept && (
          <p className={classnames(classNames?.text)}>
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
        {isDragAccept && (
          <p className={classnames(classNames?.text)}>Drop the file(s)</p>
        )}
      </div>
    </div>
  );
}
