import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

export interface IQuillProps {
  setFieldValue?: (value: any, shouldValidate?: boolean | undefined) => void;
  isReadOnly?: boolean;
  value: string;
  className?: string;
  placeholder?: string;
  bounds?: string | HTMLElement;
  onBlur?: () => void;
}

function QuillEditor({
  value,
  isReadOnly,
  setFieldValue,
  className,
  placeholder = "Tell your story",
  bounds,
  onBlur,
}: IQuillProps) {
  const handleQuillEdit = useCallback(
    async (value: string) => {
      if (setFieldValue) {
        setFieldValue(value);
      }
    },
    [setFieldValue]
  );
  const toolbarOptions = useMemo(
    () => [
      ["bold", "italic", "link", "underline", "strike"],
      [{ header: 1 }, { header: 2 }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    []
  );
  const [quillInstance, setQuillInstance] = useState(null);
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current && quillInstance) {
      //@ts-ignore
      const quill = quillInstance?.getEditor();
      //this is needed to prevent quill from adding <p> tag on initialization
      quill?.clipboard?.dangerouslyPasteHTML(value);
      mounted.current = true;
    }
  }, [quillInstance, value]);

  return (
    <ReactQuill
      ref={useCallback(
        (ref) => {
          setQuillInstance(ref);
        },
        [setQuillInstance]
      )}
      className={classnames(
        {
          "min-h-[400px]": !isReadOnly,
        },
        className
      )}
      theme="bubble"
      modules={useMemo(() => ({ toolbar: toolbarOptions }), [toolbarOptions])}
      readOnly={isReadOnly}
      placeholder={!isReadOnly ? placeholder : ""}
      //Initial value for the editor as an uncontrolled component.
      defaultValue={mounted.current ? value : ""}
      onChange={handleQuillEdit}
      onBlur={onBlur}
      bounds={bounds}
    />
  );
}

export default QuillEditor;
