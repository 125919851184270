import classnames from "classnames";
import { useCallback } from "react";

import { FormikFieldArray, Badge } from "components";

import { FIELD_NAMES } from "./ProfileForm.constants";

import FormikInput from "components/FormikInput";
import { useFormikContext } from "formik";

interface IMultiSelectProps {
  formValues: any;
  className?: string;
  readOnly?: boolean;
}

export function FormikSocialLinksArray({
  className,
  formValues,
  readOnly,
}: IMultiSelectProps) {
  const { setFieldValue } = useFormikContext();
  const clearField = useCallback(
    (currentFieldName: string) => {
      setFieldValue(currentFieldName, "");
    },
    [setFieldValue]
  );

  return (
    <div
      className={classnames("flex flex-col  ", className, {
        "w-full  ": !readOnly,
      })}
    >
      <div className="flex gap-4 flex-wrap   w-full">
        <FormikFieldArray<string> name={FIELD_NAMES.MEDIA_LINKS}>
          {({ remove, push, index }) => {
            //text-indigo-400  hover:text-indigo-500  focus:bg-indigo-500

            return (
              <>
                <div className="relative flex-row items-start justify-between gap-4 w-full ">
                  <FormikInput
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="Social media link (optional)"
                    name={FIELD_NAMES.MEDIA_LINKS + "." + index}
                    placeholder="LinkedIn, Twitter, Facebook, Instagram etc."
                  />
                  {formValues.length > 1 &&
                    index + 1 === formValues.length &&
                    !readOnly && (
                      <Badge
                        onRemove={() => {
                          clearField(FIELD_NAMES[`MEDIA_LINK_${index + 1}`]);
                          remove(index);
                        }}
                        className="absolute !items-end right-[-2rem] bottom-[4px] !p-1"
                        classNames={{
                          button: "text-red-500  focus:bg-red-500 !m-0" || "",
                        }}
                      >
                        {}
                      </Badge>
                    )}
                </div>
                {formValues.length === index + 1 &&
                  formValues.length < 4 &&
                  !readOnly && (
                    <div className="w-full flex ">
                      <button
                        type="button"
                        className="pb-4 inline-flex content-center items-center"
                        onClick={() => push("")}
                      >
                        <div className="flex flex-row gap-2 py-1 px-2 bg-white shadow-md cursor-pointer rounded-lg items-center">
                          <span className="inline-flex text-black text-md ">
                            Add social media link
                          </span>
                          <span className="inline-flex text-black font-bold text-2xl ">
                            +
                          </span>
                        </div>
                      </button>
                    </div>
                  )}
              </>
            );
          }}
        </FormikFieldArray>
      </div>
    </div>
  );
}
