import { ReactComponent as AvatarPlaceholderHover } from "assets/images/avatar-hover.svg";
import { ReactComponent as ImageIc } from "assets/images/image-ic.svg";
import classnames from "classnames";
import { IFileInputComponentProps, ImageType } from "types";
import { Label } from "components/Label";
import Avatar from "react-avatar";
import { useState } from "react";
import { AvatarEditorModal, Button } from "components";
import { useField } from "formik";

const random = Math.round(Math.random() * 10);
const color =
  random < 4
    ? "lightCoral"
    : random > 4 && random < 7
    ? "lightGreen"
    : "mediumPurple";
function EmptyLogo({ onClick, name }: { onClick: () => void; name: string }) {
  return (
    <div
      className="relative flex justify-center items-center group"
      tabIndex={1}
    >
      <Avatar
        size="55"
        className="block group-hover:hidden"
        color={color}
        name={name}
        onClick={onClick}
      />
      <div className="hidden group-hover:flex absolute  items-center justify-center bg-[#ffffff85] h-full w-full	">
        <AvatarPlaceholderHover onClick={onClick} />
      </div>
    </div>
  );
}

function Logo({ onClick, img }: { onClick: () => void; img: string }) {
  return (
    <div
      className="relative flex justify-center items-center group h-full w-full "
      tabIndex={1}
    >
      <img
        src={img}
        className="absolute"
        alt="logo"
        style={{
          objectFit: "cover",
        }}
      />
      <div
        className="hidden group-hover:flex absolute  items-center justify-center bg-[#ffffff85] h-full w-full	"
        onClick={onClick}
      >
        <ImageIc />
      </div>
    </div>
  );
}

// Image uploader content
function FormikImageUploaderContent({
  image,
  onImageUpload,
  onRemove,
  classNames,
  className,
  userName,
  fieldName,
  groupStyle = false,
  title,
  motivationalStyle = false,
  error,
  readOnly = false,
}: IFileInputComponentProps) {
  const [, , { setValue }] = useField<ImageType | string | null>(fieldName);
  const [showEditor, setShowEditor] = useState(false);

  const img =
    typeof image === "object"
      ? image && image["dataUrl"]
      : (image as string | null);

  const showImage = img !== null && image;

  return (
    <div className={classnames("flex flex-col", className)}>
      {!showImage && groupStyle && (
        <Button
          inGroupStyle={false}
          isStyleless
          type="button"
          className="bg-gray-100 border shadow-sm  "
          name={userName || ""}
          onClick={onImageUpload}
        >
          Upload
        </Button>
      )}
      {!motivationalStyle && img && (
        <div
          className={classnames(
            "flex mt-2 h-[55px] w-[55px] relative rounded-full overflow-hidden cursor-pointer"
          )}
        >
          {!showImage && !groupStyle && !motivationalStyle && (
            <EmptyLogo name={userName || ""} onClick={onImageUpload} />
          )}

          {showImage && !motivationalStyle && (
            <Logo onClick={onImageUpload} img={img} />
          )}
        </div>
      )}
      {motivationalStyle && (
        <div className=" font-bold cursor-pointer " onClick={onImageUpload}>
          <p>{title}</p>
        </div>
      )}
      {showImage && !readOnly && (
        <Label
          onClick={onRemove}
          name="Delete"
          className="mt-4 text-center cursor-pointer hover:text-alert hover:underline "
        />
      )}
      {showImage && !readOnly && (
        <Label
          onClick={() => {
            setShowEditor((val) => !val);
          }}
          name="Edit"
          className="mt-4 text-center cursor-pointer hover:text-alert hover:underline "
        />
      )}
      <div className="relative">
        {error && (
          <div
            className={classnames(
              classNames?.error,
              " text-alert text-[0.875rem] text-center "
            )}
          >
            <p>{error}</p>
          </div>
        )}
      </div>
      {showEditor && (
        <AvatarEditorModal
          userName={userName}
          fieldName={fieldName}
          setFormikValue={setValue}
          setShow={setShowEditor}
          src={img || ""}
        />
      )}
    </div>
  );
}

export default FormikImageUploaderContent;
