import { memo } from "react";
import ReactDOM from "react-dom";

import { Card } from "components";

import classnames from "classnames";

import { XIcon } from "@heroicons/react/outline";
import React from "react";

interface ICard {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
  contentChildren: React.ReactNode;
  headerChildren: React.ReactNode;
  footerChildren: React.ReactNode;
}

function RegularModal(
  {
    className,
    setShow,
    contentChildren,
    headerChildren,
    footerChildren,
  }: ICard,
  ref
) {
  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classnames(
        className,
        "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
      )}
    >
      <Card
        shouldHover={false}
        className="flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40"
        header={
          <div className="flex justify-between items-center pb-1">
            <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
              <span className="font-bold leading-6 text-lg line-clamp-2">
                {headerChildren}
              </span>
            </div>
            <XIcon
              onClick={() => {
                setShow(false);
              }}
              className="h-6 w-6 shrink-0 cursor-pointer"
              aria-hidden="true"
            />
          </div>
        }
        classNames={{
          header: "px-4 sm:px-9 py-2 flex-1 sm:max-w-[616px]",
          content: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
          footer:
            "px-3 sm:px-9 h-[60px] py-2 flex-1 !justify-center sm:max-w-[616px]",
        }}
        content={
          <div className="flex flex-col items-center py-12 text-lg">
            {contentChildren}
          </div>
        }
        footer={<>{footerChildren}</>}
      />
    </div>,
    document.body
  );
}

const RegularModalWithRef = React.forwardRef(RegularModal);

export default memo(RegularModalWithRef);
