import { FormikProps, Form } from "formik";
import classnames from "classnames";
import React, { memo } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

import {
  Button,
  FormikCheckbox,
  FormikImageUploader,
  FormikInput,
  FormikTextarea,
  ImageUploaderContent,
  Modal,
} from "components";

import { FIELD_NAMES, ProfileFormInitial } from "./ProfileForm.constants";

import {
  FormikMultiSelectWithBadgesSkills,
  FormikMultiSelectWithBadgesCategories,
} from "./ProfileFormSkillsField.component";

import { usePreventTransition } from "./ProfileForm.hooks";
import FormikAutoCompleteSelectField from "components/FormikAutoCompleteSelectField";
import { FormikSocialLinksArray } from "./ProfileFormMediaLinksField.component";
import ProfileIssues from "./ProfileTabs/ProfileIssues";
import ProfileSolutions from "./ProfileTabs/ProfileSolutions";
import ProfileActionGroups from "./ProfileTabs/ProfileActionGroups";
import ProfileMotivationals from "./ProfileTabs/ProfileMotivationals";
import { ReputationScore } from "components/ReputationScore";

interface AuthenticationPanelProps
  extends FormikProps<ProfileFormInitial>,
    React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  readOnly?: boolean;
  reputationScore: number | undefined;
  tabSelected:
    | "Profile"
    | "Issues"
    | "Solutions"
    | "ActionGroups"
    | "Motivationals";
}

function ProfileForm({
  tabSelected,
  isSubmitting,
  readOnly = undefined,
  reputationScore,
  ...props
}: AuthenticationPanelProps) {
  const { leave, close, showModal } = usePreventTransition(
    !readOnly && tabSelected === "Profile"
  );
  return (
    <div
      className={classnames("flex-1 flex flex-col pt-6", {
        "!pt-12": readOnly && props.values.isPrivate,
      })}
    >
      {tabSelected === "Profile" && (
        <Form className={classnames("")}>
          <div className="flex flex-col sm:flex-row  md:gap-24 items-center sm:items-start px-7 sm:px-0  gap-14">
            <div className=" mb-12 sm:mb-0 gap-2 flex flex-col relative z-10">
              <FormikImageUploader
                readOnly={readOnly}
                className={classnames("max-w-[106px] image-uploader ", {
                  "pointer-events-none select-none": readOnly,
                })}
                name={FIELD_NAMES.AVATAR}
                userName={`${props.values.firstName} ${props.values.lastName}`}
                Content={ImageUploaderContent}
                classNames={{
                  label: "flex justify-center mt-[10px] font-semibold",
                }}
              />
              <ReputationScore
                score={props?.values?.reputationScore}
                classNames={{ icon: " w-10 max-h-10 " }}
                place="right"
                className="  absolute right-[-50px] top-[40px] "
              />
            </div>

            <div
              className={classnames(
                "flex flex-col gap-5  max-w-[500px] col-span-4 col-start-3 mb-12 z-10",
                {
                  " maxXs:!pl-[15%] maxSm:pl-[30%]": readOnly,
                },
                {
                  "w-full": !readOnly,
                }
              )}
            >
              {readOnly && (
                <div className="flex flex-col gap-5">
                  <FormikInput
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="First name"
                    name={FIELD_NAMES.FIRST_NAME}
                    placeholder="First name"
                  />
                  <FormikInput
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="Last name"
                    name={FIELD_NAMES.LAST_NAME}
                    placeholder="Last name"
                  />
                </div>
              )}
              {!readOnly && (
                <>
                  <FormikCheckbox
                    className="!bg-white checked:!bg-white hover:!bg-white  "
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="Enable privacy settings (we already keep your email address private, but this will also hide your social media links and location)"
                    name={FIELD_NAMES.IS_PRIVATE}
                    classNames={{
                      mainContainer: "flex flex-row-reverse gap-4",
                    }}
                  />
                </>
              )}
              {!readOnly && (
                <>
                  <FormikInput
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="First name"
                    name={FIELD_NAMES.FIRST_NAME}
                    placeholder="First name"
                  />
                  <FormikInput
                    readOnly={readOnly}
                    disabled={readOnly}
                    label="Last name"
                    name={FIELD_NAMES.LAST_NAME}
                    placeholder="Last name"
                  />
                </>
              )}
              <FormikTextarea
                readOnly={readOnly}
                disabled={readOnly}
                label="About"
                name={FIELD_NAMES.ABOUT}
                placeholder="Introduce yourself"
              />
              {!readOnly && (
                <FormikSocialLinksArray
                  formValues={props.values.mediaLinks}
                  readOnly={readOnly}
                />
              )}

              {readOnly &&
                !props.values.isPrivate &&
                props.values.mediaLinks.length && (
                  <FormikSocialLinksArray
                    formValues={props.values.mediaLinks}
                    readOnly={readOnly}
                  />
                )}
              {!readOnly && (
                <FormikInput
                  autoComplete="nope"
                  label="Email address"
                  name={FIELD_NAMES.EMAIL}
                  placeholder="Email Address"
                  disabled
                />
              )}
              {!readOnly && (
                <FormikAutoCompleteSelectField
                  readOnly={readOnly}
                  name={FIELD_NAMES.PRIMARY_LOCATION}
                  label="Location"
                  className={"mt-6"}
                />
              )}
              {readOnly && !props.values.isPrivate && (
                <FormikAutoCompleteSelectField
                  readOnly={readOnly}
                  name={FIELD_NAMES.PRIMARY_LOCATION}
                  label="Location"
                  className={"mt-0"}
                />
              )}

              <FormikMultiSelectWithBadgesSkills readOnly={readOnly} />
              <FormikMultiSelectWithBadgesCategories readOnly={readOnly} />
              {!readOnly && (
                <div className={readOnly ? "" : "mt-8"}>
                  <Button
                    className="w-full  justify-center"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save changes
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!readOnly && (
            <Modal isOpen={showModal} close={close}>
              <div className="flex flex-col">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Save changes
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to leave without changes?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={leave}
                  >
                    Leave
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={close}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </Form>
      )}
      {tabSelected === "Issues" && (
        <div className="py-8 px-2">
          <ProfileIssues profileOwner={!readOnly} />
        </div>
      )}
      {tabSelected === "Solutions" && (
        <div className="py-8 px-2">
          <ProfileSolutions profileOwner={!readOnly} />
        </div>
      )}
      {tabSelected === "ActionGroups" && (
        <div className="py-8 px-2">
          <ProfileActionGroups profileOwner={!readOnly} />
        </div>
      )}
      {tabSelected === "Motivationals" && (
        <div className="py-8 px-2">
          <ProfileMotivationals profileOwner={!readOnly} />
        </div>
      )}
    </div>
  );
}

export default memo(ProfileForm);
