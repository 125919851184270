/* This example requires Tailwind CSS v2.0+ */
import { memo, useEffect, useState } from "react";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";
import {
  useElementScroll,
  useIssueRatingCategories,
  useQueryOptionsSetter,
  useScrollOnDrag,
} from "hooks";
import { ScoreTooltip } from "components/ScoreTooltip/ScoreTooltip";

interface IQueryOptions {
  placeId?: string;
  category?: string[] | null;
  user?: string | null;
  lat?: string | null;
  skills?: string[] | null;
  lng?: string | null;
  zoom?: string | null;
}

interface IProps {}

function ToggleButtonCategoriesGroup(props: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const mainValues = {
    category: searchParams.getAll("category") || "",
  };

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { scrollable, scrollableToLeft, scrollableToRight } =
    useElementScroll(element);

  useScrollOnDrag(element);

  useEffect(() => {
    if (scrollable) {
      element?.classList.toggle("is-left-overflowing", scrollableToLeft);
      element?.classList.toggle("is-right-overflowing", scrollableToRight);
    }
  }, [scrollable, element, scrollableToLeft, scrollableToRight]);
  const { data: categories } = useIssueRatingCategories();
  return (
    <div
      ref={(el) => {
        setElement(el);
      }}
      className=" one-third-step three-third-step five-third-step ten-third-step dragging-group flex flex-row gap-x-5 gap-y-1 overflow-auto pb-[16px] pt-2 hide-scroll cursor-pointer fade "
    >
      {categories?.data.map((category, index) => {
        return (
          <div className="flex flex-col">
            <div
              data-tooltip-id={"tooltip-toggle" + category.id}
              key={category.category}
              onClick={() => {
                if (!mainValues.category.includes(category.id as string)) {
                  setQueryOptions((values: IQueryOptions) => {
                    const val: IQueryOptions = {
                      ...values,
                      category:
                        [...mainValues.category, category.id as string] || "",
                    };
                    return val;
                  });
                }

                if (mainValues.category.includes(category.id as string)) {
                  setQueryOptions((values: IQueryOptions) => {
                    const val: IQueryOptions = {
                      ...values,
                      category: [
                        ...mainValues.category.filter(
                          (item) => item !== category.id
                        ),
                      ],
                    };
                    return val;
                  });
                }
              }}
              className={classnames(
                " rounded-md p-1 px-1.5 text-sm cursor-pointer select-none shadow-md whitespace-nowrap transform transition ease-in-out duration-200",
                {
                  "bg-black  hover:bg-gray-800  text-white  ":
                    mainValues.category.includes(category.id as string),
                },
                {
                  "bg-white  hover:bg-gray-100  text-black  ":
                    !mainValues.category.includes(category.id as string),
                }
              )}
            >
              {category.title}
            </div>
            <ScoreTooltip
              content={category.description}
              id={"tooltip-toggle" + category.id}
            />
          </div>
        );
      })}
    </div>
  );
}

export default memo(ToggleButtonCategoriesGroup);
