/* eslint-disable no-useless-escape */

import * as yup from "yup";

import { FIELD_NAMES } from "./ForgetPasswordForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.EMAIL]: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Your email is invalid."
      )
      .email()
      .required("Email is required!"),
  });
}
