import { memo } from "react";
import ReactDOM from "react-dom";
import { Button } from "components";
import { APP_ROUTES } from "const";
import { Card } from "components";

import classnames from "classnames";

import { useLocation, useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import React from "react";

interface ICard {
  setShow: (show: boolean)=>void;
  className?: string | undefined;
  actionCallback?: any;
}

function WarningModal({ className, setShow, actionCallback }: ICard, ref) {
  const navigate = useNavigate();
  const location = useLocation();

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classnames(
        className,
        "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
      )}
    >
      <Card
        shouldHover={false}
        className="flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40"
        header={
          <div className="flex justify-between items-center pb-1">
            <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
              <span className="font-bold leading-6 text-lg line-clamp-2">
                Warning
              </span>
            </div>
            <XIcon
              onClick={() => {
                setShow(false);
              }}
              className="h-6 w-6 shrink-0 cursor-pointer"
              aria-hidden="true"
            />
          </div>
        }
        classNames={{
          header: "px-4 sm:px-9 py-2 flex-1 sm:max-w-[616px]",
          content: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
          footer:
            "px-3 sm:px-9 h-[60px] py-2 flex-1 !justify-center sm:max-w-[616px]",
        }}
        content={
          <div className="flex flex-col items-center py-12 text-lg">
            {location.pathname === APP_ROUTES.SOLUTION_LIST && (
              <p>
                To propose a solution, you have to select the relevant issue
              </p>
            )}
            {location.pathname === APP_ROUTES.ACTION_GROUP_LIST && (
              <p>
                To create an action group, you have to select the relevant
                solution
              </p>
            )}
            {location.pathname.includes(APP_ROUTES.ACTION_GROUP) &&
              location.pathname !== APP_ROUTES.ACTION_GROUP_LIST && (
                <p>Are you sure you want to decline this user?</p>
              )}
          </div>
        }
        footer={
          <>
            {location.pathname === APP_ROUTES.SOLUTION_LIST && (
              <>
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className=" sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    navigate(APP_ROUTES.ISSUES);
                  }}
                >
                  Select issue
                </Button>
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    navigate(APP_ROUTES.ISSUES);
                  }}
                >
                  Select issue
                </Button>
              </>
            )}
            {location.pathname === APP_ROUTES.ACTION_GROUP_LIST && (
              <>
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className=" sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    navigate(APP_ROUTES.SOLUTION_LIST);
                  }}
                >
                  Select solution
                </Button>
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    navigate(APP_ROUTES.SOLUTION_LIST);
                  }}
                >
                  Select solution
                </Button>
              </>
            )}
            {location.pathname.includes(APP_ROUTES.ACTION_GROUP) &&
              location.pathname !== APP_ROUTES.ACTION_GROUP_LIST && (
                <div className="flex flex-row gap-2 justify-between w-full">
                  <Button
                    inGroupStyle={false}
                    isStyleless
                    isReverse
                    className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    inGroupStyle={false}
                    isStyleless
                    isReverse
                    className=" text-[black] border hover:border-black border-gray-500 "
                    onClick={actionCallback}
                  >
                    Decline user
                  </Button>
                </div>
              )}
          </>
        }
      />
    </div>,
    document.body
  );
}

const WarningdModalWithRef = React.forwardRef(WarningModal);

export default memo(WarningdModalWithRef);
