import React from "react";
import classnames from "classnames";
import "./checkboxElement.styles.css";

export interface CheckboxElementProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isError?: boolean;
}

function CheckboxElement({
  className,

  isError,
  ...rest
}: CheckboxElementProps) {
  return (
    <input
      className={classnames(className, "")}
      {...rest}
      checked={rest.value as boolean | undefined}
      type={"checkbox"}
    />
  );
}

export default CheckboxElement;
