import classnames from "classnames";
import { useMemo, useState } from "react";
import _ from "lodash";

import {
  FormikFieldArray,
  Badge,
  FormikAdvanceSelect,
  SearchableSelectType,
} from "components";

import { FIELD_NAMES } from "./GroupForm.constants";
import { ISelectOption } from "types";
import { useDebouncedHandler, useSkills } from "hooks";
import { OptionProps, components } from "react-select";
import FormikInput from "components/FormikInput";
import { IFormGroupSkill } from "./GroupForm.types";

interface ISkillsSelect extends ISelectOption {
  description: string;
}

function Option(props: OptionProps<ISkillsSelect>) {
  return (
    <components.Option {...props}>
      <div
        className={classnames(
          "flex flex-col  text-sm leading-6 flex-1 pr-3 pl-8 border-transparent border-l-2 border-solid pt-2 pb-2",
          {
            " !border-l-link-water hover-list-item-background":
              props.isFocused && !props.isSelected,
            " !border-l-black select-list-item-background": props.isSelected,
          }
        )}
      >
        <span className="  font-semibold">{props.data.label}</span>
        <span className="font-normal mt-1 line-clamp-2 text-gray-500">
          {props.data.description}
        </span>
      </div>
    </components.Option>
  );
}

interface IMultiSelectProps {
  name: string;
  formValues: any;
  className?: string;
}

export function FormikMultiSelectWithTime({
  className,
  formValues,
}: IMultiSelectProps) {
  const [inputValue, setInputValue] = useState("");

  const { data, isLoading } = useSkills({ search: inputValue });
  const preparedCurrentValues = useMemo(() => {
    return _.compact(
      formValues.map((skillValue) => {
        return skillValue?.skill?.value;
      })
    );
  }, [formValues]);
  const preparedData = data?.data.filter(
    (skill) => !preparedCurrentValues.includes(skill.id)
  );

  const options = useMemo(() => {
    return _.compact(
      preparedData?.map((item) => ({
        label: item.name,
        value: item.id || "",
        description: item.description,
      }))
    );
  }, [preparedData]);
  const onInputChange = useDebouncedHandler((value: string) => {
    setInputValue(value);
  });

  return (
    <div className={classnames("flex flex-col", className)}>
      <div className="flex gap-4 flex-col flex-wrap mt-8 w-full">
        <p className="text-black">Skills required</p>
        <FormikFieldArray<IFormGroupSkill> name={FIELD_NAMES.SKILLS}>
          {({ remove, push, index, ...rest }) => {
            //text-indigo-400  hover:text-indigo-500  focus:bg-indigo-500
            return (
              <>
                <div className="flex flex-col flex-1 sm:flex-row items-start justify-between gap-4 w-full">
                  <FormikAdvanceSelect<SearchableSelectType>
                    label={"Name"}
                    placeholder="Search skills..."
                    filterOption={() => true} //disable to filter options
                    name={`skills.${index}.skill`}
                    options={options}
                    isSearchable
                    isLoading={isLoading}
                    makeSearch={onInputChange}
                    styles={{
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        padding: "0px",
                      }),
                      container: (provided) => ({
                        ...provided,
                        marginTop: "8px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: "0.375rem",
                        fontSize: "14px",
                        paddingTop: "2px",
                        fontWeight: 300,
                        paddingBottom: "2px",
                        borderColor: "transparent",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: "transparent",
                        },
                      }),
                    }}
                    className=" multiselect min-w-full sm:min-w-[50%]  !pb-0   justify-between h-full"
                    //@ts-ignore
                    components={{ Option }}
                    classNames={{ message: " text-gray-500  hidden sm:inline" }}
                    closeMenuOnSelect
                    message={
                      formValues.length === index + 1
                        ? "You can add up to 7 skills"
                        : undefined
                    }
                  />
                  <div className="flex flex-row flex-wrap flex-1 w-full">
                    <FormikInput
                      className="appearance-none block w-full px-3 py-2.5  placeholder-gray-400  text-sm  rounded-[6px] border-0"
                      autoComplete="nope"
                      classNames={{
                        message: "sm:hidden inline",
                        inputContainer: " w-full flex-1",
                        mainContainer: "pt-1",
                      }}
                      label="Time (hours per week)"
                      name={`skills.${index}.timeRequired`}
                      placeholder="Time"
                      message={
                        formValues.length === index + 1
                          ? "You can add up to 7 skills"
                          : undefined
                      }
                    />
                    {formValues.length > 1 && (
                      <Badge
                        onRemove={() => {
                          remove(index);
                        }}
                        className={
                          formValues.length === index + 1
                            ? "mt-2"
                            : "!items-end"
                        }
                        classNames={{
                          button: "text-red-500  focus:bg-red-500 mb-2.5" || "",
                        }}
                      >
                        {}
                      </Badge>
                    )}
                  </div>
                </div>
                {formValues.length === index + 1 && formValues.length < 7 && (
                  <div className="w-full flex">
                    <button
                      type="button"
                      className="pb-4 inline-flex content-center items-center"
                      onClick={() => push({ skill: { value: "", label: "" } })}
                    >
                      <span className="inline-flex text-black text-md">
                        Add skill
                      </span>
                    </button>
                  </div>
                )}
              </>
            );
          }}
        </FormikFieldArray>
      </div>
    </div>
  );
}
