import { memo } from "react";
import { MotivationalForm } from "components";

function MotivationalAddEdit() {
  return (
    <div className="  pb-4 max-w-[2000px]">
      <div className="w-full   flex flex-col justify-center items-center pb-10">
        <MotivationalForm className=" w-full " onSuccess={() => {}} />
      </div>
    </div>
  );
}

export default memo(MotivationalAddEdit);
