import classnames from "classnames";
import {
  AnalyticsComponent,
  BorderBadge,
  Button,
  GroupCardModal,
  ListMap,
  WarningModal,
} from "components";
import { memo, useCallback, useMemo, useState } from "react";
import { ReactComponent as SquareLeft } from "assets/images/square-left.svg";
import { ReactComponent as SquareRight } from "assets/images/square-right.svg";

import { useOutletContext } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import { IOutletAuth } from "types";

import { GroupCard } from "./GroupCard";

import { useGroupListFilterParams } from "./GroupList.hooks";
import { useWindowDimensions } from "hooks";
import JumboImage1 from "assets/images/jumbo-1.png";

import { getFirstLocationInActionGroupEntity } from "utils";

function GroupList() {
  const authContext = useOutletContext<IOutletAuth>();

  const [open, setOpen] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );
  const [openCardLocations, setOpenCardLocations] = useState<
    (string | null | undefined)[] | undefined
  >([]);

  const { width, height, ref: mapRef } = useResizeDetector();

  const [isIssueHovered, setIsIssueHovered] = useState(false);

  const [hoveredIssueId, setHoveredIssueId] = useState<
    string | undefined | string[]
  >(undefined);
  const [hoveredIssueType, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();

  const dim = useWindowDimensions();

  const disabled = useMemo(() => {
    return !!(open && dim.width && dim.width <= 1023);
  }, [dim.width, open]);

  const [issueHoveredProps, setIssueHoveredProps] = useState({
    lat: NaN,
    lng: NaN,
  });

  const { data, inViewRef } = useGroupListFilterParams(
    width || dim.width,
    disabled
  );

  const [openWarningModal, setOpenWarningModal] = useState(false);

  return (
    <div className={classnames("flex flex-col   relative h-full  ")}>
      <div
        className={classnames(
          "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
        )}
      >
        <div
          className={classnames(
            "eight-fourth-step nine-fourth-step grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear  ",
            {
              "sm:grid-cols-2 lg:grid-cols-3 lg:col-span-3": !open,
              "hidden lg:grid max-w-[500px]": open,
            }
          )}
        >
          <div
            className={classnames(
              " relative    flex-1 max-h-[300px]  w-full  overflow-hidden",
              {
                " flex col-span-1 sm:col-span-2  lg:col-span-3": !open,
              },
              {
                hidden: open,
              }
            )}
          >
            <div className=" flex justify-center flex-1 w-full h-full">
              <img
                style={{ flexBasis: "100%" }}
                className="select-none pointer-events-none flex-1 min-h-[180px] w-full h-full object-cover sm:object-contain"
                alt="jumbo"
                src={authContext.backgroundImages || JumboImage1}
              />
              <Button
                isReverse
                className=" !absolute h-[42px] font-semibold   bottom-[10%]   z-50 justify-center flex  shrink-0"
                onClick={() => {
                  setOpenWarningModal(true);
                }}
              >
                Create action group
              </Button>
            </div>
          </div>
          {data?.pages.map((page, pagesIndex) => {
            return page.data.items.length < 1 ? (
              <div
                className="flex   justify-center col-span-6"
                key={pagesIndex}
              >
                <p className=" max-w-[700px] text-center text-xl">
                  There are no action groups found for the chosen location,
                  please adjust your search parameters or
                  <span
                    onClick={() => {
                      setOpenWarningModal(true);
                    }}
                    className="font-bold px-1 hover:text-white cursor-pointer"
                  >
                    create a group
                  </span>
                </p>
              </div>
            ) : (
              //TODO - CHANGE ON BACK
              page.data.items.map((actionGroup, issueIndex) => {
                const firstLocation =
                  getFirstLocationInActionGroupEntity(actionGroup);

                const geoLocation = {
                  lat: firstLocation?.lat,
                  lng: firstLocation?.lng,
                };
                const isLast =
                  data?.pages.length === pagesIndex + 1 &&
                  page.data.items.length === issueIndex + 1;

                const groupId = actionGroup.id!;

                const isFormattedAddress = !!firstLocation?.formattedAddress;
                const isCountry = !!firstLocation?.country;
                const isCity = !!firstLocation?.city;
                const isStreetNumber = !!firstLocation?.streetNumber;
                const isRoute = !!firstLocation?.route;
                const location =
                  typeof firstLocation === "object"
                    ? isFormattedAddress
                      ? `${firstLocation?.formattedAddress}`
                      : isCountry && isCity && isStreetNumber && isRoute
                      ? `${firstLocation.route} ${firstLocation?.streetNumber}, ${firstLocation?.city}, ${firstLocation?.country}`
                      : isCountry && isCity
                      ? ` ${firstLocation?.city}, ${firstLocation?.country}`
                      : isCountry
                      ? `${firstLocation?.country}`
                      : "No location"
                    : "No location";

                const allLocations = actionGroup?.solutions?.map((solution) => {
                  return solution?.issue?.location?.formattedAddress;
                });

                const rate = actionGroup?.rates && actionGroup?.rates[0];

                return (
                  <div
                    className={classnames(
                      "relative  maxmd:flex cursor-pointer",
                      {
                        " first-card-to-click ": issueIndex === 0,
                      }
                    )}
                    key={actionGroup?.id}
                    onClick={() => {
                      setOpenedCardId(actionGroup?.id);
                      setOpenCardModal(true);
                      setOpenCardLocations(allLocations);
                    }}
                  >
                    {actionGroup.analyticsData && (
                      <AnalyticsComponent
                        analyticsData={actionGroup.analyticsData}
                      />
                    )}
                    <GroupCard
                      setOpenCardModal={setOpenCardModal}
                      groupId={groupId}
                      forceHoverIssue={hoveredIssueId?.includes(
                        actionGroup.id || ""
                      )}
                      membersNumber={String(actionGroup.membersCount)}
                      skills={actionGroup.skills}
                      setIsIssueHovered={setIsIssueHovered}
                      setIssueHoveredProps={setIssueHoveredProps}
                      setHoveredIssueType={setHoveredIssueType}
                      geoLocation={geoLocation}
                      innerRef={isLast ? inViewRef : undefined}
                      title={actionGroup?.title || ""}
                      profileImage={actionGroup?.user?.avatar}
                      groupImage={actionGroup.logo}
                      createdAt={actionGroup?.createdAt}
                      likes={actionGroup?.likes || 0}
                      dislikes={actionGroup?.dislikes || 0}
                      location={location}
                      tags={actionGroup?.tags}
                      allLocations={allLocations}
                      isSoftDeleted={!!actionGroup?.deletedAt}
                      rate={rate}
                    />
                  </div>
                );
              })
            );
          })}
        </div>
        <div
          className={classnames("relative lg:rounded-lg lg:overflow-hidden ", {
            "hidden lg:flex lg:flex-col row-start-1 lg:col-start-4": !open,
            "lg:col-start-2 lg:col-end-[-1] ": open,
          })}
        >
          <ListMap
            setOpenMap={setOpen}
            hoveredIssueType={hoveredIssueType}
            setHoveredIssueId={setHoveredIssueId}
            issueCardHovered={isIssueHovered}
            issueCardProps={issueHoveredProps}
            innerRef={mapRef}
            mapHeight={height || dim.height}
            mapWidth={width || dim.width}
          />

          <div
            className="flex flex-col absolute top-7 left-5 cursor-pointer"
            onClick={useCallback(() => setOpen((open) => !open), [])}
          >
            {!open && <SquareLeft />}
            {open && <SquareRight />}
          </div>
        </div>
      </div>
      <BorderBadge
        className="bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 fixed bottom-14 right-[50%] translate-x-1/2 cursor-pointer block lg:hidden z-10"
        onClick={() => setOpen((open) => !open)}
        classNames={{
          text: "!text-sm",
          contend: "px-4 py-2 ",
        }}
      >
        {open ? "Show groups" : "Show map"}
      </BorderBadge>
      {openCardModal && openedCardId && (
        <GroupCardModal
          id={openedCardId}
          setShow={setOpenCardModal}
          mapWidth={width || dim.width}
          allLocations={openCardLocations}
        />
      )}
      {openWarningModal && <WarningModal setShow={setOpenWarningModal} />}
    </div>
  );
}

export default memo(GroupList);
