import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ReactComponent as Icon } from "assets/images/toaster-icon.svg";
export function showSuccessMessage(
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  toast.success(message, {
    position: "bottom-right",
    theme: "light",
    hideProgressBar: true,
    icon: <Icon />,
    ...options,
  });
}
export function showErrorMessage(
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  toast.error(message, {
    position: "bottom-right",
    hideProgressBar: true,
    theme: "light",
    ...options,
  });
}
