import { APP_ROUTES, ENDPOINTS } from "const";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { ImageType, IMotivationalRatings, IOutletAuth } from "types";
import { Card, PromptModal, Button } from "components";

import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import { ReactComponent as Video } from "assets/images/video.svg";
import { ReactComponent as Article } from "assets/images/article.svg";

import classnames from "classnames";
import {
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import { isNil } from "lodash";
import {
  useClickOnLikeDislikeOnMotivational,
  useUpdateMotivationalRating,
} from "hooks";
import { useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";

interface IProps {
  title: string;
  id: string | undefined;
  videoLink: string;
  thumbnailLink: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  likes: number;
  dislikes: number;
  rate?: IMotivationalRatings | undefined;
  index?: number;
  image: string | ImageType | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  detailsMode?: boolean;
  views: number;
}

function MotivationalCard({
  id,
  title = "",
  videoLink = "",
  thumbnailLink = "",
  image = "",
  firstName = "",
  lastName = "",
  createdAt = "",
  views,
  index = NaN,
  detailsMode = false,
  likes,
  dislikes,
  rate,
  innerRef,
}: IProps) {
  const timeDifference = Math.abs(
    differenceInMinutes(new Date(createdAt), new Date())
  );
  const timeDifferenceInWeeks = Math.abs(
    differenceInWeeks(new Date(createdAt), new Date())
  );
  const timeDifferenceInMonths = Math.abs(
    differenceInMonths(new Date(createdAt), new Date())
  );
  const timeDifferenceInYears = Math.abs(
    differenceInYears(new Date(createdAt), new Date())
  );
  const notificationTime =
    timeDifferenceInYears < 1
      ? timeDifferenceInWeeks < 1
        ? timeDifference < 60
          ? `${timeDifference} minutes ago`
          : timeDifference > 59 && timeDifference < 1440
          ? `${Math.ceil(timeDifference / 60)} hours ago`
          : `${Math.ceil(timeDifference / 1440)} days ago`
        : timeDifferenceInWeeks < 4
        ? `${timeDifferenceInWeeks} weeks ago`
        : `${timeDifferenceInMonths} months ago`
      : `${timeDifferenceInYears} years ago`;
  const [, setIsHovered] = useState(false);
  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL]);
    queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL_RATINGS]);
  };
  const isLikesDislikes = useMemo(() => {
    return !isNil(likes) && !isNil(dislikes);
  }, [likes, dislikes]);

  const {
    mutateAsync: mutateUpdateMotivationalAsync,
    isLoading: updateLoading,
  } = useUpdateMotivationalRating({
    // it used to create if not exist and update if exist
    motivationalRatingId: rate?.id,
  });
  const isLoading = updateLoading;
  const [rates, setRates] = useState({
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });
  useEffect(() => {
    if (likes) {
      setRates((val) => ({ ...val, likes: likes }));
    }
    if (dislikes) {
      setRates((val) => ({ ...val, dislikes: dislikes }));
    }
    if (rate?.rate === 1) {
      setRates((val) => ({ ...val, liked: true, disliked: false }));
    }
    if (rate?.rate === 0) {
      setRates((val) => ({ ...val, liked: false, disliked: true }));
    }
  }, [likes, dislikes, rate]);

  const like = useClickOnLikeDislikeOnMotivational(
    id,
    rate?.rate,
    mutateUpdateMotivationalAsync,
    invalidate
  );
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      <Card
        showContent={false}
        defaultStyle={false}
        className="maxmd:flex-1 !gap-1.5 !pt-3 !pb-2 h-full !border-none hover:!shadow-none"
        showHeaderDivider={false}
        showSubHeaderDivider={false}
        showFooterDivider={false}
        header={
          <Fragment>
            <span
              className={classnames(
                "relative flex items-start justify-start text-black font-semibold   w-full",
                {
                  " max-h-[180px]": detailsMode,
                  " max-h-[123px]": !detailsMode,
                }
              )}
              ref={innerRef}
            >
              <img
                alt="thumb"
                className={classnames("  w-full object-cover", {
                  " max-h-[180px]": detailsMode,
                  " max-h-[123px]": !detailsMode,
                })}
                src={thumbnailLink || (image as string)}
              />
              {videoLink ? (
                <Video
                  strokeWidth={0.35}
                  stroke="black"
                  fill="white"
                  className="absolute left-2 top-2 "
                />
              ) : (
                <Article
                  strokeWidth={0.35}
                  stroke="black"
                  fill="white"
                  className="absolute left-2 top-2 "
                />
              )}
            </span>
          </Fragment>
        }
        subHeader={
          <Fragment>
            <div
              className={classnames(
                " min-w-0 grid grid-flow-col grid-cols-[4fr_1fr] lg:grid-cols-[1fr_95px] items-center gap-2  "
              )}
            >
              <span
                className={classnames(
                  "text-sm font-semibold  text-black line-clamp-2 "
                )}
              >
                {title}
              </span>

              <span className="  rounded text-sm font-normal  inline  text-center  ">
                <div className="flex flex-col gap-1">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className={classnames(
                      "flex flex-row maxXs:flex-wrap gap-2 items-center ",
                      { " sixteen-third-step ": index === 0 }
                    )}
                  >
                    {isLikesDislikes && (
                      <>
                        <div className="flex flex-row gap-1 items-center">
                          <span
                            onClick={() => {
                              if (authContext.isAuth) {
                                if (!isLoading) {
                                  if (rates.liked) {
                                    setRates((val) => ({
                                      ...val,
                                      liked: false,
                                      likes: val.likes - 1,
                                    }));
                                  } else {
                                    setRates((val) => ({
                                      ...val,
                                      liked: true,
                                      likes: val.likes + 1,
                                      dislikes:
                                        val.dislikes > 0
                                          ? val.dislikes - 1
                                          : val.dislikes,
                                      disliked: false,
                                    }));
                                  }
                                  like(true);
                                }
                              } else {
                                setShowGoSignInModal(true);
                              }
                            }}
                            className={classnames(
                              "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm  p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                              {
                                " bg-[#EFEFEF]": rates.liked === false,
                                " bg-[#606060]": rates.liked === true,
                              }
                            )}
                          >
                            <Like
                              stroke={
                                rates.liked === true ? "white" : "#606060"
                              }
                            />
                          </span>
                          <span className="text-center text-[#606060] text-xs ">
                            {rates.likes}
                          </span>
                        </div>

                        <div className="flex flex-row gap-1 items-center ">
                          <span
                            onClick={() => {
                              if (authContext.isAuth) {
                                if (!isLoading) {
                                  if (rates.disliked) {
                                    setRates((val) => ({
                                      ...val,
                                      disliked: false,
                                      dislikes: val.dislikes - 1,
                                    }));
                                  } else {
                                    setRates((val) => ({
                                      ...val,
                                      liked: false,
                                      likes:
                                        val.likes > 0
                                          ? val.likes - 1
                                          : val.likes,
                                      dislikes: val.dislikes + 1,
                                      disliked: true,
                                    }));
                                  }
                                  like(false); //dislike
                                }
                              } else {
                                setShowGoSignInModal(true);
                              }
                            }}
                            className={classnames(
                              "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm   p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                              {
                                " bg-[#EFEFEF]": rates.disliked === false,
                                " bg-[#606060]": rates.disliked === true,
                              }
                            )}
                          >
                            <Dislike
                              stroke={
                                rates.disliked === true ? "white" : "#606060"
                              }
                            />
                          </span>
                          <span className="text-center text-[#606060] text-xs ">
                            {rates.dislikes}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </span>
            </div>
          </Fragment>
        }
        classNames={{
          header: classnames(
            "w-full  min-h-[52px] !justify-center  !gap-0 !pt-1",
            {
              " max-h-[180px]": detailsMode,
              " max-h-[123px]": !detailsMode,
            }
          ),
          subHeader:
            "  max-w-[520px]  !justify-start !gap-0 min-h-[28px] !pt-1",
          content: " max-w-[520px] h-full flex-1",
          footer: "max-w-[520px] pb-2 !shrink ",
        }}
        content={
          // <span className="line-clamp-3 leading-6 h-[75px]">{details}</span>
          <></>
        }
        footer={
          <>
            <div
              className={classnames(
                "flex items-center justify-start gap-1 flex-1 w-full"
              )}
            >
              <div
                className={classnames(
                  " min-w-0 flex flex-col gap-1 justify-between   flex-1"
                )}
              >
                <div className="flex flex-row  gap-2 text-sm text-gray-400   font-light leading-6">
                  <span className="flex">{firstName || "N/A"}</span>
                  <span className="flex">{lastName || "N/A"}</span>
                </div>
                <div className="flex flex-col xs:flex-row flex-wrap justify-start items-start xs:items-center shrink gap-2  ">
                  <div>
                    <span className="text-sm text-gray-400   font-light leading-6 line-clamp-1">
                      {views} views
                    </span>
                  </div>
                  <span
                    className={classnames(
                      "rounded-full h-1 w-1 bg-[#666666]  "
                    )}
                  />

                  <span className="text-sm self-start  text-gray-400   font-light leading-6 line-clamp-1">
                    {notificationTime}
                  </span>
                </div>
              </div>
            </div>
          </>
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(MotivationalCard);
