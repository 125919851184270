import { memo, useCallback, useEffect, useState } from "react";
import { FullSolutionType, ISolutionRating } from "types";
import { APP_ROUTES, ENDPOINTS } from "const";
import {
  ProfileBlock,
  Card,
  BorderBadgeRound,
  StarRatingItem,
  Loader,
  CustomToolTip,
  PromptModal,
  BorderBadge,
} from "components";
import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";
import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import {
  useMe,
  useRestoreSolution,
  useSoftDeleteSolution,
  useSolutionRatings,
} from "hooks";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { showErrorMessage, showSuccessMessage } from "utils";
import { AxiosResponse } from "axios";
import { createSolutionRating, updateSolutionRating } from "services";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { useNavigate } from "react-router-dom";
import { AttachmentList } from "modules/Attachments/AttachmentList/AttachmentList";

interface IProps {
  solution: FullSolutionType | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  onEdit: () => void;
  allowEdit: boolean;
  isSoftDeleted?: boolean;
}

function SolutionItem({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {content}
      </span>
    </>
  );
}

interface ISolutionProps
  extends Partial<
    UseMutationOptions<AxiosResponse<ISolutionRating>, any, any>
  > {
  solutionId?: string | undefined;
}

export function useCreateSolutionRating({ ...rest }: ISolutionProps) {
  return useMutation<AxiosResponse<ISolutionRating>, any, any>(
    (solution: ISolutionRating) => createSolutionRating({ solution }),
    {
      ...rest,
    }
  );
}

export function useUpdateSolutionRating({
  solutionRatingId = "",
  ...rest
}: ISolutionProps & {
  solutionRatingId: string | undefined;
}) {
  return useMutation<AxiosResponse<ISolutionRating>, any, any>(
    (solution: ISolutionRating) =>
      updateSolutionRating({ solutionRatingId, solution }),
    {
      ...rest,
    }
  );
}

function SolutionsCard({ onEdit, allowEdit, solution }: IProps) {
  const queryClient = useQueryClient();
  const { data: meData } = useMe();

  const { data: solutionRatingData } = useSolutionRatings({
    solutionId: solution?.id,
    userId: meData?.data.id,
    enabled: !!meData?.data.id,
  });

  const date =
    solution?.createdAt &&
    new Date(solution?.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

  const { data: dataMe } = useMe();
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  const [showRateSolutionPopup, setShowRateSolutionPopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [ratingValue, setRatingValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      solutionRatingData &&
      solutionRatingData?.data.items.length > 0 &&
      solutionRatingData?.data.items[0].rate
    ) {
      setRatingValue(solutionRatingData?.data.items[0].rate);
    }
  }, [solutionRatingData]);

  const {
    mutateAsync: mutateCreateIssueRatingAsync,
    isLoading: loadingCreate,
  } = useCreateSolutionRating({
    solutionId: solution?.id,
  });
  const {
    mutateAsync: mutateUpdateIssueRatingAsync,
    isLoading: loadingUpdate,
  } = useUpdateSolutionRating({
    solutionRatingId:
      solutionRatingData && solutionRatingData?.data.items.length > 0
        ? solutionRatingData?.data.items[0].id
        : undefined,
  });

  const createRate = useCallback(
    (ratingValue: number) => {
      mutateCreateIssueRatingAsync({
        solution: solution?.id,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
          showSuccessMessage("Solution rating has been created!");
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
        });
    },
    [solution, queryClient, mutateCreateIssueRatingAsync]
  );

  const updateRate = useCallback(
    (ratingValue: number) => {
      mutateUpdateIssueRatingAsync({
        solution: solution?.id,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);

          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
          showSuccessMessage("Solution rating has been updated!");
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
        });
    },
    [solution, queryClient, mutateUpdateIssueRatingAsync]
  );
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();
  const isSoftDeleted = !!solution?.deletedAt;

  // Soft delete and restore
  const { mutateAsync: softDeleteIssue } = useSoftDeleteSolution();
  const { refetch: restoreIssue } = useRestoreSolution({ id: solution?.id });

  const handleDeleteRestore = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (isSoftDeleted) {
          await restoreIssue();
        } else {
          await softDeleteIssue(solution?.id);
        }
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS, solution?.id]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [isSoftDeleted, solution?.id, queryClient, restoreIssue, softDeleteIssue]
  );

  return (
    <>
      <Card
        className={classnames("bg-white", {
          "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
        })}
        shouldHover={false}
        collapse={descriptionCollapsed}
        header={
          <div
            // onTouchStart={() => {
            //   setDescriptionCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1"
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
              <img
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": descriptionCollapsed,
                })}
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">Solutions</span>
            </div>

            {allowEdit && (
              <div className="flex gap-2">
                <Button
                  type="button"
                  onTouchStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit();
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                  }}
                  onClick={onEdit}
                  disabled={!allowEdit}
                >
                  Edit
                </Button>
                <Button
                  onTouchStart={handleDeleteRestore}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                  }}
                  onClick={handleDeleteRestore}
                  disabled={!allowEdit}
                  className="!pointer-events-auto"
                >
                  {isSoftDeleted ? "Restore" : "Delete"}
                </Button>
              </div>
            )}
          </div>
        }
        classNames={{
          header: "px-6 sm:px-16 ",
          content: "px-6 sm:px-16 flex-1 ",
          footer: "px-6 sm:px-16 h-[60px] flex-row gap-2",
        }}
        content={
          <div className="flex flex-col w-full gap-4">
            <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
              {solution && (
                <>
                  <SolutionItem
                    title="Details"
                    content={solution.description}
                  />
                  <SolutionItem
                    title="Effectiveness"
                    content={solution.effectiveness}
                  />
                  <SolutionItem
                    title="Skills Required"
                    content={
                      <div className="flex maxLg:flex-col maxLg:w-max flex-wrap h-full min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4  ">
                        {solution?.primaryActionGroup?.skills?.map((skill) => {
                          return (
                            <BorderBadgeRound
                              key={skill.id}
                              className="flex"
                              withNotification
                              notificationContent={`${
                                skill.timeRequired || 0
                              } ${
                                Number(skill.timeRequired) === 1
                                  ? "hour"
                                  : "hours"
                              } per week`}
                              classificationName={skill?.skill?.name}
                            >
                              {skill?.skill?.name}
                            </BorderBadgeRound>
                          );
                        })}
                        {((solution?.primaryActionGroup?.skills &&
                          solution?.primaryActionGroup?.skills.length < 1) ||
                          !solution?.primaryActionGroup?.skills) && (
                          <div>No skills assigned</div>
                        )}
                      </div>
                    }
                  />
                  {solution?.tags && solution?.tags?.length > 0 && (
                    <SolutionItem
                      title="Labels"
                      content={
                        <div className="flex items-center flex-wrap gap-2 py-1">
                          {solution?.tags?.map((tag) => (
                            <BorderBadge
                              key={tag}
                              classificationName={tag}
                              styleLess
                              classNames={{
                                text: "text-gray-500 text-xs underline",
                                contend: "flex",
                              }}
                            >
                              {tag}
                            </BorderBadge>
                          ))}
                        </div>
                      }
                    />
                  )}
                </>
              )}
            </div>

            <AttachmentList
              attachments={solution?.attachments}
              readOnly
              classNames={{
                attachmentsContainer: "grid-cols-8 lg:grid-cols-12 ",
              }}
              directory="solutions"
            />
          </div>
        }
        footer={
          <>
            <ProfileBlock
              userId={solution?.user.id}
              firstName={solution?.user.firstName}
              lastName={solution?.user.lastName}
              classNames={{
                name: "!justify-start",
              }}
              isModalVariant
              createdAt={date}
              profileImage={solution?.user.avatar}
              email={solution?.user.email}
              score={solution?.user.reputationScore}
            />
            <div
              onMouseOver={() => {
                setShowTooltip(true);
              }}
              onMouseOut={() => {
                setShowTooltip(false);
              }}
              className="flex flex-row min-w-[36px]seven-sixth-step"
            >
              <div className="relative flex flex-row items-center gap-1">
                <p className="350:right-[-10px] right-[120%] 350:bottom-[120%] bottom-0">
                  Rating:{" "}
                </p>
                {!!solution?.impactRanking ? (
                  <span
                    onClick={() => {
                      if (!!dataMe?.data.id) {
                        setShowRateSolutionPopup((val) => !val);
                      } else {
                        setShowGoSignInModal(true);
                      }
                    }}
                    className={classnames(
                      "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                      {
                        " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                          !!dataMe?.data.id,
                        " outline outline-[1px] outline-[#8159D7]":
                          showRateSolutionPopup,
                        " cursor-default ": !dataMe?.data.id,
                      }
                    )}
                  >
                    {solution?.impactRanking}
                    <RatingStar height={8} width={8} fill="black" />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      if (!!dataMe?.data.id) {
                        setShowRateSolutionPopup((val) => !val);
                      } else {
                        setShowGoSignInModal(true);
                      }
                    }}
                    className={classnames(
                      "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                      {
                        " cursor-pointer ": !!dataMe?.data.id,
                        " cursor-default ": !dataMe?.data.id,
                      }
                    )}
                  >
                    {0}
                    <RatingStar height={8} width={8} fill="black" />
                  </span>
                )}
                {!ratingValue && (
                  <CustomToolTip
                    className="!bg-white !border-black !border !text-black !min-w-[110px] !bottom-[140%]"
                    position="top"
                    showTooltip={showTooltip}
                    tooltipText={"Click to rate!"}
                  />
                )}
                {showRateSolutionPopup && (
                  <div className="absolute  text-black gradient-outline-forced-roundless text-center    z-10 left-[-470%] xx:left-[-360%] lg:left-[-330%] top-[-600%] text-sm font-normal after:tooltipbottomarrow">
                    <div className="flex flex-row relative  bg-white p-2">
                      {loadingCreate || loadingUpdate ? (
                        <div className="  w-[175px] h-[84px]">
                          <Loader
                            type="rings"
                            globalLoader={false}
                            size="tiny"
                          />
                        </div>
                      ) : (
                        <div className=" py-5 px-1">
                          <StarRatingItem
                            starSize="small"
                            classNames={{
                              label: "font-bold !text-xs",
                              input: "!gap-2 pt-2",
                            }}
                            title="How effective is this solution?"
                            ratingValue={ratingValue || 0}
                            onClick={(ratingValue) => {
                              if (
                                ratingValue &&
                                solutionRatingData &&
                                solutionRatingData?.data.items.length === 0
                              ) {
                                createRate(ratingValue);
                              }
                              if (
                                ratingValue &&
                                solutionRatingData &&
                                solutionRatingData?.data.items.length > 0
                              ) {
                                updateRate(ratingValue);
                              }
                            }}
                            setRatingValue={setRatingValue}
                          />
                        </div>
                      )}
                      <XIcon
                        onClick={() => {
                          setShowRateSolutionPopup(false);
                        }}
                        className="h-4 w-4 opacity-50  shrink-0 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                    <span className="absolute border-[8px] right-[9%] xx:right-[25%] lg:right-[31%] border-b-transparent border-t-[#8159D7] border-r-transparent border-l-transparent tooltipbottomarrow" />
                  </div>
                )}
              </div>
            </div>
          </>
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(SolutionsCard);
