import { memo } from "react";

interface IProps {
  children: React.ReactNode;
  type: "h1" | "h2";
}

function Heading({ children, type }: IProps) {
  const h1 = (
    <h1 className=" font-semibold text-[30px] leading-[36px] text-gray-900">
      {children}
    </h1>
  );
  if (type === "h1") {
    return h1;
  }

  return h1;
}

export default memo(Heading);
