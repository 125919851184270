/* This example requires Tailwind CSS v2.0+ */
import { memo, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AvatarPlaceholder from "assets/images/avatar.png";

import { APP_ROUTES } from "const";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as WeLogo } from "assets/images/weatt-wide-logo-v1.svg";
import { ReactComponent as NotificationIcon } from "assets/images/notification.svg";
import { ReactComponent as Facebook } from "assets/images/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/twitter.svg";

import { Button } from "components";
import { getAuth } from "firebase/auth";

import { IUser } from "types";
import { logout } from "utils";

import { Step } from "react-joyride";

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

interface IMenu {
  isAuth?: boolean;
  setHandoutsNoGroupsFound?:
    | React.Dispatch<React.SetStateAction<"NoGroups" | "Groups" | "NoSearch">>
    | undefined;
  open?: boolean;
  navigation: { current: boolean; href: string; name: string }[];
  profile?: IUser;
  isFetching: boolean;
  close?: () => void;
  isScrolled: boolean;
}

function MobileMenu({ isAuth, open, navigation, profile, close }: IMenu) {
  const location = useLocation();
  useEffect(() => {
    close && close();
  }, [close, location]);
  const auth = getAuth();
  return (
    <Disclosure.Panel className="sm:hidden fixed h-full w-full top-0 bg-white flex flex-col justify-between pb-8">
      <div>
        <div className="mx-auto">
          <div className="flex w-full justify-between  pt-3 pb-3 items-center px-[5%]">
            <div className=" shrink-0">
              <WeLogo className="h-[54px] w-[54px] cursor-pointer" />
            </div>
            <div className=" shrink-0 -mr-2">
              <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-700 ">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>
        </div>
        <div className="w-full border-b border-solid mt-3 " />
        <div className="py-4  leading-5 font-semibold ">
          {navigation.map((item, key) => {
            const showBTop =
              key && !item.current && !navigation[key - 1].current;
            return (
              <Link
                to={item.href}
                aria-current={item.current ? "page" : undefined}
                key={item.name}
                onClick={() => {
                  if (item.name === "Sign out") {
                    auth.signOut().then(() => {
                      logout();
                    });
                  }
                }}
                className={classnames(
                  "block pl-5 pr-4  border-l-2 text-base font-medium py-4 border-t border-l-transparent ",
                  {
                    "border-gray-300": showBTop,
                  },
                  {
                    "border-transparent": showBTop,
                  },
                  {
                    "from-[#f7f7ff] via-[#f0f7ff] to-[#e8f6ff] bg-gradient-to-r  border-t-transparent !border-l-black":
                      item.current,
                    "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900":
                      !item.current,
                  }
                )}
              >
                {item.name}
              </Link>
            );
          })}
          <a
            href={"https://www.weattitude.org/"}
            target="_blank"
            rel="noreferrer"
            key={"https://www.weattitude.org/"}
            className={classnames(
              "block pl-5 pr-4  border-l-2 text-base  py-4 border-t border-l-transparent text-blue-500 font-bold"
            )}
          >
            Homepage
          </a>
          {isAuth && (
            <div className="pt-4 pb-3 ">
              <div className="flex items-center px-6 gap-3">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to={APP_ROUTES.PROFILE}>
                      <div
                        className="h-[60px] w-[60px] rounded-full font-bold text-lg flex items-center justify-center from-[#aab5fb] to-[#d3b6ff] bg-gradient-to-b shrink-0 cursor-pointer focus:from-zambezi focus:to-zambezi p-[1px] relative overflow-hidden"
                        tabIndex={1}
                      >
                        {!profile?.avatar && (
                          <div className="bg-white w-full h-full  shrink-0  rounded-full flex items-center justify-center">
                            <img src={AvatarPlaceholder} alt="avatar" />
                          </div>
                        )}
                        {profile?.avatar && (
                          <img
                            className="rounded-full absolute "
                            src={profile?.avatar}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                </div>
                <Link to={APP_ROUTES.PROFILE}>
                  {
                    <span className=" text-sm font-semibold flex gap-1">
                      {!!profile?.firstName && (
                        <span>{profile?.firstName}</span>
                      )}
                      {!!profile?.lastName && <span>{profile?.lastName}</span>}
                    </span>
                  }
                  {!profile?.firstName && !profile?.lastName && (
                    <span className=" text-sm font-semibold">
                      {profile?.email}
                    </span>
                  )}
                </Link>

                <button
                  type="button"
                  className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none "
                >
                  <span className="sr-only">View notifications</span>

                  <NotificationIcon
                    onClick={() => {}}
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isAuth && (
        <div className="flex flex-col flex-1 px-4 items-center justify-center">
          <Button
            onClick={() => {
              auth.signOut();
              logout();
            }}
            className="w-full justify-center"
          >
            Sign out
          </Button>
        </div>
      )}
      {!isAuth && (
        <div className="flex px-4 flex-col flex-1 justify-center gap-6">
          <Link to={APP_ROUTES.SIGN_UP}>
            <Button className="w-full justify-center">Sign up</Button>
          </Link>
          <div className="flex gap-2 text-sm justify-center">
            <span className=" font-light">Existing customer?</span>
            <Link
              className=" font-semibold"
              state={{ from: location.pathname }}
              to={APP_ROUTES.SIGN_IN}
            >
              Sign in
            </Link>
          </div>
        </div>
      )}
      <div className="flex flex-col text-zambezi gap-3 pl-5">
        <span>Stay in touch</span>
        <div className="flex gap-5 items-center  i">
          <Facebook className="fill-black" />
          <Instagram className="fill-black" />
          <Twitter className="fill-black" />
        </div>
      </div>
    </Disclosure.Panel>
  );
}
export default memo(MobileMenu);
