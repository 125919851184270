import { memo, useEffect, useMemo, useState, useCallback } from "react";
import { FullActionGroupType, IOutletAuth, ISolutionRating } from "types";
import { APP_ROUTES, ENDPOINTS } from "const";
import {
  ProfileBlock,
  Card,
  BorderBadgeRound,
  PromptModal,
  BorderBadge,
} from "components";
import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";

import classnames from "classnames";
import {
  useClickOnLikeDislikeActionGroup,
  useMe,
  useQueryOptionsSetter,
  useRestoreActionGroup,
  useSoftDeleteActionGroup,
  useSolutionRatings,
  useUpdateGroupRating,
} from "hooks";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { AxiosResponse } from "axios";
import { createSolutionRating, updateSolutionRating } from "services";

import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { isNil } from "lodash";
import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import AvatarPlaceholder from "assets/images/avatar.png";
import { AttachmentList } from "modules/Attachments/AttachmentList/AttachmentList";

interface IProps {
  actionGroup: FullActionGroupType | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  onEdit: () => void;
}

function Item({
  title,
  content,
  isLink = false,
}: {
  title: string;
  content: React.ReactNode;
  isLink?: boolean;
}) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {isLink ? (
          <a href={"https://" + content} target="_blank" rel="noreferrer">
            {content}
          </a>
        ) : (
          content
        )}
      </span>
    </>
  );
}

interface ISolutionProps
  extends Partial<
    UseMutationOptions<AxiosResponse<ISolutionRating>, any, any>
  > {
  solutionId?: string | undefined;
}

export function useCreateSolutionRating({ ...rest }: ISolutionProps) {
  return useMutation<AxiosResponse<ISolutionRating>, any, any>(
    (solution: ISolutionRating) => createSolutionRating({ solution }),
    {
      ...rest,
    }
  );
}

export function useUpdateSolutionRating({
  solutionRatingId = "",
  ...rest
}: ISolutionProps & {
  solutionRatingId: string | undefined;
}) {
  return useMutation<AxiosResponse<ISolutionRating>, any, any>(
    (solution: ISolutionRating) =>
      updateSolutionRating({ solutionRatingId, solution }),
    {
      ...rest,
    }
  );
}

function ActionGroupCard({ onEdit, actionGroup }: IProps) {
  const queryClient = useQueryClient();
  const { data: meData } = useMe();

  const { data: solutionRatingData } = useSolutionRatings({
    solutionId: actionGroup?.id,
    userId: meData?.data.id,
    enabled: !!meData?.data.id,
  });

  const date =
    actionGroup?.createdAt &&
    new Date(actionGroup?.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

  const isLikesDislikes =
    !isNil(actionGroup?.likes) && !isNil(actionGroup?.dislikes);

  const invalidate = () => {
    queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
  };
  const rate = actionGroup?.rates && actionGroup?.rates[0];

  const { mutateAsync: mutateUpdateSolutionAsync, isLoading: updateLoading } =
    useUpdateGroupRating(
      useMemo(() => {
        return {
          actionGroupRatingId: rate?.id,
        };
      }, [rate?.id])
    );
  const isLoadingGroupRating = updateLoading;

  const [rates, setRates] = useState({
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });

  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  const [, setRatingValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      solutionRatingData &&
      solutionRatingData?.data.items.length > 0 &&
      solutionRatingData?.data.items[0].rate
    ) {
      setRatingValue(solutionRatingData?.data.items[0].rate);
    }
  }, [solutionRatingData]);

  const isAllowToEdit =
    !!meData &&
    actionGroup?.actionGroupUsers?.find(
      (aUser) =>
        aUser.user?.id === meData.data.id &&
        (aUser.role === "admin" || aUser.role === "creator")
    );
  const [searchParams, setSearchParams] = useSearchParams();
  useQueryOptionsSetter(searchParams, setSearchParams);

  useEffect(() => {
    if (actionGroup?.likes) {
      setRates((val) => ({ ...val, likes: actionGroup?.likes || 0 }));
    }
    if (actionGroup?.dislikes) {
      setRates((val) => ({ ...val, dislikes: actionGroup?.dislikes || 0 }));
    }
    if (rate?.rate === 1) {
      setRates((val) => ({ ...val, liked: true, disliked: false }));
    }
    if (rate?.rate === 0) {
      setRates((val) => ({ ...val, liked: false, disliked: true }));
    }
  }, [actionGroup, rate]);
  const like = useClickOnLikeDislikeActionGroup(
    actionGroup?.id,
    rate?.rate,
    mutateUpdateSolutionAsync,
    invalidate
  );
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();

  // Soft delete and restore
  const { mutateAsync: softDeleteIssue } = useSoftDeleteActionGroup();
  const { refetch: restoreIssue } = useRestoreActionGroup({
    id: actionGroup?.id,
  });

  const isSoftDeleted = !!actionGroup?.deletedAt;

  const handleDeleteRestore = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (isSoftDeleted) {
          await restoreIssue();
        } else {
          await softDeleteIssue(actionGroup?.id);
        }
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS, actionGroup?.id]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [isSoftDeleted, actionGroup?.id, queryClient, restoreIssue, softDeleteIssue]
  );

  return (
    <>
      <Card
        className={classnames("bg-white", {
          "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
        })}
        shouldHover={false}
        collapse={descriptionCollapsed}
        header={
          <div
            // onTouchStart={() => {
            //   setDescriptionCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1 gap-2"
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px] shrink-0">
              <img
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": descriptionCollapsed,
                })}
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">Description</span>
            </div>
            {isAllowToEdit && (
              <div className="flex gap-2">
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="text-[black] border hover:border-black border-gray-500  maxmxs:!px-1 maxmxs:!py-1 !pointer-events-auto"
                  type="button"
                  onTouchStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit();
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                  }}
                  onClick={onEdit}
                >
                  Edit group
                </Button>
                <Button
                  onTouchStart={handleDeleteRestore}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                  }}
                  onClick={handleDeleteRestore}
                  disabled={!isAllowToEdit}
                  className="!pointer-events-auto"
                >
                  {isSoftDeleted ? "Restore" : "Delete"}
                </Button>
              </div>
            )}
          </div>
        }
        classNames={{
          header: "px-6 sm:px-16 ",
          content: "px-6 sm:px-16 flex-1 ",
          footer: "px-6 sm:px-16 h-[60px]",
        }}
        content={
          <div className="flex flex-col w-full gap-4 ">
            <div className="flex flex-col sm:flex-row justify-between items-center flex-1">
              <div className="grid order-2 sm:order-1 grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
                <Item
                  title="Website"
                  content={actionGroup?.contactUrl}
                  isLink
                />
                <Item title="Goals" content={actionGroup?.description} />
                <Item title="Schedule" content={actionGroup?.schedule} />
                <Item title="Contact" content={actionGroup?.contact} />
                <Item
                  title="Skills Required"
                  content={
                    <>
                      {actionGroup?.skills && actionGroup?.skills.length < 1 ? (
                        <div>No skills assigned</div>
                      ) : (
                        <div className="flex items-start flex-wrap  gap-4 pt-1">
                          {actionGroup?.skills?.map((skill) => {
                            return (
                              <BorderBadgeRound
                                key={skill.id}
                                className="flex"
                                withNotification
                                notificationContent={`${
                                  skill.timeRequired || 0
                                } ${
                                  Number(skill.timeRequired) === 1
                                    ? "hour"
                                    : "hours"
                                } per week`}
                                classificationName={skill?.skill?.name}
                              >
                                {skill?.skill?.name}
                              </BorderBadgeRound>
                            );
                          })}
                        </div>
                      )}
                    </>
                  }
                />
                {actionGroup?.tags && actionGroup?.tags?.length > 0 && (
                  <Item
                    title="Labels"
                    content={
                      <div className="flex items-center flex-wrap gap-2 py-1">
                        {actionGroup?.tags?.map((tag) => (
                          <BorderBadge
                            key={tag}
                            classificationName={tag}
                            styleLess
                            classNames={{
                              text: "text-gray-500 text-xs underline",
                              contend: "flex",
                            }}
                          >
                            {tag}
                          </BorderBadge>
                        ))}
                      </div>
                    }
                  />
                )}
              </div>
              <div className="flex order-1 sm:order-2 flex-col items-center justify-center  h-[100px] w-[100px] rounded-full shrink-0">
                <img
                  alt="groupimage"
                  className="h-full w-full rounded-full border"
                  src={actionGroup?.logo || AvatarPlaceholder}
                />
              </div>
            </div>
            <AttachmentList
              attachments={actionGroup?.attachments}
              readOnly
              classNames={{
                attachmentsContainer: "grid-cols-8 lg:grid-cols-12 ",
              }}
              directory="actionGroups"
            />
          </div>
        }
        footer={
          <>
            <ProfileBlock
              userId={actionGroup?.user?.id}
              firstName={actionGroup?.user?.firstName}
              lastName={actionGroup?.user?.lastName}
              classNames={{
                name: "!justify-start",
              }}
              isModalVariant
              createdAt={date}
              profileImage={actionGroup?.user?.avatar}
              email={actionGroup?.user?.email}
              score={actionGroup?.user?.reputationScore}
            />
            <div className="flex flex-row gap-2 items-center">
              {isLikesDislikes && (
                <>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className="flex flex-col gap-0.5"
                  >
                    <span
                      onClick={() => {
                        if (authContext.isAuth) {
                          if (!isLoadingGroupRating) {
                            if (rates.liked) {
                              setRates((val) => ({
                                ...val,
                                liked: false,
                                likes: val.likes - 1,
                              }));
                            } else {
                              setRates((val) => ({
                                ...val,
                                liked: true,
                                likes: val.likes + 1,
                                dislikes:
                                  val.dislikes > 0
                                    ? val.dislikes - 1
                                    : val.dislikes,
                                disliked: false,
                              }));
                            }
                            like(true);
                          }
                        } else {
                          setShowGoSignInModal(true);
                        }
                      }}
                      className={classnames(
                        "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm  p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                        {
                          " bg-[#EFEFEF]": rates.liked === false,
                          " bg-[#606060]": rates.liked === true,
                        }
                      )}
                    >
                      <Like
                        stroke={rates.liked === true ? "white" : "#606060"}
                      />
                    </span>
                    <span className="text-center text-[#606060] text-xs max-h-[5px]">
                      {rates.likes}
                    </span>
                  </div>

                  <div className="flex flex-col gap-0.5">
                    <span
                      onClick={() => {
                        if (authContext.isAuth) {
                          if (!isLoadingGroupRating) {
                            if (rates.disliked) {
                              setRates((val) => ({
                                ...val,
                                disliked: false,
                                dislikes: val.dislikes - 1,
                              }));
                            } else {
                              setRates((val) => ({
                                ...val,
                                liked: false,
                                likes:
                                  val.likes > 0 ? val.likes - 1 : val.likes,
                                dislikes: val.dislikes + 1,
                                disliked: true,
                              }));
                            }
                            like(false);
                          }
                        } else {
                          setShowGoSignInModal(true);
                        }
                      }}
                      className={classnames(
                        "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm   p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                        {
                          " bg-[#EFEFEF]": rates.disliked === false,
                          " bg-[#606060]": rates.disliked === true,
                        }
                      )}
                    >
                      <Dislike
                        stroke={rates.disliked === true ? "white" : "#606060"}
                      />
                    </span>
                    <span className="text-center text-[#606060] text-xs max-h-[5px]">
                      {rates.dislikes}
                    </span>
                  </div>
                </>
              )}
            </div>
          </>
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(ActionGroupCard);
