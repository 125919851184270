import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import React from "react";

type CbType<T> = (
  props: FieldArrayRenderProps & { index: number; value: T }
) => React.ReactNode;
interface IProps<T> {
  name: string;
  children: CbType<T>;
  bottomRenderProps?: (props: FieldArrayRenderProps) => React.ReactNode;
}

export function FormikFieldArray<T>({
  name,
  bottomRenderProps,
  children,
}: IProps<T>) {
  const [{ value }] = useField<T[]>(name);
  return (
    <FieldArray name={name}>
      {(props) => {
        return (
          <>
            {value.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {children({ ...props, index, value })}
                </React.Fragment>
              );
            })}
            {bottomRenderProps && bottomRenderProps(props)}
          </>
        );
      }}
    </FieldArray>
  );
}
