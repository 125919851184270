import { Formik } from "formik";
import { memo } from "react";

import validationSchema from "./SignUpForm.schema";
import { useInitialState } from "./SignUpForm.hooks";
import SignUpForm from "./SignUpForm.component";
import { useSubmitHandler } from "./SignUpForm.hooks";

function SignUp() {
  const initialState = useInitialState();

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler()}
    >
      <SignUpForm />
    </Formik>
  );
}

export default memo(SignUp);
