import { useField } from "formik";
import classnames from "classnames";
import { ITextareaProps, Textarea, Label } from "components";

interface IProps extends Omit<ITextareaProps, "classNames"> {
  classNames?: {
    inputContainer?: string;
    input?: string;
    error?: string;
    icon?: string;
    label?: string;
  };
  className?: string;
  withErrorMessage?: boolean;

  showOnlyCustomMessage?: boolean;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  name: string;
  label?: string;
  icon?: string;

  message?: React.ReactNode;
}

function TextareaField({
  classNames,
  name,
  className,
  label,
  withErrorMessage = true,
  showOnlyCustomMessage,

  icon,
  message,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const errorMessage = form.touched && form.error;
  const showError = withErrorMessage && !!errorMessage;

  return (
    <div className={classnames(className, " flex flex-col  relative gap-1")}>
      {label && (
        <Label className={rest.readOnly ? "font-bold " : ""} name={label} />
      )}
      <div
        className={classnames(classNames?.inputContainer, {
          "mb-5": !showError && !message && !rest.readOnly,
        })}
      >
        {icon && (
          <div className={classnames(classNames?.icon, " ")}>
            <img src={icon} alt="input-icon" />
          </div>
        )}
        <Textarea
          className={classnames(
            classNames?.input,
            { "!px-0": rest.readOnly },
            "placeholder-gray-400 text-sm font-light"
          )}
          {...field}
          {...rest}
          isError={showError}
          classNames={{ wrapper: "mt-1" }}
        />
      </div>
      {!showOnlyCustomMessage && (showError || message) && (
        <div
          className={classnames(classNames?.error, "  font-light text-sm", {
            "text-red-400": showError,
          })}
        >
          {errorMessage || message}
        </div>
      )}
      {showOnlyCustomMessage && message && (
        <div className={classnames(classNames?.error, "  font-light text-sm")}>
          {message}
        </div>
      )}
    </div>
  );
}

export default TextareaField;
