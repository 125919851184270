/* eslint-disable no-useless-escape */

import {
  checkFileDimension,
  checkFileSize,
  checkFileType,
  mapTestRules,
} from "utils/validation";
import * as yup from "yup";

import { FIELD_NAMES } from "./ProfileForm.constants";
const MAX_FILE_SIZE = 5000000; // file size in kilobyte
const MAX_DIMENSION = 1024;
export const ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".PNG",
  ".JPG",
  ".gif",
  "image/png",
];

const schema = mapTestRules([
  {
    cb: checkFileDimension(MAX_DIMENSION),
    id: "fileDimension",
    message: `Error: That’s a very nice photo, but it’s a bit too big. Click Edit to adjust the size or upload another photo smaller than ${MAX_DIMENSION} px`,
  },
  {
    cb: checkFileSize(MAX_FILE_SIZE),
    id: "fileSize",
    message:
      "Error: That’s a very nice photo, but it’s a bit too big. Click Edit to adjust the size or upload another photo smaller than 5 MB",
  },
  {
    cb: checkFileType(ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES),
    id: "fileType",
    message: "Profile photo should be GIF, JPG or PNG",
  },
]);

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 50;
const MAX_TEXTAREA_LENGTH = 300;

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.AVATAR]: schema,
    [FIELD_NAMES.EMAIL]: yup
      .string()
      .email()
      .required("Email is required!")
      .nullable(),
    [FIELD_NAMES.FIRST_NAME]: yup
      .string()
      .min(MIN_NAME_LENGTH, `min first name length is ${MIN_NAME_LENGTH}`)
      .max(MAX_NAME_LENGTH, `max first name length is ${MAX_NAME_LENGTH}`)
      .required("First name is required!")
      .nullable(),
    [FIELD_NAMES.PRIMARY_LOCATION]: yup.mixed(),
    [FIELD_NAMES.IS_PRIVATE]: yup.boolean(),
    [FIELD_NAMES.LAST_NAME]: yup
      .string()
      .min(MIN_NAME_LENGTH, `min last name length is ${MIN_NAME_LENGTH}`)
      .max(MAX_NAME_LENGTH, `max last name length is ${MAX_NAME_LENGTH}`)
      .required("Last name is required!")
      .nullable(),
    [FIELD_NAMES.ABOUT]: yup
      .string()

      .max(MAX_TEXTAREA_LENGTH, `max field length is ${MAX_TEXTAREA_LENGTH}`)

      .nullable(),

    [FIELD_NAMES.SKILLS]: yup
      .array()
      .min(1, "Please add at least one skill")
      .max(5, "You can add up to 5 skills")
      .required("Skills are required!")
      .nullable(),
    [FIELD_NAMES.CATEGORIES]: yup
      .array()
      .min(1, "Please add at least one category")
      .max(3, "You can add up to 3 categories")
      .required("Categories are required!")
      .nullable(),
    [FIELD_NAMES.MEDIA_LINKS]: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter correct url!"
          )
          .max(MAX_NAME_LENGTH, `max media link length is ${120}`)
          .nullable()
      )
      .max(4, "Please add to up 4 links")
      .nullable(),
  });
}
