/* This example requires Tailwind CSS v2.0+ */
import { SetStateAction, useState } from "react";
import { XIcon } from "@heroicons/react/outline";

import classnames from "classnames";
import { components, OptionProps } from "react-select";
import { AdvancedSelectField } from "components";
import { ISelectOption } from "types";
import { Step } from "react-joyride";
import { twMerge } from "tailwind-merge";

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

function Option(props: OptionProps<any>) {
  return (
    <div
      className={classnames("mt-1 ", {
        "selected-option-gradient": props.isSelected,
        " hovered-selected-option-gradient px-6": !props.isSelected,
      })}
    >
      <components.Option {...props}>
        <label
          className={classnames(" line-clamp-1 text-sm cursor-pointer py-3.5")}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  );
}

export function SkillsSelectField({
  currentSelectedSkills,
  skillOptions,
  onSkillsChange,
  setSkillSearchParam,
  className,
  mainClassName,
}: {
  currentSelectedSkills: ISelectOption[];
  skillOptions: ISelectOption[] | undefined;
  onSkillsChange: (value: any) => void;
  setSkillSearchParam: (value: SetStateAction<string>) => void;
  className?: string;
  mainClassName?: string;
}) {
  const [focus, setFocus] = useState(false);
  const placeholder = "Skills...";
  return (
    <div
      className={classnames(
        "flex w-full relative items-center group",
        mainClassName
      )}
    >
      {!focus && !!currentSelectedSkills?.length && (
        <span className="absolute z-10 pl-3 group-focus:hidden text-sm text-[#a5abb6]">
          {placeholder}
        </span>
      )}
      <AdvancedSelectField
        isMulti
        readOnly
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        isClearable={false}
        square
        placeholder={placeholder}
        className={twMerge(
          classnames(
            "pb-0 md:w-[150px] md:max-w-[160px] shrink-0 flex-[2_2_0%] md:flex-auto ",
            className
          )
        )}
        closeMenuOnSelect
        options={skillOptions}
        value={currentSelectedSkills}
        onInputChange={(value) => {
          setSkillSearchParam(value);
        }}
        onChange={onSkillsChange}
        styles={{
          indicatorsContainer: () => ({
            display: "flex",
            alignItems: "center",
          }),
          option: () => ({}),
          control: (provided) => ({
            ...provided,
            borderRadius: 0,
            border: "none",
          }),
          container: (provided) => ({
            ...provided,
            borderRadius: 0,
            border: "none",
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 30,
            position: "absolute",
            width: "280px",
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: "0px",
            paddingBottom: "0px",
          }),
        }}
        components={{
          Option,
          DropdownIndicator: () => null,
          ClearIndicator: (props) => {
            return (
              <XIcon
                className="h-4 w-4 cursor-pointer m-1 z-30"
                aria-hidden="true"
                onClick={props.clearValue}
                onTouchStart={props.clearValue}
              />
            );
          },
        }}
      />
    </div>
  );
}
