import { useMutation, UseMutationOptions } from "react-query";
import { AxiosResponse } from "axios";
import { createActivities } from "services";
import { IActivity, IUser } from "types";

interface ICreateActivitiesProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IActivity<IUser>>, any, any>
  > {}

export function useCreateActivities({ ...rest }: ICreateActivitiesProps = {}) {
  return useMutation<AxiosResponse<IActivity<IUser>>, any, Partial<IActivity>>(
    (args) => createActivities(args),
    {
      ...rest,
    }
  );
}
