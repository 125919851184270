/* This example requires Tailwind CSS v2.0+ */
import { memo, useCallback, useRef } from "react";

import classnames from "classnames";
import { IProductTour } from "types";

import { useOnClickOutside } from "hooks";

import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { Step } from "react-joyride";

interface IProps {
  fullProductTour: IProductTour[];
  setOpenNotificationSlideover?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setScenarioSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  handleClickStart: (event: React.MouseEvent<HTMLElement>) => void;
  setOpenProductTourPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentScenario: React.Dispatch<React.SetStateAction<number>>;
  mobileVersion?: boolean;
  className?: string;
}

interface IItemProps {
  navigate: NavigateFunction;
  scenarioSteps: Step[];
  orderNumber: number;
  title: string;
  startLocationPathname: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNotificationSlideover?: React.Dispatch<React.SetStateAction<boolean>>;
  setScenarioSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  handleClickStart: (event: React.MouseEvent<HTMLElement>) => void;
  setOpenProductTourPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentScenario: React.Dispatch<React.SetStateAction<number>>;

  className?: string;
}

function ProductTourItem({
  navigate,
  title,
  setIsOpen,
  setOpenNotificationSlideover,
  setOpenProductTourPopup,
  scenarioSteps,
  orderNumber,
  startLocationPathname,
  setCurrentScenario,
  setScenarioSteps,
  handleClickStart,
}: IItemProps) {
  const location = useLocation();
  return (
    <span
      onClick={(e) => {
        if (!location.pathname.includes(startLocationPathname)) {
          navigate(startLocationPathname);
        }
        setIsOpen(false);
        setCurrentScenario(orderNumber);
        setScenarioSteps(scenarioSteps);
        setOpenProductTourPopup(false);
        setOpenNotificationSlideover && setOpenNotificationSlideover(false);
        handleClickStart(e);
      }}
      className="cursor-pointer py-2 px-4 hover:bg-gray-100 border-b border-gray-100 text-sm rounded-tr-md rounded-tl-md "
    >
      {title}
    </span>
  );
}

function ProductTourComponent({
  setIsOpen,
  fullProductTour,
  mobileVersion = false,
  setOpenProductTourPopup,
  setCurrentScenario,
  setScenarioSteps,
  setOpenNotificationSlideover,
  handleClickStart,
}: IProps) {
  const navigate = useNavigate();
  const mainContainer = useRef<HTMLDivElement | null>(null);

  useOnClickOutside({
    ref: mainContainer,
    handler: useCallback(() => {
      setOpenProductTourPopup(false);
    }, [setOpenProductTourPopup]),
    truthfulCondition: true,
  });

  return (
    <div
      ref={mainContainer}
      className={classnames("", {
        " absolute top-[100%] z-[100] right-[100%]  rounded-md bg-white shadow-md flex flex-col min-w-[300px]  ":
          !mobileVersion,
        " absolute top-[-250px] z-[100] right-[100%]  rounded-md bg-white shadow-lg border-[1px] border-gray-100 flex flex-col max-w-[300px] w-[300px] max-h-[300px] overflow-auto ":
          mobileVersion,
      })}
    >
      <div className="flex flex-col py-2 px-4 text-lg font-bold border-b border-gray-100">
        <h1>Tutorials</h1>
      </div>
      <div
        className={classnames("", {
          " flex flex-col ": !mobileVersion,
          " flex flex-col max-h-[300px] overflow-auto ": mobileVersion,
        })}
      >
        {fullProductTour.map((productTourItem) => {
          return (
            <ProductTourItem
              setIsOpen={setIsOpen}
              setOpenNotificationSlideover={setOpenNotificationSlideover}
              key={productTourItem.title + productTourItem.orderNumber}
              setOpenProductTourPopup={setOpenProductTourPopup}
              setCurrentScenario={setCurrentScenario}
              setScenarioSteps={setScenarioSteps}
              handleClickStart={handleClickStart}
              navigate={navigate}
              scenarioSteps={productTourItem.scenarioStep}
              orderNumber={productTourItem.orderNumber}
              title={productTourItem.title}
              startLocationPathname={productTourItem.startLocationPathname}
            />
          );
        })}
      </div>
    </div>
  );
}

export default memo(ProductTourComponent);
