import { ENDPOINTS } from "const";
import { axios } from "services";
import { IIssue, IIssueRating } from "types";
import { IssuePayload } from "./IssueForm.constants";

interface IIssueProps {
  issue: IIssue;
}

export function createIssue({ issue }: IIssueProps) {
  return axios.post<IIssue>(ENDPOINTS.ISSUES, issue);
}

export function updateIssue({ id, issue }: IIssueProps & { id: string }) {
  return axios.patch<IIssue>(ENDPOINTS.ISSUES + "/" + id, issue);
}

export function createIssueRating(issue: IIssueRating) {
  return axios.post<IIssueRating>(ENDPOINTS.ISSUE_RATINGS, issue);
}
export function updateIssueRating(issue: IIssueRating) {
  return axios.patch<IIssueRating>(
    ENDPOINTS.ISSUE_RATINGS + "/" + issue.id,
    issue
  );
}

export function bulkUpdateOrCreateIssueRatings(
  issueRatings: ReturnType<IssuePayload["getIssueRatings"]>,
  issueId: string | undefined
) {
  return axios.put<IIssueRating[]>(
    ENDPOINTS.ISSUE_RATINGS + "/bulk/" + issueId,
    issueRatings
  );
}
