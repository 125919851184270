/* eslint-disable no-useless-escape */

import * as yup from "yup";
import { IManagementProps } from "./SolutionForm.types";

import { FIELD_NAMES } from "./SolutionForm.constants";
import { attachmentValidation } from "modules/Attachments/attachments.validation";

const MIN_NAME_LENGTH = 3;
export const MAX_TEXT_LENGTH = 120;
export const MAX_TITLE_LENGTH = 150;

const solutionsSchema = {
  [FIELD_NAMES.TITLE]: yup
    .string()
    .trim()
    .max(MAX_TITLE_LENGTH, `max title length is ${MAX_TITLE_LENGTH}`)
    .min(MIN_NAME_LENGTH, `min title length is ${MIN_NAME_LENGTH}`)
    .required("Title is a required field"),
  [FIELD_NAMES.DESCRIPTION]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min description length is ${MIN_NAME_LENGTH}`)
    .required("Description is a required field"),
  [FIELD_NAMES.EFFECTIVENESS]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min effectiveness length is ${MIN_NAME_LENGTH}`)
    .required("Effectiveness is a required field"),
  [FIELD_NAMES.TAGS]: yup.array().max(5, "You can add up to 5 tags").nullable(),
  ...attachmentValidation,
};

export default function formValidationSchema(manage: IManagementProps) {
  let schema = {};
  if (manage.showSolutionFields) {
    schema = { ...schema, ...solutionsSchema };
  }
  return yup.object().shape(schema);
}
