import Heading from "components/Heading/Heading";
import { useMe, useQueryOptionsSetter, useSolution } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APP_ROUTES } from "const";

import { StaticMapCard } from "./StaticMapCard";
import { SlideOver } from "components/SlideOver";
import { CommentsCard, GroupForm, SolutionForm } from "components";
import { SolutionsCard } from "./SolutionsCard";
import { ActionGroupsCard } from "./ActionGroupsCard";
import { AddActionGroup } from "modules/AddActionGroup";
import { Switch } from "@headlessui/react";
import classnames from "classnames";

function Solution() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { data: solutionsData } = useSolution({
    id: params.id,
    suspense: true,
  });
  const solution = solutionsData?.data;
  const issue = solutionsData?.data.issue;
  const user = solutionsData?.data.user;
  const { data: me } = useMe();

  const [solutionsForm, setSolutionsForm] = useState(false);
  const [groupForm, setGroupForm] = useState(false);
  const [toggleForms, setToggleForms] = useState(true);

  const onAddSolutions = useCallback(() => {
    if (solutionsData?.data.user?.id === me?.data.id) {
      setSolutionsForm(true);
    }
  }, [solutionsData?.data.user?.id, me?.data.id]);

  const onAddGroup = useCallback(() => {
    if (me?.data.id) {
      setGroupForm(true);
    }
  }, [me?.data.id]);

  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const editGroupId = searchParams.get("groupId") || "";
  useEffect(() => {
    if (editGroupId) {
      setGroupForm(true);
    }
  }, [editGroupId]);

  const isSoftDeleted = !!solution?.deletedAt;

  return (
    <div className=" sm:mx-[5%] pt-8 pb-4 max-w-[1600px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap">
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUES);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issues
        </span>
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUE + "/" + solution?.issue.id);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issue: {solution?.issue.title}
        </span>
        <span
          onClick={() => {
            navigate(APP_ROUTES.SOLUTION_LIST);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Solutions
        </span>
        <span className="text-[#606060] font-normal text-md cursor-pointer">
          &gt; Solution: {solution?.title}
        </span>
      </div>
      <Heading type="h1">
        {solutionsData?.data.title}{" "}
        {isSoftDeleted && "(This solution was deleted)"}
      </Heading>
      <div className="grid pt-12 lg:grid-cols-2 gap-6">
        <SolutionsCard
          solution={solution}
          onEdit={onAddSolutions}
          allowEdit={user?.id === me?.data.id}
        />
        <StaticMapCard issue={issue} isSoftDeleted={!!solution?.deletedAt} />
        <ActionGroupsCard
          onEdit={onAddGroup}
          solution={params.id!}
          allowEdit={!!me?.data.id}
          isSoftDeleted={!!solution?.deletedAt}
        />
        <CommentsCard
          entityOwnerId={solution?.user.id}
          isSoftDeleted={!!solution?.deletedAt}
        />
      </div>
      <SlideOver
        open={!!solutionsForm}
        close={useCallback(() => {
          setSolutionsForm(false);
        }, [])}
        title="Add Solution"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <SolutionForm
          onClose={useCallback(() => {
            setSolutionsForm(false);
          }, [])}
          onSuccess={useCallback(() => {
            setSolutionsForm(false);
          }, [])}
          showSolutionFields
          showSolutionRatings={false}
        />
      </SlideOver>
      <SlideOver
        preventClose={true}
        position="right"
        open={!!groupForm}
        close={useCallback(() => {
          setGroupForm(false);
          setQueryOptions((val) => ({
            ...val,
            groupId: "",
          }));
        }, [setQueryOptions])}
        title="Create action group"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <>
          <div className="flex flex-row px-4 sm:px-20 pb-4 gap-2">
            <div className="text-gray-500 text-[14px]">
              Select Existing Group
            </div>
            <Switch
              checked={toggleForms}
              onChange={() => {
                setToggleForms((prev) => !prev);
              }}
              className="bg-[#659ae9] relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
            >
              <span
                aria-hidden="true"
                className={classnames(
                  toggleForms ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <div className="text-gray-500 text-[14px]">Create New Group</div>
          </div>
          {toggleForms && (
            <GroupForm
              onSuccess={() => {
                setGroupForm(false);
                setQueryOptions((val) => ({
                  ...val,
                  groupId: "",
                }));
              }}
            />
          )}
          {!toggleForms && <AddActionGroup solution={params.id!} />}
        </>
      </SlideOver>
    </div>
  );
}

export default Solution;
