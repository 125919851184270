import { memo, useState } from "react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "const";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { getRating } from "utils";
import ProfileImage from "components/ProfileImage/ProfileImage";

interface IProps {
  profileImage: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  email: string | undefined;
  userId: string | undefined;
  showName?: boolean;
  showAvatar?: boolean;
  showCreatedAt?: boolean;
  location?: string | undefined;
  solutionOnIssueVariant?: boolean;
  rating?: string | number | undefined;
  isModalVariant?: boolean;
  showIssueRatingTooltip?: boolean;
  className?: string;
  classNames?: { avatar?: string; name?: string; dotSeparator?: string };
  score?: number;
  tooltipId?: string;
}

function ProfileBlock({
  profileImage,
  firstName,
  lastName,
  createdAt,
  email,
  userId,
  className,
  classNames,
  location,
  rating,
  solutionOnIssueVariant = false,
  showName = true,
  showAvatar = true,
  showCreatedAt = true,
  showIssueRatingTooltip = false,
  isModalVariant = false,
  score,
  tooltipId,
}: IProps) {
  let name = [];
  if (firstName) {
    name.push(firstName as never);
  }
  if (lastName) {
    name.push(lastName as never);
  }
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={classnames(
        "flex items-center justify-center flex-1",
        className
      )}
    >
      {showAvatar && (
        <ProfileImage
          profileImage={profileImage}
          isModalVariant={isModalVariant}
          score={score}
          tooltipId={tooltipId}
        />
      )}
      <div
        className={classnames("grid grid-flow-row  ", {
          "flex-1 gap-1": !solutionOnIssueVariant,
        })}
      >
        <div
          className={classnames(
            "ml-3 min-w-0 grid   items-center  flex-1",
            { "grid-flow-col gap-2": !isModalVariant },
            { " gap-4 ": solutionOnIssueVariant },
            { "justify-evenly": isModalVariant },
            classNames?.name
          )}
        >
          {showName && (
            <span
              onClick={(e) => {
                e.preventDefault();
                if (userId) {
                  navigate(APP_ROUTES.PROFILE + "/" + userId);
                }
              }}
              className="text-sm  text-gray-500 group-hover:text-gray-900 cursor-pointer overflow-ellipsis overflow-hidden whitespace-nowrap"
            >
              {(firstName || lastName) && name.join(" ")}
              {!firstName && !lastName && email}
            </span>
          )}
          {!isModalVariant && (
            <span
              className={classnames(
                "rounded-full h-1 w-1 bg-black  ",
                classNames?.dotSeparator
              )}
            />
          )}
          {showCreatedAt && (
            <span className="text-sm  text-gray-400 group-hover:text-gray-500 font-light leading-5 overflow-ellipsis overflow-hidden whitespace-nowrap ">
              {createdAt}
            </span>
          )}
        </div>
        {(rating || location) && (
          <div
            className={classnames(
              "ml-3 min-w-0 grid grid-flow-col grid-cols-[1fr_35px] items-center gap-2  "
            )}
          >
            <span className="text-sm  text-gray-500 group-hover:text-gray-900    overflow-ellipsis overflow-hidden whitespace-nowrap       ">
              {location}
            </span>

            <span
              onMouseOver={() => {
                if (showIssueRatingTooltip) {
                  setShowTooltip(true);
                }
              }}
              onMouseOut={() => {
                setShowTooltip(false);
              }}
              className={classnames(
                " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start justify-center text-center",
                {
                  " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                    showTooltip,
                }
              )}
            >
              {getRating(Number(rating)) || 0}
              <RatingStar height={8} width={8} fill="black" />
              {showIssueRatingTooltip && showTooltip && (
                <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[-450%] text-sm font-normal after:tooltipbottomarrow">
                  <div className="flex flex-col relative text-xs bg-white p-2">
                    <p>How relevant is this issue? </p>
                    <p>Please click on issue to rate</p>
                  </div>
                  <span className="absolute border-[8px] right-[9%] xx:right-[25%] lg:right-[31%] border-b-transparent border-t-[#8159D7] border-r-transparent border-l-transparent tooltipbottomarrow" />
                </div>
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ProfileBlock);
