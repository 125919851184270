import { useField } from "formik";
import classnames from "classnames";
import { Label, QuillEditor } from "components";
import { IQuillProps } from "components/QuillEditor/QuillEditor";

interface IProps extends Partial<IQuillProps> {
  classNames?: {
    inputContainer?: string;
    input?: string;
    error?: string;
    label?: string;
  };
  className?: string;
  withErrorMessage?: boolean;
  showOnlyCustomMessage?: boolean;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  name: string;
  label?: string;
  icon?: string;
  message?: React.ReactNode;
}

function FormikQuill({
  classNames,
  name,
  className,
  label,
  withErrorMessage = true,
  showOnlyCustomMessage,
  message,
  ...rest
}: IProps) {
  const [field, form, { setValue, setTouched }] = useField(name);
  const errorMessage = form.touched && form.error;
  const showError = withErrorMessage && !!errorMessage;
  return (
    <div className={classnames(className, " flex flex-col  relative ")}>
      {label && <Label name={label} className={classNames?.label} />}
      <div
        className={classnames(classNames?.inputContainer, {
          "mb-5": !showError && !message,
        })}
      >
        <QuillEditor
          {...field}
          {...rest}
          onBlur={() => {
            setTouched(true);
          }}
          setFieldValue={setValue}
          value={field.value}
          className={classNames?.input}
        />
      </div>
      {!showOnlyCustomMessage && (showError || message) && (
        <div
          className={classnames(classNames?.error, "  font-light text-sm", {
            "text-red-400": showError,
          })}
        >
          {errorMessage || message}
        </div>
      )}
      {showOnlyCustomMessage && message && (
        <div className={classnames(classNames?.error, "  font-light text-sm")}>
          {message}
        </div>
      )}
    </div>
  );
}

export default FormikQuill;
