import { Button, Loader } from "components";
import { APP_ROUTES, ENDPOINTS } from "const";
import { useDeleteUser, useMe, useUsers } from "hooks";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "types";

export function Admin() {
  const queryClient = useQueryClient();
  const { data: users } = useUsers({ limit: 100, page: 1 });
  const { mutateAsync: deleteUser, isLoading } = useDeleteUser();
  const { data: me } = useMe();
  const navigate = useNavigate();
  //
  useEffect(() => {
    if (!me?.data?.roles?.includes(USER_ROLES.ADMIN)) {
      navigate(APP_ROUTES.DASHBOARD);
    }
  }, [me, navigate]);
  //
  return (
    <div className="flex w-full flex-col gap-2 relative pt-8">
      {users?.data.items.map((user) => {
        return (
          <div className="flex  gap-2 justify-between border-b border-solid border-black py-2 ">
            <div className="font-bold">{user.email}</div>
            <Button
              disabled={isLoading}
              onClick={async () => {
                await deleteUser(user.id);
                queryClient.invalidateQueries([ENDPOINTS.USERS]);
              }}
            >
              Delete
            </Button>
          </div>
        );
      })}
      {isLoading && <Loader type="rings" />}
    </div>
  );
}
