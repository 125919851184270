import { APP_ROUTES, ENDPOINTS } from "const";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { IMotivationalRatings, IOutletAuth, IUser } from "types";
import { Card, Loader, QuillEditor, PromptModal } from "components";

import { Button } from "components/Button";

import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import AvatarPlaceholder from "assets/images/avatar.png";
import classnames from "classnames";
import {
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import { useQueryClient } from "react-query";
import { isNil } from "lodash";
import {
  useClickOnLikeDislikeOnMotivational,
  useUpdateMotivationalRating,
} from "hooks";
import { useNavigate, useOutletContext } from "react-router-dom";

interface IProps {
  title: string;
  id: string;
  description: string | undefined;

  user: IUser | undefined;
  createdAt: string | undefined;
  rating: number | undefined;
  likes: number;
  dislikes: number;
  views: number;
  rate?: IMotivationalRatings;
  innerRef?: (node?: Element | null | undefined) => void;

  isLoading: boolean;
}

function MotivationalDetailCard({
  title,
  id,
  description,
  user,
  createdAt = "",
  likes,
  dislikes,
  views,
  rate,
  isLoading,
}: IProps) {
  const [time, setTime] = useState(true);
  const timerId1 = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    setTime(true);
    timerId1.current = setTimeout(() => {
      setTime(false);
    }, 50);

    return () => {
      if (timerId1.current) {
        clearTimeout(timerId1.current);
        setTime(false);
      }
    };
  }, [description]);

  const [desc, setDesc] = useState({ description: description });
  useEffect(() => {
    setDesc({ description: description });
  }, [description]);

  const timeDifference = Math.abs(
    differenceInMinutes(new Date(createdAt), new Date())
  );
  const timeDifferenceInWeeks = Math.abs(
    differenceInWeeks(new Date(createdAt), new Date())
  );
  const timeDifferenceInMonths = Math.abs(
    differenceInMonths(new Date(createdAt), new Date())
  );
  const timeDifferenceInYears = Math.abs(
    differenceInYears(new Date(createdAt), new Date())
  );
  const notificationTime =
    timeDifferenceInYears < 1
      ? timeDifferenceInWeeks < 1
        ? timeDifference < 60
          ? `${timeDifference} minutes ago`
          : timeDifference > 59 && timeDifference < 1440
          ? `${Math.ceil(timeDifference / 60)} hours ago`
          : `${Math.ceil(timeDifference / 1440)} days ago`
        : timeDifferenceInWeeks < 4
        ? `${timeDifferenceInWeeks} weeks ago`
        : `${timeDifferenceInMonths} months ago`
      : `${timeDifferenceInYears} years ago`;
  const [descriptionCollapsed] = useState(false);
  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL]);
    queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL_RATINGS]);
  };
  const isLikesDislikes = useMemo(() => {
    return !isNil(likes) && !isNil(dislikes);
  }, [likes, dislikes]);

  const {
    mutateAsync: mutateUpdateMotivationalAsync,
    isLoading: updateLoading,
  } = useUpdateMotivationalRating({ motivationalRatingId: rate?.id });
  const isLikeLoading = updateLoading;
  const [rates, setRates] = useState({
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });
  useEffect(() => {
    if (likes) {
      setRates((val) => ({ ...val, likes: likes }));
    }
    if (dislikes) {
      setRates((val) => ({ ...val, dislikes: dislikes }));
    }
    if (rate?.rate === 1) {
      setRates((val) => ({ ...val, liked: true, disliked: false }));
    }
    if (rate?.rate === 0) {
      setRates((val) => ({ ...val, liked: false, disliked: true }));
    }
  }, [likes, dislikes, rate]);
  const like = useClickOnLikeDislikeOnMotivational(
    id,
    rate?.rate,
    mutateUpdateMotivationalAsync,
    invalidate
  );
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Card
        className="!h-full"
        shouldHover={false}
        collapse={descriptionCollapsed}
        header={
          <div className="flex justify-between items-center pb-2">
            <div className="grid grid-flow-col gap-x-2 flex-1 items-center  ">
              <div className="flex flex-col gap-5 min-h-[84px]">
                {isLoading ? (
                  <Loader type="rings" globalLoader={false} />
                ) : (
                  <>
                    <span className="font-bold leading-6 text-3xl">
                      {title}
                    </span>
                    <div className="flex flex-row gap-3 justify-between">
                      <div className="flex flex-row flex-wrap gap-3 items-center ">
                        <span className="text-sm   text-black   font-light leading-6 line-clamp-1">
                          {views} views
                        </span>
                        <span
                          className={classnames(
                            "rounded-full   h-1 w-1 bg-[#666666]  "
                          )}
                        />
                        <span className="text-sm    text-black   font-light leading-6 line-clamp-1">
                          {notificationTime}
                        </span>
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        className="flex flex-col sm:flex-row gap-2 items-center"
                      >
                        {isLikesDislikes && (
                          <>
                            <div className="flex flex-row gap-1 items-center">
                              <span
                                onClick={() => {
                                  if (authContext.isAuth) {
                                    if (!isLikeLoading) {
                                      if (rates.liked) {
                                        setRates((val) => ({
                                          ...val,
                                          liked: false,
                                          likes: val.likes - 1,
                                        }));
                                      } else {
                                        setRates((val) => ({
                                          ...val,
                                          liked: true,
                                          likes: val.likes + 1,
                                          dislikes:
                                            val.dislikes > 0
                                              ? val.dislikes - 1
                                              : val.dislikes,
                                          disliked: false,
                                        }));
                                      }
                                      like(true);
                                    }
                                  } else {
                                    setShowGoSignInModal(true);
                                  }
                                }}
                                className={classnames(
                                  "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm  p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                                  {
                                    " bg-[#EFEFEF]": rates.liked === false,
                                    " bg-[#606060]": rates.liked === true,
                                  }
                                )}
                              >
                                <Like
                                  stroke={
                                    rates.liked === true ? "white" : "#606060"
                                  }
                                />
                              </span>
                              <span className="text-center text-[#606060] text-xs ">
                                {rates.likes}
                              </span>
                            </div>

                            <div className="flex flex-row gap-1 items-center ">
                              <span
                                onClick={() => {
                                  if (authContext.isAuth) {
                                    if (!isLikeLoading) {
                                      if (rates.disliked) {
                                        setRates((val) => ({
                                          ...val,
                                          disliked: false,
                                          dislikes: val.dislikes - 1,
                                        }));
                                      } else {
                                        setRates((val) => ({
                                          ...val,
                                          liked: false,
                                          likes:
                                            val.likes > 0
                                              ? val.likes - 1
                                              : val.likes,
                                          dislikes: val.dislikes + 1,
                                          disliked: true,
                                        }));
                                      }
                                      like(false);
                                    }
                                  } else {
                                    setShowGoSignInModal(true);
                                  }
                                }}
                                className={classnames(
                                  "flex justify-center   rounded min-w-[31px] min-h-[18px] text-sm   p-1 items-center text-center cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]",
                                  {
                                    " bg-[#EFEFEF]": rates.disliked === false,
                                    " bg-[#606060]": rates.disliked === true,
                                  }
                                )}
                              >
                                <Dislike
                                  stroke={
                                    rates.disliked === true
                                      ? "white"
                                      : "#606060"
                                  }
                                />
                              </span>
                              <span className="text-center text-[#606060] text-xs ">
                                {rates.dislikes}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className={classnames(
                          "relative gap-2  overflow-hidden flex flex-col sm:flex-row  justify-center items-center shrink-0"
                        )}
                      >
                        <img
                          decoding="async"
                          height={40}
                          width={40}
                          src={user?.avatar || AvatarPlaceholder}
                          alt="avatar"
                          className={classnames(
                            " object-cover rounded-full h-10 w-10 border border-white"
                          )}
                        />
                        <div className="flex flex-row gap-2 font-normal text-sm">
                          <span>{user?.firstName || "N/A"}</span>
                          <span>{user?.lastName || "N/A"}</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        }
        classNames={{
          header: "sm:px-16 pt-2",
          content: " maxSm:!px-2 sm:px-12 flex-1 !justify-center ",
          contentInner: "flex ",
          footer: "  sm:px-16 h-[60px]",
        }}
        content={
          <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
            {!time && desc.description && (
              <QuillEditor isReadOnly value={desc.description} />
            )}
            {!time && (isLoading || !desc.description) && (
              <QuillEditor isReadOnly value={"<p>No story yet</p>"} />
            )}
          </div>
        }
        showFooterDivider={false}
        footer={<></>}
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(MotivationalDetailCard);
