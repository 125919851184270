import { ENDPOINTS } from "const";
import { axios } from "services";
import { FullActionGroupType, IActionGroupSkill } from "types";
import { toFormData } from "utils";

interface IActionGroupsProps {
  group: FullActionGroupType;
}

interface IActionGroupsSkillsProps {
  groupSkill: IActionGroupSkill[];
}

export function createActionGroup({ group }: IActionGroupsProps) {
  return axios.post<FullActionGroupType>(
    ENDPOINTS.ACTION_GROUPS,
    toFormData(group)
  );
}

export function updateActionGroup({
  id,
  group,
}: IActionGroupsProps & { id: string }) {
  return axios.patch<FullActionGroupType>(
    ENDPOINTS.ACTION_GROUPS + "/" + id,
    toFormData(group)
  );
}

export function createActionGroupSkills({
  groupSkill,
}: IActionGroupsSkillsProps) {
  return axios.post<IActionGroupSkill[]>(
    ENDPOINTS.ACTION_GROUP_SKILLS_BULK,
    groupSkill
  );
}
export function updateActionGroupSkills({
  groupSkill,
}: IActionGroupsSkillsProps) {
  return axios.put<IActionGroupSkill[]>(
    ENDPOINTS.ACTION_GROUP_SKILLS_BULK + "/",
    groupSkill
  );
}
