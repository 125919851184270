import { Outlet } from "react-router-dom";
import { Footer, Loader, MenuBar } from "components";

import { memo, useMemo, useState } from "react";
import classnames from "classnames";
import { useAuth } from "./layouts.hooks";
import { ToastContainer } from "react-toastify";

import { IOutletAuth } from "types";
import {
  useCheckNotificationToken,
  useGetRandomBackgroundImage,
  usePdfUrl,
  useRegisterServiceWorker,
} from "./MainLayout.hooks";
import { BetaPhaseNotification } from "components/BetaPhaseNotification/BetaPhaseNotification";

export function MainLayout() {
  const { user, loading } = useAuth();

  const randomBackground = useGetRandomBackgroundImage();
  //
  const [accessType, setAccessType] = useState<
    null | "public" | "private" | "common"
  >(null);
  const [handoutsNoGroupsFound, setHandoutsNoGroupsFound] = useState<
    "NoGroups" | "Groups" | "NoSearch"
  >("NoSearch");

  const [pdfUrl, setPdfUrl] = usePdfUrl();

  const context: IOutletAuth = useMemo(() => {
    return {
      backgroundImages: randomBackground?.url,
      isAuth: !!user?.emailVerified,
      pdfUrl,
      handoutsNoGroupsFound,
      accessType,
      setAccessType: setAccessType,
      setPdfUrl: setPdfUrl,
    };
  }, [
    randomBackground?.url,
    user?.emailVerified,
    pdfUrl,
    handoutsNoGroupsFound,
    accessType,
    setPdfUrl,
  ]);

  useCheckNotificationToken(context);
  useRegisterServiceWorker();
  const [show, setShow] = useState(true);
  return (
    <div
      style={{
        background: `${randomBackground?.color}`,
      }}
      className={classnames(
        "flex flex-col w-full flex-1 max-w-[2000px] mx-auto",
        {
          // " public-layout ": !user?.emailVerified,
          // " private-layout": user?.emailVerified,
        }
      )}
    >
      {!loading && (
        <>
          {show && <BetaPhaseNotification setShow={setShow} />}

          {accessType !== "public" && (
            <MenuBar
              setHandoutsNoGroupsFound={setHandoutsNoGroupsFound}
              setPdfUrl={setPdfUrl}
              isAuth={user?.emailVerified}
            />
          )}
          <main className={classnames("flex flex-col w-full flex-1")}>
            <Outlet context={context} />
          </main>
          <ToastContainer />
          <Footer />
        </>
      )}
      {loading && <Loader />}
    </div>
  );
}
export default memo(MainLayout);
