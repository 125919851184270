import { APP_ROUTES } from "const";
import { memo, useState } from "react";
import { IActionGroupRatings, IOutletAuth, IUser } from "types";

import {
  Card,
  BorderBadgeRound,
  ActionGroupProfileBlock,
  GroupCardModal,
  PromptModal,
} from "components";

import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";

import AvatarPlaceholder from "assets/images/avatar.png";
import classnames from "classnames";
import { useActionGroups, useMe, useQueryOptionsSetter } from "hooks";

import { QueryClient, useQueryClient } from "react-query";
import { AxiosResponse } from "axios";

import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import { IQueryOptions } from "types/requests";
import { AddActionGroup } from "modules/AddActionGroup";

interface IProps {
  solution: string;
  innerRef?: (node?: Element | null | undefined) => void;
  onEdit: () => void;
  allowEdit?: boolean;
  className?: string;
  isSoftDeleted?: boolean;
}

function ActionGroup({
  meData,
  group,
  rate,
  setCurrentCroupRating,
  setQueryOptions,
  setOpenCardModal,
}: {
  meData: AxiosResponse<IUser, any> | undefined;
  group: any;
  rate: IActionGroupRatings | undefined;
  queryClient: QueryClient;

  setCurrentCroupRating?: (
    value: React.SetStateAction<string | undefined>
  ) => void;
  setOpenCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryOptions: (cb: (val: IQueryOptions) => IQueryOptions) => void;
}) {
  const date =
    group?.createdAt &&
    new Date(group?.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

  const isGroupOwner =
    !!meData &&
    group?.actionGroupUsers?.find(
      (aUser) =>
        aUser.user?.id === meData.data.id &&
        (aUser.role === "admin" || aUser.role === "creator")
    );

  return (
    <div
      onMouseOver={() => {
        if (rate) {
          setCurrentCroupRating && setCurrentCroupRating(rate.id);
        }
      }}
      className={classnames("relative z-10 group bg-white")}
    >
      <div className="relative  z-20   bg-white">
        <span className="flex justify-between items-center w-full px-6 sm:px-16 pt-2">
          <ActionGroupProfileBlock
            title={group.title}
            showName={false}
            createdAt={date}
            membersCount={group.membersCount}
            actionGroupUsers={group.actionGroupUsers}
          />
          <div
            className={classnames(
              "flex flex-row justify-end py-2   sm:pl-16   ",
              {
                " sm:pl-6 ": isGroupOwner,
              }
            )}
          >
            <div className="flex flex-row gap-2">
              {isGroupOwner && (
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="text-[black] border hover:border-black border-gray-500 "
                  type="button"
                  onClick={() => {
                    setQueryOptions((val) => ({
                      ...val,
                      groupId: group?.id,
                    }));
                  }}
                >
                  Edit group
                </Button>
              )}
            </div>
          </div>
        </span>

        <div className="grid grid-flow-row gap-x-2  h-full gap-y-2 items-center z-20  px-6 sm:px-16">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-black font-semibold leading-6 text-md line-clamp-2 align-middle ">
                {group.title}
              </span>
              <span className="text-[#00000099] font-normal leading-6 text-md line-clamp-2 align-middle ">
                {group.description}
              </span>
              <GroupItem
                title="Skills Required"
                content={
                  <div className="flex flex-wrap h-full min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4  ">
                    {group.skills?.slice(0, 2).map((skill, index) => {
                      return (
                        <BorderBadgeRound
                          key={skill.id}
                          className="flex"
                          classNames={{
                            text: index > 0 ? " line-clamp-1 " : "",
                          }}
                          withNotification
                          notificationContent={`${skill.timeRequired || 0} ${
                            Number(skill.timeRequired) === 1 ? "hour" : "hours"
                          } per week`}
                          classificationName={skill?.skill?.name}
                        >
                          {skill?.skill?.name}
                        </BorderBadgeRound>
                      );
                    })}
                    {group.skills.length > 2 && (
                      <div>+{group.skills.length - 2}</div>
                    )}
                    {group.skills && group.skills.length < 1 && (
                      <div>No skills assigned</div>
                    )}
                  </div>
                }
              />
            </div>
            <div className="flex flex-col items-center justify-center  h-[100px] w-[100px] rounded-full shrink-0">
              <img
                className="h-full w-full rounded-full border"
                alt="logo"
                src={group.logo || AvatarPlaceholder}
              />
            </div>
          </div>

          <p
            onClick={() => {
              setOpenCardModal((val) => !val);
            }}
            className="font-bold text-gray-700 hover:text-black cursor-pointer"
          >
            Show more
          </p>
        </div>
        <div
          className={classnames("border-b border-gray-200 w-full h-[1px] py-1")}
        />
      </div>
    </div>
  );
}

function GroupItem({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) {
  return (
    <div className="flex md:flex-row flex-col gap-2">
      <span className=" font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className=" font-normal leading-[30px] text-[#00000099] ">
        {content}
      </span>
    </div>
  );
}

function ActionGroupsCard({
  onEdit,
  allowEdit = false,
  solution,
  isSoftDeleted,
}: IProps) {
  const authContext = useOutletContext<IOutletAuth>();
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  const { data: meData } = useMe();
  const { data: actionGroupsData } = useActionGroups({
    solution: solution,
  });
  const groupsData = actionGroupsData?.data.items;
  const queryClient = useQueryClient();

  const [openCardModal, setOpenCardModal] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState<string | undefined>(
    undefined
  );
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const [showSelectActionGroupModal, setShowSelectActionGroupModal] =
    useState(false);

  const [openCardLocations, setOpenCardLocations] = useState<
    (string | null | undefined)[] | undefined
  >([]);

  const navigate = useNavigate();

  return (
    <>
      <Card
        className={classnames(
          "bg-white !gap-0 ten-seventh-step eleven-sixth-step",
          { "bg-gray-100 pointer-events-none select-none": isSoftDeleted }
        )}
        shouldHover={false}
        collapse={descriptionCollapsed}
        header={
          <div
            // onTouchStart={() => {
            //   setDescriptionCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1 gap-2 "
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
              <img
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": descriptionCollapsed,
                })}
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg whitespace-nowrap">
                Action Groups
              </span>
            </div>

            {allowEdit && (
              <Button
                isStyleless
                inGroupStyle={false}
                className="border hover:border-gray-300 border-transparent bg-gray-100 border-gray-100 add-group-button-to-click z-10  min-w-[90px]"
                type="button"
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={onEdit}
                disabled={!allowEdit}
                classNames={{ content: "line-clamp-1" }}
              >
                + Add Group
              </Button>
            )}
            {!authContext.isAuth && (
              <Button
                isStyleless
                inGroupStyle={false}
                className="border hover:border-gray-300 border-transparent bg-gray-100 border-gray-100 add-group-button-to-click z-10"
                type="button"
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowGoSignInModal(true);
                }}
                onClick={() => {
                  setShowGoSignInModal(true);
                }}
              >
                + Add Group
              </Button>
            )}
          </div>
        }
        classNames={{
          header: " px-6 sm:px-16 card-header pb-2",
          content: "!px-0 flex-1",
          footer: "px-6 sm:px-16 h-[60px]",
        }}
        showFooterDivider={false}
        content={
          <div className="max-h-[400px] overflow-y-auto overflow-x-hidden  ">
            <div className="flex flex-col   ">
              {groupsData && groupsData.length > 0 ? (
                groupsData.map((group) => {
                  const rate = group?.rates && group?.rates[0];
                  const allLocations = group?.solutions?.map((solution) => {
                    return solution?.issue?.location?.formattedAddress;
                  });
                  return (
                    <div
                      key={group.id}
                      onMouseOver={() => {
                        setCurrentGroupId(group.id);
                        setOpenCardLocations(allLocations);
                      }}
                    >
                      <ActionGroup
                        setOpenCardModal={setOpenCardModal}
                        //setCurrentCroupRating={setCurrentCroupRating}
                        setQueryOptions={setQueryOptions}
                        group={group}
                        meData={meData}
                        rate={rate}
                        queryClient={queryClient}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center px-4 sm:px-16 py-6 w-full">
                  <span className="text-black font-bold text-lg">
                    No action groups yet
                  </span>
                </div>
              )}
            </div>
            {openCardModal && currentGroupId && (
              <GroupCardModal
                id={currentGroupId}
                setShow={setOpenCardModal}
                allLocations={openCardLocations}
              />
            )}
          </div>
        }
      />
      {showGoSignInModal && ( ////
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
      {showSelectActionGroupModal && ( ////
        <PromptModal
          disableScrollOnOpen
          showFooterDivider={false}
          classNames={{ content: "py-0 pt-12" }}
          setShow={setShowSelectActionGroupModal}
          header="Select your existing action group"
          content={<AddActionGroup solution={solution} />}
        />
      )}
    </>
  );
}

export default memo(ActionGroupsCard);
