import { ENDPOINTS } from "const";
import { useInfinityActionGroups, useMe } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import { getRange, getSearchParams } from "utils";
import { CallBackProps, STATUS, EVENTS } from "react-joyride";
import { State } from "./MenuBar";

export function useActivitiesNotificationOnUpdate(isAuth: boolean) {
  const { data } = useMe({
    enabled: !!isAuth,
    retry: false,
    keepPreviousData: false,
    suspense: false,
  });

  const client = useQueryClient();

  useEffect(() => {
    if (data?.data.id) {
      const source = new EventSource(
        process.env.REACT_APP_API_URL +
          ENDPOINTS.ACTIVITIES_EVENTS +
          "?user=" +
          data?.data.id
      );
      source.onmessage = () => {
        client.invalidateQueries([ENDPOINTS.ACTIVITIES]);
      };
      return () => {
        source.close();
      };
    }
  }, [client, data?.data.id]);
}

export function useGroupList(
  params: Partial<ReturnType<typeof getSearchParams>>,
  mapWidth?: number,
  limit?: number,
  disabled = false
) {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();
  const { data, fetchNextPage, ...rest } = useInfinityActionGroups({
    limit: limit || 999,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },
    ...params,
    range: getRange(params.zoom, Number(params.lat), mapWidth),
    keepPreviousData: true,
    suspense: !isMount,
    enabled: !disabled,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}

interface IHanldeJoyrideCallbackProps {
  setJoyState: (
    patch: Partial<State> | ((prevState: State) => Partial<State>)
  ) => void;
  setPreventClose: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  setOpenIssueModal: (value: React.SetStateAction<boolean>) => void;
  currentScenario: number;
}

export function useHandleJoyrideCallback({
  setJoyState,
  setPreventClose,
  setIsOpen,
  currentScenario,
  setOpenIssueModal,
}: IHanldeJoyrideCallbackProps) {
  return useCallback(
    (data: CallBackProps) => {
      const { status, type, index } = data;

      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
      if (finishedStatuses.includes(status)) {
        setJoyState({ run: false, stepIndex: 0 });
      } else if (
        ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
          type
        )
      ) {
        const nextStepIndex = index + 1;

        if (currentScenario === 1) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }
        if (currentScenario === 2) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 2) {
            setPreventClose(false);
            setIsOpen(false);
            setOpenIssueModal(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
            setOpenIssueModal(false);
          }
        }

        if (currentScenario === 3) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".issue-full-details-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else if (index === 6) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".rate-button-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 4) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 5) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".issue-full-details-button-to-click"
            );

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 6) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 3) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".issue-full-details-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".add-solution-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 600);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 7) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".solution-details-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 8) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 9) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            } else {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 10) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".issue-full-details-button-to-click"
            );

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else if (index === 6) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".solution-to-click");

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 11) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 3) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".first-card-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".issue-full-details-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 1000);
            }
          } else if (index === 5) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(".solution-to-click");
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 600);
            }
          } else if (index === 6) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".add-group-button-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 600);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 12) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 13) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 14) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 2) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".create-motivational-button-to-click"
            );

            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 15) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else if (index === 4) {
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            const cardToClick = document.querySelector(
              ".motivational-card-to-click"
            );
            if (cardToClick) {
              cardToClick.dispatchEvent(clickEvent);
              setTimeout(() => {
                setJoyState({
                  stepIndex: nextStepIndex,
                });
              }, 500);
            }
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }

        if (currentScenario === 16) {
          if (index === 0) {
            setPreventClose(true);
            setIsOpen(true);
            setTimeout(() => {
              setJoyState({
                stepIndex: nextStepIndex,
              });
            }, 500);
          } else if (index === 1) {
            setJoyState({
              stepIndex: nextStepIndex,
            });
          } else {
            setPreventClose(false);
            setJoyState({
              stepIndex: nextStepIndex,
            });
            setIsOpen(false);
          }
        }
      }
    },
    [
      currentScenario,
      setIsOpen,
      setJoyState,
      setOpenIssueModal,
      setPreventClose,
    ]
  );
}
///
