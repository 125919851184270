import { useEffect, useMemo, useState } from "react";
import { APP_ROUTES, DEFAULT_CENTER, DEFAULT_ZOOM } from "const";

import { fitBounds } from "google-map-react";

import { useMapLocations, useQueryOptionsSetter } from "hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import { IQueryOptions } from "types/requests";
import { getGeocode } from "use-places-autocomplete";
import { calculateEps, getBounds, getRange, getSearchParams } from "utils";

export function useMapSearch(mapWidth?: number) {
  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const location = useLocation();
  const params = getSearchParams(searchParams);
  const range = getRange(
    params.zoom || DEFAULT_ZOOM,
    Number(searchParams.get("lat")) || DEFAULT_CENTER.lat,
    mapWidth
  );

  const { data, isLoading } = useMapLocations({
    ...params,
    path: location.pathname.includes(APP_ROUTES.SOLUTION_LIST)
      ? "solution"
      : location.pathname.includes(APP_ROUTES.ISSUES)
      ? "issue"
      : "groups",
    lat: params.lat || DEFAULT_CENTER.lat + "",
    lng: params.lng || DEFAULT_CENTER.lng + "",
    skills: params.skills,
    eps: calculateEps(range),
    range,
    keepPreviousData: true,
  });
  return { data, isLoading, setQueryOptions };
}

function useCalculateOnPlaceChanging({
  width,
  height = 100,
  setQueryOptions,
  searchParams,
}: {
  searchParams: globalThis.URLSearchParams;
  width?: number;
  height?: number;
  setQueryOptions: (cb: (val: IQueryOptions) => IQueryOptions) => void;
}) {
  const placeId = searchParams.get("placeId");

  const [place, setPlace] = useState<google.maps.GeocoderResult | null>(null);

  useEffect(() => {
    if (placeId) {
      getGeocode({ placeId }).then((result) => {
        const place = result[0];
        setPlace(place);
      });
    }
  }, [placeId]);

  const latlng = place?.geometry.location;
  const lat = String(latlng?.lat());
  const lng = String(latlng?.lng());
  const viewport = place?.geometry.viewport;
  const bounds = useMemo(() => {
    if (!viewport) return;
    return getBounds(viewport);
  }, [viewport]);

  useEffect(() => {
    if (viewport && width && bounds) {
      const { zoom } = fitBounds(bounds, {
        height,
        width,
      });

      setQueryOptions((val) => ({
        ...val,
        lat,
        lng,
        zoom: String(zoom),
      }));
      setPlace(null); //delete to prevent recalculate on drag map, when we drag map we also change viewport and this run logic like if we put new place id
    }
  }, [bounds, height, lat, lng, setQueryOptions, viewport, width]);
}

export function useNavigateMap(
  mapHeight: number | undefined,
  mapWidth: number | undefined
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  useCalculateOnPlaceChanging({
    width: mapWidth,
    height: mapHeight,
    setQueryOptions,
    searchParams,
  });

  return {
    center: {
      lat: searchParams.get("lat"),
      lng: searchParams.get("lng"),
    },

    zoom: searchParams.get("zoom"),
    setQueryOptions,
  };
}
