/* This example requires Tailwind CSS v2.0+ */
import { memo, useEffect, useState } from "react";
import {} from "@headlessui/react";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useElementScroll, useScrollOnDrag } from "hooks";
import { Badge } from "components/Badge";
import { BADGE_COLORS } from "const";

interface IQueryOptions {
  placeId?: string;
  category?: string[] | null;
  user?: string | null;
  lat?: string | null;
  skills?: string[] | null;
  lng?: string | null;
  zoom?: string | null;
}

interface IProps {
  setQueryOptions: (cb: (val: IQueryOptions) => IQueryOptions) => void;
  setCurrentSelectedSkills: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
        label: string;
      }[]
    >
  >;
  currentSelectedSkills: {
    value: string;
    label: string;
  }[];
}

function ToggleButtonSkillsGroup({
  currentSelectedSkills,
  setQueryOptions,
  setCurrentSelectedSkills,
}: IProps) {
  const [, setEnabledIndex] = useState(NaN);
  const [searchParams] = useSearchParams();
  const values = {
    skills: searchParams.get("skills") || "",
  };
  useEffect(() => {
    if (values.skills) {
      currentSelectedSkills.find((option, index) => {
        if (option.value === values.skills) {
          setEnabledIndex(index);
          return true;
        }
        return false;
      });
    }
  }, [currentSelectedSkills, values.skills]);
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const { scrollable, scrollableToLeft, scrollableToRight } =
    useElementScroll(element);

  useScrollOnDrag(element);

  useEffect(() => {
    if (scrollable) {
      element?.classList.toggle("is-left-overflowing", scrollableToLeft);
      element?.classList.toggle("is-right-overflowing", scrollableToRight);
    }
  }, [scrollable, element, scrollableToLeft, scrollableToRight]);

  return (
    <div
      ref={(el) => {
        setElement(el);
      }}
      className={classnames(
        "dragging-group flex flex-row gap-x-5 gap-y-1 overflow-auto pb-[16px] h-[52px] pt-2 hide-scroll  fade ",
        {
          invisible: currentSelectedSkills.length < 0,
          " cursor-pointer ": currentSelectedSkills.length > 0,
        }
      )}
    >
      {currentSelectedSkills.map((skill, index) => {
        return (
          <Badge
            key={skill.value + index}
            className=" shadow-md whitespace-nowrap"
            onRemove={() => {
              setQueryOptions((values: IQueryOptions) => {
                const val: IQueryOptions = {
                  ...values,
                  skills: [
                    ...currentSelectedSkills
                      .filter((item) => {
                        return item.value !== skill.value;
                      })
                      .map((item) => {
                        return item.value as string;
                      }),
                  ],
                };
                setCurrentSelectedSkills(() => [
                  ...currentSelectedSkills
                    .filter((item) => {
                      return item.value !== skill.value;
                    })
                    .map((item) => {
                      return item;
                    }),
                ]);
                return val;
              });
            }}
            classNames={{
              button: BADGE_COLORS[index] || "",
            }}
          >
            {skill.label}
          </Badge>
        );
      })}
    </div>
  );
}

export default memo(ToggleButtonSkillsGroup);
