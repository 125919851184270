import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RequestStatusPanel } from "components";

function ConfirmEmail() {
  const { search } = useLocation();
  const [notification, setNotification] = useState<
    | null
    | "emailVerified"
    | "passwordReset"
    | "registered"
    | "passwordConfirmed"
  >(null);

  //TODO - refactor to different pages
  useEffect(() => {
    const query = new URLSearchParams(search);

    if (query.has("passwordConfirmed")) {
      const value = query.get("passwordConfirmed");
      if (value === "true") {
        setNotification("passwordConfirmed");
      }
    }
    if (query.has("emailVerified")) {
      const value = query.get("emailVerified");
      if (value === "true") {
        setNotification("emailVerified");
      }
    }
    if (query.has("passwordReset")) {
      const value = query.get("passwordReset");
      if (value === "true") {
        setNotification("passwordReset");
      }
    }
    if (query.has("registered")) {
      const value = query.get("registered");
      if (value === "true") {
        setNotification("registered");
      }
    }
  }, [search]);

  return (
    <div className="h-full w-full flex items-center flex-col justify-center">
      <div className=" maxmxs:h-full mxs:grid  grid-cols-12 grid-rows-12">
        {notification === "registered" && (
          <RequestStatusPanel panelType="registered" />
        )}
        {notification === "emailVerified" && (
          <RequestStatusPanel panelType="confirmed" />
        )}
        {notification === "passwordReset" && (
          <RequestStatusPanel panelType="passwordReset" />
        )}
        {notification === "passwordConfirmed" && (
          <RequestStatusPanel panelType="passwordConfirmed" />
        )}
        {!notification && <RequestStatusPanel panelType="error" />}
      </div>
    </div>
  );
}

export default ConfirmEmail;
