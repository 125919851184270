import ReactDOM from "react-dom";
import { Rings, ThreeDots } from "react-loader-spinner";
import classnames from "classnames";
import { LOADER_SIZES } from "const";
import LoaderGif from "assets/images/weatt-loader.gif";

interface IProps {
  className?: string;
  portalNode?: HTMLElement;
  globalLoader?: boolean;
  type?: "rings" | "dots" | "default";
  size?: "tiny" | "small" | "medium" | "large" | "huge";
}

function LoaderComponent({
  className,
  portalNode = document.body,
  globalLoader = true,
  type = "default",
  size = globalLoader ? "medium" : "tiny",
}: IProps) {
  const loaderSize = LOADER_SIZES[size];

  return globalLoader ? (
    ReactDOM.createPortal(
      <div
        className={classnames(className, {
          "z-20 top-0  absolute left-0 w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
            globalLoader,
          "z-20   w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
            !globalLoader,
          "bg-white ": globalLoader && type === "default",
        })}
      >
        {type === "default" && (
          <img
            alt="loader"
            width={loaderSize}
            className="self-center loader "
            src={LoaderGif}
          />
        )}
        {type === "rings" && (
          <Rings color="#DA7D78" height={loaderSize} width={loaderSize} />
        )}
        {type === "dots" && (
          <ThreeDots color="#DA7D78" height={loaderSize} width={loaderSize} />
        )}
      </div>,
      portalNode
    )
  ) : (
    <div
      className={classnames(className, {
        "z-20    w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
          !globalLoader,
      })}
    >
      {type === "default" && (
        <img
          alt="loader"
          width={loaderSize}
          className="self-center loader "
          src={LoaderGif}
        />
      )}
      {type === "rings" && (
        <Rings color="#DA7D78" height={loaderSize} width={loaderSize} />
      )}
      {type === "dots" && (
        <ThreeDots color="#DA7D78" height={loaderSize} width={loaderSize} />
      )}
    </div>
  );
}

export default LoaderComponent;
