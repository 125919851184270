import { memo, useRef, useState } from "react";

import classnames from "classnames";
import { BorderBadge, Button, IssueCardModal } from "components";
import { useIssue, useOnClickOutside } from "hooks";
import { XIcon } from "@heroicons/react/outline";

export interface IMapProps {
  issueId: string | undefined;
  setShowPreviewCard: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPreviewCardId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setOpenMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapCardPreview({
  issueId,
  setCurrentPreviewCardId,
  setOpenMap,
  setShowPreviewCard,
}: IMapProps) {
  const [openCardModal, setOpenCardModal] = useState(false);
  const { data, isLoading } = useIssue({ id: issueId, suspense: false });
  const isFormattedAddress = !!data?.data?.location?.formattedAddress;
  const isCountry = !!data?.data?.location?.country;
  const isCity = !!data?.data?.location?.city;
  const isStreetNumber = !!data?.data?.location?.streetNumber;
  const isRoute = !!data?.data?.location?.route;
  const location =
    typeof data?.data?.location === "object"
      ? isFormattedAddress
        ? `${data?.data?.location?.formattedAddress}`
        : isCountry && isCity && isStreetNumber && isRoute
        ? `${data?.data?.location?.country}, ${data?.data?.location?.city}, ${data?.data?.location?.route} ${data?.data?.location?.streetNumber}`
        : isCountry && isCity
        ? `${data?.data?.location?.country}, ${data?.data?.location?.city}`
        : isCountry
        ? `${data?.data?.location?.country}`
        : "No location"
      : "No location";

  const showPopupRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const tileButtonRef = useRef<HTMLDivElement | null>(null);
  const truthfulCondition = true;
  useOnClickOutside({
    ref: showPopupRef,
    handler: () => {
      setCurrentPreviewCardId(undefined);
      setShowPreviewCard(false);
    },
    truthfulCondition: truthfulCondition,
    childRef: childRef,
    tileButtonRef: tileButtonRef,
  });

  return (
    <>
      <div ref={tileButtonRef} className="absolute top-2.5 right-16">
        <BorderBadge
          styleLess
          className="rounded-lg p-[2px] bg-gradient-to-r from-[#DC88DE] via-[#8159D7] to-[#9BB6FB]  "
        >
          <Button
            onClick={() => {
              setOpenMap((val) => !val);
            }}
            className="!p-2 z-20 !rounded-md"
            isReverse
          >
            Show tile
          </Button>
        </BorderBadge>
      </div>
      <div
        ref={showPopupRef}
        onClick={() => {
          setOpenCardModal(true);
        }}
        className={classnames(
          " absolute flex justify-center bottom-3 left-0 w-full z-30"
        )}
      >
        {!isLoading && data?.data && (
          <div className="max-w-[85%] w-full py-4 pl-4 pr-3  bg-white rounded-lg flex flex-row justify-between shadow-lg ">
            <div className="flex flex-col gap-1">
              <span className="font-bold leading-6 text-lg line-clamp-2">
                {data?.data.title}
              </span>
              <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                {location}
              </span>
            </div>
            <div>
              <XIcon
                onClick={() => {
                  setCurrentPreviewCardId(undefined);
                  setShowPreviewCard(false);
                }}
                className="h-6 w-6 cursor-pointer opacity-50"
                aria-hidden="true"
              />
            </div>
          </div>
        )}
      </div>
      {openCardModal && issueId && (
        <IssueCardModal
          ref={childRef}
          id={issueId}
          setShow={setOpenCardModal}
        />
      )}
    </>
  );
}
export default memo(MapCardPreview);
