import {
  BorderBadge,
  Button,
  IMapProps,
  Loader,
  Map,
  MapCardPreview,
  MapCardPreviewInArray,
} from "components";
import { memo, useCallback, useRef, useState } from "react";
import { MAP_THEMES } from "const";
import { MapMarker } from "components/MapMarker/MapMarker";
import { IPoint } from "types";
import GoogleMapReact from "google-map-react";
import { useWindowDimensions } from "hooks";
import { isMobile, isTablet } from "react-device-detect";
import { useOnClickOutside } from "hooks";
import { useMapSearch, useNavigateMap } from "./ListMap.hooks";

interface IProps extends Omit<IMapProps, "selectedTheme" | "lat" | "lng"> {
  innerRef: React.MutableRefObject<any>;
  hoveredIssueType?: "regular" | "city" | "country" | undefined;
  mapHeight?: number;
  mapWidth?: number;
  issueCardProps: {
    lat: number;
    lng: number;
  };
  issueCardHovered: boolean;
  setOpenMap: React.Dispatch<React.SetStateAction<boolean>>;
  setHoveredIssueId: React.Dispatch<
    React.SetStateAction<string | undefined | string[]>
  >;
}

function SolutionListMap({
  innerRef,
  mapHeight,
  mapWidth,
  hoveredIssueType,
  issueCardProps,
  issueCardHovered,
  setHoveredIssueId,
  setOpenMap,
  ...rest
}: IProps) {
  const { width: defaultWidth } = useWindowDimensions();
  const { center, zoom, setQueryOptions } = useNavigateMap(
    mapHeight,
    mapWidth || defaultWidth
  );
  const { data, isLoading } = useMapSearch(mapWidth);
  const ref = useRef<GoogleMapReact | null>(null);
  const [showPreviewCard, setShowPreviewCard] = useState(false);
  const [currentPreviewCardId, setCurrentPreviewCardId] = useState<
    undefined | string
  >(undefined);
  const [currentPreviewCardArrayId, setCurrentPreviewCardArrayId] = useState<
    undefined | string[]
  >(undefined);
  const mainContainer = useRef<HTMLDivElement | null>(null);
  const tileButtonRef = useRef<HTMLDivElement | null>(null);
  const [openCardModalRef, setOpenCardModalRef] = useState(false);
  useOnClickOutside({
    ref: mainContainer,
    handler: () => {
      if (currentPreviewCardArrayId) {
        setCurrentPreviewCardArrayId(undefined);
        setShowPreviewCard(false);
      }
    },
    truthfulCondition: !openCardModalRef,
    tileButtonRef: tileButtonRef,
  });
  const [isDragging, setIsDragging] = useState(false);
  const prevZoom = useRef(null);

  return (
    <div className="flex flex-col  w-full h-full" ref={innerRef}>
      <Map
        innerRef={ref}
        shouldUnregisterMapOnUnmount={false} //this is important, otherwise we will get error
        selectedTheme={MAP_THEMES.SILVER}
        zoom={Number(zoom)}
        debounced
        onZoomAnimationEnd={useCallback(
          (zoom) => {
            if (prevZoom.current) {
              //@ts-ignore
              const center: IPoint = ref.current?.map_.center.toJSON();
              let payload = { zoom: String(zoom) };
              if (center) {
                payload = { ...payload, ...center };
              }
              setQueryOptions((val) => ({
                ...val,
                ...payload,
              }));
            }
            prevZoom.current = zoom; // to prevent first onZoomAnimationEnd since it change query params on start
          },
          [setQueryOptions]
        )}
        onDragEnd={useCallback(
          (map) => {
            setIsDragging(false);
            const { lat, lng } = map.center.toJSON();
            setQueryOptions((val) => ({
              ...val,
              placeId: undefined,
              lat: String(lat),
              lng: String(lng),
              zoom: String(map.zoom),
            }));
          },
          [setQueryOptions]
        )}
        onDrag={() => {
          setIsDragging(true);
        }}
        lat={!!center.lat ? center.lat : undefined}
        lng={!!center.lng ? center.lng : undefined}
        {...rest}
      >
        {issueCardHovered &&
          !isNaN(issueCardProps.lat) &&
          !isNaN(issueCardProps.lng) && (
            <MapMarker
              onTop
              key="999f9"
              lat={issueCardProps.lat}
              lng={issueCardProps.lng}
              numberOfItems={1}
            />
          )}
        {!isDragging &&
          data?.data.map(({ location, count, entity, type }, i) => {
            return (
              <MapMarker
                key={i}
                lat={location[1]}
                lng={location[0]}
                numberOfItems={count > 1 ? count : 1}
                setHoveredIssueId={setHoveredIssueId}
                issue={entity}
                onClick={() => {
                  if (
                    entity &&
                    typeof entity === "string" &&
                    (isMobile || isTablet)
                  ) {
                    setCurrentPreviewCardId(entity);
                    setShowPreviewCard(true);
                  }
                  if (
                    entity &&
                    typeof entity === "object" &&
                    (isMobile || isTablet)
                  ) {
                    setCurrentPreviewCardArrayId(entity);
                    setShowPreviewCard(true);
                  }
                  if (zoom && Number(zoom) < 22 && !type) {
                    setQueryOptions((val) => ({
                      ...val,
                      lat: String(location[1]),
                      lng: String(location[0]),
                      zoom: String(Number(zoom) + 3),
                    }));
                  }
                  if (zoom && Number(zoom) < 22 && type) {
                    setQueryOptions((val) => ({
                      ...val,
                      lat: String(location[1]),
                      lng: String(location[0]),
                      zoom: String(
                        type === "country" ? 6 : type === "city" ? 10 : 16
                      ),
                    }));
                  }
                }}
              />
            );
          })}
      </Map>
      {showPreviewCard && currentPreviewCardId && (
        <MapCardPreview
          setOpenMap={setOpenMap}
          setShowPreviewCard={setShowPreviewCard}
          setCurrentPreviewCardId={setCurrentPreviewCardId}
          issueId={currentPreviewCardId}
        />
      )}
      {(isLoading || isDragging) && (
        <>
          <div className="absolute h-full w-full bg-gray-400 opacity-20" />
          <div className="absolute top-[50%] left-[50%]   z-50">
            <Loader globalLoader={false} type="rings" />
          </div>
        </>
      )}
      {showPreviewCard && currentPreviewCardArrayId && (
        <>
          <div ref={tileButtonRef} className="absolute top-2.5 right-16">
            <BorderBadge
              styleLess
              className="rounded-lg p-[2px] bg-gradient-to-r from-[#DC88DE] via-[#8159D7] to-[#9BB6FB]  "
            >
              <Button
                onClick={() => {
                  setOpenMap((val) => !val);
                }}
                className="!p-2 z-20 !rounded-md"
                isReverse
              >
                Show tile
              </Button>
            </BorderBadge>
          </div>
          <div
            ref={mainContainer}
            className="absolute hide-scroll justify-center bottom-3 left-0 w-full overflow-auto"
          >
            <div className="w-max flex flex-row px-4">
              {currentPreviewCardArrayId.map((issue) => {
                return (
                  <MapCardPreviewInArray
                    key={issue}
                    setOpenCardModalRef={setOpenCardModalRef}
                    setShowPreviewCard={setShowPreviewCard}
                    setCurrentPreviewCardArrayId={setCurrentPreviewCardArrayId}
                    issueId={issue}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default memo(SolutionListMap);
