import { useAuthState } from "react-firebase-hooks/auth";
import { axios } from "services";
import { getAuth } from "firebase/auth";
import { useMemo } from "react";

export function useAuth() {
  const auth = useMemo(() => {
    return getAuth();
  }, []);

  const [user, loading, error] = useAuthState(auth);
  axios.interceptors.request.use(
    async function (request) {
      if (user) {
        try {
          const token = await user.getIdToken(false); // Force refresh is false

          request.headers = {
            ...request.headers,
            Authorization: "Bearer " + token,
          };
        } catch (error) {}
      }

      return request;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  return { user, loading, error };
}
