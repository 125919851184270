import {
  AttachmentsInitial,
  AttachmentsPayload,
  IAttachmentPayloadArguments,
} from "modules/Attachments/Attachments.types";
import {
  IActionGroupSkill,
  ISkill,
  ISelectOption,
  isImageType,
  FullActionGroupType,
} from "types";

export enum ACTION_GROUPS_FIELD_NAMES {
  TITLE = "title",
  CONTACT_URL = "contactUrl",
  ADDRESS = "address",
  DESCRIPTION = "description",
  SCHEDULE = "schedule",
  STAGE = "stage",
  LOGO = "logo",
}

export enum FIELD_NAMES {
  TITLE = "title",
  CONTACT_URL = "contactUrl",
  DESCRIPTION = "description",
  CONTACT = "contact",
  SCHEDULE = "schedule",
  LOGO = "logo",
  STAGE = "stage",
  SKILLS = "skills",
  IS_PRIVATE = "isPrivate",
  SOLUTIONS = "solutions",
  USER = "user",
  ATTACHMENTS = "attachments",
  TAGS = "tags",
}

export class InitActionGroup extends AttachmentsInitial {
  [FIELD_NAMES.TITLE] = "";
  [FIELD_NAMES.CONTACT_URL] = "";
  [FIELD_NAMES.CONTACT] = "";
  [FIELD_NAMES.DESCRIPTION] = "";
  [FIELD_NAMES.SCHEDULE] = "";
  [FIELD_NAMES.LOGO] = "";
  [FIELD_NAMES.IS_PRIVATE] = { label: "Public", value: false };
  [FIELD_NAMES.STAGE] = { value: "", label: "" };
  [FIELD_NAMES.SKILLS]: {
    skill: ISelectOption<string | null, string | null>;
    timeRequired: number | null;
  }[] = [
    {
      skill: {
        value: null,
        label: null,
      },
      timeRequired: null,
    },
  ];
  [FIELD_NAMES.SOLUTIONS]: string[] = [];
  [FIELD_NAMES.TAGS]: string[] = [];
  validator = "";
  constructor(
    actionGroup: FullActionGroupType | undefined,
    actionGroupSkills: IActionGroupSkill<ISkill, string>[] | undefined
  ) {
    super(actionGroup?.attachments);
    if (actionGroup) {
      this.addTitle(actionGroup);
      this.addContactUrl(actionGroup?.contactUrl);
      this.addContact(actionGroup?.contact);
      this.addDescription(actionGroup?.description);
      this.addSchedule(actionGroup?.schedule);
      this.addLogo(actionGroup?.logo);
      this.addIsPrivate(actionGroup);
      this.addStage(actionGroup);
      this.addActionGroupSkills(actionGroupSkills);
      this.addSolutions(actionGroup);
      this.addTags(actionGroup?.tags);
    }
  }

  addTitle(actionGroup: FullActionGroupType) {
    if (actionGroup?.title) {
      this.title = actionGroup?.title;
    }
  }
  addSolutions(actionGroup: FullActionGroupType) {
    if (actionGroup?.solutions?.length) {
      const ids = actionGroup?.solutions.map((solution) => solution?.id);
      this.solutions = ids.filter((id) => id) as string[];
    }
  }

  addStage(actionGroup: FullActionGroupType) {
    if (actionGroup?.stage) {
      this.stage = {
        value: actionGroup?.stage,
        label: actionGroup?.stage,
      };
    }
  }
  addIsPrivate(actionGroup: FullActionGroupType) {
    this.isPrivate = {
      value: !!actionGroup?.isPrivate,
      label: actionGroup?.isPrivate === true ? "Private" : "Public",
    };
  }
  addActionGroupSkills(
    actionGroupSkills: IActionGroupSkill<ISkill, string>[] | undefined
  ) {
    if (actionGroupSkills && actionGroupSkills?.length > 0) {
      this.skills = actionGroupSkills?.map((skill) => {
        return {
          skill: {
            value: skill.skill.id || "",
            label: skill?.skill?.name || "",
          },
          timeRequired: skill.timeRequired,
        };
      });
    }
  }
  addContact(contact: string | undefined) {
    if (contact) {
      this[FIELD_NAMES.CONTACT] = contact;
    }
  }
  addContactUrl(contactUrl: string | undefined) {
    if (contactUrl) {
      this[FIELD_NAMES.CONTACT_URL] = contactUrl;
    }
  }
  addDescription(description: string | undefined) {
    if (description) {
      this[FIELD_NAMES.DESCRIPTION] = description;
    }
  }
  addLogo(logo: string | undefined) {
    if (logo) {
      this[FIELD_NAMES.LOGO] = logo;
    }
  }
  addSchedule(schedule: string | undefined) {
    if (schedule) {
      this[FIELD_NAMES.SCHEDULE] = schedule;
    }
  }
  addTags(tags: string[] | undefined) {
    if (tags && tags.length > 0) {
      this[FIELD_NAMES.TAGS] = tags;
    }
  }
}

export class ActionGroupPayload extends AttachmentsPayload {
  [FIELD_NAMES.IS_PRIVATE]: boolean = false;
  [FIELD_NAMES.STAGE]: string = "";
  [FIELD_NAMES.SKILLS]: {
    skill?: string | null;
    timeRequired?: number | null;
  }[] = [];
  [FIELD_NAMES.TITLE]: string = "";
  [FIELD_NAMES.DESCRIPTION]: string = "";
  [FIELD_NAMES.LOGO]: string = "";
  [FIELD_NAMES.SOLUTIONS]: string[] = [];
  [FIELD_NAMES.CONTACT_URL]: string = "";
  [FIELD_NAMES.SCHEDULE] = "";
  [FIELD_NAMES.USER] = "";
  [FIELD_NAMES.CONTACT] = "";
  [FIELD_NAMES.TAGS]: string[] = [];

  constructor({
    solutions,
    logo,
    stage,
    isPrivate,
    skills,
    attachments,
    contact,
    title,
    attachmentsInput,
    attachmentsQuill,
    attachmentsTitle,
    attachmentsType,
    tags,
    ...data
  }: Partial<
    InitActionGroup & IAttachmentPayloadArguments & { user: string }
  >) {
    super({
      attachmentsType,
      attachments,
      attachmentsInput,
      attachmentsQuill,
      attachmentsTitle,
    });
    //super(attachments, "actionGroups", attachmentsInput);
    this.addIsPrivate(isPrivate);
    this.addStage(stage);
    this.addActionGroupSkills(skills);
    this.addTitle(title);
    this.addDescription(data.description);
    this.addLogo(logo);
    this.addSolutions(solutions);
    this.addContactUrl(data.contactUrl);
    this.addSchedule(data.schedule);
    this.addUser(data.user);
    this.addContact(contact);
    this.addTags(tags);
  }
  addUser(user: string | undefined) {
    if (user) {
      this[FIELD_NAMES.USER] = user;
    }
  }

  addSchedule(schedule: string | undefined) {
    if (schedule) {
      this[FIELD_NAMES.SCHEDULE] = schedule;
    }
  }

  addContactUrl(contactUrl: string | undefined) {
    if (contactUrl) {
      this[FIELD_NAMES.CONTACT_URL] = contactUrl;
    }
  }
  addDescription(description: string | undefined) {
    if (description) {
      this.description = description;
    }
  }

  addTitle(data: Partial<InitActionGroup["title"]> | undefined) {
    if (data) {
      this.title = data;
    }
  }

  addContact(contact: Partial<InitActionGroup["contact"]> | undefined) {
    if (contact) {
      this.contact = contact;
    }
  }

  addSolutions(solutions: string[] | undefined) {
    if (solutions && solutions.length > 0) {
      this.solutions = solutions;
    }
  }
  addLogo(logo: File | null | string | undefined) {
    let extractedLogo;
    if (isImageType(logo)) {
      extractedLogo = logo;
    }
    if (logo === null) {
      extractedLogo = null;
    }
    this.logo = extractedLogo;
  }
  addStage(stage: InitActionGroup["stage"] | undefined) {
    if (stage && typeof stage === "object" && stage.value) {
      this.stage = stage.value;
    }
  }
  addIsPrivate(isPrivate: InitActionGroup["isPrivate"] | undefined) {
    if (isPrivate && typeof isPrivate === "object" && isPrivate.value) {
      this.isPrivate = isPrivate.value;
    }
  }
  addActionGroupSkills(
    actionGroupSkills: InitActionGroup["skills"] | undefined
  ) {
    if (actionGroupSkills && actionGroupSkills.length > 0) {
      actionGroupSkills.forEach((skill) => {
        this.skills.push({
          skill: skill.skill?.value,
          timeRequired: skill.timeRequired,
        });
      });
    }
  }
  getActionGroup() {
    return {
      [FIELD_NAMES.IS_PRIVATE]: this[FIELD_NAMES.IS_PRIVATE],
      [FIELD_NAMES.STAGE]: this[FIELD_NAMES.STAGE],
      [FIELD_NAMES.TITLE]: this[FIELD_NAMES.TITLE],
      [FIELD_NAMES.DESCRIPTION]: this[FIELD_NAMES.DESCRIPTION],
      [FIELD_NAMES.LOGO]: this[FIELD_NAMES.LOGO],
      [FIELD_NAMES.SOLUTIONS]: this[FIELD_NAMES.SOLUTIONS],
      [FIELD_NAMES.CONTACT_URL]: this[FIELD_NAMES.CONTACT_URL],
      [FIELD_NAMES.SCHEDULE]: this[FIELD_NAMES.SCHEDULE],
      [FIELD_NAMES.CONTACT]: this[FIELD_NAMES.CONTACT],
      [FIELD_NAMES.TAGS]: this[FIELD_NAMES.TAGS],
    };
  }
  getSkills() {
    return this[FIELD_NAMES.SKILLS];
  }
  addTags(tags: string[] | undefined) {
    if (tags && tags.length > 0) {
      this[FIELD_NAMES.TAGS] = tags;
    }
  }
}
