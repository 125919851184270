import { APP_ROUTES } from "const";

import { lazy, useEffect } from "react";
import { ICustomRouteObject, IOutletAuth } from "types";
import {
  CreateNewPasswordForm,
  Dashboard,
  IssueList,
  LoginForm,
  NotFound,
  ProfileForm,
  ForgetPasswordForm,
  SignUpForm,
  SolutionList,
  ConfirmEmail,
  PublicLayoutError,
  Issue,
  Solution,
  MotivationalList,
  Motivational,
  Handouts,
  ActionGroup,
  Admin,
} from "modules";
import { MainLayout } from "layouts";
import { Navigate, useOutletContext } from "react-router-dom";
import GroupList from "modules/GroupList";
import { MotivationalAddEdit } from "modules/MotivationalAddEdit";

const PrivateLayout = lazy(() => import("../layouts/PrivateLayout"));
const PublicLayout = lazy(() => import("../layouts/PublicLayout"));
const CommonLayout = lazy(() => import("../layouts/CommonLayout"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout"));

interface IRouteAccess {
  children: any;
  redirectTo: string;
}

function PublicAccess({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("public");
  }, [authContext]);

  return authContext?.isAuth ? <Navigate to={redirectTo} /> : children;
}

function PrivateAccess({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("private");
  }, [authContext]);
  return authContext?.isAuth ? children : <Navigate to={redirectTo} />;
}

function CommonAccess({ children }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("common");
  }, [authContext]);

  return children;
}

const routes: ICustomRouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      {
        element: (
          <PrivateAccess redirectTo={APP_ROUTES.ISSUES}>
            <PrivateLayout />
          </PrivateAccess>
        ),
        children: [
          {
            path: APP_ROUTES.PROFILE + "/:userId",
            element: <ProfileForm />,
          },
          {
            path: APP_ROUTES.PROFILE,
            element: <ProfileForm />,
          },
          {
            path: APP_ROUTES.ADMIN,
            element: <Admin />,
          },
        ],
      },
      {
        element: (
          <CommonAccess redirectTo={APP_ROUTES.NOTFOUND}>
            <CommonLayout />
          </CommonAccess>
        ),
        children: [
          { index: true, element: <Dashboard /> },
          {
            path: APP_ROUTES.DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: APP_ROUTES.ISSUES,
            element: <IssueList />,
          },
          {
            path: APP_ROUTES.HANDOUTS,
            element: <Handouts />,
          },
          {
            path: APP_ROUTES.MOTIVATIONAL_LIST,
            element: <MotivationalList />,
          },

          {
            path: APP_ROUTES.MOTIVATIONAL_CREATE,
            element: <MotivationalAddEdit />,
          },
          {
            path: APP_ROUTES.MOTIVATIONAL_EDIT + "/:id",
            element: <MotivationalAddEdit />,
          },
          {
            path: APP_ROUTES.MOTIVATIONAL + "/:id",
            element: <Motivational />,
          },
          {
            path: APP_ROUTES.ISSUE + "/:id",
            element: <Issue />,
          },
          {
            path: APP_ROUTES.SOLUTION + "/:id",
            element: <Solution />,
          },
          {
            path: APP_ROUTES.ACTION_GROUP + "/:id",
            element: <ActionGroup />,
          },
          {
            path: APP_ROUTES.SOLUTION_LIST,
            element: <SolutionList />,
          },
          {
            path: APP_ROUTES.ACTION_GROUP_LIST,
            element: <GroupList />,
          },
          {
            path: APP_ROUTES.CONFIRM_EMAIL,
            element: <ConfirmEmail />,
          },
        ],
      },
      {
        element: (
          <CommonAccess redirectTo={APP_ROUTES.NOTFOUND}>
            <BlankLayout />
          </CommonAccess>
        ),
        children: [{ path: "*", element: <NotFound /> }],
      },
      {
        element: (
          <PublicAccess redirectTo={APP_ROUTES.ISSUES}>
            <PublicLayout />
          </PublicAccess>
        ),
        children: [
          {
            path: APP_ROUTES.SIGN_IN,
            element: <LoginForm />,
          },
          {
            path: APP_ROUTES.SIGN_UP,
            element: <SignUpForm />,
          },
          {
            path: APP_ROUTES.CHANGE_PASSWORD,
            element: <CreateNewPasswordForm />,
          },
          {
            path: APP_ROUTES.FORGET_PASSWORD,
            element: <ForgetPasswordForm />,
          },
          {
            path: APP_ROUTES.ERROR,
            element: <PublicLayoutError />,
          },
        ],
      },
    ],
  },
];

export default routes;
