import { useField } from "formik";
import { useCallback } from "react";
import { MultiValue, SingleValue } from "react-select";
import { ISelectOption } from "types";
import AutoCompleteSelectField from "components/AutoCompleteSelectField";
import { AutoCompleteSelectFieldType } from "components/AutoCompleteSelectField/AutoCompleteSelectField";

export default function FormikAdvanceSelect<T>({
  debounceTime = 100,
  returnValueOnly = false,
  ...rest
}: AutoCompleteSelectFieldType<T>) {
  const [field, meta, { setValue, setTouched }] = useField<
    MultiValue<ISelectOption> | SingleValue<ISelectOption>
  >(rest?.name || "");

  //@ts-ignore
  const error = !!meta.touched && meta.error;

  return (
    <AutoCompleteSelectField
      filterOption={() => {
        return true;
      }}
      onChange={useCallback(
        (value) => {
          if (returnValueOnly) {
            setValue(value.value);
          } else {
            setValue(value);
          }
        },
        [setValue, returnValueOnly]
      )}
      message={error}
      value={field.value}
      onBlur={useCallback(() => {
        setTouched(true);
      }, [setTouched])}
      {...rest}
    />
  );
}
