import { AxiosResponse } from "axios";
import { APP_ROUTES, ENDPOINTS } from "const";
import { FormikHelpers } from "formik";
import { useSolution, useUpdateAttachments } from "hooks";
import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { ISolution } from "types";
import { showErrorMessage, showSuccessMessage } from "utils";
import { IManagementProps } from "./SolutionForm.types";
import { createSolution, updateSolution } from "./SolutionForm.services";
import { SolutionInitial, SolutionPayload } from "./SolutionForm.constants";

export function useInitialState() {
  const location = useLocation();
  const isSolution = location.pathname.includes(APP_ROUTES.SOLUTION);
  const { id } = useParams<{ id: string }>();

  const { data: solutionData } = useSolution({
    id: id,
    enabled: !!id && isSolution,
  });

  return useMemo(() => {
    return new SolutionInitial(solutionData?.data);
  }, [solutionData?.data]);
}

interface ISolutionProps
  extends Partial<UseMutationOptions<AxiosResponse<ISolution>, any, any>> {
  solutionId?: string | undefined;
}

export function useCreateSolution({ ...rest }: ISolutionProps) {
  return useMutation<AxiosResponse<ISolution>, any, any>(
    (solution: ISolution) => createSolution({ solution }),
    {
      ...rest,
    }
  );
}

export function useUpdateSolution({
  solutionId = "",
  ...rest
}: ISolutionProps & {
  solutionId: string | undefined;
}) {
  return useMutation<AxiosResponse<ISolution>, any, any>(
    (solution: ISolution) => updateSolution({ solutionId, solution }),
    {
      ...rest,
    }
  );
}

export function useSubmitHandler(
  success: (id?: string) => void,
  manage: IManagementProps
) {
  const params = useParams<{ id: string }>();

  const { mutateAsync: mutateCreateSolutionAsync } = useCreateSolution({});
  const { mutateAsync: mutateUpdateSolutionAsync } = useUpdateSolution({
    solutionId: params.id,
  });

  const queryClient = useQueryClient();
  const location = useLocation();
  //on solution page - get issue id from backend
  const isSolution = location.pathname.includes(APP_ROUTES.SOLUTION);
  const { data: solutionData } = useSolution({
    id: params.id,
    enabled: !!params.id && isSolution,
  });
  const { mutateAsync: updateAttachments } = useUpdateAttachments();

  return useCallback(
    async (
      solution: SolutionInitial,
      formikHelpers: FormikHelpers<SolutionInitial>
    ) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
        queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
        queryClient.invalidateQueries([ENDPOINTS.MAP_LOCATIONS]);
      };

      const isSolution = location.pathname.includes(APP_ROUTES.SOLUTION);
      const isIssue = location.pathname.includes(APP_ROUTES.ISSUE);
      let saved: any;

      try {
        if (params.id) {
          const issueId = isSolution ? solutionData?.data.issue.id : params.id;
          const payload = new SolutionPayload(
            { ...solution, attachmentsType: "solutions" },
            issueId
          );
          console.log(payload, solution);

          if (manage.showSolutionFields && isSolution) {
            saved = await mutateUpdateSolutionAsync(payload.getSolution());
            showSuccessMessage("Solution is updated!");
          }
          if (manage.showSolutionFields && isIssue) {
            saved = await mutateCreateSolutionAsync(payload.getSolution());
            showSuccessMessage("Solution is created!");
          }
          if (saved?.data?.id) {
            await updateAttachments(payload.getAttachments(saved.data.id));
            showSuccessMessage("Attachments are updated!");
          }
          success(saved?.data.id);
          invalidate();
          formikHelpers.setSubmitting(false);
        }
      } catch (error: any) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(error.response.data);
        showErrorMessage("Error occurred! Check form!");
      }
    },
    [
      location.pathname,
      params.id,
      queryClient,
      solutionData?.data.issue.id,
      manage.showSolutionFields,
      success,
      mutateUpdateSolutionAsync,
      mutateCreateSolutionAsync,
      updateAttachments,
    ]
  );
}
