import classnames from "classnames";
import { memo } from "react";

function Line({ ratingValue }: { ratingValue: number }) {
  const rate = ratingValue ? Math.abs(ratingValue) : 0;
  const percentWidthBasedOnRating = (rate / 5) * 100;

  return (
    <span
      style={{
        width: `${percentWidthBasedOnRating}%`,
      }}
      className={classnames("rounded-md  h-full bg-[#212221E0]")}
    />
  );
}

export default memo(Line);
