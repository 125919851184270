/* This example requires Tailwind CSS v2.0+ */
import { memo } from "react";
import {} from "@headlessui/react";
import classnames from "classnames";

interface IProps {
  enabled: boolean;
  setEnabled: (flag: boolean) => void;
  label: React.ReactNode | string;
  disabled?: boolean;
  className?: string;
}

function ToggleButton({
  enabled,
  setEnabled,
  label,
  className,
  ...rest
}: IProps) {
  return (
    <div
      className={classnames(
        className,
        " flex flex-row items-center  justify-center"
      )}
    >
      <button
        onClick={() => {
          setEnabled(!enabled);
        }}
        className={classnames(
          {
            "bg-indigo-200 border border-gray-200": enabled,
            "bg-white border border-transparent": !enabled,
          },
          "relative py-5 inline-flex flex-shrink-0 h-7 lg:h-6  maxLg:min-w-max   border-gray-200 border-1 maxLg:outline maxLg:outline-[1px] maxLg:outline-gray-200 border-transparent rounded-md cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none  items-center"
        )}
        {...rest}
      >
        <span
          className={classnames(
            " flex font-bold min-w-max text-black  text-sm px-2  ",
            {
              "text-black": enabled,
              "text-black ": !enabled,
            }
          )}
        >
          {label}
        </span>
        {/* <span
          aria-hidden="true"
          className={classnames(
            { "translate-x-5": enabled, "translate-x-0": !enabled },
            "hidden pointer-events-none lg:inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        /> */}
      </button>
    </div>
  );
}

export default memo(ToggleButton);
