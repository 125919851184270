import { Outlet, useOutletContext } from "react-router-dom";
import { LayoutWrp } from "components";
import { IOutletAuth } from "types";
import { memo } from "react";

export function CommonLayout() {
  return (
    <LayoutWrp className="px-[5%]">
      <Outlet context={useOutletContext<IOutletAuth>()} />
    </LayoutWrp>
  );
}

export default memo(CommonLayout);
