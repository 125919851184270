import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import routes from "router/router.config";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

import { Loader, ErrorFallback } from "./components";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};
export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export function App() {
  const element = useRoutes(routes);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader />}>{element}</Suspense>
    </ErrorBoundary>
  );
}

export default App;
