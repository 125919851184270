import { useNavigate } from "react-router-dom";
import { Button } from "components";
import { APP_ROUTES } from "const";

import mail from "assets/images/mail.svg";

interface IAuthPanelProps {
  panelType:
    | "registered"
    | "confirmed"
    | "passwordReset"
    | "passwordConfirmed"
    | "error";
}

export function RequestStatusPanel({ panelType }: IAuthPanelProps) {
  const navigate = useNavigate();
  return (
    <div className="h-full w-full flex justify-center flex-row row-span-12 row-start-1 row-end-10  col-span-12 col-start-3 col-end-11 ">
      <div className="flex-1 flex flex-col  justify-center pt-14 pb-14 sm:pb-24  px-6 lg:flex-none min-w-[350px] lg:px-28 xl:px-28 bg-white rounded-lg">
        <div className="mx-auto h-full w-full max-w-sm lg:w-96">
          <div className="flex flex-col justify-center items-center ">
            <img alt="mail" src={mail} className="max-w-[6rem] max-h-[6rem]" />
            <h2 className="mt-12 text-3xl font-extrabold text-gray-900 text-center">
              {(panelType === "confirmed" ||
                panelType === "passwordConfirmed") && (
                <span className=" text-gradient">Success</span>
              )}
              {panelType !== "confirmed" &&
                panelType !== "passwordConfirmed" &&
                "Check your email"}
            </h2>
            <div className="mt-8 text-center">
              {panelType === "registered" && (
                <p>
                  Thank you for joining the WEattitude.org family. Please verify
                  your email address. We sent you an email.
                </p>
              )}
              {panelType === "confirmed" && (
                <p>
                  You have successfully verified your email address. Please sign
                  in below.
                </p>
              )}
              {panelType === "passwordReset" && (
                <p>
                  We have sent password recovery instructions to your email
                  address.
                </p>
              )}
              {panelType === "passwordConfirmed" && (
                <p>Your password has been successfuly reset.</p>
              )}
              {panelType === "error" && <p>Something wrong happened.</p>}
            </div>

            <div className="mt-14 w-full">
              <Button
                className="w-full justify-center"
                onClick={() => {
                  navigate(APP_ROUTES.SIGN_IN);
                }}
              >
                Sign In
              </Button>
            </div>
            {panelType === "registered" && (
              <p className="mt-4">
                If you cannot find it, remember to check your spam emails.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default RequestStatusPanel;
