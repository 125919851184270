import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { IActionGroupUsers, IUser } from "types";

interface IMembersPopupProps {
  membersList: Partial<IActionGroupUsers<Partial<IUser>>>[];
  membersCount: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MembersPopup({
  membersList,
  membersCount,
  setShow,
}: IMembersPopupProps) {
  return (
    <div className="absolute flex flex-col min-w-[200px] min-h-[200px] top-[100%] z-[100] w-full h-full bg-white border shadow-md rounded-lg ">
      <div className="flex flex-row justify-between px-2 pt-2">
        <div className="flex flex-row items-center gap-2">
          <p className="font-bold text-black">{`Members`}</p>
          <span className="h-1 w-1 rounded-full bg-gray-400" />
          <p>{`${membersCount}`}</p>
        </div>
        <div>
          <XIcon
            onTouchStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(false);
            }}
            onClick={() => {
              setShow(false);
            }}
            className="h-6 w-6 shrink-0 cursor-pointer"
            aria-hidden="true"
          />
        </div>
      </div>
      <div
        className={classnames("border-b border-[#D1D5DB] w-full h-[1px] py-1 ")}
      />
      <div className=" py-1 overflow-auto">
        {membersList.length > 0 &&
          membersList.map((member) => {
            return (
              <div className="flex flex-col py-1">
                <div className="flex flex-row items-center justify-left gap-3 px-2">
                  <div className="rounded-full w-[24px] h-[24px] outline outline-[1px] outline-gray-200">
                    <img
                      className="  h-full w-full rounded-full object-cover"
                      alt="avatar"
                      src={member?.user?.avatar}
                    />
                  </div>
                  <p>{`${member?.user?.firstName} ${member?.user?.lastName}`}</p>
                </div>
                <div
                  className={classnames(
                    "border-b border-[#E5E7EB] w-full h-[1px] py-1 "
                  )}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
