import classnames from "classnames";
import { AdvancedSelectField, IAdvanceFieldProps } from "components";
import { useField } from "formik";
import { useDebouncedHandler } from "hooks";
import { useCallback, useMemo } from "react";
import {
  MultiValue,
  SingleValue,
  Options,
  OptionProps,
  components,
} from "react-select";
import { ISelectOption } from "types";

export type CommonSelectType = Omit<IAdvanceFieldProps, "message"> & {
  name: string;
  options: Options<
    ISelectOption<string | undefined | null, string | undefined | null>
  >;
  message?: string;
};

export type SearchableSelectType = CommonSelectType & {
  isSearchable: true;
  makeSearch: (value: string) => void;
  isLoading?: boolean;
};

export function Option(props: OptionProps<any>) {
  return (
    <div
      className={classnames("py-1 cursor-pointer", {
        "selected-option-gradient": props.isSelected,
        " hovered-selected-option-gradient px-6": !props.isSelected,
      })}
    >
      <components.Option {...props}>
        <label className=" font-light text-sm cursor-pointer">
          {props.label}
        </label>
      </components.Option>
    </div>
  );
}

type PropsType<T> = (T extends { isSearchable: true }
  ? SearchableSelectType
  : CommonSelectType) & { debounceTime?: number; returnValueOnly?: boolean };

export function FormikAdvanceSelect<T>({
  debounceTime = 100,
  returnValueOnly = false,
  ...rest
}: PropsType<T>) {
  const [field, meta, { setValue, setTouched }] = useField<
    MultiValue<ISelectOption> | SingleValue<ISelectOption>
  >(rest.name);

  const error = (!!meta.touched && meta.error) || "";

  rest.onInputChange = useDebouncedHandler((value: string) => {
    if (rest?.isSearchable) {
      (rest as SearchableSelectType).makeSearch(value);
    }
  }, debounceTime);

  const classNames = useMemo(() => {
    let classes: PropsType<T>["classNames"] = {};
    if (rest.classNames) {
      classes = { ...rest.classNames };
    }
    if (error) {
      //@ts-ignore
      classes = {
        ...classes,
        message: classes?.message
          ? classes?.message + " !text-red-500"
          : " !text-red-500",
      };
    }
    return classes;
  }, [error, rest.classNames]);

  return (
    <AdvancedSelectField
      onChange={useCallback(
        (value) => {
          if (returnValueOnly) {
            setValue(value.value);
          } else {
            setValue(value);
          }
        },
        [setValue, returnValueOnly]
      )}
      components={{ Option }}
      message={error}
      value={field.value}
      onBlur={useCallback(() => {
        setTouched(true);
      }, [setTouched])}
      {...rest}
      classNames={classNames}
      isError={!!error}
    />
  );
}
