import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useCommentConfig, useMe } from "hooks";
import { FastCommentsCommentWidget } from "fastcomments-react";
import { useLocation, useParams } from "react-router-dom";
import { ActionGroupUserMembership, ActionGroupUserStatus } from "types";

interface IProps {
  isPrivate?: boolean;
  profileOnly?: boolean;
  membership?: undefined | ActionGroupUserMembership;
  status?: undefined | ActionGroupUserStatus;
  ruleSet?: "Solution" | "ActionGroup" | "Issue" | "Motivational" | "Default";
}

function CommentsCard({
  isPrivate = false,

  status = undefined,
  ruleSet = "Default",
}: IProps) {
  const mountRef = useRef(0);
  useEffect(() => {
    if (mountRef.current < 1) {
      mountRef.current = mountRef.current + 1;
    }
  }, []);

  const params = useParams();
  const urlId = useMemo(() => {
    return params.id;
  }, [params.id]);
  const me = useMe();
  const meId = useMemo(() => {
    return me?.data?.data.id;
  }, [me?.data?.data.id]);

  const config = useCommentConfig({ enabled: mountRef.current === 0 });
  const memoizedConfig = useMemo(() => {
    return config?.data?.data;
  }, [config]);

  const ref = useRef();
  const location = useLocation();
  const memoizedLocation = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const privateCustomCss = `
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600&display=swap');
    * {
        font-family: Manrope;
        color: #606060;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
    }
    .top-area {
      display: ${meId ? "block" : "none"}
    }
    .footer {
      display: none
    }
    .downvote, .upvote {
      pointer-events: ${meId ? "default" : "none"}
    }
    .comment-toolbar-reply {
      display: ${meId ? "block" : "none"}
    }
   
    .menu {
      display: ${meId ? "block" : "none !important"}
    }
    .avatar-wrapper {
      pointer-events: ${meId ? "default" : "none"};
      margin-top: 15px
    }
    .icon.up {
      background: url(https://app.weattitude.org/like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .icon.up.active, .icon.up:hover {
      background: url(https://app.weattitude.org/active-like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .downvote {
      display:none;
    }
    .divider {
      display:none;
    }
    .comment-bottom-toolbar {
      display:flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
    .comment-toolbar-reply {
      position: relative !important;
    }
    .comment .avatar-wrapper {
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
    .children .comment .avatar-wrapper {
      width: 35px;
      height: 35px;
    }
    .comment > .inner > .comment-bottom {
      margin-top: 0px;
    }
    #comment-area {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    .comment > .inner {
      padding: 0
    }
    .select-dir-wrapper {
      border-bottom: 0;
    }
    .menu.empty, .menu.dropdown, .icon.bubble {
      display: none !important;
    }
    .comment > .inner > .comment-content .comment-text {
      color: #606060;
      line-height: 25.5px;
      font-weight: 400;
    }         
    b {
      color: black
    }
    .comment {
      margin-top: 10px;
    }
    .comment > .inner > .comment-bottom .reply-form-wrapper {
      padding: 0
    }
    .comment > .toggle-replies {
      margin-top: 5px;
    }
    .comment-reply textarea {
      border-radius: 7px;
      border-bottom: 1px solid #bfbfbf;
    }
    .comment-reply.root {
      padding: 15px 0 0 0;
    }
    .horizontal-border {
      display: none !important;
    }
    .comment .comment-reply textarea {
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
    }
    .fast-comments-reply, .comment .fast-comments-reply, .edit-cancel, .edit-save {
      border: 0px; 
      border-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      transition-property: opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background: linear-gradient( 89.55deg, #4d3dd5 -13.7%, #7a6ff0 6.72%, #a39dfd 21.77%, #a4a5fb 42.04%, #59cbe4 74.84%, rgba(29, 127, 243, 0.886708) 93.78%, rgba(36, 77, 221, 0.79) 109.94% );
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      background-size: 400% 400%;
      animation: gradient 10s ease infinite;
      font-weight: 600;

    }
    .fast-comments-reply:before, .comment .fast-comments-reply:before, .edit-cancel:before, .edit-save:before {
      content: "Submit Reply";
      display: flex;
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .fast-comments-reply:hover:before, .edit-cancel:hover:before, .edit-save:hover:before  {
      background-color: #11ffee00;
    }
    .notification-bell {
      display: none !important;
    }
    .edit-cancel:before {
      content: "Cancel"
    }
    .edit-save:before {
      content: "Save"
    }
    .edit-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    .comment-edit textarea {
      border-radius: 11px
    }
    .pagination {
      margin-top: 6px;
      padding-bottom: 16px;
    }
`;

  const publicCustomCss = `
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600&display=swap');
    * {
        font-family: Manrope;
        color: #606060;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
    }
    .top-area {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none"
          : meId
          ? "block"
          : "none"
      };
    }
    .footer {
      display: none
    }
    .downvote, .upvote {
      pointer-events: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "default"
            : "none"
          : meId
          ? "default"
          : "none"
      };
    }
    .comment-toolbar-reply {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none"
          : meId
          ? "block"
          : "none"
      };
    }
    .comment-input{
      display: none;
    }
    .reply-button-wrapper{
      display: none;
    }
    .menu {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "block"
            : "none !important"
          : meId
          ? "block"
          : "none !important"
      };
    }
    .avatar-wrapper {
      pointer-events: ${meId ? "default" : "none"};
      margin-top: 15px
    }
    .icon.up {
      background: url(https://app.weattitude.org/like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .icon.up.active, .icon.up:hover {
      background: url(https://app.weattitude.org/active-like.svg) no-repeat center;
      background-size: 30px;
      width: 30px;
    }
    .downvote {
      display:none;
    }
    .divider {
      display:none;
    }
    .comment-bottom-toolbar {
      display: ${
        ruleSet === "ActionGroup"
          ? meId && status !== "pending" && status !== undefined
            ? "flex"
            : "none"
          : meId
          ? "flex"
          : "none"
      };
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
    .comment-toolbar-reply {
      position: relative !important;
    }
    .comment .avatar-wrapper:not(.fast-comments-profile *) {
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
    .comment-reply-top-bar:not(.fast-comments-profile *) {
      position: relative;
      min-height: 33px !important;
      margin: 0 !important;
       
  }
    .children .comment .avatar-wrapper {
      width: 35px;
      height: 35px;
    }
    .comment > .inner > .comment-bottom {
      margin-top: 0px;
    }
    .comment-reply .comment-reply-top-bar .logged-in-info {
      display: inline-block;
      width: calc(100% - 60px);
      min-width: 200px !important;
  }
  .avatar:not(.fast-comments-profile *) {
    display: inline-block;
    height: 33px !important;
    vertical-align: middle;
    margin-right: 5px;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 3px 3px 3px 0 rgb(0 0 0 / 7%);
    font-size: 0;
    width: 120px !important;
    background-color: white;
}
.avatar img:not(.fast-comments-profile *){
   width: 100% !important;
   height: 100% !important;
   
}
    .open-profile:not(.fast-comments-profile *){
      width: 100%;
    }
    .username:not(.fast-comments-profile *){
      display: none !important;
    }
    .comment > .inner {
      padding: 0
    }
    .select-dir-wrapper {
      border-bottom: 0;
    }
    .menu.empty, .menu.dropdown, .icon.bubble {
      display: none !important;
    }
    .comment > .inner > .comment-content .comment-text {
      color: #606060;
      line-height: 25.5px;
      font-weight: 400;
    }         
    b {
      color: black
    }
    .comment {
      margin-top: 10px;
    }
    .comment > .inner > .comment-bottom .reply-form-wrapper {
      padding: 0
    }
    .comment > .toggle-replies {
      margin-top: 5px;
    }
    .comment-reply textarea {
      border-radius: 7px;
      border-bottom: 1px solid #bfbfbf;
    }
    .comment-reply.root {
      padding: 0;
      margin: 0;
    }
    .horizontal-border {
      display: none !important;
    }
    .comment .comment-reply textarea {
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
    }
    .fast-comments-reply, .comment .fast-comments-reply, .edit-cancel, .edit-save {
      border: 0px; 
      border-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      transition-property: opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background: linear-gradient( 89.55deg, #4d3dd5 -13.7%, #7a6ff0 6.72%, #a39dfd 21.77%, #a4a5fb 42.04%, #59cbe4 74.84%, rgba(29, 127, 243, 0.886708) 93.78%, rgba(36, 77, 221, 0.79) 109.94% );
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      background-size: 400% 400%;
      animation: gradient 10s ease infinite;
      font-weight: 600;

    }
    .fast-comments-reply:before, .comment .fast-comments-reply:before, .edit-cancel:before, .edit-save:before {
      content: "Submit Reply";
      display: flex;
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .fast-comments-reply:hover:before, .edit-cancel:hover:before, .edit-save:hover:before  {
      background-color: #11ffee00;
    }
    .notification-bell {
      display: none !important;
    }
    .edit-cancel:before {
      content: "Cancel"
    }
    .edit-save:before {
      content: "Save"
    }
    .edit-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    .comment-edit textarea {
      border-radius: 11px
    }
    .pagination {
      margin-top: 6px;
      padding-bottom: 16px;
    }
    .fast-comments-profile {
      background-color: white !important;
  }
`;
  return (
    <div
      className="relative flex flex-1 flex-col max-w-[200px] min-w-[120px] max-h-[33px] maxSm:pt-4 mt-1"
      //@ts-ignore
      ref={ref}
    >
      <p className="absolute top-[-10px] sm:top-[-25px] left-1 text-sm">
        My comments
      </p>

      <FastCommentsCommentWidget
        allowAnon={false}
        allowAnonVotes={false}
        disableEmailInputs
        disableImageRedirect
        disableUnverifiedLabel
        disableSuccessMessage
        disableMentions
        disableToolbar
        tenantId={process.env.REACT_APP_FAST_COMMENT_TENANT_ID + ""}
        sso={memoizedConfig}
        collapseReplies
        disableLiveCommenting
        onRender={useCallback(() => {}, [])}
        onReplySuccess={useCallback(() => {
          return;
        }, [])}
        onVoteSuccess={useCallback(() => {
          return;
        }, [])}
        locale="en"
        url={process.env.REACT_APP_HOST + "" + memoizedLocation}
        urlId={urlId}
        //noStyles
        //@ts-ignore
        customCSS={isPrivate ? privateCustomCss : publicCustomCss}
      />
    </div>
  );
}

export default memo(CommentsCard);
