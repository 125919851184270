/* eslint-disable no-useless-escape */

import * as yup from "yup";

import { FIELD_NAMES } from "./LoginForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.EMAIL]: yup.string().email().required("Email is required!"),
    [FIELD_NAMES.PASSWORD]: yup.string().required("Password is required!"),
  });
}
