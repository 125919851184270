import classnames from "classnames";
import { memo } from "react";
import { twMerge } from "tailwind-merge";

interface ILabelProps {
  name: string;
  className?: string;
  required?: boolean;
  onClick?: () => void;
  htmlFor?: string;
}

function Label({ name, className, required, ...rest }: ILabelProps) {
  return (
    <label
      className={twMerge(classnames(className, "text-#606060 text-[0.875rem]"))}
      {...rest}
    >
      {name}
      {required && <span className="text-green-500 text-sm ml-1">*</span>}
    </label>
  );
}

export default memo(Label);
