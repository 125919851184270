/* eslint-disable no-useless-escape */

import * as yup from "yup";
import { IManagementProps } from "./IssueForm.types";

import { ISSUE_FIELD_NAMES } from "./IssueForm.constants";
import { attachmentValidation } from "modules/Attachments/attachments.validation";

const MIN_NAME_LENGTH = 3;
export const MAX_TEXT_LENGTH = 150;
export const MAX_TITLE_TEXT_LENGTH = 120;
const range = [0, 1, 2, 3, 4, 5];

const issueFieldSchema = {
  [ISSUE_FIELD_NAMES.TITLE]: yup
    .string()
    .trim()
    .max(MAX_TITLE_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min title length is ${MIN_NAME_LENGTH}`)
    .required("Title is a required field"),
  [ISSUE_FIELD_NAMES.DETAILS]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min details length is ${MIN_NAME_LENGTH}`)
    .required("Details is a required field"),
  [ISSUE_FIELD_NAMES.LOCATION]: yup
    .mixed()
    .required("Location is a required field"),
  [ISSUE_FIELD_NAMES.ORIGIN]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min origin length is ${MIN_NAME_LENGTH}`)
    .required("Origin is a required field"),
  [ISSUE_FIELD_NAMES.IMPORTANCE]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min details length is ${MIN_NAME_LENGTH}`)
    .required("Importance is a required field"),
  [ISSUE_FIELD_NAMES.TAGS]: yup
    .array()
    .max(5, "You can add up to 5 tags")
    .nullable(),
  ...attachmentValidation,
};

export default function formValidationSchema(
  manage: IManagementProps,
  issueRateCategories: string[] | undefined
) {
  const schema = {};
  if (manage.showIssueFields) {
    Object.assign(schema, issueFieldSchema);
  }
  if (manage.showRatings) {
    issueRateCategories?.forEach((category) => {
      schema[category] = yup.number().oneOf(range);
    });
    const validator = {
      validator: yup.bool().when(issueRateCategories || [], {
        is: (...rest) => {
          return rest.find((i) => !!i);
        },
        then: yup.bool(),
        otherwise: yup.bool().required("Please rate at least one rating"),
      }),
    };
    Object.assign(schema, validator);
  }
  return yup.object().shape(schema);
}
