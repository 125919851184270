import {
  IInfiniteQueryActionGroupUsersProps,
  useInfinityActionGroupUsers,
} from "hooks";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions } from "react-query";
import { IAxiosResponseWithPagination } from "types/requests";

import { useEffect, useState } from "react";
import { FullActionGroupType, IActionGroupUsers, IUser } from "types";

import { useMutation, UseMutationOptions } from "react-query";
import { AxiosResponse } from "axios";
import {
  createActionGroupUser,
  deleteActionGroupUser,
  updateActionGroupUser,
} from "services";

interface IActionGroupUserProps
  extends Partial<
    UseMutationOptions<
      AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
      any,
      any
    >
  > {
  actionGroupUserId?: string | undefined;
}
interface IActionGroupUsersProps
  extends Partial<
    UseMutationOptions<
      AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
      any,
      any
    >
  > {}

export function useUpdateActionGroupUser({
  ...rest
}: IActionGroupUserProps = {}) {
  return useMutation<
    AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
    any,
    any
  >(
    (actionUser: IActionGroupUsers<IUser, FullActionGroupType>) =>
      updateActionGroupUser({ actionUser }),
    {
      ...rest,
    }
  );
}

export function useCreateActionGroupUser({ ...rest }: IActionGroupUsersProps) {
  return useMutation<
    AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
    any,
    any
  >(
    (actionUser: IActionGroupUsers<IUser, FullActionGroupType>) =>
      createActionGroupUser({ actionUser }),
    {
      ...rest,
    }
  );
}

export function useDeleteActionGroupUser({ ...rest }: IActionGroupUsersProps) {
  return useMutation<
    AxiosResponse<IActionGroupUsers<IUser, FullActionGroupType>>,
    any,
    any
  >(
    (actionUserId: string) =>
      deleteActionGroupUser({ actionUser: actionUserId }),
    {
      ...rest,
    }
  );
}

interface IInfiniteQueryActionGroupsProps
  extends Partial<
      UseInfiniteQueryOptions<
        IAxiosResponseWithPagination<IActionGroupUsers<IUser>>
      >
    >,
    IInfiniteQueryActionGroupUsersProps {}

export function useActionGroupUsersList(
  params: IInfiniteQueryActionGroupsProps
) {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();

  const { data, fetchNextPage, ...rest } = useInfinityActionGroupUsers({
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },

    keepPreviousData: true,
    suspense: !isMount,
    refetchOnWindowFocus: false,
    ...params,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}
