import { useCallback, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import prependHttp from "prepend-http";
import classnames from "classnames";

import {
  IAsset,
  IAttachment,
  ImageType,
  isSavedAttachment,
  isFileType,
  isImageType,
} from "types";
import { FileIcon, defaultStyles } from "react-file-icon";
import JsFileDownloader from "js-file-downloader";

import { Tooltip } from "react-tooltip";
import { useDownloadCustomTextAttachment } from "hooks";
import { twMerge } from "tailwind-merge";
import { Loader } from "components";
import { ScoreTooltip } from "components/ScoreTooltip/ScoreTooltip";

function CloseIcon({ onClick }: { onClick?: () => void }) {
  return (
    <div
      className="  absolute right-0 top-0 bg-gray-200 hover:bg-gray-100 rounded-full items-center flex justify-center cursor-pointer p-1"
      onClick={onClick}
    >
      <XIcon className="h-4 w-4" aria-hidden="true" />
    </div>
  );
}

export function AttachmentFiletItem({
  attachment,
  onDelete,
  error,
  readOnly = false,
}: {
  readOnly?: boolean;
  attachment: File | ImageType | Partial<IAttachment<IAsset, string>>;
  onDelete?: () => void;
  error: string | undefined;
}) {
  const isFile = isFileType(attachment);
  const isImage = isImageType(attachment); //is file image
  const isSaved = isSavedAttachment(attachment); //is saved asset
  const isSavedFile = isSaved && attachment.type === "file";
  let extension = "";
  if (isFile) {
    extension = attachment?.name?.split(".")?.pop() || "";
  }
  if (isSaved) {
    extension = attachment?.asset?.path?.split(".")?.pop() || "";
  }

  let title = "";
  if (isFile) {
    title = attachment.name;
  }
  if (isSaved && isSavedFile) {
    title = attachment?.asset?.originalName || "";
  }
  const showFileIcon =
    (isFile && !isImage) || (isSaved && attachment.asset.type === "document");
  const showImage = isImage || (isSaved && attachment.asset.type === "image");

  let alt = "";
  if (isSaved && isSavedFile) {
    alt = attachment?.asset?.path || "";
  }
  if (isFile) {
    alt = attachment?.name || "";
  }

  let src = "";
  if (isSaved && isSavedFile) {
    src = attachment?.asset?.url || "";
  }
  if (isImage) {
    src = attachment?.dataUrl || "";
  }
  const [showClose, setShowClose] = useState(false);
  const [progress, setProgress] = useState(0);

  const progressHandler = useCallback((event: ProgressEvent): undefined => {
    if (!event.lengthComputable) return; // guard
    var downloadingPercentage = Math.floor((event.loaded / event.total) * 100);
    setProgress(downloadingPercentage);
  }, []);

  let url = "";
  if (isSavedFile) {
    url = attachment?.asset?.url || "";
  }

  const onDownload = useCallback(async () => {
    if (url) {
      await new JsFileDownloader({
        url: url || "",
        process: progressHandler,
      });
      setProgress(0);
    }
  }, [url, progressHandler]);
  const ref = useRef(null);
  return (
    <div
      className={classnames(
        " flex  shrink-0 relative gap-2 flex-gap-2 flex-col justify-between ",
        { "cursor-pointer": readOnly }
      )}
      onClick={() => {
        if (readOnly) {
          onDownload();
        }
      }}
      ref={ref}
    >
      {!readOnly && ((showClose && showImage) || !showImage) && (
        <CloseIcon onClick={onDelete} />
      )}
      {showImage && (
        <img
          className=" object-cover rounded-lg"
          alt={alt}
          src={src}
          onLoad={() => {
            setShowClose(true);
            if (isImage && attachment?.dataUrl) {
              URL.revokeObjectURL(attachment?.dataUrl);
            }
          }}
          onClick={() => {
            if (readOnly) {
              window.open(src, "_blank");
            }
          }}
        />
      )}
      {showFileIcon && (
        <div
        //data-tooltip-id={isSaved ? "title " + attachment?.id : ""}
        >
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </div>
      )}
      <div className="flex  line-clamp-1 w-full text-xs flex-col">
        {isSaved && !readOnly && (
          <div className="flex w-full">
            <span
              onClick={onDownload}
              className="text-center cursor-pointer w-full text-blue-400 hover:text-blue-300"
            >
              {progress ? progress + "%" : "Download"}
            </span>
          </div>
        )}
        <div
          className="w-full text-center flex justify-center line-clamp-1"
          data-tooltip-id={isSaved ? "title " + attachment?.id : ""}
        >
          {title}
        </div>
        {!!error && (
          <div
            className="text-red-400 text-sm cursor-pointer"
            data-tooltip-id="attachments-error"
          >
            {error}
          </div>
        )}
        {!!error && (
          <Tooltip
            content={error}
            id="attachments-error"
            place="top"
            className="z-10"
            style={{ backgroundColor: "rgb(248 113 113)" }}
          />
        )}
        {isSaved && (
          <ScoreTooltip
            content={"File name: " + title}
            id={"title " + attachment.id}
            place="top"
          />
        )}
      </div>
      {!!progress && readOnly && (
        <Loader
          type="rings"
          globalLoader={false}
          portalNode={ref.current || undefined}
          size="tiny"
          className="absolute"
        />
      )}
    </div>
  );
}

export function AttachmentLinkItem({
  onDelete,
  error,
  value,
  readOnly = false,
  className,
}: {
  value: Partial<IAttachment<any>> | undefined;
  onDelete?: () => void;
  error?: string | undefined;
  readOnly: boolean;
  className?: string;
}) {
  let href = "";
  if (value?.data) {
    href = prependHttp(value.data);
  }
  let name = "";
  if (value?.title) {
    name = value.title;
  }

  return (
    <div
      className={classnames("flex gap-2", className, {
        "flex-col": !readOnly,
      })}
    >
      <div className="flex shrink-0 relative gap-2 flex-gap-2 flex-col justify-between">
        <a
          className={classnames(
            "overflow-ellipsis text-blue-400 hover:text-blue-300",
            {
              "line-clamp-1": !readOnly,
            }
          )}
          href={href}
          target="blank"
        >
          {name || href || ""}
        </a>
        {!readOnly && <CloseIcon onClick={onDelete} />}
      </div>
      {error && <div className="text-red-400 text-sm">{error}</div>}
    </div>
  );
}

export function AttachmentTextItem({
  onEdit,
  onDelete,
  error,
  attachment,
  readOnly = false,
  classNames,
}: {
  attachment: Partial<IAttachment<any, any>> | undefined;
  onDelete?: () => void;
  onEdit?: () => void;
  error: string | undefined;
  readOnly: boolean;
  classNames?: {
    fileIconWrp?: string;
  };
  className?: string;
}) {
  const { onDownload, isLoading } = useDownloadCustomTextAttachment(
    attachment?.id
  );
  const ref = useRef(null);
  return (
    <div
      className={twMerge(
        classnames("flex flex-col  gap-2 justify-between relative", {
          "cursor-pointer": readOnly,
        })
      )}
      onClick={() => {
        if (readOnly) {
          onDownload();
        }
      }}
    >
      <div
        className={twMerge(
          classnames(
            " flex  shrink-0 relative gap-2 flex-gap-2 flex-col justify-between",
            classNames?.fileIconWrp
          )
        )}
        //data-tooltip-id={attachment?.id ? "title " + attachment?.id : ""}
      >
        <FileIcon extension="custom" {...defaultStyles["txt"]} />
        {!readOnly && <CloseIcon onClick={onDelete} />}
      </div>
      <div className="flex  w-full text-xs flex-col text-center">
        <div className="flex">
          {!readOnly && (
            <span
              onClick={onEdit}
              className="text-center cursor-pointer w-full text-blue-400 hover:text-blue-300 line-clamp-1 "
            >
              Edit
            </span>
          )}
          {attachment?.id && !readOnly && (
            <span
              ref={ref}
              onClick={onDownload}
              data-tooltip-id="save-button"
              className="text-center cursor-pointer w-full text-blue-400 hover:text-blue-300 line-clamp-1 relative flex items-center justify-center "
            >
              {isLoading ? "...loading" : "Download"}
            </span>
          )}
        </div>
        <Tooltip
          content="Will download the saved version"
          id="save-button"
          place="top"
          className="z-10"
        />
        <div
          className="line-clamp-1  "
          data-tooltip-id={attachment?.id ? "title " + attachment?.id : ""}
        >
          {attachment?.title}
        </div>
      </div>
      {error && <div className="text-red-400 text-sm">{error}</div>}
      {isLoading && readOnly && (
        <Loader
          type="rings"
          globalLoader={false}
          portalNode={ref.current || undefined}
          size="tiny"
          className="absolute"
        />
      )}
      {attachment?.id && (
        <ScoreTooltip
          content={"Title: " + attachment?.title}
          id={"title " + attachment.id}
          place="top"
        />
      )}
    </div>
  );
}
