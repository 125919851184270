/* eslint-disable no-useless-escape */

import * as yup from "yup";

import { FIELD_NAMES } from "./GroupForm.constants";
import {
  checkFileDimension,
  checkFileSize,
  checkFileType,
  mapTestRules,
} from "utils/validation";
import { attachmentValidation } from "modules/Attachments/attachments.validation";

export const MAX_TITLE_LENGTH = 120;
const MIN_NAME_LENGTH = 3;
export const MAX_TEXT_LENGTH = 150;

const MAX_FILE_SIZE = 5000000; // file size in kilobyte
const MAX_DIMENSION = 1024;
export const ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".PNG",
  ".JPG",
  ".gif",
  "image/png",
];

const schema = mapTestRules([
  {
    cb: checkFileDimension(MAX_DIMENSION),
    id: "fileDimension",
    message: `Error: That’s a very nice photo, but it’s a bit too big. Click Edit to adjust the size or upload another photo smaller than ${MAX_DIMENSION} px`,
  },
  {
    cb: checkFileSize(MAX_FILE_SIZE),
    id: "fileSize",
    message:
      "Error: That’s a very nice photo, but it’s a bit too big. Click Edit to adjust the size or upload another photo smaller than 5 MB",
  },
  {
    cb: checkFileType(ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES),
    id: "fileType",
    message: "Profile photo should be GIF, JPG or PNG",
  },
]);

const groupSchema = {
  [FIELD_NAMES.LOGO]: schema,
  [FIELD_NAMES.TITLE]: yup
    .string()
    .trim()
    .max(MAX_TITLE_LENGTH, `max title length is ${MAX_TITLE_LENGTH}`)
    .min(MIN_NAME_LENGTH, `min title length is ${MIN_NAME_LENGTH}`)
    .required("Title is a required field"),
  [FIELD_NAMES.DESCRIPTION]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min details length is ${MIN_NAME_LENGTH}`)
    .required("Description is a required field"),
  [FIELD_NAMES.STAGE]: yup.mixed(),
  [FIELD_NAMES.SCHEDULE]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min origin length is ${MIN_NAME_LENGTH}`)
    .required("Schedule is a required field"),
  [FIELD_NAMES.CONTACT_URL]: yup.string().trim().max(MAX_TEXT_LENGTH, `max`),
  [FIELD_NAMES.CONTACT]: yup
    .string()
    .trim()
    .max(MAX_TEXT_LENGTH, `max`)
    .min(MIN_NAME_LENGTH, `min details length is ${MIN_NAME_LENGTH}`)
    .required("Contact is a required field"),
  [FIELD_NAMES.IS_PRIVATE]: yup.object().shape({
    value: yup.boolean(),
    label: yup.string(),
  }),
  [FIELD_NAMES.TAGS]: yup.array().max(5, "You can add up to 5 tags").nullable(),
  ...attachmentValidation,
};

const skillsSchema = {
  [FIELD_NAMES.SKILLS]: yup
    .array(
      yup.object().shape({
        skill: yup.mixed().required("This field is required"),
        timeRequired: yup.string().required("This field is required"),
      })
    )
    .max(7, "Please add to up to 7 skills")
    .nullable(),
};

export default function formValidationSchema() {
  let schema = { ...groupSchema, ...skillsSchema };

  return yup.object().shape(schema);
}
