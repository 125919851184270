import { memo, useState } from "react";
import { Card, PromptModal, StarRatingItem } from "components";
import { Button } from "components/Button";
import { ICategories, IOutletAuth } from "types";

import { APP_ROUTES } from "const";
import ChevronDown from "assets/images/chevron-down.svg";
import classnames from "classnames";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getRating } from "utils";
import {
  useAverageIssueRatings,
  useIssue,
  useIssueRatingCategories,
} from "hooks";

interface IProps extends Partial<ICategories<number>> {
  issue: string | undefined;
  rating?: number;
  onEdit: () => void;
  allowEdit: boolean;
  isSoftDeleted?: boolean;
}

interface IRatingBlockProps {
  rating: number;
  showRatingTooltip: boolean;
}

function RatingBlock({ rating, showRatingTooltip }: IRatingBlockProps) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (showRatingTooltip) {
          setOpenPopup(true);
        }
      }}
      onMouseOut={() => {
        setOpenPopup(false);
      }}
      className={classnames(
        " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start  px-2 text-center  ",
        {
          " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
            openPopup,
        }
      )}
    >
      {getRating(rating) || 0}
      <RatingStar height={8} width={8} fill="black" />
      {showRatingTooltip && openPopup && (
        <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[150%] text-sm font-normal after:tooltiptoparrow">
          <div className="flex flex-col relative text-xs bg-white p-2">
            <p>How relevant is this issue? </p>
          </div>
          <span className="absolute border-[8px] top-[-17px] right-[9%] xx:right-[25%] lg:right-[31%] border-t-transparent border-b-[#8159D7] border-r-transparent border-l-transparent tooltiptoparrow" />
        </div>
      )}
    </span>
  );
}

function ImpactRankingCard({
  issue,
  onEdit,
  allowEdit,
  isSoftDeleted,
}: IProps) {
  const [ratingCollapsed, setRatingCollapsed] = useState(false);
  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();
  const { data: categories } = useIssueRatingCategories();
  const { data: averageIssueRatings } = useAverageIssueRatings({ id: issue });
  const { data: issueData } = useIssue({ id: issue });
  return (
    <>
      <Card
        shouldHover={false}
        collapse={ratingCollapsed}
        className={
          isSoftDeleted
            ? "bg-gray-100 pointer-events-none select-none"
            : "bg-white"
        }
        header={
          <div
            // onTouchStart={() => {
            //   setRatingCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1"
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
              <img
                onClick={() => {
                  setRatingCollapsed((val) => !val);
                }}
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": ratingCollapsed,
                })}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">Issue rating</span>
            </div>
            {allowEdit && (
              <Button
                className=" three-sixth-step rate-button-to-click "
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={onEdit}
                disabled={!allowEdit}
              >
                Rate
              </Button>
            )}
            {!authContext.isAuth && (
              <Button
                className=" three-sixth-step rate-button-to-click "
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowGoSignInModal(true);
                }}
                onClick={() => {
                  setShowGoSignInModal(true);
                }}
              >
                Rate
              </Button>
            )}
          </div>
        }
        classNames={{
          header: " px-6 sm:px-16",
          content: " px-6 sm:px-16 flex-1 pb-6",
          footer: " px-6 sm:px-16 h-[60px]",
        }}
        content={
          <div className="grid sm:grid-cols-2 gap-x-5 shrink gap-y-2">
            {categories?.data?.map((category) => {
              let ratingValue = 0;

              if (typeof averageIssueRatings?.data === "object") {
                ratingValue =
                  Object.entries(averageIssueRatings?.data).find(
                    ([key, value]) => key === category.id
                  )?.[1] || 0;
              }
              const ratingValueWithDecimal = ratingValue.toFixed(1);

              return (
                <StarRatingItem
                  ratingStyle="line"
                  title={category.title}
                  ratingValue={+ratingValueWithDecimal}
                  displayOnly
                  description={category.description}
                  category={category.category}
                />
              );
            })}
          </div>
        }
        footer={
          <div className="flex gap-4 flex-1">
            <span className=" text-sm font-semibold">Issue Rating</span>
            <div className="flex gap-2">
              {!!issueData?.data.rating && (
                <RatingBlock
                  rating={issueData?.data.rating}
                  showRatingTooltip
                />
              )}

              {!!issueData?.data.numberOfRatings &&
                issueData?.data.numberOfRatings === 1 && (
                  <div className="hidden sm:block  font-light text-sm text-gray-500">{`(Based on vote of one person)`}</div>
                )}
              {!!issueData?.data.numberOfRatings &&
                issueData?.data.numberOfRatings > 1 && (
                  <div className="hidden sm:block  font-light text-sm text-gray-500">{`(Based on votes of ${issueData?.data.numberOfRatings} people)`}</div>
                )}
              {(!issueData?.data.numberOfRatings ||
                issueData?.data.numberOfRatings === 0) && (
                <div className="hidden sm:block  font-light text-sm text-gray-500">{`No votes yet`}</div>
              )}
              {/* {!!numberOfRatings && (
              <div className="block sm:hidden font-light text-sm text-gray-500">{`(${numberOfRatings} people voted)`}</div>
            )} */}
            </div>
          </div>
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(ImpactRankingCard);
