import { useState, useEffect, memo } from "react";
import GoogleMapReact from "google-map-react";
import { DEFAULT_CENTER, DEFAULT_ZOOM, mapThemes, MAP_THEMES } from "const";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export interface IMapProps extends GoogleMapReact.Props {
  lat: string | number | undefined;
  lng: string | number | undefined;
  selectedTheme: MAP_THEMES;
  children?: React.ReactNode[] | React.ReactNode;
  innerRef?: React.MutableRefObject<any>;
}

export function Map({
  lat = DEFAULT_CENTER.lat,
  lng = DEFAULT_CENTER.lng,
  zoom = DEFAULT_ZOOM,
  selectedTheme,
  innerRef,
  ...rest
}: IMapProps) {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    setMapLoaded(true);
  }, []);

  return (
    <div className="h-full w-full">
      {mapLoaded && (
        <GoogleMapReact
          ref={innerRef}
          bootstrapURLKeys={{
            key: "" + API_KEY,
          }}
          options={{
            styles: mapThemes[selectedTheme],
            fullscreenControl: false,
            minZoom: 2,
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          center={{ lat: Number(lat), lng: Number(lng) }}
          zoom={zoom}
          {...rest}
        />
      )}
    </div>
  );
}

export default memo(Map);
