import { IAsset, IAttachment } from "./entities";
import { ImageType } from "./forms";

// ImageType type guard
export function isImageType(data: any | ImageType): data is ImageType {
  if (!data) return false;
  const img = data as ImageType;
  return isFileType(data) && !!img["dataUrl"];
}

// ImageType type guard
export function isFileType(data: any): data is File {
  if (!data) return false;
  const img = data as File;
  if (typeof img === "object" && data instanceof File) {
    return true;
  }

  return false;
}
//
// ImageType type guard
export function isSavedAttachment(data: any): data is IAttachment<IAsset> {
  if (!data) return false;
  if (
    typeof data === "object" &&
    data.hasOwnProperty("data") &&
    data.hasOwnProperty("type") &&
    // data.hasOwnProperty("asset") &&
    data?.hasOwnProperty("id")
  ) {
    return true;
  }

  return false;
}
