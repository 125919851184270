import type { Blocker, History, Transition } from "history";
import { ContextType, useContext, useEffect, useMemo, useState } from "react";
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
  URLSearchParamsInit,
} from "react-router-dom";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { UseMutateAsyncFunction, useQueryClient } from "react-query";
import { IMotivationalRatings, IRatings } from "types";
import { IQueryOptions } from "types/requests";
import {
  useDownloadTextAttachment,
  useSetActivitiesAsSeenQuery,
} from "./react-query";
import { ENDPOINTS } from "const";
import { saveAs } from "file-saver";

interface Navigator extends BaseNavigator {
  block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when: boolean) {
  const { navigator } = useContext(
    NavigationContext
  ) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
//

export function useDebouncedHandler<T>(
  handler: (params: T) => any | void,
  duration: number = 300
) {
  const debouncedHandler = useMemo(() => {
    return debounce(handler, duration);
  }, [duration, handler]);

  useEffect(() => {
    return () => {
      debouncedHandler.cancel();
    };
  }, [debouncedHandler]);

  return debouncedHandler;
}

export function useClickOnLikeDislikeOnMotivational(
  id: string | undefined,
  rate: null | number | undefined,
  mutateAsync: UseMutateAsyncFunction<
    AxiosResponse<IMotivationalRatings, any>,
    any,
    any,
    unknown
  >,
  invalidate: () => void
) {
  return useCallback(
    (isLike: boolean) => {
      let mewRate: null | number = null;
      if (isLike) {
        mewRate = rate === 1 ? null : 1;
      }
      if (!isLike) {
        mewRate = rate === 0 ? null : 0;
      }
      mutateAsync({
        motivational: id,
        rate: mewRate,
      }).then(() => {
        invalidate();
      });
      return;
    },
    [id, invalidate, mutateAsync, rate]
  );
}

export function useClickOnLikeDislikeActionGroup(
  groupId: string | undefined,
  rate: number | null | undefined,
  mutateAsync: UseMutateAsyncFunction<
    AxiosResponse<IRatings, any>,
    any,
    any,
    unknown
  >,
  invalidate: () => void
) {
  return useCallback(
    (isLike: boolean) => {
      let mewRate: null | number = null;

      if (isLike) {
        mewRate = rate === 1 ? null : 1;
      }
      if (!isLike) {
        mewRate = rate === 0 ? null : 0;
      }
      mutateAsync({
        actionGroup: groupId,
        rate: mewRate,
      }).then(() => {
        invalidate();
      });
    },
    [mutateAsync, groupId, rate, invalidate]
  );
}
//
interface IClickOutside {
  ref: React.MutableRefObject<HTMLDivElement | HTMLParagraphElement | null>;
  handler: any;
  truthfulCondition?: boolean;

  childRef?: React.MutableRefObject<
    HTMLDivElement | HTMLParagraphElement | null
  >;
  tileButtonRef?: React.MutableRefObject<
    HTMLDivElement | HTMLParagraphElement | null
  >;
  disableCancelable?: boolean;
}

export function useOnClickOutside({
  ref,
  handler,
  truthfulCondition,
  childRef,
  tileButtonRef,
  disableCancelable,
}: IClickOutside) {
  useEffect(() => {
    const listener = (event: any) => {
      const isContain = ref?.current?.contains(event.target);
      const childRefContain =
        childRef && childRef.current && childRef.current.contains(event.target);
      const tileButtonContain =
        tileButtonRef &&
        tileButtonRef.current &&
        tileButtonRef.current.contains(event.target);
      if (truthfulCondition) {
        if (!ref.current || isContain || childRefContain || tileButtonContain) {
          return;
        }

        if (event.cancelable && !disableCancelable) {
          event.preventDefault();
        }

        handler(event);
      } else {
        return;
      }
    };

    document.addEventListener("mousedown", listener, {
      passive: false,
    });
    document.addEventListener("touchstart", listener, {
      passive: false,
    });
    const keyPressHandler = (e) => {
      if (e.key?.includes("Esc")) {
        return listener(e);
      }
    };
    document.addEventListener("keydown", keyPressHandler, {
      passive: false,
    });
    return () => {
      document.removeEventListener("mousedown", listener, {
        //@ts-ignore
        passive: true,
      });
      document.removeEventListener("touchstart", listener, {
        //@ts-ignore
        passive: true,
      });
      document.addEventListener("keypress", keyPressHandler, {
        passive: false,
      });
    };
  }, [
    ref,
    handler,
    childRef,
    tileButtonRef,
    truthfulCondition,
    disableCancelable,
  ]);
}
export function useQueryOptionsSetter(
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void
) {
  return useMemo(() => {
    return (cb: (val: IQueryOptions) => IQueryOptions) => {
      const values: IQueryOptions = {
        user: searchParams.get("user") || "",
        category: searchParams.getAll("category") || "",
        skills: searchParams.getAll("skills") || "",
        placeId: searchParams.get("placeId") || "",
        lng: searchParams.get("lng") || "",
        lat: searchParams.get("lat") || "",
        zoom: searchParams.get("zoom") || "",
        actionGroup: searchParams.get("actionGroup") || "",
      };
      const newValues = cb(values);
      //@ts-ignore
      setSearchParams(_.pickBy(newValues, _.identity));
    };
  }, [searchParams, setSearchParams]);
}

export function useTextOverflow(myRef: React.RefObject<any>) {
  const [isOverflow, setOverflow] = useState(false);

  useEffect(() => {
    const getOverflow = () =>
      0 > myRef.current.clientWidth - myRef.current.scrollWidth;

    const handleResize = () => {
      setOverflow(getOverflow());
    };

    if (myRef.current) {
      setOverflow(getOverflow());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return isOverflow;
}
//
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
///
export function useSetActivitiesAsSeen() {
  const queryClient = useQueryClient();
  const [seen, setSeen] = useState(new Set<string>());
  const setActivityAsSeen = useCallback((id: string) => {
    setSeen((prev) => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });
  }, []);

  const { mutateAsync } = useSetActivitiesAsSeenQuery();
  //
  const debounced = useDebouncedHandler(
    useCallback(
      async (seen: string[]) => {
        await mutateAsync(seen);
        queryClient.invalidateQueries([
          ENDPOINTS.ACTIVITIES + "/unread-amount",
        ]);
        queryClient.invalidateQueries([ENDPOINTS.ACTIVITIES]);
      },
      [mutateAsync, queryClient]
    ),
    2000
  );
  useEffect(() => {
    debounced(Array.from(seen));
  }, [debounced, seen]);

  return setActivityAsSeen;
}

export function useDownloadCustomTextAttachment(
  id: string | undefined,
  type: "docx" | "pdf" = "pdf"
) {
  const { refetch } = useDownloadTextAttachment({
    id,
    enabled: false,
    type,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onDownload = useCallback(async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await refetch();
      if (res.data && res.data?.data?.data.data.length) {
        const blob = new Blob([new Uint8Array(res.data?.data?.data.data)], {
          type: res.data?.data?.contentType,
        });
        await saveAs(blob, res.data.data.name).finally(() => {
          setIsLoading(false);
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [id, refetch]);

  return { onDownload, isLoading };
}
///
