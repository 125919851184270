/* eslint-disable no-useless-escape */
import { MIN_PASSWORD_LENGTH } from "./SignUpForm.constants";
import * as yup from "yup";

import { MAX_FORM_FIELDS_LENGTH, FIELD_NAMES } from "./SignUpForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.EMAIL]: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Your email is invalid."
      )
      .email()
      .required("Email is required!"),
    [FIELD_NAMES.PASSWORD]: yup
      .string()
      .min(
        MIN_PASSWORD_LENGTH,
        "Your password must be greater than 6 characters."
      )
      .max(MAX_FORM_FIELDS_LENGTH)
      .required("Password is required!"),
  });
}
