import { ENDPOINTS } from "const";
import { axios } from "services";
import { IMotivational, IMotivationalTag, ITag } from "types";
import { toFormData } from "utils";

interface IMotivationalProps {
  group: IMotivational<string, IMotivationalTag<ITag>>;
}

interface IMotivationalTagProps {
  groupTag: IMotivationalTag<string, string>[];
}

export function createMotivational({ group }: IMotivationalProps) {
  return axios.post<IMotivational<string, IMotivationalTag<ITag>>>(
    ENDPOINTS.MOTIVATIONAL,
    toFormData(group)
  );
}

export function updateMotivational({
  id,
  group,
}: IMotivationalProps & { id: string }) {
  return axios.patch<IMotivational<string, IMotivationalTag<ITag>>>(
    ENDPOINTS.MOTIVATIONAL + "/" + id,
    toFormData(group)
  );
}

export function createMotivationalTags({ groupTag }: IMotivationalTagProps) {
  return axios.post<IMotivationalTag<string, string>[]>(
    ENDPOINTS.MOTIVATIONAL_TAGS_BULK,
    groupTag
  );
}
export function updateMotivationalTags({ groupTag }: IMotivationalTagProps) {
  return axios.put<IMotivationalTag<string, string>[]>(
    ENDPOINTS.MOTIVATIONAL_TAGS_BULK + "/",
    groupTag
  );
}
