import { ValuesType } from "./LoginForm.types";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FormikHelpers } from "formik";
import { useSignInApi } from "hooks";
import { useCallback, useMemo } from "react";
import { ERRORS, FIELD_NAMES } from "./LoginForm.constants";
import { useLocation, useNavigate } from "react-router-dom";

export function useInitialState() {
  return useMemo(() => {
    return {
      [FIELD_NAMES.EMAIL]: "",
      [FIELD_NAMES.PASSWORD]: "",
    };
  }, []);
}

export function useSubmitHandler() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { mutateAsync } = useSignInApi({});
  const auth = getAuth();
  return useCallback(
    async (props: ValuesType, formikHelpers: FormikHelpers<ValuesType>) => {
      formikHelpers.setSubmitting(true);
      const email = props.email.toLowerCase();
      const result = await mutateAsync({ email }).catch((error: any) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(error.response.data);
      });

      if (result && result.data.userExists) {
        await signInWithEmailAndPassword(auth, email, props.password)
          .then(() => {
            formikHelpers.setSubmitting(false);
            if (state) {
              navigate((state as any).from); //
            }
            // Reload after login (for map)
            window.location.reload();
          })
          .catch(({ code }) => {
            formikHelpers.setSubmitting(false);
            ERRORS.forEach((error) => {
              if (error.code === code) {
                return formikHelpers.setErrors({
                  [error.field]: error.message,
                });
              }
            });
          });
      }
    },
    [auth, mutateAsync, navigate, state]
  );
}
