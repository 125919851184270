import { memo, useState } from "react";
import { FullIssueType } from "types";
import { Card } from "components";
import ChevronDown from "assets/images/chevron-down.svg";
import { ReactComponent as Location } from "assets/images/location.svg";
import classnames from "classnames";
import { StaticGoogleMap, Marker } from "react-static-google-map";

interface IProps {
  issue: FullIssueType | undefined;
  isSoftDeleted?: boolean;
}

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
function StaticMapCard({ issue, isSoftDeleted }: IProps) {
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);
  const isFormattedAddress = !!issue?.location?.formattedAddress;
  const isCountry = !!issue?.location?.country;
  const isCity = !!issue?.location?.city;
  const isStreetNumber = !!issue?.location?.streetNumber;
  const isRoute = !!issue?.location?.route;

  const formattedAddress = issue?.location?.formattedAddress;
  const zoom =
    typeof issue?.location === "object"
      ? isCountry && isCity && isStreetNumber && isRoute
        ? 16
        : isCountry && isCity
        ? 12
        : isCountry
        ? 6
        : isFormattedAddress
        ? 3
        : 2
      : 2;

  return (
    <Card
      className={classnames("!gap-0", {
        "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
      })}
      shouldHover={false}
      collapse={descriptionCollapsed}
      header={
        <div
          // onTouchStart={() => {
          //   setDescriptionCollapsed((val) => !val);
          // }}
          // onTouchEnd={(e) => {
          //   e.preventDefault();
          // }}
          className="flex justify-between items-center pb-1"
        >
          <div className="grid grid-flow-col gap-x-2 items-center  ">
            <img
              alt="chevron-down"
              className={classnames("cursor-pointer lg:hidden", {
                "rotate-180": descriptionCollapsed,
              })}
              onClick={() => {
                setDescriptionCollapsed((val) => !val);
              }}
              src={ChevronDown}
            />
            <div className="flex flex-col gap-2">
              <span className="text-black font-bold leading-6 text-lg">
                {issue?.title}
              </span>
              <div className="flex flex-row items-center gap-2">
                <Location className="opacity-30" />
                <span className="text-gray-400 font-light leading-6 text-sm">
                  {formattedAddress || "No location"}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
      classNames={{
        header: "px-6 sm:px-16  !justify-center",
        content: "!p-0 flex-1",
      }}
      showFooterDivider={false}
      content={
        <div className=" flex justify-center bg-gray-100">
          <StaticGoogleMap
            className=" "
            maptype="roadmap"
            // eslint-disable-next-line react/style-prop-object
            style="style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e"
            size="600x300"
            zoom={zoom}
            apiKey={API_KEY}
          >
            <Marker
              iconURL="https://i.ibb.co/rwKFf53/marker-Highlight.png"
              location={`${issue?.location.lat}, ${issue?.location.lng}`}
            />
          </StaticGoogleMap>
        </div>
      }
    />
  );
}

export default memo(StaticMapCard);
