import classnames from "classnames";
import { twMerge } from "tailwind-merge";

import ToolTip from "components/ReactTooltip";
import { ITooltipProps } from "components/ReactTooltip/ReactTooltip";

export function ScoreTooltip({ className, ...props }: ITooltipProps) {
  return (
    <ToolTip
      className={twMerge(
        classnames(
          "white-tooltip font-semibold !rounded-md !p-2 z-30 max-w-[200px] break-words",
          className
        )
      )}
      {...props}
    />
  );
}
//
