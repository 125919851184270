import _ from "lodash";
import { IIssueRatingCategory, IUserSkill } from "types";

export function buildSkills(data?: Partial<IUserSkill>[]) {
  return _.compact(
    data?.map(({ skill }) => ({
      value: skill?.id || "",
      label: skill?.name,
      description: skill?.description,
    }))
  );
}

export function buildCategories(data?: Partial<IIssueRatingCategory>[]) {
  return _.compact(
    data?.map((category) => ({
      value: category?.id,
      label: category?.title,
      description: category?.description,
    }))
  );
}
