import { XIcon } from "@heroicons/react/outline";

export function BetaPhaseNotification({
  setShow,
}: {
  setShow: (show: boolean) => void;
}) {
  return (
    <div className="bg-black text-white text-center px-5 py-6 relative">
      <p>
        We're excited to have you onboard. We're currently in beta phase, which
        means we're still fine-tuning everything to ensure the best experience
        for you. Found a bug or have a suggestion? Please don't hesitate to let
        us know{" "}
        <a
          href="https://weattitude.org/#contact"
          className=" text-blue-300 underline"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <XIcon
        onClick={() => {
          setShow(false);
        }}
        className="h-6 w-6 shrink-0 cursor-pointer absolute top-2 right-2"
        aria-hidden="true"
      />
    </div>
  );
}
