import _ from "lodash";
import { IUpdateAttachmentsProps } from "services";
import { IAsset, IAttachment, ImageType, isFileType } from "types";

export class AttachmentsInitial {
  attachments: (File | ImageType | Partial<IAttachment<IAsset, string>>)[] = [];
  attachmentsInput: string = "";
  attachmentsQuill: string = "";
  attachmentsTitle: string = "";
  isQuillModalOpen: boolean = false;
  attachmentIndexToEdit?: number | null = null;

  constructor(attachmentList: Partial<IAttachment<IAsset, any>>[] | undefined) {
    if (attachmentList && attachmentList.length) {
      this.addAttachments(attachmentList);
    }
  }
  addAttachments(attachments: Partial<IAttachment<IAsset>>[] | undefined) {
    const list = attachments?.filter((i) => {
      if (i.type === "file") {
        return !!i.asset;
      }
      return !!i;
    });
    if (list?.length) {
      this.attachments = [...list];
    }
  }
}

export interface IAttachmentPayloadArguments {
  attachments: (File | ImageType | Partial<IAttachment<IAsset, string>>)[];
  attachmentsInput: string;
  attachmentsQuill: string;
  attachmentsTitle: string;
  attachmentsType: IUpdateAttachmentsProps["type"];
}
export class AttachmentsPayload {
  attachments: IUpdateAttachmentsProps | null = null;
  attachmentsInput: string = "";
  attachmentsQuill: string = "";
  attachmentsTitle: string = "";

  constructor({
    attachments,
    attachmentsType,
    attachmentsInput,
    attachmentsQuill,
    attachmentsTitle,
  }: Partial<IAttachmentPayloadArguments>) {
    const prepared: IUpdateAttachmentsProps["attachments"] = [];
    attachments?.forEach((i) => {
      if (isFileType(i)) {
        prepared.push(i);
      } else {
        //if already saved file
        if (i.type === "file" && i.asset) {
          prepared.push({ id: i.id, type: "file" });
        }
        //if already saved links or new added links (unsaved links without id)
        if (i.type === "link") {
          const data = _.pickBy(
            { id: i.id, type: "link", data: i.data, title: i.title },
            _.identity
          );
          prepared.push(data);
        }
        //if already saved text or new added text (unsaved text without id)
        if (i.type === "text") {
          const data = _.pickBy(
            { id: i.id, type: "text", data: i.data, title: i.title },
            _.identity
          );
          prepared.push(data);
        }
      }
    });
    //
    if (attachmentsTitle) {
      this.attachmentsTitle = attachmentsTitle;
    }
    if (attachmentsInput) {
      this.attachmentsInput = attachmentsInput;
    }
    if (attachmentsQuill) {
      this.attachmentsQuill = attachmentsQuill;
    }

    if (attachmentsType) {
      this.attachments = {
        id: "",
        attachments: prepared,
        type: attachmentsType,
      };
    }
  }

  getAttachments(id: string): Partial<IUpdateAttachmentsProps> | null {
    if (this.attachments) {
      this.attachments.id = id;
    }
    //if there is value in attachment input - create new link
    if (this.attachmentsInput) {
      let title = "";
      if (this.attachmentsTitle) {
        title = this.attachmentsTitle;
      }
      this.attachments?.attachments?.push({
        type: "link",
        data: this.attachmentsInput,
        title,
      });
    }

    return {
      ...this.attachments,
    };
  }
}
