import { messaging } from "App";
import { APP_ROUTES, ENDPOINTS } from "const";
import { getToken, onMessage } from "firebase/messaging";
import {
  useBackgroundImages,
  useCreateUserDeviceToken,
  useMe,
  useUserDeviceToken,
} from "hooks";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { IOutletAuth } from "types";
import { showSuccessMessage } from "utils";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

async function requestPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    //We can send token to server
    return token;
  }
}

function checkLocations(location: Partial<Location>) {
  const checkedLocation =
    location.pathname === APP_ROUTES.ISSUES ||
    location.pathname === APP_ROUTES.SOLUTION_LIST ||
    location.pathname === APP_ROUTES.ACTION_GROUP_LIST ||
    location.pathname === APP_ROUTES.MOTIVATIONAL_LIST ||
    location.pathname === "/";
  return checkedLocation;
}
///
export function useGetRandomBackgroundImage() {
  const location = useLocation();

  const { data: backgroundImages } = useBackgroundImages({
    refetchOnWindowFocus: false,
  });
  const checkedLocation = checkLocations(location);

  const [randomIndex, setRandomIndex] = useState(
    (backgroundImages &&
      Math.floor(Math.random() * backgroundImages?.data.length)) ||
      0
  );

  useEffect(() => {
    if (checkedLocation && backgroundImages) {
      setRandomIndex(Math.floor(Math.random() * backgroundImages?.data.length));
    }
  }, [location.pathname, checkedLocation, backgroundImages]);
  return backgroundImages?.data[randomIndex];
}

export function usePdfUrl(): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>
] {
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (location.pathname === APP_ROUTES.HANDOUTS) {
      setPdfUrl(location.search.split("=")[1]);
    }
  }, [location.pathname, location.search]);
  return [pdfUrl, setPdfUrl];
}

export function useNotificationToken() {
  const [token, setToken] = useState<string | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    requestPermission().then((token) => {
      setToken(token || "");
    });
  });
  useEffect(() => {
    onMessage(messaging, (payload) => {
      if (payload?.notification) {
        queryClient.invalidateQueries(
          [ENDPOINTS.ACTIVITIES + "/unread-amount"],
          {
            exact: false,
          }
        );
        showSuccessMessage(
          <div className="flex flex-row gap-1">
            <img
              className="rounded-full self-start"
              alt="icon"
              src="https://app.weattitude.org/favicon.ico"
              height={30}
              width={30}
            />
            <div className="flex flex-col gap-1">
              <p className="font-bold">{payload.notification.title}</p>
              <p>{payload.notification.body}</p>
            </div>
          </div>,
          {
            position: "top-left",
            icon: false,
          }
        );
      }
    });
  }, [queryClient]);

  return token;
}

export function useCheckNotificationToken(context: IOutletAuth) {
  const { mutateAsync: mutateToken } = useCreateUserDeviceToken();
  const { data: meData } = useMe();
  const { data: tokens, isFetched } = useUserDeviceToken({
    enabled: !!context.isAuth && !!meData?.data.id,
    user: meData?.data.id,
  });

  const token = useNotificationToken();
  const tokenAlreadyExists = !!tokens?.data.find(
    (tokenEntity) => tokenEntity.token === token
  );
  useEffect(() => {
    if (token && context.isAuth && isFetched && !tokenAlreadyExists) {
      mutateToken({ token });
    }
  }, [mutateToken, token, context.isAuth, tokenAlreadyExists, isFetched]);
}

export function useRegisterServiceWorker() {
  useEffect(() => {
    serviceWorkerRegistration.register({
      swUrl: "/firebase-messaging-sw.js",
      onSuccess: (registration) => {
        // if (
        //   navigator &&
        //   navigator?.serviceWorker &&
        //   navigator?.serviceWorker?.controller
        // ) {
        //   navigator?.serviceWorker?.controller.postMessage({
        //     message: "Hello from the React app!",
        //   });
        // }
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      },
    });
  }, []);
}
