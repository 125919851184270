import map from "assets/images/auth-map.png";
import { memo } from "react";

interface IAuthPanelProps {
  children: React.ReactNode;
}
function AuthPanel({ children }: IAuthPanelProps) {
  return (
    <div className="row-start-5 row-end-9 h-full w-full">
      <div className=" maxmxs:h-full mxs:grid grid-cols-12 grid-rows-12 max-w-[1454px] m-auto">
        <div className="h-full w-full flex flex-row row-span-12 row-start-1 row-end-10 col-span-full   ">
          <div className="hidden lg:block relative w-0 flex-1  ">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={map}
              alt=""
            />
          </div>
          <div className="flex-1">
            <div className="flex-1 flex flex-col  justify-center pt-12 pb-14 sm:pb-24  px-4 lg:flex-none sm:min-w-[350px]  mxs:bg-white">
              <div className="mx-auto h-full w-full max-w-sm lg:w-96">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(AuthPanel);
