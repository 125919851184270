import { useInfinitySolutions, useQueryOptionsSetter } from "hooks";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions } from "react-query";
import { useSearchParams } from "react-router-dom";
import { ISolutionsProps } from "services";
import { FullSolutionType } from "types";
import { IAxiosResponseWithPagination } from "types/requests";

import { getRange, getSearchParams } from "utils";

type SolutionParamsTypes = Partial<ISolutionsProps["params"]>;
interface IInfiniteQuerySolutionsProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullSolutionType>>
    >,
    SolutionParamsTypes {}

export function useSolutionList(
  params: Partial<
    ReturnType<typeof getSearchParams> & IInfiniteQuerySolutionsProps
  >,
  mapWidth?: number | undefined,
  limit?: number,
  disabled = false
) {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();
  const { data, fetchNextPage, ...rest } = useInfinitySolutions({
    limit: limit || 20,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },
    range: getRange(params?.zoom, Number(params.lat), mapWidth),
    keepPreviousData: true,
    suspense: !isMount,
    enabled: !disabled,
    withDeleted: !!params.user,
    ...params,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}

export function useSolutionListFilterParams(
  mapWidth: number | undefined,
  disabled = false,
  limit?: number
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, inViewRef } = useSolutionList(
    getSearchParams(searchParams),
    mapWidth,
    limit,
    disabled
  );
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  return { data, inViewRef, setQueryOptions, searchParams };
}
