/* This example requires Tailwind CSS v2.0+ */

import AvatarPlaceholder from "assets/images/avatar.png";

import { APP_ROUTES } from "const";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as Help } from "assets/images/help.svg";
import { ReactComponent as WeLogo } from "assets/images/weatt-wide-logo-v1.svg";
import { ReactComponent as NotificationIcon } from "assets/images/notification.svg";
import { ReactComponent as Facebook } from "assets/images/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/twitter.svg";

import { Button, ProductTourComponent } from "components";
import { getAuth } from "firebase/auth";

import { IUser } from "types";
import { logout } from "utils";

import { SlideOver } from "components/SlideOver";

import { Step } from "react-joyride";
import { fullProductTour } from "const/tourSteps";
import { useFindUnreadActivitiesAmount, useWindowDimensions } from "hooks";
import { memo } from "react";
import { isNumber } from "lodash";

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const navigation = [
  { name: "Issues", href: APP_ROUTES.ISSUES, current: false },
  { name: "Solutions", href: APP_ROUTES.SOLUTION_LIST, current: false },
  { name: "Action Groups", href: APP_ROUTES.ACTION_GROUP_LIST, current: false },
  { name: "- - - - - More - - - - -", href: "" },
  { name: "Dashboard", href: APP_ROUTES.DASHBOARD, current: false },
  {
    name: "Motivational Contents",
    href: APP_ROUTES.MOTIVATIONAL_LIST,
    current: false,
  },
  { name: "Handout Sheets", href: APP_ROUTES.HANDOUTS, current: false },
];

function SlideMenuList({
  isOpen,
  preventClose,
  setIsOpen,
  isScrolled,
  isAuth,
  profile,
  notEnoughPersonalData,
  setOpenNotificationSlideover,
  setOpenProductTourPopup,
  openProductTourPopup,
  setCurrentScenario,
  setScenarioSteps,
  handleClickStart,
  setShowGoSignInModal,
}: {
  isOpen: boolean;
  preventClose: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  isScrolled: boolean;
  isAuth: boolean;
  profile: IUser | undefined;
  notEnoughPersonalData?: boolean;
  setOpenNotificationSlideover?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenProductTourPopup: (value: React.SetStateAction<boolean>) => void;
  openProductTourPopup: boolean;
  setCurrentScenario: React.Dispatch<React.SetStateAction<number>>;
  setScenarioSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  handleClickStart: (event: React.MouseEvent<HTMLElement>) => void;
  setShowGoSignInModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const location = useLocation();
  const auth = getAuth();
  const dimensions = useWindowDimensions();
  const { data: unreadActivitiesAmount } = useFindUnreadActivitiesAmount({
    enabled: !!isAuth,
  });
  const amount = +(unreadActivitiesAmount?.data || 0);
  const notificationsAreMoreThanZero =
    isNumber(amount) && !isNaN(amount || 0) && amount > 0;
  return (
    <SlideOver
      open={isOpen}
      preventClose={preventClose}
      position="left"
      close={() => {
        setIsOpen(() => false);
      }}
      classNames={{ headerIcon: "sm:!px-6" }}
      headerIcon={
        <WeLogo
          fill="black"
          className="h-[84px] w-[290px] sm:h-[64px] sm:w-[220px]"
        />
      }
    >
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col">
          {navigation.map((item, index) => {
            if (index === 3) {
              return (
                <div
                  key={item.name}
                  className="items-center px-6 py-3 text-sm flex font-normal text-gray-400 select-none"
                >
                  <span>{item.name}</span>
                </div>
              );
            }
            return (
              <Link
                key={item.name}
                to={item.href}
                onClick={() => {
                  setTimeout(() => {
                    setIsOpen(() => false);
                  }, 100);
                }}
                className={classnames(
                  " items-center px-6 py-3  text-lg flex font-normal",
                  {
                    "border-gray-700": !isScrolled,
                    "border-black": isScrolled,
                    " bg-[#F8F8F8]  ": item.current,
                    "border-transparent hover:text-gray-700 hover:bg-gray-100 ":
                      !item.current,
                    " one-first-step two-first-step three-first-step five-first-step six-first-step ten-first-step eleven-first-step":
                      index === 1,
                    " four-first-step seven-first-step": index === 2,
                    " eight-first-step nine-first-step": index === 3,
                    " thirteen-first-step fourteen-first-step fifteen-first-step sixteen-first-step":
                      index === 4,
                    " twelve-first-step ": index === 5,
                  }
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <span
                  className={classnames({
                    " font-semibold": item.current,
                    "border-transparent hover:text-gray-700 ": !item.current,
                  })}
                >
                  {item.name}
                </span>
              </Link>
            );
          })}
          <a
            href={"https://www.weattitude.org/"}
            target="_blank"
            rel="noreferrer"
            key={"https://www.weattitude.org/"}
            className={classnames(
              " items-center px-6 py-3  text-lg flex font-bold border-transparent hover:text-gray-700 hover:bg-gray-100 text-blue-500 "
            )}
          >
            Homepage
          </a>
          {isAuth && (
            <div className="pt-4 pb-3 sm:hidden">
              <div className="flex items-center px-6 gap-3">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link
                      onClick={() => {
                        setIsOpen(() => false);
                      }}
                      to={APP_ROUTES.PROFILE}
                    >
                      <div
                        className="h-[60px] w-[60px] rounded-full font-bold text-lg flex items-center justify-center from-[#aab5fb] to-[#d3b6ff] bg-gradient-to-b shrink-0 cursor-pointer focus:from-zambezi focus:to-zambezi p-[1px] relative overflow-hidden"
                        tabIndex={1}
                      >
                        {!profile?.avatar && (
                          <div className="bg-white w-full h-full  shrink-0  rounded-full flex items-center justify-center">
                            <img src={AvatarPlaceholder} alt="avatar" />
                          </div>
                        )}
                        {profile?.avatar && (
                          <img
                            className="rounded-full absolute "
                            src={profile?.avatar}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                </div>
                <Link
                  onClick={() => {
                    setIsOpen(() => false);
                  }}
                  to={APP_ROUTES.PROFILE}
                >
                  {
                    <span className=" text-sm font-semibold flex gap-1">
                      {!!profile?.firstName && (
                        <span>{profile?.firstName}</span>
                      )}
                      {!!profile?.lastName && <span>{profile?.lastName}</span>}
                    </span>
                  }
                  {!profile?.firstName && !profile?.lastName && (
                    <span className=" text-sm font-semibold">
                      {profile?.email}
                    </span>
                  )}
                </Link>

                <button
                  type="button"
                  className={classnames(
                    "relative p-1 ml-auto bg-white flex-shrink-0 text-gray-400 hover:text-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                    {
                      " !ring-4 !ring-offset-4 !ring-red-500":
                        notEnoughPersonalData,
                    }
                  )}
                >
                  <span className="sr-only">View notifications</span>
                  {notificationsAreMoreThanZero && (
                    <span
                      className={classnames(
                        " absolute flex  bg-black rounded-full bottom-[60%] left-[60%] text-white outline outline-2 outline-white text-xs font-black  text-white-stroke-sm text-center items-center justify-center",
                        {
                          "w-[22px] h-[22px]": amount > 99,
                        },
                        {
                          "w-[18px] h-[18px]": amount > 9,
                        },
                        {
                          "w-[14px] h-[14px]": amount < 10,
                        }
                      )}
                    >
                      {amount < 100 ? amount : "99+"}
                    </span>
                  )}
                  <NotificationIcon
                    onClick={() => {
                      setOpenNotificationSlideover &&
                        setOpenNotificationSlideover(true);
                    }}
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
                {dimensions && dimensions.width < 641 && (
                  <div className="relative flex items-center">
                    <button
                      type="button"
                      onClick={() => {
                        setOpenProductTourPopup((val) => !val);
                      }}
                      className={classnames("relative p-1 rounded-full  ")}
                    >
                      <Help className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {openProductTourPopup && (
                      <ProductTourComponent
                        setIsOpen={setIsOpen}
                        setOpenNotificationSlideover={
                          setOpenNotificationSlideover
                        }
                        mobileVersion={true}
                        setOpenProductTourPopup={setOpenProductTourPopup}
                        setCurrentScenario={setCurrentScenario}
                        setScenarioSteps={setScenarioSteps}
                        handleClickStart={handleClickStart}
                        fullProductTour={fullProductTour}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {isAuth && (
          <div className="flex flex-col flex-1 px-4 items-center justify-center sm:hidden">
            <Button
              onClick={() => {
                setIsOpen((val) => !val);
                auth.signOut();
                logout();
              }}
              className="w-full justify-center"
            >
              Sign out
            </Button>
          </div>
        )}
        {!isAuth && (
          <div className="flex px-4 flex-col flex-1 justify-center gap-6 sm:hidden">
            <Link
              onClick={() => {
                setIsOpen(() => false);
              }}
              to={APP_ROUTES.SIGN_UP}
            >
              <Button className="w-full justify-center">Sign up</Button>
            </Link>
            <div className="flex gap-2 text-sm justify-center">
              <span className=" font-light">Existing customer?</span>
              <Link
                onClick={() => {
                  setIsOpen(() => false);
                }}
                className=" font-semibold"
                state={{ from: location?.pathname }}
                to={APP_ROUTES.SIGN_IN}
              >
                Sign in
              </Link>
            </div>
            <div className="flex  items-center justify-end self-end">
              {!!dimensions && dimensions?.width < 641 && (
                <div className="relative flex items-center">
                  <button
                    type="button"
                    onClick={() => {
                      setShowGoSignInModal((val) => !val);
                    }}
                    className={classnames("relative p-1 rounded-full  ")}
                  >
                    <Help className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {openProductTourPopup && (
                    <ProductTourComponent
                      setIsOpen={setIsOpen}
                      setOpenNotificationSlideover={
                        setOpenNotificationSlideover
                      }
                      mobileVersion={true}
                      setOpenProductTourPopup={setOpenProductTourPopup}
                      setCurrentScenario={setCurrentScenario}
                      setScenarioSteps={setScenarioSteps}
                      handleClickStart={handleClickStart}
                      fullProductTour={fullProductTour}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col text-zambezi gap-3 pl-5">
          <span>Stay in touch</span>
          <div className="flex gap-5 items-center  i">
            <Facebook className="fill-black" />
            <Instagram className="fill-black" />
            <Twitter className="fill-black" />
          </div>
        </div>
      </div>
    </SlideOver>
  );
}

export default memo(SlideMenuList);
