import { AxiosResponse } from "axios";
import { FormikHelpers, useFormikContext } from "formik";
import { useBlocker, useMe, useUser, useUserSkills } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { ISelectOption, IUser, IUserSkill } from "types";
import { useNavigate, matchPath } from "react-router-dom";

import { APP_ROUTES, ENDPOINTS } from "const";
import { showErrorMessage, showSuccessMessage } from "utils";
import _ from "lodash";
import { Transition } from "history";

import {
  ProfileFormInitial,
  ProfileFormPayload,
} from "./ProfileForm.constants";
import { updateUserSkills, updateUser } from "./ProfileForm.services";

interface IProfileProps
  extends Partial<UseMutationOptions<AxiosResponse<IUser>, any, any>> {
  id?: string | undefined;
}

export function useUpdateUser({ id = "", ...rest }: IProfileProps) {
  return useMutation<AxiosResponse<IUser>, any, any>(
    (user: IUser) => updateUser({ id, user }),
    {
      ...rest,
    }
  );
}

interface ICreateUserSkillProps
  extends Partial<UseMutationOptions<AxiosResponse<IUserSkill>, any, any>> {
  user?: string | string;
}

export function useUpdateUserSkills({
  user = "",
  ...rest
}: ICreateUserSkillProps) {
  return useMutation<AxiosResponse<IUserSkill>, any, string[]>(
    (skills) => updateUserSkills({ user, skills }),
    {
      ...rest,
    }
  );
}

export function useInitialState() {
  const params = useParams<{ userId: string }>();
  const { data: initial } = useMe({ keepPreviousData: false }); ///
  const viewAUser = params.userId ? params.userId !== initial?.data.id : false;

  const { data: anotherUser } = useUser({
    id: params.userId,
    enabled: viewAUser,
  });

  const { data: userSkills } = useUserSkills({
    id: viewAUser ? params.userId : initial?.data.id,
  });

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!params.userId) {
      if (matchPath(APP_ROUTES.PROFILE, pathname)) {
        navigate(APP_ROUTES.PROFILE + "/" + initial?.data.id, {
          replace: true,
        });
      }
    }
  }, [initial?.data?.id, params.userId, navigate, pathname]);

  return useMemo(() => {
    if (viewAUser) {
      return new ProfileFormInitial(anotherUser?.data, userSkills?.data);
    } else {
      return new ProfileFormInitial(initial?.data, userSkills?.data);
    }
  }, [initial, userSkills, anotherUser, viewAUser]);
}

export function useSubmitHandler() {
  const params = useParams<{ userId: string }>();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateAsyncProfile } = useUpdateUser({
    id: params.userId,
  });
  const { mutateAsync: mutateAsyncUserSkills } = useUpdateUserSkills({
    user: params.userId,
  });

  return useCallback(
    async (
      values: ProfileFormInitial,
      formikHelpers: FormikHelpers<ProfileFormInitial>
    ) => {
      const prepared = new ProfileFormPayload(values);

      try {
        const updatedProfile = mutateAsyncProfile(prepared.getUser());
        const updatedSkills = mutateAsyncUserSkills(prepared.getSkills());
        formikHelpers.setSubmitting(true);
        await Promise.all([updatedProfile, updatedSkills]);
        queryClient.invalidateQueries([ENDPOINTS.USER_SKILLS]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.invalidateQueries([ENDPOINTS.ME]);
        queryClient.invalidateQueries([ENDPOINTS.COMMENTS_CONFIG]); //update avatar in comments

        formikHelpers.setSubmitting(false);
        showSuccessMessage("Changes are saved!");
      } catch (err) {
        showErrorMessage("Error occurred! Check form!");
      }
    },
    [mutateAsyncProfile, mutateAsyncUserSkills, queryClient]
  );
}

function useSaveModal() {
  const params = useParams<{ userId: string }>();
  const { data, isFetched: isMeFetched } = useMe();

  const [prevent, setPrevent] = useState(true);

  const {
    values: { skills, mediaLinks, ...values },
  } = useFormikContext<ProfileFormInitial>();
  const { data: prevUserSkills, isFetched: isUserSkillsFetched } =
    useUserSkills({ id: params.userId });

  const userSkillsToUpdate = skills.map((i) => i.value);
  const prevUserSkillsToUpdate = prevUserSkills?.data.map((i) => i.skill.id);

  const preventAction = useMemo(() => {
    const preparedData = { ...data?.data };
    if (prevent && isMeFetched && isUserSkillsFetched) {
      if (
        preparedData.primaryLocation &&
        preparedData.formattedAddress &&
        preparedData.placeId
      ) {
        (preparedData.primaryLocation as unknown as ISelectOption) = {
          label: preparedData.formattedAddress,
          value: preparedData.placeId,
        };
      }

      // const isUserEq = _.isEqual(values, preparedData);

      const isSkillsEq = _.isEqual(userSkillsToUpdate, prevUserSkillsToUpdate);

      const keysToCompare = [
        "isPrivate",
        "firstName",
        "lastName",
        "about",
        "email",
        "primaryLocation",
        "avatar",
      ];
      const isUserEq = areObjectsSimilar(values, preparedData, keysToCompare);

      if (!isSkillsEq || !isUserEq) {
        return true;
      }
    }
    return false;
  }, [
    data?.data,
    isMeFetched,
    isUserSkillsFetched,
    prevUserSkillsToUpdate,
    prevent,
    userSkillsToUpdate,
    values,
  ]);

  return {
    preventAction,
    setPrevent,
  };
}

export function usePreventTransition(disabled?: boolean) {
  const { preventAction, setPrevent } = useSaveModal();

  const action =
    disabled === undefined || disabled === true ? preventAction : disabled;

  const [transition, setTransition] = useState<Transition | null>(null);
  const navigation = useNavigate();
  useBlocker((transition) => {
    setTransition(transition);
  }, action);

  const closeModal = useCallback(() => {
    setTransition(null);
  }, [setTransition]);

  useEffect(() => {
    if (!action) {
      closeModal();
      transition && navigation(transition.location.pathname);
    }
  }, [closeModal, navigation, action, transition]);
  return {
    leave: useCallback(() => {
      setPrevent(false);
    }, [setPrevent]),
    close: useCallback(() => {
      setTransition(null);
    }, []),

    showModal: !!transition,
  };
}

function areObjectsSimilar(obj1: {}, obj2: {}, keysToCompare: string[]) {
  if (!obj1 || !obj2) {
    return false;
  }

  for (const key of keysToCompare) {
    // console.log(obj1[key], obj2[key]);

    if (
      obj1[key] &&
      typeof obj1[key] === "object" &&
      obj2[key] &&
      typeof obj2[key] === "object"
    ) {
      if (!areObjectsSimilar(obj1[key], obj2[key], Object.keys(obj1[key]))) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}
