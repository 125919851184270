import classnames from "classnames";
import ReactTooltip from "components/ReactTooltip";
import { CATEGORIES, CATEGORIES_COLORS } from "const";
import { memo, ReactNode, useMemo } from "react";

interface IDotProps {
  classificationName?: string;
  classificationDescription?: string;
  tooltipId?: string;
  onClick?: () => void;
  className?: string;
  styleLess?: boolean;
  children: ReactNode;
  classNames?: {
    contend?: string;
    text?: string;
  };
}

function BorderBadge({
  classificationName = "",
  classificationDescription = "",
  tooltipId,
  className,
  children,
  classNames,
  styleLess = false,
  ...rest
}: IDotProps) {
  const badgeColor = useMemo(() => {
    if (!classificationDescription) {
      return false;
    }

    if (CATEGORIES[classificationName]) {
      return CATEGORIES[classificationName];
    }

    const badgeColors = JSON.parse(localStorage.getItem("badgeColors") || "{}");
    const storedColor = badgeColors[classificationName];
    if (storedColor) {
      return storedColor;
    } else {
      const randomColor =
        CATEGORIES_COLORS[Math.floor(Math.random() * CATEGORIES_COLORS.length)];

      const updatedBadgeColors = {
        ...badgeColors,
        [classificationName]: randomColor,
      };
      localStorage.setItem("badgeColors", JSON.stringify(updatedBadgeColors));
      return randomColor;
    }
  }, [classificationDescription, classificationName]);

  return (
    <div
      className={classnames({ "rounded  ": !styleLess }, className, badgeColor)}
      {...rest}
    >
      <div
        className={classnames(
          {
            "relative flex items-center justify-center py-1  px-2 rounded-[3px]":
              !styleLess,
          },

          classNames?.contend
        )}
      >
        <span
          className={classnames(
            {
              " text-black  text-xs font-medium  line-clamp-1 text-center":
                !styleLess,
            },
            classNames?.text
          )}
          data-tooltip-id={classificationName + tooltipId}
        >
          {children}
        </span>
        {classificationDescription && (
          <ReactTooltip
            id={classificationName + tooltipId}
            content={classificationDescription}
            className={classnames(
              "white-tooltip font-semibold !rounded-md !p-2 z-30 max-w-[200px]",
              className
            )}
          />
        )}
      </div>
    </div>
  );
}
export default memo(BorderBadge);
