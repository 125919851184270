/* This example requires Tailwind CSS v2.0+ */
import {
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOnClickOutside } from "hooks";
import { ReactComponent as AnalyticInsight } from "assets/images/analytic-icon-insights.svg";
import classnames from "classnames";

interface IProps {
  analyticsData: string;
  className?: string;
}

function getParentWidth(elemRef: React.MutableRefObject<HTMLDivElement>) {
  if (elemRef.current.parentElement) {
    return elemRef.current.parentElement.clientWidth;
  } else {
    return 50;
  }
}

function AnalyticsComponent({ className, analyticsData }: IProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [parentWidth, setParentWidth] = useState(0);
  const childRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setParentWidth(getParentWidth(childRef));
  }, []);

  useEffect(() => {
    function handleResize() {
      setParentWidth(getParentWidth(childRef));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useOnClickOutside({
    ref: childRef,
    handler: useCallback(() => {
      setShowTooltip(false);
    }, [setShowTooltip]),
    truthfulCondition: true,
    disableCancelable: true,
  });

  return (
    <>
      <div
        ref={childRef}
        onClick={(e) => {
          e.stopPropagation();
          setShowTooltip((val) => !val);
        }}
        className={classnames(
          className,
          "absolute top-0 left-0 z-[5] w-0 h-0 border-t-[40px] border-solid border-r-transparent border-t-[#DBDBDB] border-r-[40px]   "
        )}
      >
        <p className="absolute top-[-40px] left-[2px] z-[5] font-bold text-black ">
          <AnalyticInsight fill="black" />
        </p>
      </div>
      <div
        style={{ width: parentWidth }}
        className={classnames(
          "absolute top-[45px] z-[11] block transform translate-y-[1px] text-clip text-sm  p-2 bg-[#DBDBDB] border text-black  border-gray-300 shadow-sm h-[135px] overflow-auto rounded-md",
          {
            hidden: !showTooltip,
          }
        )}
      >
        {analyticsData}
      </div>
    </>
  );
}

export default memo(AnalyticsComponent);
