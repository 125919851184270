import { memo, useEffect, useState } from "react";

import classnames from "classnames";
import { IssueCardModal } from "components";
import { useIssue } from "hooks";
import { XIcon } from "@heroicons/react/outline";
import { useWindowDimensions } from "hooks";

export interface IMapProps {
  issueId: string | undefined;
  setShowPreviewCard: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPreviewCardArrayId: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;

  setOpenCardModalRef: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapCardPreviewInArray({
  issueId,
  setCurrentPreviewCardArrayId,
  setOpenCardModalRef,
  setShowPreviewCard,
}: IMapProps) {
  const { data, isLoading } = useIssue({ id: issueId, suspense: false });
  const [openCardModal, setOpenCardModal] = useState(false);
  const isFormattedAddress = !!data?.data?.location?.formattedAddress;
  const isCountry = !!data?.data?.location?.country;
  const isCity = !!data?.data?.location?.city;
  const isStreetNumber = !!data?.data?.location?.streetNumber;
  const isRoute = !!data?.data?.location?.route;
  const location =
    typeof data?.data?.location === "object"
      ? isFormattedAddress
        ? `${data?.data?.location?.formattedAddress}`
        : isCountry && isCity && isStreetNumber && isRoute
        ? `${data?.data?.location?.country}, ${data?.data?.location?.city}, ${data?.data?.location?.route} ${data?.data?.location?.streetNumber}`
        : isCountry && isCity
        ? `${data?.data?.location?.country}, ${data?.data?.location?.city}`
        : isCountry
        ? `${data?.data?.location?.country}`
        : "No location"
      : "No location";

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (openCardModal) {
      setOpenCardModalRef(true);
    } else {
      setOpenCardModalRef(false);
    }
  }, [openCardModal, setOpenCardModalRef]);

  return (
    <>
      <div
        style={{
          minWidth: `${Math.ceil(width * 0.8)}px`,
          maxWidth: `${Math.ceil(width * 0.8)}px`,
        }}
        onClick={() => {
          setOpenCardModal(true);
        }}
        className={classnames(`flex justify-center  z-30 w-full`)}
      >
        {!isLoading && data?.data && (
          <div className="max-w-[88%] w-full py-4 pl-4 pr-3  bg-white rounded-lg flex flex-row justify-between shadow-lg ">
            <div className="flex flex-col gap-1">
              <span className="font-bold leading-6 text-lg line-clamp-2">
                {data?.data.title}
              </span>
              <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                {location}
              </span>
            </div>
            <div>
              <XIcon
                onClick={() => {
                  setCurrentPreviewCardArrayId(undefined);
                  setShowPreviewCard(false);
                }}
                className="h-6 w-6 cursor-pointer opacity-50"
                aria-hidden="true"
              />
            </div>
          </div>
        )}
      </div>
      {openCardModal && issueId && (
        <IssueCardModal id={issueId} setShow={setOpenCardModal} />
      )}
    </>
  );
}
export default memo(MapCardPreviewInArray);
