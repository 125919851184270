import { default as axiosService } from "axios";
import { APP_ROUTES } from "const";
import { getAuth } from "firebase/auth";

export const axios = axiosService.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const axiosToPdfApi = axiosService.create({
  baseURL:
    "https://we-api.weattitude.org/ocpu/library/WEapi/R/gen.handout.sheet.fun",
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      getAuth().signOut();
      window.location.assign(APP_ROUTES.ISSUES);
    }
    //add some logic if needed
    return Promise.reject(error);
  }
);

export default axios;
