import { memo } from "react";
import AvatarPlaceholder from "assets/images/avatar.png";
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import { ReputationScore } from "components/ReputationScore/ReputationScore";

interface IProps {
  profileImage: string | undefined;
  onClick?: () => void;
  isModalVariant?: boolean;
  score?: number;
  className?: string;
  classNames?: { avatar?: string; blueMark?: string };
  tooltipId?: string;
}

function ProfileImage({
  profileImage,
  className,
  classNames,
  score,
  onClick,
  isModalVariant = false,
  tooltipId,
}: IProps) {
  return (
    <div
      className={twMerge(
        classnames(
          "   flex justify-center items-center shrink-0 relative",
          { " cursor-pointer": !onClick },
          className
        )
      )}
    >
      {!!profileImage && (
        <img
          className={twMerge(
            classnames(
              " object-cover rounded-full ",
              {
                "h-10 w-10": isModalVariant,
                "h-8 w-8": !isModalVariant,
              },
              classNames?.avatar
            )
          )}
          src={profileImage}
          alt=""
        />
      )}
      {!profileImage && (
        <img
          src={AvatarPlaceholder}
          alt="avatar"
          className={twMerge(
            classnames(
              " object-cover rounded-full ",
              {
                "h-10 w-10": isModalVariant,
                "h-8 w-8": !isModalVariant,
              },
              classNames?.avatar
            )
          )}
        />
      )}
      {/* TODO - ADD MORE ICONS */}

      <ReputationScore
        score={score}
        className=" absolute bottom-[2px] -right-1 max-h-4 z-10"
        classNames={{ icon: "fill-white " }}
        tooltipId={tooltipId}
      />
    </div>
  );
}

export default memo(ProfileImage);
