import classnames from "classnames";
import { memo } from "react";

interface IDotProps {
  className?: string;
  classNames?: {
    dot?: string;
  };
  category: string;
}

function DotBadge({ classNames, className, category }: IDotProps) {
  return (
    <div
      className={classnames(
        "flex gap-2 items-center justify-center",
        className
      )}
    >
      <span
        className={classnames(
          classNames?.dot,
          "rounded-full h-[6px] w-[6px] shrink-0"
        )}
      />
      <span className="line-clamp-1 font-semibold text-gray-500">
        {category}
      </span>
    </div>
  );
}
export default memo(DotBadge);
