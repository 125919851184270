import { APP_ROUTES } from "const";
import { memo, useState } from "react";
import { FullSolutionType, IIssueRatingCategory, IOutletAuth } from "types";
import { ProfileBlock, Card, BorderBadgeRound, PromptModal } from "components";

import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";

import classnames from "classnames";
import { useNavigate, useOutletContext } from "react-router-dom";

interface IProps {
  solutions: FullSolutionType[] | undefined;
  details: string;
  origin: string;
  importance: string;
  profileImage: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  primaryClassification: Partial<IIssueRatingCategory> | undefined;
  secondaryClassification: Partial<IIssueRatingCategory> | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  onEdit: () => void;
  allowEdit: boolean;
  email: string | undefined;
  score: number | undefined;
  isSoftDeleted?: boolean;
}

function SolutionsCard({
  onEdit,
  allowEdit,
  solutions,
  isSoftDeleted,
}: IProps) {
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  const authContext = useOutletContext<IOutletAuth>();
  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Card
        className={classnames(
          "bg-white five-sixth-step six-fifth-step ten-sixth-step eleven-fifth-step",
          { "bg-gray-100 pointer-events-none select-none": isSoftDeleted }
        )}
        shouldHover={false}
        collapse={descriptionCollapsed}
        header={
          <div
            // onTouchStart={() => {
            //   setDescriptionCollapsed((val) => !val);
            // }}
            // onTouchEnd={(e) => {
            //   e.preventDefault();
            // }}
            className="flex justify-between items-center pb-1"
          >
            <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
              <img
                onClick={() => {
                  setDescriptionCollapsed((val) => !val);
                }}
                alt="chevron-down"
                className={classnames("cursor-pointer lg:hidden", {
                  "rotate-180": descriptionCollapsed,
                })}
                src={ChevronDown}
              />
              <span className="font-bold leading-6 text-lg">Solutions</span>
            </div>

            {allowEdit && (
              <Button
                isStyleless
                inGroupStyle={false}
                className="border hover:border-gray-300 border-transparent bg-gray-100 border-gray-100 add-solution-button-to-click z-10"
                type="button"
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={onEdit}
                disabled={!allowEdit}
              >
                + Add Solution
              </Button>
            )}
            {!authContext.isAuth && (
              <Button
                isStyleless
                inGroupStyle={false}
                className="border hover:border-gray-300 border-transparent bg-gray-100 border-gray-100 add-solution-button-to-click z-10"
                type="button"
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowGoSignInModal(true);
                }}
                onClick={() => {
                  setShowGoSignInModal(true);
                }}
              >
                + Add Solution
              </Button>
            )}
          </div>
        }
        classNames={{
          header: " px-6 sm:px-16 ",
          content: "!px-0 flex-1 ",
          footer: " px-6 sm:px-16 h-[60px]",
        }}
        showFooterDivider={false}
        content={
          <div className="max-h-[400px] overflow-y-auto overflow-x-hidden p-[1px] ">
            <div className="flex flex-col    ">
              {solutions && solutions.length > 0 ? (
                solutions.map((solution, index) => {
                  const firstName = solution?.user.firstName;
                  const id = solution?.user.id;
                  const lastName = solution?.user.lastName;
                  const profileImage = solution?.user.avatar;
                  const email = solution?.user.email;
                  const score = solution?.user.reputationScore;

                  const createdAt =
                    solution?.createdAt &&
                    new Date(solution?.createdAt).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    });
                  return (
                    <div
                      key={solution.id}
                      onClick={() => {
                        navigate(APP_ROUTES.SOLUTION + "/" + solution.id);
                      }}
                      className={classnames(
                        "relative z-10 hover:bg-[#F7F6F6] cursor-pointer ",
                        {
                          " solution-to-click ": index === 0,
                        }
                      )}
                    >
                      <div className="  z-20 hover:bg-[#F7F6F6] bg-white pt-8">
                        <div className="grid grid-flow-row gap-x-2  h-full gap-y-2 items-center z-20 px-6 sm:px-16">
                          <span className="flex justify-between items-start w-full">
                            <ProfileBlock
                              className=" !justify-start"
                              solutionOnIssueVariant
                              firstName={firstName}
                              userId={id}
                              lastName={lastName}
                              createdAt={createdAt} // fixed: solution's createdAt instead of issue's one, as was before
                              profileImage={profileImage}
                              email={email}
                              score={score}
                              tooltipId={`solution-${solution.id}`}
                              classNames={{
                                dotSeparator: "!bg-[#666666]",
                              }}
                            />
                          </span>
                          <span className="text-black font-semibold leading-6 text-md line-clamp-2">
                            {solution.title}
                          </span>

                          <span className="leading-6 text-md  text-gray-400 line-clamp-1 font-light">
                            {solution.description}
                          </span>
                          <div className="flex flex-wrap h-full min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4 pt-4 ">
                            {solution?.primaryActionGroup?.skills
                              ?.slice(0, 2)
                              .map((skill, index) => {
                                return (
                                  <BorderBadgeRound
                                    key={skill.id}
                                    className="flex"
                                    classNames={{
                                      text: index > 0 ? " line-clamp-1 " : "",
                                    }}
                                    withNotification
                                    notificationContent={`${
                                      skill.timeRequired || 0
                                    } ${
                                      Number(skill.timeRequired) === 1
                                        ? "hour"
                                        : "hours"
                                    } per week`}
                                    classificationName={skill?.skill?.name}
                                  >
                                    {skill?.skill?.name}
                                  </BorderBadgeRound>
                                );
                              })}
                            {solution?.primaryActionGroup?.skills &&
                              solution?.primaryActionGroup?.skills?.length >
                                2 && (
                                <div>
                                  +
                                  {solution?.primaryActionGroup?.skills
                                    ?.length - 2}
                                </div>
                              )}
                            {(!solution?.primaryActionGroup?.skills ||
                              solution?.primaryActionGroup?.skills.length <
                                1) && <div>No skills assigned</div>}
                          </div>
                        </div>
                        <div
                          className={classnames(
                            "border-b border-gray-200 w-full h-[1px] pt-8"
                          )}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center px-6 sm:px-16 py-6 w-full">
                  <span className="text-black font-bold text-lg">
                    No solutions yet
                  </span>
                </div>
              )}
            </div>
          </div>
        }
      />
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}

export default memo(SolutionsCard);
