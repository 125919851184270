import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { IActionGroupSkill, ICategories, ISkill } from "types";
import { ENDPOINTS } from "const";
import {
  ProfileBlock,
  Card,
  BorderBadgeRound,
  StarRatingItem,
  Loader,
  BorderBadge,
} from "components";
import {
  useCreateSolutionRating,
  useUpdateSolutionRating,
} from "modules/Solution/SolutionsCard/SolutionsCard";
import { useMe, useSolutionRatings } from "hooks";
import { getRating, showErrorMessage, showSuccessMessage } from "utils";
import { useQueryClient } from "react-query";
import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";

interface IProps {
  title: string;
  location?: string;

  profileImage: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  id: string | undefined;
  rating: number;
  skills?: Partial<IActionGroupSkill<Partial<ISkill>>>[];
  email: string | undefined;
  forceHoverIssue?: boolean;
  issueType: "regular" | "city" | "country";
  setHoveredIssueType: React.Dispatch<
    React.SetStateAction<"regular" | "city" | "country" | undefined>
  >;
  setIsIssueHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  setIssueHoveredProps?: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  geoLocation: {
    lat?: number | undefined;
    lng?: number | undefined;
  };
  solutionId: string | undefined;
  primaryClassification: keyof ICategories<any>;
  secondaryClassification: keyof ICategories<any>;
  innerRef?: (node?: Element | null | undefined) => void;
  score: number | undefined;
  tags?: string[] | undefined;
  isSoftDeleted?: boolean;
}

function SolutionCard({
  title,
  location,

  profileImage,
  firstName = "",
  lastName = "",
  createdAt = "",
  rating,
  issueType,
  id,
  skills,
  solutionId,
  setHoveredIssueType,
  setIsIssueHovered,
  setIssueHoveredProps,
  forceHoverIssue,
  geoLocation,
  email,
  innerRef,
  score,
  tags,
  isSoftDeleted,
}: IProps) {
  const date =
    createdAt &&
    new Date(createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  const { data: meData } = useMe();
  const queryClient = useQueryClient();

  const [showRateSolutionPopup, setShowRateSolutionPopup] = useState(false);
  const { data: solutionRatingData } = useSolutionRatings({
    solutionId: solutionId,
    userId: meData?.data.id,
    enabled: !!meData?.data.id && showRateSolutionPopup,
  });

  const [, setShowTooltip] = useState(false);
  const [ratingValue, setRatingValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      solutionRatingData &&
      solutionRatingData?.data.items.length > 0 &&
      solutionRatingData?.data.items[0].rate
    ) {
      setRatingValue(solutionRatingData?.data.items[0].rate);
    }
  }, [solutionRatingData]);

  const {
    mutateAsync: mutateCreateIssueRatingAsync,
    isLoading: loadingCreate,
  } = useCreateSolutionRating({
    solutionId: solutionId,
  });
  const {
    mutateAsync: mutateUpdateIssueRatingAsync,
    isLoading: loadingUpdate,
  } = useUpdateSolutionRating({
    solutionRatingId:
      solutionRatingData && solutionRatingData?.data.items.length > 0
        ? solutionRatingData?.data.items[0].id
        : undefined,
  });

  const createRate = useCallback(
    (ratingValue: number) => {
      mutateCreateIssueRatingAsync({
        solution: solutionId,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
          showSuccessMessage("Solution rating has been created!");
          setShowRateSolutionPopup(false);
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
          setShowRateSolutionPopup(false);
        });
    },
    [solutionId, queryClient, mutateCreateIssueRatingAsync]
  );

  const updateRate = useCallback(
    (ratingValue: number) => {
      mutateUpdateIssueRatingAsync({
        solution: solutionId,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          showSuccessMessage("Solution rating has been updated!");
          setShowRateSolutionPopup(false);
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
          setShowRateSolutionPopup(false);
        });
    },
    [solutionId, queryClient, mutateUpdateIssueRatingAsync]
  );

  return (
    <Card
      className={classnames(
        "maxmd:flex-1 !gap-1.5 !pt-3 !pb-2 h-full solution-card-content",
        {
          "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
        }
      )}
      issueType={issueType}
      setHoveredIssueType={setHoveredIssueType}
      forceHoverIssue={forceHoverIssue}
      setIsIssueHovered={setIsIssueHovered}
      setIssueHoveredProps={setIssueHoveredProps}
      geoLocation={geoLocation}
      showHeaderDivider={false}
      header={
        <Fragment>
          <span className=" font-bold leading-6 line-clamp-2" ref={innerRef}>
            {title}
          </span>
          <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
            {location}
          </span>
        </Fragment>
      }
      subHeader={
        <div className="flex flex-wrap h-full min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4  ">
          {skills?.slice(0, 2).map((skill, index) => {
            return (
              <BorderBadgeRound
                key={skill.id}
                className="flex"
                classNames={{
                  text: index > 0 ? " line-clamp-1 " : "",
                }}
                withNotification
                notificationContent={`${skill.timeRequired || 0} ${
                  Number(skill.timeRequired) === 1 ? "hour" : "hours"
                } per week`}
                classificationName={skill?.skill?.name}
              >
                {skill?.skill?.name}
              </BorderBadgeRound>
            );
          })}
          {skills && skills.length > 2 && <div>+{skills.length - 2}</div>}
          {((skills && skills.length < 1) || !skills) && (
            <div>No skills assigned</div>
          )}
        </div>
      }
      showSubHeaderDivider={false}
      classNames={{
        header:
          "max-w-[460px] sm:max-w-[360px] min-h-[52px] !justify-center  !gap-0 !pt-1",
        subHeader:
          "max-w-[460px] sm:max-w-[360px]   !justify-start !gap-0  min-h-[28px] !pt-1",
        content: "max-w-[460px] sm:max-w-[360px] h-full flex-1",
        footer: "max-w-[460px] sm:max-w-[360px] pt-2 pb-1 !shrink !h-auto",
      }}
      content={
        // <span className="line-clamp-3 leading-6 h-[75px]">{details}</span>
        <></>
      }
      footer={
        <>
          <ProfileBlock
            showName
            userId={id}
            firstName={firstName}
            lastName={lastName}
            createdAt={date}
            profileImage={profileImage}
            email={email}
            classNames={{ name: " " }}
            score={score}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseOver={() => {
              setShowTooltip(true);
            }}
            onMouseOut={() => {
              setShowTooltip(false);
            }}
            className="relative flex flex-row min-w-[36px]"
          >
            {!!rating ? (
              <span
                onClick={() => {
                  if (!!meData?.data.id) {
                    setShowRateSolutionPopup((val) => !val);
                  }
                }}
                className={classnames(
                  "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                  {
                    " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                      !!meData?.data.id,
                    " outline outline-[1px] outline-[#8159D7]":
                      showRateSolutionPopup,
                    " cursor-default ": !meData?.data.id,
                  }
                )}
              >
                {getRating(rating)}
                <RatingStar height={8} width={8} fill="black" />
              </span>
            ) : (
              <span
                onClick={() => {
                  if (!!meData?.data.id) {
                    setShowRateSolutionPopup((val) => !val);
                  }
                }}
                className={classnames(
                  "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                  {
                    " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                      !!meData?.data.id,
                    " cursor-default ": !meData?.data.id,
                  }
                )}
              >
                {0}
                <RatingStar height={8} width={8} fill="black" />
              </span>
            )}
            {/* {!ratingValue && (
              <CustomToolTip
                className="!bg-white !border-black !border !text-black !min-w-[110px] !bottom-[140%]"
                position="top"
                showTooltip={showTooltip}
                tooltipText={"Click to rate!"}
              />
            )} */}
            {showRateSolutionPopup && (
              <div className="absolute  text-black gradient-outline-forced-roundless text-center    z-10 left-[-420%] xx:left-[-420%] lg:left-[-440%] top-[-600%] text-sm font-normal after:tooltipbottomarrow">
                <div className="flex flex-row relative  bg-white p-2">
                  {loadingCreate || loadingUpdate ? (
                    <div className="  w-[175px] h-[84px]">
                      <Loader type="rings" globalLoader={false} size="tiny" />
                    </div>
                  ) : (
                    <div className=" py-5 px-1">
                      <StarRatingItem
                        starSize="small"
                        classNames={{
                          label: "font-bold !text-xs",
                          input: "!gap-2 pt-2",
                        }}
                        title="How effective is this solution?"
                        ratingValue={ratingValue || 0}
                        onClick={(ratingValue) => {
                          if (
                            ratingValue &&
                            solutionRatingData &&
                            solutionRatingData?.data.items.length === 0
                          ) {
                            createRate(ratingValue);
                          }
                          if (
                            ratingValue &&
                            solutionRatingData &&
                            solutionRatingData?.data.items.length > 0
                          ) {
                            updateRate(ratingValue);
                          }
                        }}
                        setRatingValue={setRatingValue}
                      />
                    </div>
                  )}
                  <XIcon
                    onClick={() => {
                      setShowRateSolutionPopup(false);
                    }}
                    className="h-4 w-4 opacity-50  shrink-0 cursor-pointer"
                    aria-hidden="true"
                  />
                </div>
                <span className="absolute border-[8px] right-[20%] xx:right-[21%] lg:right-[16%] border-b-transparent border-t-[#8159D7] border-r-transparent border-l-transparent tooltipbottomarrow" />
              </div>
            )}
          </div>
        </>
      }
      tags={
        tags &&
        tags?.length > 0 && (
          <div className="flex items-start flex-wrap gap-x-2 justify-start px-6">
            {tags?.map((tag) => (
              <BorderBadge
                key={tag}
                classificationName={tag}
                styleLess
                classNames={{
                  text: "text-gray-500 text-xs underline",
                }}
              >
                {tag}
              </BorderBadge>
            ))}
          </div>
        )
      }
    />
  );
}

export default memo(SolutionCard);
