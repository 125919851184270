/* This example requires Tailwind CSS v2.0+ */
import { memo } from "react";

import classnames from "classnames";
import { twMerge } from "tailwind-merge";

interface IProps {
  showTooltip?: boolean;
  tooltipText: string;
  className?: string;
  position?: "top" | "bottom" | "left" | "right";
  badgeStyle?: boolean;
  useProsToShowTooltip?: boolean;
  classNames?: { text: string; arrow: string };
}

function CustomToolTip({
  showTooltip,
  className,
  tooltipText,
  position = "bottom",
  badgeStyle = true,
  useProsToShowTooltip = true,
}: IProps) {
  return (
    <span
      className={twMerge(
        classnames(
          `absolute bg-gray-500 bg-opacity-70  text-white table min-w-full max-w-[120%]  break-words text-center rounded-[6px] px-2 py-1.5 z-[100]    font-bold  `,
          {
            invisible: useProsToShowTooltip && !showTooltip,
            visible: useProsToShowTooltip && showTooltip,
            " top-[90%] left-[50%] translate-x-[-50%]":
              position === "bottom" && !badgeStyle,
            " bottom-[105%] left-[50%] translate-x-[-50%]":
              position === "top" && !badgeStyle,
            " tooltipbadgetoparrow top-[105%] left-[50%] translate-x-[-50%]":
              position === "bottom" && badgeStyle,
            "  tooltipbadgebottomarrow bottom-[125%] left-[50%] translate-x-[-50%]":
              position === "top" && badgeStyle,
          },
          className
        )
      )}
    >
      <p className="font-bold text-sm h-full   ">{tooltipText}</p>
      <span
        className={classnames("absolute border-[8px] ", {
          "tooltiptoparrow  bottom-[100%] left-[50%] border-t-transparent border-b-[#000] border-r-transparent border-l-transparent translate-x-[-50%]":
            position === "bottom" && badgeStyle,
          "  bottom-[100%] left-[50%] border-t-transparent border-b-[#9c9c9c] border-r-transparent border-l-transparent translate-x-[-50%]":
            position === "bottom" && !badgeStyle,
          " tooltipbottomarrow  top-[101%] left-[50%] border-b-transparent border-t-[#000] border-r-transparent border-l-transparent translate-x-[-50%]":
            position === "top" && badgeStyle,
          "   top-[101%] left-[50%] border-b-transparent border-t-[#9c9c9c] border-r-transparent border-l-transparent translate-x-[-50%]":
            position === "top" && !badgeStyle,
        })}
      />
    </span>
  );
}

export default memo(CustomToolTip);
