import { AxiosResponse } from "axios";
import { ENDPOINTS } from "const";
import { FormikHelpers } from "formik";
import { useActionGroups, useMe } from "hooks";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { assignActionGroups } from "services";
import { ISelectOption, ISolution } from "types";
import { showErrorMessage, showSuccessMessage } from "utils";

import * as yup from "yup";

interface IActionGroupOption
  extends ISelectOption<string | undefined, string | undefined> {}

export enum FIELD_NAMES {
  ACTION_GROUPS = "actionGroups",
}
export interface IValues {
  [FIELD_NAMES.ACTION_GROUPS]: IActionGroupOption[];
}

interface IAssignActionGroupProps
  extends Partial<
    UseMutationOptions<AxiosResponse<Partial<ISolution>>, any, any>
  > {
  id?: string | undefined; // solution id
}

export function useAssignActionGroups({
  id = "",
  ...rest
}: IAssignActionGroupProps) {
  return useMutation<AxiosResponse<Partial<ISolution>>, any, any>(
    (values: string[]) => assignActionGroups({ id, actionGroups: values }),
    {
      ...rest,
    }
  );
}

export function useInitialState(solution: string | undefined) {
  const { data: me } = useMe();

  const { data } = useActionGroups({
    user: me?.data.id,
    solution,
    suspense: true,
    keepPreviousData: true,
  });
  return useMemo(() => {
    const actionGroups = _.compact(
      data?.data?.items?.map((item) => ({
        value: item.id,
        label: item.title,
      }))
    );
    return { actionGroups: actionGroups || [] };
  }, [data?.data?.items]);
}

export function useSubmitHandler(solution: string | undefined) {
  const { mutateAsync } = useAssignActionGroups({ id: solution });
  const queryClient = useQueryClient();

  return useCallback(
    async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
      try {
        formikHelpers.setSubmitting(true);
        await mutateAsync(values.actionGroups.map((item) => item.value));
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
        formikHelpers.setSubmitting(false);
        showSuccessMessage("Changes are saved!");
      } catch (error) {
        showErrorMessage("Error occurred!");
      }
    },
    [mutateAsync, queryClient]
  );
}
export function validationSchema() {
  return yup.object().shape({
    actionGroups: yup.array().of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    ),
  });
}
