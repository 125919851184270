import { useCallback, useEffect, useState, useRef } from "react";

export function useHookWithRefCallback() {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [ref, setRef];
}

export function useMenuScroll() {
  const [isScrolledMenu, setIsScrolledMenu] = useState(false);
  useEffect(() => {
    const eventListener = () => {
      if (!isScrolledMenu && window.scrollY >= 50) {
        setIsScrolledMenu(true);
      }
      if (isScrolledMenu && window.scrollY < 50) {
        setIsScrolledMenu(false);
      }
    };
    window.addEventListener("scroll", eventListener);
    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [isScrolledMenu, setIsScrolledMenu]);
  return isScrolledMenu;
}

export function useElementScroll(
  element: HTMLDivElement | null,
  trigger?: any
) {
  const [scrollable, setIsScrollable] = useState(true);
  const [scrollableToLeft, setIsScrollableToLeft] = useState(false);
  const [scrollableToRight, setIsScrollableToRight] = useState(true);
  useEffect(() => {
    const currentElement = element;
    if (currentElement) {
      if (currentElement.scrollWidth <= currentElement.clientWidth) {
        setIsScrollable(false);
      }
      if (currentElement.scrollWidth > currentElement.clientWidth) {
        setIsScrollable(true);
        setIsScrollableToRight(true);
      }

      const eventListener = () => {
        if (currentElement.scrollWidth > currentElement.clientWidth) {
          setIsScrollable(true);
          setIsScrollableToRight(true);
        }
        if (currentElement.scrollWidth <= currentElement.clientWidth) {
          setIsScrollable(false);
        }
        const scrollXOffset =
          currentElement.scrollWidth - currentElement.clientWidth;
        const scrollLeft = currentElement.scrollLeft;

        if (scrollable && scrollLeft === 0) {
          setIsScrollableToLeft(false);
          setIsScrollableToRight(true);
        }
        if (scrollable && scrollLeft > 0 && scrollLeft < scrollXOffset) {
          setIsScrollableToLeft(true);
          setIsScrollableToRight(true);
        }
        if (scrollable && scrollLeft === scrollXOffset) {
          setIsScrollableToLeft(true);
          setIsScrollableToRight(false);
        }
      };
      currentElement.addEventListener("scroll", eventListener);
      return () => {
        currentElement.removeEventListener("scroll", eventListener);
      };
    }
  }, [
    setIsScrollable,
    setIsScrollableToLeft,
    setIsScrollableToRight,
    element,
    trigger,
    scrollable,
  ]);

  return { scrollable, scrollableToLeft, scrollableToRight };
}

export function useScrollOnDrag(element: HTMLDivElement | null) {
  const [dragOnScrollParams, setDragOnScrollParams] = useState<{
    isDown: boolean;
    startX: number;
    scrollLeft: number;

    momentumId: number;
  }>({
    isDown: false,
    startX: NaN,
    scrollLeft: NaN,
    momentumId: NaN,
  });
  const velX = useRef(0);
  const currentElement = element;
  const [isAnimating, setIsAnimating] = useState(false);

  const cancelMomentumTracking = useCallback(() => {
    cancelAnimationFrame(dragOnScrollParams.momentumId);
  }, [dragOnScrollParams]);
  const momentumLoop = useCallback(() => {
    if (currentElement) {
      setIsAnimating(true);
      currentElement.scrollLeft += velX.current;
      velX.current *= 0.95;

      if (Math.abs(velX.current) > 0.5) {
        setDragOnScrollParams((prevValue) => ({
          ...prevValue,
          momentumId: requestAnimationFrame(momentumLoop),
        }));
      } else {
        setIsAnimating(false);
      }
    }
  }, [currentElement]);
  const beginMomentumTracking = useCallback(() => {
    cancelMomentumTracking();
    setDragOnScrollParams((prevValue) => ({
      ...prevValue,
      momentumId: requestAnimationFrame(momentumLoop),
    }));
  }, [cancelMomentumTracking, momentumLoop]);

  useEffect(() => {
    if (currentElement) {
      const mouseDownHandler = (e: MouseEvent) => {
        dragOnScrollParams.isDown = true;
        currentElement.classList.add("active");
        dragOnScrollParams.startX = e.pageX - currentElement.offsetLeft;
        dragOnScrollParams.scrollLeft = currentElement.scrollLeft;
        cancelMomentumTracking();
      };
      const mouseLeaveHandler = () => {
        dragOnScrollParams.isDown = false;
        currentElement.classList.remove("active");
      };
      const mouseUpHandler = () => {
        dragOnScrollParams.isDown = false;
        currentElement.classList.remove("active");
        beginMomentumTracking();
      };
      const mouseMoveHandler = (e) => {
        if (!dragOnScrollParams.isDown) return;

        e.preventDefault();
        setIsAnimating(true);
        const x = e.pageX - currentElement.offsetLeft;
        const walk = (x - dragOnScrollParams.startX) * 3; //scroll-fast
        var prevScrollLeft = currentElement.scrollLeft;
        currentElement.scrollLeft = dragOnScrollParams.scrollLeft - walk;
        velX.current = currentElement.scrollLeft - prevScrollLeft;
      };
      const wheelHandler = () => {
        cancelMomentumTracking();
      };
      currentElement.addEventListener("mousedown", mouseDownHandler);
      currentElement.addEventListener("mouseleave", mouseLeaveHandler);
      currentElement.addEventListener("mouseup", mouseUpHandler);
      currentElement.addEventListener("mousemove", mouseMoveHandler);
      currentElement.addEventListener("wheel", wheelHandler);

      return () => {
        if (currentElement) {
          currentElement.removeEventListener("mousedown", mouseDownHandler);
          currentElement.removeEventListener("mouseleave", mouseLeaveHandler);
          currentElement.removeEventListener("mouseup", mouseUpHandler);
          currentElement.removeEventListener("mousemove", mouseMoveHandler);
          currentElement.removeEventListener("wheel", wheelHandler);
        }
      };
    }
  }, [
    beginMomentumTracking,
    cancelMomentumTracking,
    momentumLoop,
    dragOnScrollParams,
    currentElement,
  ]);
  return [isAnimating];
}
