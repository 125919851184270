import { memo, useState } from "react";

import { SolutionCardModal } from "components";

import classnames from "classnames";
import { useParams } from "react-router-dom";

import { useSolutionList } from "modules/SolutionList/SolutionList.hooks";
import { useMe } from "hooks";
import { SolutionCard } from "modules/SolutionList/SolutionCard";
import { getRating } from "utils";

interface IProps {
  profileOwner?: boolean;
}

function ProfileSolutionsCard({ profileOwner }: IProps) {
  const { data: meData } = useMe();
  const params = useParams<{ userId: string }>();
  const { data: solutionsData } = useSolutionList({
    user: meData?.data && profileOwner ? meData?.data.id! : params.userId!,
    skills: [],
    tags: [],
    category: [],
    zoom: 1,
    lat: "",
    lng: "",
    suspense: false,
    withDeleted: true,
  });

  const [openCardModal, setOpenCardModal] = useState(false);
  const [openedCardId, setOpenedCardId] = useState<string | undefined>(
    undefined
  );
  const [hoveredIssueId] = useState<string | undefined | string[]>(undefined);
  const [, setHoveredIssueType] = useState<
    "regular" | "city" | "country" | undefined
  >();
  const [, setIsIssueHovered] = useState(false);

  return (
    <div
      className={classnames(
        "grid lg:grid-cols-[1fr_1fr_1fr_1.3fr]   h-full lg:h-[calc(100vh-118px)]  lg:mr-0 gap-4    "
      )}
    >
      <div
        className={classnames(
          "grid grid-cols-1 auto-rows-max gap-4 lg:overflow-scroll pb-4 pt-[3px] pl-[3px]    pr-2 max-w-[2000px] transition-all duration-500 ease-linear sm:grid-cols-2 lg:grid-cols-4 lg:col-span-4"
        )}
      >
        {solutionsData?.pages.map((page) => {
          return page.data.items.length < 1 ? (
            <div className="flex   justify-center col-span-6">
              <p className=" max-w-[700px] text-center text-xl">
                {profileOwner
                  ? "You have not proposed any solution"
                  : "There are no solutions proposed by this user"}
              </p>
            </div>
          ) : (
            page.data.items.map((solution) => {
              const isFormattedAddress =
                !!solution?.issue.location?.formattedAddress;
              const isCountry = !!solution?.issue?.location?.country;
              const isCity = !!solution?.issue?.location?.city;
              const isStreetNumber = !!solution?.issue?.location?.streetNumber;
              const isRoute = !!solution?.issue?.location?.route;
              const location =
                typeof solution?.issue?.location === "object"
                  ? isFormattedAddress
                    ? `${solution?.issue?.location?.formattedAddress}`
                    : isCountry && isCity && isStreetNumber && isRoute
                    ? `${solution?.issue?.location?.route} ${solution?.issue?.location?.streetNumber}, ${solution?.issue?.location?.city}, ${solution?.issue?.location?.country}`
                    : isCountry && isCity
                    ? ` ${solution?.issue?.location?.city}, ${solution?.issue?.location?.country}`
                    : isCountry
                    ? `${solution?.issue?.location?.country}`
                    : "No location"
                  : "No location";
              const geoLocation = {
                lat: solution?.issue.location.lat,
                lng: solution?.issue.location.lng,
              };

              const issueType =
                solution?.issue?.location?.administrativeArea ||
                solution?.issue?.location?.route ||
                solution?.issue?.location?.streetNumber
                  ? "regular"
                  : solution?.issue?.location?.city
                  ? "city"
                  : "country";
              return (
                <div
                  className=" cursor-pointer z-10  maxmd:flex"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    solution && setOpenedCardId(solution.id);
                    solution && setOpenCardModal(true);
                  }}
                  key={solution.id}
                >
                  <SolutionCard
                    solutionId={solution.id}
                    id={solution?.user.id}
                    skills={solution?.primaryActionGroup?.skills}
                    issueType={issueType}
                    setHoveredIssueType={setHoveredIssueType}
                    forceHoverIssue={hoveredIssueId?.includes(
                      solution.id || ""
                    )}
                    setIsIssueHovered={setIsIssueHovered}
                    geoLocation={geoLocation}
                    title={solution.title}
                    location={location}
                    profileImage={solution?.user?.avatar}
                    firstName={solution.user?.firstName}
                    lastName={solution.user?.lastName}
                    createdAt={solution.createdAt}
                    rating={
                      solution.impactRanking
                        ? getRating(solution.impactRanking)
                        : 0
                    }
                    primaryClassification={"climate"}
                    secondaryClassification={"climate"}
                    email={solution?.user?.email}
                    score={solution?.user.reputationScore}
                    isSoftDeleted={!!solution?.deletedAt}
                  />
                </div>
              );
            })
          );
        })}
      </div>
      {openCardModal && openedCardId && (
        <SolutionCardModal id={openedCardId} setShow={setOpenCardModal} />
      )}
    </div>
  );
}

export default memo(ProfileSolutionsCard);
