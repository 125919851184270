import { ReactComponent as Facebook } from "assets/images/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/twitter.svg";

export function Footer() {
  return (
    <footer className="flex w-full bg-black items-center justify-between px-5 py-4">
      <span className=" font-light text-xs text-white">
        {`© ${new Date().getFullYear()} WEattitude.org. All rights reserved.`}
      </span>
      <div className="flex items-center gap-3">
        <Facebook className="fill-white" />
        <Instagram className="fill-white" />
        <Twitter className="fill-white" />
      </div>
    </footer>
  );
}
export default Footer;
