import { APP_ROUTES } from "const";
import { memo, useState } from "react";
import { Card, BorderBadge } from "components";

import ChevronDown from "assets/images/chevron-down.svg";

import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { getRating } from "utils";
import ArrowRight from "assets/images/arrow-right.svg";
import ArrowRightHovered from "assets/images/arrow-right-hovered.svg";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { FullIssueType } from "types";

interface IProps {
  issues: FullIssueType[] | undefined;
  params: {
    lat: string | undefined;
    lng: string | undefined;
  };
}

interface IRatingBlockProps {
  rating: number;
  showRatingTooltip: boolean;
}

function RatingBlock({ rating, showRatingTooltip }: IRatingBlockProps) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (showRatingTooltip) {
          setOpenPopup(true);
        }
      }}
      onMouseOut={() => {
        setOpenPopup(false);
      }}
      className={classnames(
        " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start  px-2 text-center  ",
        {
          " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
            openPopup,
        }
      )}
    >
      {getRating(rating) || 0}
      <RatingStar height={8} width={8} fill="black" />
      {showRatingTooltip && openPopup && (
        <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[150%] text-sm font-normal after:tooltiptoparrow">
          <div className="flex flex-col relative text-xs bg-white p-2">
            <p>How relevant is this issue? </p>
            <p>Please click on issue to rate</p>
          </div>
          <span className="absolute border-[8px] top-[-17px] right-[9%] xx:right-[25%] lg:right-[31%] border-t-transparent border-b-[#8159D7] border-r-transparent border-l-transparent tooltiptoparrow" />
        </div>
      )}
    </span>
  );
}

function DashboardIssuesCard({ issues, params }: IProps) {
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <Card
      className="bg-white !py-2 !gap-0"
      shouldHover={false}
      collapse={descriptionCollapsed}
      header={
        <div
          // onTouchStart={() => {
          //   setDescriptionCollapsed((val) => !val);
          // }}
          // onTouchEnd={(e) => {
          //   e.preventDefault();
          // }}
          className={classnames("flex justify-between items-center py-2  ", {
            "pb-4": !descriptionCollapsed,
            "pb-2": descriptionCollapsed,
          })}
        >
          <div className="grid grid-flow-col gap-x-2 items-center sm:h-[40px] ">
            <img
              alt="chevron-down"
              className={classnames("cursor-pointer lg:hidden", {
                "rotate-180": descriptionCollapsed,
              })}
              onClick={() => {
                setDescriptionCollapsed((val) => !val);
              }}
              src={ChevronDown}
            />
            <span className="font-bold leading-6 text-lg">Issues Nearby</span>
          </div>
        </div>
      }
      classNames={{
        header: "px-10 ",
        content: "!px-0 flex-1 ",
        footer: "px-16 h-[60px]",
      }}
      showFooterDivider={false}
      content={
        <div className="max-h-[600px] overflow-y-auto overflow-x-hidden  p-[1px] ">
          <div className="flex flex-col  ">
            {issues && issues.length > 0 ? (
              issues.map((issue) => {
                return (
                  <div
                    key={issue.id}
                    onClick={() => {
                      navigate(APP_ROUTES.ISSUE + "/" + issue.id);
                    }}
                    className={classnames(
                      "relative  z-10  hover:bg-[#F7F6F6] h-full   cursor-pointer "
                    )}
                  >
                    <div className="  z-20 hover:bg-[#F7F6F6] bg-white">
                      <div className="grid grid-flow-row gap-x-2  h-full gap-y-2 py-8 items-center z-20 maxSm:px-6  px-16">
                        <div className="flex flex-row justify-between">
                          <span className="text-black font-semibold leading-6 text-md line-clamp-2">
                            {issue.title}
                          </span>

                          <span className="flex justify-between items-start ">
                            <div className="flex flex-col">
                              {!!issue.rating ? (
                                <RatingBlock
                                  rating={getRating(issue.rating)}
                                  showRatingTooltip
                                />
                              ) : (
                                <RatingBlock rating={0} showRatingTooltip />
                              )}
                            </div>
                          </span>
                        </div>
                        <span className="leading-6 text-md  text-gray-400 line-clamp-1 font-light">
                          {issue.details}
                        </span>
                        <div className="flex flex-col flex-wrap h-full min-h-[20px]  gap-x-2 gap-y-2 md:gap-x-4  ">
                          Issue Category
                          <div className="flex flex-wrap gap-4 pt-2">
                            {!!issue.primaryClassification && (
                              <BorderBadge
                                classificationName={
                                  issue.primaryClassification.category
                                }
                                classificationDescription={
                                  issue.primaryClassification.description
                                }
                                tooltipId={issue.id}
                              >
                                {issue.primaryClassification.title}
                              </BorderBadge>
                            )}
                            {!!issue.secondaryClassification && (
                              <BorderBadge
                                classificationName={
                                  issue.secondaryClassification.category
                                }
                                classificationDescription={
                                  issue.secondaryClassification.description
                                }
                                tooltipId={issue.id}
                              >
                                {issue.secondaryClassification.category}
                              </BorderBadge>
                            )}
                          </div>
                          {!issue?.primaryClassification &&
                            !issue?.secondaryClassification && (
                              <div>No categories assigned</div>
                            )}
                        </div>
                      </div>
                      <div
                        className={classnames(
                          "border-b border-gray-200 w-full h-[1px] py-1"
                        )}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col items-center px-16 py-6 w-full">
                <span className="text-black font-normal text-md">
                  <p className="  text-center ">
                    No more issues found , please
                    <span
                      onClick={() => {
                        navigate(APP_ROUTES.ISSUES);
                      }}
                      className="  px-1 font-bold   cursor-pointer"
                    >
                      browse an issue
                    </span>
                  </p>
                </span>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div>
          <Link
            onMouseOver={() => {
              setLinkHovered(true);
            }}
            onMouseOut={() => {
              setLinkHovered(false);
            }}
            to={
              params.lng
                ? APP_ROUTES.ISSUES +
                  `?lng=${params.lng}&lat=${params.lat}&zoom=9`
                : APP_ROUTES.ISSUES
            }
          >
            <div className="flex flex-row gap-3 text-gradient">
              <p className="font-semibold ">View All</p>
              <img
                alt="arrow"
                src={linkHovered ? ArrowRightHovered : ArrowRight}
              />
            </div>
          </Link>
        </div>
      }
    />
  );
}

export default memo(DashboardIssuesCard);
