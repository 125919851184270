import { memo, useState } from "react";
import { Card } from "components";
import ChevronDown from "assets/images/chevron-down.svg";
import { ReactComponent as Location } from "assets/images/location.svg";
import classnames from "classnames";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { FullIssueType, ILocation } from "types";
import { Tooltip } from "react-tooltip";

interface IProps {
  issue: FullIssueType | undefined;
  allLocations?: (Partial<ILocation> | undefined)[] | undefined;
  isSoftDeleted?: boolean;
}

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function StaticMapCard({ issue, allLocations, isSoftDeleted }: IProps) {
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  const markerCoordinates = allLocations?.map((location) => ({
    lat: location?.lat,
    lng: location?.lng,
  }));

  let zoom = 4;

  if (markerCoordinates?.length === 0) {
    zoom = 1;
  }

  if (markerCoordinates && markerCoordinates?.length > 1) {
    const LatLngArray = markerCoordinates?.map(
      (coord) =>
        coord.lat && coord.lng && new google.maps.LatLng(coord.lat, coord.lng)
    );

    const bounds = new google.maps.LatLngBounds();
    LatLngArray?.forEach((latLng) => {
      bounds.extend(latLng as google.maps.LatLng);
    });

    zoom = computeZoomLevel(bounds) - 1;
  }

  const staticMapZoom = zoom > 0 ? zoom : 0;

  return (
    <Card
      className={classnames("!gap-0", {
        "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
      })}
      shouldHover={false}
      collapse={descriptionCollapsed}
      header={
        <div
          // onTouchStart={() => {
          //   setDescriptionCollapsed((val) => !val);
          // }}
          // onTouchEnd={(e) => {
          //   e.preventDefault();
          // }}
          className="flex justify-between items-center pb-1"
        >
          <div className="grid grid-flow-col gap-x-2 items-center  ">
            <img
              alt="chevron-down"
              className={classnames("cursor-pointer lg:hidden", {
                "rotate-180": descriptionCollapsed,
              })}
              onClick={() => {
                setDescriptionCollapsed((val) => !val);
              }}
              src={ChevronDown}
            />
            <div className="flex flex-col gap-2">
              <span className="text-black font-bold leading-6 text-lg">
                {issue?.title}
              </span>
              <div className="flex flex-row items-center gap-2">
                <Location className="opacity-30" />
                <span
                  className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light"
                  data-tooltip-id={
                    !!allLocations && allLocations?.length > 1
                      ? "locations_for_" + issue?.id
                      : ""
                  }
                >
                  {!!allLocations &&
                    allLocations?.length > 0 &&
                    allLocations[0]?.formattedAddress}{" "}
                  {!!allLocations &&
                    allLocations?.length > 1 &&
                    "+" + (allLocations?.length - 1)}
                </span>
                <Tooltip
                  id={"locations_for_" + issue?.id}
                  place="top"
                  className="white-tooltip font-semibold !rounded-md !p-2 z-30 max-w-[260px]"
                >
                  <ul className="list-disc pl-4">
                    {allLocations?.map((location, index) => (
                      <li key={index}>{location?.formattedAddress}</li>
                    ))}
                  </ul>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      }
      classNames={{
        header: "px-6 sm:px-16  !justify-center",
        content: "!p-0 flex-1",
      }}
      showFooterDivider={false}
      content={
        <div className="flex justify-center bg-gray-100">
          <StaticGoogleMap
            className=" "
            maptype="roadmap"
            // eslint-disable-next-line react/style-prop-object
            style="style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e"
            size="600x300"
            zoom={staticMapZoom}
            apiKey={API_KEY}
          >
            {allLocations?.map((location, index) => (
              <Marker
                key={index}
                iconURL="https://i.ibb.co/rwKFf53/marker-Highlight.png"
                location={`${location?.lat}, ${location?.lng}`}
              />
            ))}
          </StaticGoogleMap>
        </div>
      }
    />
  );
}

function computeZoomLevel(viewport: google.maps.LatLngBounds | null) {
  const defaultZoom = 20;
  if (!viewport) return defaultZoom;

  // Approximate Earth circumference in meters at the equator
  const CIRCUMFERENCE = 40075000;

  // Calculate the distance between northeast and southwest points of the viewport.
  let latDiff = viewport.getNorthEast().lat() - viewport.getSouthWest().lat();
  let lngDiff = viewport.getNorthEast().lng() - viewport.getSouthWest().lng();
  let diagonalDistance =
    Math.sqrt(latDiff * latDiff + lngDiff * lngDiff) * (CIRCUMFERENCE / 360);

  // Use the logarithm to compute the zoom level
  const maxZoom = 20; // the most zoomed-in level
  const baseline = 50; // this is an adjusted value based on Madagascar's diagonal
  let zoom = Math.round(maxZoom - Math.log2(diagonalDistance / baseline));

  // Clip the zoom level to be between 0 and 20
  zoom = Math.min(Math.max(zoom, 0), 20);

  return zoom;
}

export default memo(StaticMapCard);
