/* This example requires Tailwind CSS v2.0+ */
import { memo } from "react";
import { Disclosure } from "@headlessui/react";

import { APP_ROUTES } from "const";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { useMe, useMenuScroll } from "hooks";

import { useActivitiesNotificationOnUpdate } from "./MenuBar.hooks";

import { Step } from "react-joyride";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const navigation = [
  { name: "Dashboard", href: APP_ROUTES.DASHBOARD, current: false },
  { name: "Issues", href: APP_ROUTES.ISSUES, current: false },
  { name: "Solutions", href: APP_ROUTES.SOLUTION_LIST, current: false },
  { name: "Action Groups", href: APP_ROUTES.ACTION_GROUP_LIST, current: false },
  {
    name: "Motivational Contents",
    href: APP_ROUTES.MOTIVATIONAL_LIST,
    current: false,
  },
  { name: "Handout Sheets", href: APP_ROUTES.HANDOUTS, current: false },
];

function useNavigationLinks() {
  const location = useLocation();

  return navigation.map((navItem) => ({
    ...navItem,
    current: location.pathname.includes(navItem.href),
  }));
}

interface IProps {
  isAuth?: boolean;
  setPdfUrl?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setHandoutsNoGroupsFound?: React.Dispatch<
    React.SetStateAction<"NoGroups" | "Groups" | "NoSearch">
  >;
}

export function MenuBar({
  isAuth,
  setPdfUrl,
  setHandoutsNoGroupsFound,
}: IProps) {
  const navigation = useNavigationLinks();
  const { data: profile, isFetching } = useMe({
    enabled: !!isAuth,
    retry: false,
    keepPreviousData: false,
    suspense: false,
  });
  const isScrolled = useMenuScroll();
  useActivitiesNotificationOnUpdate(!!isAuth);
  return (
    <header className={classnames(" z-30  top-0 ", { sticky: isScrolled })}>
      <Disclosure as="nav" className={classnames("relative ")}>
        {({ open, close }) => {
          return (
            <div
              className={classnames(" w-full", {
                "h-full": open,
                "bg-transparent": !isScrolled,
                "bg-white shadow-lg": isScrolled,
              })}
            >
              <DesktopMenu
                isFetching={isFetching}
                isAuth={isAuth}
                setHandoutsNoGroupsFound={setHandoutsNoGroupsFound}
                setPdfUrl={setPdfUrl}
                navigation={navigation}
                profile={profile?.data}
                isScrolled={isScrolled}
              />
              <MobileMenu
                isFetching={isFetching}
                isAuth={isAuth}
                open={open}
                navigation={navigation}
                profile={profile?.data}
                close={close}
                isScrolled={isScrolled}
              />
            </div>
          );
        }}
      </Disclosure>
    </header>
  );
}
export default memo(MenuBar);
