export enum FIELD_NAMES {
  EMAIL = "email",
  PASSWORD = "password",
}

export const MAX_FORM_FIELDS_LENGTH = 24;
export const MIN_PASSWORD_LENGTH = 6;

export const ERRORS = [
  //https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
  {
    code: "auth/account-exists-with-different-credential",
    field: "email",
    message: "Account exists with different credential",
  },
  {
    code: "auth/invalid-credential",
    field: "email",
    message: "Invalid credential",
  },
  {
    code: "auth/operation-not-allowed",
    field: "email",
    message: "Operation not allowed",
  },
  {
    code: "auth/user-disabled",
    field: "email",
    message: "User disabled",
  },
  {
    code: "auth/user-not-found",
    field: "email",
    message: "User not found",
  },
  {
    code: "auth/wrong-password",
    field: "password",
    message: "Wrong password",
  },
  {
    code: "auth/invalid-verification-code",
    field: "email",
    message: "Invalid verification code",
  },
  {
    code: "auth/invalid-verification-id",
    field: "email",
    message: "Invalid verification id",
  },
  {
    code: "auth/too-many-requests",
    field: "email",
    message: "Too many requests! Try later.",
  },
];
