import classnames from "classnames";
import { memo } from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
  issueType?: "regular" | "city" | "country";
  setHoveredIssueType?: React.Dispatch<
    React.SetStateAction<"regular" | "city" | "country" | undefined>
  >;
  setIsIssueHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  setIssueHoveredProps?: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  forceHoverIssue?: boolean;
  geoLocation?: {
    lat?: number | undefined;
    lng?: number | undefined;
  };
  header?: React.ReactNode | React.ReactNode[];
  subHeader?: React.ReactNode | React.ReactNode[];
  showSubHeaderDivider?: boolean;
  content?: React.ReactNode | React.ReactNode[];
  list?: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode | React.ReactNode[];
  collapse?: boolean;
  customClickEventsOnCardAreas?: {
    headerBlock?: () => void;
    contentBlock?: () => void;
    footerBlock?: () => void;
    dividersBlock?: () => void;
  };
  classNames?: {
    header?: string;
    subHeader?: string;
    content?: string;
    list?: string;
    footer?: string;
    contentInner?: string;
  };
  showContent?: boolean;
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
  shouldHover?: boolean;
  showHeaderDivider?: boolean;
  showFooterDivider?: boolean;
  defaultStyle?: boolean;
  tags?: React.ReactNode | React.ReactNode[];
}

function Card({
  header,
  content,
  issueType,
  setHoveredIssueType,
  setIsIssueHovered,
  setIssueHoveredProps,
  forceHoverIssue,
  shouldHover = true,
  list,
  customRef,
  geoLocation,
  footer,
  subHeader,
  classNames,
  customClickEventsOnCardAreas,
  collapse = false,
  className,
  showContent = true,
  showSubHeaderDivider = false,
  showHeaderDivider = true,
  showFooterDivider = true,
  defaultStyle = true,
  tags,
}: IProps) {
  return (
    <div
      ref={customRef}
      onMouseOver={() => {
        setHoveredIssueType && setHoveredIssueType(issueType);
        setIsIssueHovered && setIsIssueHovered(true);
        geoLocation &&
          geoLocation.lng &&
          geoLocation.lat &&
          setIssueHoveredProps &&
          setIssueHoveredProps({
            lat: geoLocation?.lat,
            lng: geoLocation?.lng,
          });
      }}
      onMouseOut={() => {
        setHoveredIssueType && setHoveredIssueType(undefined);
        setIsIssueHovered && setIsIssueHovered(false);
        setIssueHoveredProps && setIssueHoveredProps({ lat: NaN, lng: NaN });
      }}
      className={classnames(
        "bg-white rounded-lg relative flex flex-col gap-2 py-4 transition-all duration-300 ease-in-out",
        {
          " shadow-issueCardStrong": forceHoverIssue && shouldHover,
          "  shadow-issueCard hover:shadow-issueCardStrong":
            shouldHover && defaultStyle,
          " bg-white ": defaultStyle,
          " bg-transparent hover:bg-white hover:shadow-lg": !defaultStyle,
        },
        className
      )}
    >
      <div className="w-full flex flex-col gap-2">
        <div
          onClick={customClickEventsOnCardAreas?.headerBlock}
          className={twMerge(
            classnames(
              "flex flex-col gap-2 w-full px-6 shrink-0 justify-between",
              classNames?.header
            )
          )}
        >
          {header}
        </div>
        {showSubHeaderDivider && (
          <div
            onClick={customClickEventsOnCardAreas?.dividersBlock}
            className={classnames(
              {
                "maxLg:h-0 maxLg:hidden": collapse,
              },
              "border-b border-gray-200 w-full h-[1px]"
            )}
          />
        )}
        {subHeader && (
          <div
            onClick={customClickEventsOnCardAreas?.headerBlock}
            className={classnames(
              "flex  flex-col gap-2 w-full  px-6   shrink-0 justify-between",
              classNames?.subHeader
            )}
          >
            {subHeader}
          </div>
        )}
        {showHeaderDivider && (
          <div
            onClick={customClickEventsOnCardAreas?.dividersBlock}
            className={classnames(
              {
                "maxLg:h-0 maxLg:hidden": collapse,
              },
              "border-b border-gray-200 w-full h-[1px]"
            )}
          />
        )}
        {showContent && (
          <div
            onClick={customClickEventsOnCardAreas?.contentBlock}
            className={classnames(
              "content flex w-full  flex-col gap-1 px-6  shrink-0 justify-between transition-all duration-300 ease-in-out",
              { "h-0 ": collapse },
              classNames?.content
            )}
          >
            <div
              className={classnames(
                "leading-5 h-full text-sm  font-normal text-gray-500 transition-all duration-300 ease-in-out ",
                { "h-0 hidden": collapse },
                classNames?.contentInner
              )}
            >
              {content}
            </div>
            {!!list && (
              <div
                className={classnames(
                  "flex gap-4 transition-all  duration-300 ease-in-out",
                  { "h-0 hidden": collapse },
                  classNames?.list
                )}
              >
                {list}
              </div>
            )}
          </div>
        )}
        {showFooterDivider && (
          <div
            onClick={customClickEventsOnCardAreas?.dividersBlock}
            className={classnames("border-b border-gray-200 w-full  ", {
              "h-0 hidden": collapse,
              "h-[1px]": !collapse,
            })}
          />
        )}
      </div>

      {footer && (
        <div
          onClick={customClickEventsOnCardAreas?.footerBlock}
          className={classnames(
            "footer w-full  flex justify-between  items-center px-6 transition-all duration-300 ease-in-out  ",
            { "h-0 hidden": collapse },
            classNames?.footer
          )}
        >
          {footer}
        </div>
      )}

      {tags && <>{tags}</>}
    </div>
  );
}

export default memo(Card);
