import {
  useMe,
  useMotivational,
  useUpdateMotivationalViews,
  useWindowDimensions,
} from "hooks";
import { memo, useEffect } from "react";
import { Button, CommentsCard } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getRating } from "utils";

import { MotivationalDetailCard } from "./MotivationalDetailCard";

import { APP_ROUTES } from "const";
import { MotivationalsCard } from "./MotivationalsCard";
import { useMotivationalListFilterParams } from "modules/MotivationalList/MotivationalList.hooks";
import { useResizeDetector } from "react-resize-detector";
import classnames from "classnames";

function Motivational() {
  const myStorage = window.localStorage;
  const params = useParams<{ id: string }>();
  const { mutateAsync: mutateUpdateMotivationalView } =
    useUpdateMotivationalViews(params.id);

  useEffect(() => {
    if (params.id) {
      if (myStorage.getItem("Viewed")) {
        let viewed = JSON.parse(myStorage.getItem("Viewed") as string);
        const isAlreadyViewedMotivational = !!viewed.includes(params.id);

        if (!isAlreadyViewedMotivational) {
          viewed.push(params.id);
          myStorage.setItem("Viewed", JSON.stringify(viewed));
          mutateUpdateMotivationalView({});
        }
      } else {
        let viewed: string[] = [];
        viewed.push(params.id);

        myStorage.setItem("Viewed", JSON.stringify(viewed));
        mutateUpdateMotivationalView({});
      }
    }
  }, [mutateUpdateMotivationalView, myStorage, params.id]);
  const navigate = useNavigate();
  const { data: me } = useMe();
  const { data, isLoading } = useMotivational({
    id: params.id,
    suspense: false,
    keepPreviousData: true,
  });

  const { width } = useResizeDetector();

  const dim = useWindowDimensions();

  const { data: motivationalsData, inViewRef } =
    useMotivationalListFilterParams(width || dim.width, false);

  const allowEdit = data?.data.user?.id === me?.data.id;
  return (
    <div className=" relative pb-4 max-w-[2000px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap">
        <span
          onClick={() => {
            navigate(APP_ROUTES.MOTIVATIONAL_LIST);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Motivational content
        </span>
        <span className="text-[#606060] font-normal text-md cursor-pointer">
          &gt; Motivational: {data?.data.title}
        </span>
      </div>
      <div
        className={classnames("  ", {
          " flex flex-row justify-end pb-4 pr-8 min-h-[60px] ": allowEdit,
          "fifteen-fifth-step absolute right-0 top-0 h-[50px] w-[100px]":
            !allowEdit,
        })}
      >
        {allowEdit && (
          <Button
            className=" fifteen-fifth-step "
            onClick={() => {
              navigate(APP_ROUTES.MOTIVATIONAL_EDIT + "/" + data?.data.id);
            }}
            disabled={!allowEdit}
          >
            Edit
          </Button>
        )}
      </div>
      <div className=" grid grid-cols-[1fr] lg:grid-cols-mainMotivational min-h-0 min-w-0 gap-x-1 gap-y-5">
        <div className="flex flex-col gap-5 ">
          {data?.data.videoLink && (
            <div className="flex relative  justify-end items-start lg:min-h-[566px] max-h-[566px] h-full rounded-lg overflow-hidden  w-full   aspect-w-16  flex-row  ">
              <div style={{ padding: "100% 100% 0 0" }}>
                <iframe
                  src={`https://www.youtube.com/embed/${data?.data.videoLink
                    .split(/[/]+/)
                    .pop()}`}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  title="Sequence Builder Tutorial.mov"
                ></iframe>
              </div>
            </div>
          )}
          {data?.data.image && (
            <div className="flex relative  justify-end items-start  max-h-[306px]   w-full   aspect-w-16  flex-row  ">
              <img
                alt="banner"
                className="w-full aspect-[21/9] object-cover max-h-[306px]"
                src={(data?.data.image as string | undefined) || ""}
              />
            </div>
          )}

          <MotivationalDetailCard
            id={data?.data.id!}
            rate={data?.data.rates && data?.data.rates[0]}
            isLoading={isLoading}
            title={data?.data.title || ""}
            description={data?.data.description}
            createdAt={data?.data.createdAt || ""}
            user={data?.data.user}
            rating={getRating(5)}
            likes={data?.data.likes || 0}
            dislikes={data?.data.dislikes || 0}
            views={data?.data.views || 0}
          />
        </div>

        <MotivationalsCard
          className=" order-4 lg:order-3"
          innerRef={inViewRef}
          motivationals={motivationalsData?.pages}
        />
        <div className="order-3 lg:order-4">
          <CommentsCard entityOwnerId={data?.data.user.id} />
        </div>
      </div>
    </div>
  );
}

export default memo(Motivational);
