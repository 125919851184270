import {
  AttachmentsInitial,
  AttachmentsPayload,
  IAttachmentPayloadArguments,
} from "modules/Attachments/Attachments.types";
import {
  FullIssueType,
  IIssueRating,
  IIssueRatingCategory,
  ISelectOption,
} from "types";

export enum ISSUE_FIELD_NAMES {
  TITLE = "title",
  LOCATION = "location",
  DETAILS = "details",
  ORIGIN = "origin",
  IMPORTANCE = "importance",
  ATTACHMENTS = "attachments",
  TAGS = "tags",
}

export class IssueInitial extends AttachmentsInitial {
  [ISSUE_FIELD_NAMES.TITLE] = "";
  [ISSUE_FIELD_NAMES.DETAILS] = "";
  [ISSUE_FIELD_NAMES.ORIGIN] = "";
  [ISSUE_FIELD_NAMES.IMPORTANCE] = "";
  [ISSUE_FIELD_NAMES.LOCATION]: ISelectOption<string, string> | null = null;
  [ISSUE_FIELD_NAMES.TAGS]: string[] = [];

  constructor(
    issue: FullIssueType | undefined,
    issueRatingCategory: Partial<IIssueRatingCategory>[] | undefined,
    issueRatings:
      | Partial<IIssueRating<Partial<IIssueRatingCategory>>>[]
      | undefined
  ) {
    super(issue?.attachments);
    if (issue) {
      this.addTitle(issue.title);
      this.addDetails(issue.details);
      this.addOrigin(issue.origin);
      this.addImportance(issue.importance);
      this.addLocation(issue.location);
      this.addTags(issue.tags);
    }

    issueRatingCategory?.forEach((c) => {
      if (c.category) {
        this[c.category] = 0; //set default value for all categories
      }
    });
    issueRatings?.forEach((r) => {
      if (r.issueRatingCategory && r.issueRatingCategory.category) {
        this[r.issueRatingCategory.category] = r.rate; //fill categories with values from issueRatings
      }
    });
  }
  addTitle(title: FullIssueType["title"]) {
    if (title) {
      this[ISSUE_FIELD_NAMES.TITLE] = title;
    }
  }
  addDetails(details: FullIssueType["details"]) {
    if (details) {
      this[ISSUE_FIELD_NAMES.DETAILS] = details;
    }
  }
  addOrigin(origin: FullIssueType["origin"]) {
    if (origin) {
      this[ISSUE_FIELD_NAMES.ORIGIN] = origin;
    }
  }
  addImportance(importance: FullIssueType["origin"]) {
    if (importance) {
      this[ISSUE_FIELD_NAMES.IMPORTANCE] = importance;
    }
  }
  addLocation(location: FullIssueType["location"]) {
    let description = "";
    if (location?.country) {
      description = location.country;
    }
    if (location?.city) {
      description = description + ", " + location.city;
    }
    if (location?.administrativeArea) {
      description = description + ", " + location.administrativeArea;
    }

    if (location?.route) {
      description = description + ", " + location.route;
    }
    if (location?.streetNumber) {
      description = description + ", " + location.streetNumber;
    }
    this.location = {
      label: description,
      value: location?.placeId || "",
    };
  }
  addTags(tags: FullIssueType["tags"]) {
    if (tags) {
      this[ISSUE_FIELD_NAMES.TAGS] = tags;
    }
  }
}

export class IssuePayload extends AttachmentsPayload {
  [ISSUE_FIELD_NAMES.TITLE] = "";
  [ISSUE_FIELD_NAMES.DETAILS] = "";
  [ISSUE_FIELD_NAMES.ORIGIN] = "";
  [ISSUE_FIELD_NAMES.IMPORTANCE] = "";
  placeId: string | null | undefined = "";
  issueRatingCategory: Partial<IIssueRatingCategory>[] = [];

  constructor(
    {
      location,
      attachments: attachmentList,
      attachmentsInput,
      attachmentsQuill,
      attachmentsTitle,
      attachmentsType,
      ...issue
    }: Partial<IssueInitial> & Partial<IAttachmentPayloadArguments>,
    issueRatingCategory: Partial<IIssueRatingCategory>[] | undefined
  ) {
    super({
      attachmentsInput,
      attachmentsQuill,
      attachmentsTitle,
      attachmentsType,
      attachments: attachmentList,
    });
    if (issueRatingCategory) {
      this.issueRatingCategory = issueRatingCategory;
    }
    //
    Object.entries(issue).forEach(([key, value]) => {
      this[key] = value;
    });
    this.placeId = location?.value || "";
    //
  }
  getIssueRatings(issueId: string | undefined) {
    const issueRatings: {
      rate: Range;
      issueRatingCategory: string;
      issue: string | undefined;
    }[] = [];
    Object.entries(this).forEach(([key, value]) => {
      const cat = this.issueRatingCategory.find((i) => i.category === key);
      if (cat) {
        issueRatings.push({
          rate: value,
          issueRatingCategory: cat.id as string,
          issue: issueId,
        });
      }
    });
    return issueRatings;
  }
  getIssue() {
    const issue: Partial<FullIssueType> = {};
    Object.entries(this).forEach(([key, value]) => {
      if (
        (Object.values(ISSUE_FIELD_NAMES).includes(key as ISSUE_FIELD_NAMES) ||
          key === "placeId") &&
        key !== ISSUE_FIELD_NAMES.ATTACHMENTS
      ) {
        issue[key] = value;
      }
    });
    return issue;
  }
}
