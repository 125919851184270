import { Form } from "formik";
import classnames from "classnames";
import { Button, FormikInput, AuthPanel } from "components";

import {
  FIELD_NAMES,
  MAX_FORM_FIELDS_LENGTH,
} from "./CreateNewPasswordForm.constants";
import { ReactComponent as WeLogo } from "assets/images/we-logo.svg";

function CreateNewPasswordForm() {
  return (
    <AuthPanel>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <WeLogo />
        </div>

        <h2 className="mt-8 text-3xl font-bold text-gray-900 text-center">
          Create New Password
        </h2>
      </div>

      <div className="mt-16">
        <Form className={classnames("")}>
          <div className="mt-7">
            <FormikInput
              label="New password"
              autoComplete="nope"
              maxLength={MAX_FORM_FIELDS_LENGTH}
              name={FIELD_NAMES.PASSWORD}
              type="password"
              placeholder="New password"
            />
          </div>

          <div className="mt-7">
            <FormikInput
              label="Confirm new password"
              autoComplete="nope"
              maxLength={MAX_FORM_FIELDS_LENGTH}
              name={FIELD_NAMES.NEW_PASSWORD}
              type="password"
              placeholder="Confirm new password"
            />
          </div>

          <div className="mt-[5.25rem]">
            <Button className="w-full justify-center" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </AuthPanel>
  );
}

export default CreateNewPasswordForm;
