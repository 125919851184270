import { axios, axiosToPdfApi } from "services";
import { ENDPOINTS } from "const";
import {
  FullIssueType,
  FullSolutionType,
  IActionGroupHangout,
  IActionGroupRatings,
  IActionGroupSkill,
  IActionGroupUsers,
  IActivity,
  IAsset,
  IAttachment,
  IAverageIssueRating,
  IBackgroundImage,
  IIssueRating,
  IIssueRatingCategory,
  IMapLocation,
  IMotivational,
  IMotivationalRatings,
  IMotivationalTag,
  IResetPasswordData,
  IResetPasswordEmail,
  ISignInFormData,
  ISignUpFormData,
  ISkill,
  ISolution,
  ISolutionRating,
  ITag,
  IUser,
  IUserDeviceTokens,
  IUserSkill,
  FullActionGroupType,
  IMandatoryFile,
  IMandatoryAttachments,
  IIssue,
  IActionGroup,
} from "types";
import { CancelTokenSource } from "axios";
import { BufferType, IPagination } from "types/requests";
import { FastCommentsSSO } from "fastcomments-typescript";
import { toFormData } from "utils";
import _ from "lodash";

interface IFindSolutionProps {
  solutionId?: string;
  userId?: string;
  source: CancelTokenSource;
}

export function findSolutionRatings({
  source,
  solutionId,
  userId,
}: IFindSolutionProps) {
  const promise = axios.get<IPagination<ISolutionRating>>(
    ENDPOINTS.SOLUTION_RATINGS,
    {
      params: {
        user: userId,
        solution: solutionId,
      },
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IActionGroupUsersProps {
  actionUser: IActionGroupUsers<IUser, FullActionGroupType>;
}

export function createActionGroupUser({ actionUser }: IActionGroupUsersProps) {
  return axios.post<IActionGroupUsers<IUser, FullActionGroupType>>(
    ENDPOINTS.ACTION_GROUP_USERS,
    actionUser
  );
}

export function updateActionGroupUser({ actionUser }: IActionGroupUsersProps) {
  return axios.patch<IActionGroupUsers<IUser, FullActionGroupType>>(
    ENDPOINTS.ACTION_GROUP_USERS + "/" + actionUser.id,
    actionUser
  );
}

export function deleteActionGroupUser({ actionUser }: { actionUser: string }) {
  return axios.delete<IActionGroupUsers<IUser, FullActionGroupType>>(
    ENDPOINTS.ACTION_GROUP_USERS + "/" + actionUser
  );
}

interface ISolutionRatingProps {
  solution: ISolutionRating;
}

export function createSolutionRating({ solution }: ISolutionRatingProps) {
  return axios.post<ISolutionRating>(ENDPOINTS.SOLUTION_RATINGS, solution);
}

export function updateSolutionRating({
  solutionRatingId,
  solution,
}: ISolutionRatingProps & { solutionRatingId: string }) {
  return axios.patch<ISolutionRating>(
    ENDPOINTS.SOLUTION_RATINGS + "/" + solutionRatingId,
    solution
  );
}

interface IFindSkillsProps {
  limit: number;
  search: string;
  source: CancelTokenSource;
}

export function findSkills({ source, ...params }: IFindSkillsProps) {
  const promise = axios.get<ISkill[]>(ENDPOINTS.SKILLS, { params });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindUserSkillsProps {
  params: { user?: string };
  source: CancelTokenSource;
}

export function findUserSkills({ source, params }: IFindUserSkillsProps) {
  const promise = axios.get<IUserSkill[]>(ENDPOINTS.USER_SKILLS, { params });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindActionGroupsSkillsProps {
  params: { user?: string; actionGroup?: string };
  source: CancelTokenSource;
}

export function findActionGroupsSkills({
  source,
  params,
}: IFindActionGroupsSkillsProps) {
  const promise = axios.get<IActionGroupSkill<ISkill>[]>(
    ENDPOINTS.ACTION_GROUP_SKILLS,
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface ILocationParams {
  user?: string;
  issue?: string;
  solution?: string;
  lat?: string;
  lng?: string;
  range?: number;
  category?: string[] | string;
  skills?: string[] | string;
  tags?: string[] | string;
}

export interface ISolutionsProps {
  params: ILocationParams & {
    page?: number;
    limit?: number;
    actionGroup?: string;
    withDeleted?: boolean;
  };
  source: CancelTokenSource;
}

export function findSolutions({ params, source }: ISolutionsProps) {
  const promise = axios.get<IPagination<FullSolutionType>>(
    ENDPOINTS.SOLUTIONS,
    {
      params,
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface ISolutionProps {
  id: string;
  source: CancelTokenSource;
}

export function findSolution({ id, source }: ISolutionProps) {
  const promise = axios.get<FullSolutionType>(ENDPOINTS.SOLUTIONS + "/" + id);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IActionGroupProps extends Partial<ILocationParams> {
  id: string;
  source: CancelTokenSource;
}

export function findActionGroup({ id, source, ...rest }: IActionGroupProps) {
  const params = _.pickBy({ ...rest }, _.identity);
  const promise = axios.get<FullActionGroupType>(
    ENDPOINTS.ACTION_GROUPS + "/" + id,
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface IIssuesProps {
  params: ILocationParams & {
    page?: number;
    limit?: number;
    actionGroup?: string;
    withDeleted?: boolean;
  };
  source: CancelTokenSource;
}

export function findIssues({ params, source }: IIssuesProps) {
  const promise = axios.get<IPagination<FullIssueType>>(ENDPOINTS.ISSUES, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface IFindActivitiesProps {
  representEntityUser?: string;
  limit?: number;
  page?: number;
  source?: CancelTokenSource;
}

export function findActivities({ source, ...params }: IFindActivitiesProps) {
  const promise = axios.get<IPagination<IActivity<IUser>>>(
    ENDPOINTS.ACTIVITIES,
    {
      params,
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface IUpdateActivitiesProps {
  params?: ILocationParams & {
    entityUser?: string;
  };
  source: CancelTokenSource;
}

export function updateActivities() {
  return axios.patch(ENDPOINTS.ACTIVITIES);
}

export function createActivities(payload) {
  return axios.post(ENDPOINTS.ACTIVITIES, payload);
}

export interface IFindUserDeviceTokensProps {
  params: ILocationParams & {
    entityUser?: string;
    limit?: number;
  };
  source: CancelTokenSource;
}

export function findUserDeviceTokens({
  params,
  source,
}: IFindUserDeviceTokensProps) {
  const promise = axios.get<IUserDeviceTokens<IUser>[]>(
    ENDPOINTS.USER_DEVICE_TOKENS,
    {
      params,
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function createFirebasePushToken(payload: Partial<IUserDeviceTokens>) {
  return axios.post(ENDPOINTS.USER_DEVICE_TOKENS, payload);
}

export interface IActionGroupsProps {
  params: ILocationParams & {
    page?: number;
    limit?: number;
    title?: string;
    withDeleted?: boolean;
  };
  source: CancelTokenSource;
}

export function findActionGroups({ params, source }: IActionGroupsProps) {
  const promise = axios.get<IPagination<FullActionGroupType>>(
    ENDPOINTS.ACTION_GROUPS,
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface IActionGroupsListProps {
  params: ILocationParams & {
    page?: number;
    limit?: number;
  };
  source: CancelTokenSource;
}

export function findActionGroupsList({
  params,
  source,
}: IActionGroupsListProps) {
  const promise = axios.get<IPagination<FullActionGroupType>>(
    ENDPOINTS.ACTION_GROUPS,
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IIssueProps {
  id: string;
  source: CancelTokenSource;
}

export function findIssue({ id, source }: IIssueProps) {
  const promise = axios.get<FullIssueType>(ENDPOINTS.ISSUES + "/" + id);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindIssueProps {
  issueId?: string;
  userId?: string;
  source: CancelTokenSource;
}

export function findIssueRatings({ source, issueId, userId }: IFindIssueProps) {
  const promise = axios.get<IPagination<IIssueRating<IIssueRatingCategory>>>(
    ENDPOINTS.ISSUE_RATINGS,
    {
      params: {
        userId,
        issueId,
      },
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export interface IFindActionGroupUsersProps {
  actionGroup?: string;
  user?: string;
  source: CancelTokenSource;
  page?: number;
  status?: IActionGroupUsers["status"];
  role?: IActionGroupUsers["role"];
  limit?: number;
}

export function findActionGroupUsers({
  source,
  ...params
}: IFindActionGroupUsersProps) {
  const promise = axios.get<IPagination<IActionGroupUsers<IUser>>>(
    ENDPOINTS.ACTION_GROUP_USERS,
    {
      params,
    }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IAverageIssueRatingProps {
  id: string;
  source: CancelTokenSource;
}

export function findAverageIssueRating({
  id,
  source,
}: IAverageIssueRatingProps) {
  const promise = axios.get<IAverageIssueRating>(
    ENDPOINTS.ISSUE_RATINGS + "/" + id + "/average"
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function signInVerify(data: ISignInFormData) {
  return axios.post<ISignInFormData>(ENDPOINTS.SIGN_IN_VERIFY, {
    ...data,
  });
}

export function sendResetPasswordRequest(data: IResetPasswordEmail) {
  return axios.post<IResetPasswordEmail>(ENDPOINTS.REQUEST_RESET_PASSWORD, {
    ...data,
  });
}

export function resetPassword(data: IResetPasswordData) {
  return axios.post<IResetPasswordData>(ENDPOINTS.RESET_PASSWORD, {
    ...data,
  });
}

export function signUp(data: ISignUpFormData) {
  return axios.post<ISignUpFormData>(ENDPOINTS.SIGN_UP, {
    ...data,
  });
}

export function getMe(source?: CancelTokenSource) {
  const promise = axios.get<IUser>(ENDPOINTS.ME);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IMapLocationProps {
  params: ILocationParams & {
    eps?: number;
  };
  source: CancelTokenSource;
}
export function findMapLocations({ params, source }: IMapLocationProps) {
  const promise = axios.get<IMapLocation[]>(ENDPOINTS.ISSUES_LOCATIONS, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IMapLocationProps {
  params: ILocationParams & {
    eps?: number;
  };
  source: CancelTokenSource;
}
export function findMapSolutionLocations({
  params,
  source,
}: IMapLocationProps) {
  const promise = axios.get<IMapLocation[]>(ENDPOINTS.SOLUTIONS_LOCATIONS, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function findMapActionGroupsLocations({
  params,
  source,
}: IMapLocationProps) {
  const promise = axios.get<IMapLocation[]>(ENDPOINTS.ACTION_GROUPS_LOCATIONS, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function getCommentsConfig() {
  return axios.get<FastCommentsSSO>(ENDPOINTS.COMMENTS_CONFIG);
}

export interface IGroupRatingProps {
  group: IActionGroupRatings;
}

export function createGroupRating({ group }: IGroupRatingProps) {
  return axios.post<IActionGroupRatings>(ENDPOINTS.ACTION_GROUP_RATINGS, group);
}

export function updateGroupRating({
  actionGroupRatingId,
  group,
}: IGroupRatingProps & { actionGroupRatingId: string }) {
  return axios.patch<IActionGroupRatings>(
    ENDPOINTS.ACTION_GROUP_RATINGS + "/" + actionGroupRatingId,
    group
  );
}

export interface IMotivationalRatingProps {
  motivational: IMotivationalRatings;
}

export function createMotivationalRating({
  motivational,
}: IMotivationalRatingProps) {
  return axios.post<IMotivationalRatings>(
    ENDPOINTS.MOTIVATIONAL_RATINGS,
    motivational
  );
}

export function updateMotivationalRating({
  motivationalRatingId,
  motivational,
}: IMotivationalRatingProps & { motivationalRatingId: string }) {
  return axios.patch<IMotivationalRatings>(
    ENDPOINTS.MOTIVATIONAL_RATINGS + "/" + motivationalRatingId,
    motivational
  );
}

export interface IMotivationalsProps {
  params: ILocationParams & {
    page?: number;
    limit?: number;
  };
  source: CancelTokenSource;
}

export function findMotivationals({ params, source }: IMotivationalsProps) {
  const promise = axios.get<
    IPagination<IMotivational<IUser, IMotivationalTag>>
  >(ENDPOINTS.MOTIVATIONAL, { params });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IMotivationalProps {
  id: string;
  source: CancelTokenSource;
}

export function findMotivational({ id, source }: IMotivationalProps) {
  const promise = axios.get<IMotivational<IUser, IMotivationalTag<any, ITag>>>(
    ENDPOINTS.MOTIVATIONAL + "/" + id
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IBackgroundImageProps {
  source: CancelTokenSource;
}

export function getBackgroundImages({ source }: IBackgroundImageProps) {
  const promise = axios.get<IBackgroundImage[]>(ENDPOINTS.BACKGROUNDS);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindTagsProps {
  limit: number;

  source: CancelTokenSource;
}

export function findTags({ source, ...params }: IFindTagsProps) {
  const promise = axios.get<ITag[]>(ENDPOINTS.TAGS, { params });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindMotivationalTagsProps {
  params: { user?: string; motivational?: string };
  source: CancelTokenSource;
}

export function findMotivationalTags({
  source,
  params,
}: IFindMotivationalTagsProps) {
  const promise = axios.get<IMotivationalTag<IMotivational, ITag>[]>(
    ENDPOINTS.MOTIVATIONAL_TAGS,
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function updateMotivationalView(motivationalId: string | undefined) {
  return axios.patch(ENDPOINTS.MOTIVATIONAL_VIEWS + "/" + motivationalId);
}

export function createHandouts(actionGroups: FullActionGroupType[]) {
  return axiosToPdfApi.post("/json", actionGroups).then((result) => result);
}

interface IUserProps {
  id: string;
  source: CancelTokenSource;
}

export function findUser({ id, source }: IUserProps) {
  const promise = axios.get<IUser>(ENDPOINTS.USERS + "/" + id);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function assignActionGroups({
  id,
  actionGroups,
}: {
  id: string;
  actionGroups: string[];
}) {
  const promise = axios.put<Partial<ISolution>>(
    ENDPOINTS.ACTION_GROUPS_ASSIGN_ACTION_GROUPS + "/" + id,
    actionGroups
  );

  return promise;
}

export function findIssueRatingCategories({
  source,
}: {
  source: CancelTokenSource;
}) {
  const promise = axios.get<IIssueRatingCategory[]>(
    ENDPOINTS.ISSUE_RATING_CATEGORIES
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}
//
export function findActionGroupsHandouts({
  params,
  source,
}: IActionGroupsProps) {
  const promise = axios.get<IActionGroupHangout[]>(
    ENDPOINTS.ACTION_GROUPS + "/handouts",
    { params }
  );
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function findUnreadActivitiesAmount(source: CancelTokenSource) {
  const promise = axios.get<number>(ENDPOINTS.ACTIVITIES + "/unread-amount");
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}
///
export function setActivitiesAsSeen(ids: string[]) {
  const promise = axios.put<string[]>(ENDPOINTS.ACTIVITIES + "/seen", { ids });
  return promise;
}

export interface IUpdateAttachmentsProps {
  type: "issues" | "solutions" | "actionGroups";
  id: string;
  attachments: (
    | Partial<{ type: "link" | "file"; data: any; id: string; title?: string }>
    | File
  )[];
}
//
export function updateAttachments(attachments: IUpdateAttachmentsProps) {
  const promise = axios.put<IAttachment<IAsset, IUser>>(
    ENDPOINTS.ATTACHMENTS,
    toFormData(attachments, { indices: true })
  );
  return promise;
}

export function getTextAttachment({
  id,
  source,
  type,
}: {
  id: string;
  source: any;
  type: string;
}) {
  const promise = axios.get<BufferType>(
    `${ENDPOINTS.ATTACHMENTS_DOWNLOAD}/${id}`,
    {
      params: { type },
    }
  );
  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindMandatoryFilesProps {
  params: { directory?: string };
  source: CancelTokenSource;
}

export function getMandatoryFiles({
  source,
  params,
}: IFindMandatoryFilesProps) {
  const promise = axios.get<IMandatoryFile[]>(ENDPOINTS.MANDATORY_FILES, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

interface IFindMandatoryAttachmentsProps {
  params: { commonFor?: string };
  source: CancelTokenSource;
}

export function getMandatoryAttachments({
  source,
  params,
}: IFindMandatoryAttachmentsProps) {
  const promise = axios.get<IMandatoryAttachments>(ENDPOINTS.ATTACHMENTS, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function deleteUser({ user }: { user: string }) {
  return axios.delete<IUser>(ENDPOINTS.USERS + "/" + user);
}

export interface IFindUsersProps {
  limit?: number;
  page?: number;
  source?: CancelTokenSource;
}

export function findUsers({ source, ...params }: IFindUsersProps) {
  const promise = axios.get<IPagination<IUser>>(ENDPOINTS.USERS, {
    params,
  });
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

// Soft delete issues

export function softDeleteIssue({ issue }: { issue: string }) {
  return axios.delete<IIssue>(ENDPOINTS.ISSUES + "/soft/" + issue);
}

export function restoreIssue({ issue }: { issue: string }) {
  return axios.get<IIssue>(ENDPOINTS.ISSUES + "/soft/" + issue);
}

// Soft delete solutions

export function softDeleteSolution({ solution }: { solution: string }) {
  return axios.delete<ISolution>(ENDPOINTS.SOLUTIONS + "/soft/" + solution);
}

export function restoreSolution({ solution }: { solution: string }) {
  return axios.get<ISolution>(ENDPOINTS.SOLUTIONS + "/soft/" + solution);
}

// Soft delete action groups

export function softDeleteActionGroup({
  actionGroup,
}: {
  actionGroup: string;
}) {
  return axios.delete<IActionGroup>(
    ENDPOINTS.ACTION_GROUPS + "/soft/" + actionGroup
  );
}

export function restoreActionGroup({ actionGroup }: { actionGroup: string }) {
  return axios.get<IActionGroup>(
    ENDPOINTS.ACTION_GROUPS + "/soft/" + actionGroup
  );
}
