import { memo, useCallback, useState } from "react";
import { IAsset, IAttachment, IIssueRatingCategory } from "types";
import { ProfileBlock, Card } from "components";
import { BorderBadge } from "components/BorderBadge";
import { Button } from "components/Button";
import ChevronDown from "assets/images/chevron-down.svg";
import classnames from "classnames";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { getRating } from "utils";
import { AttachmentList } from "modules/Attachments/AttachmentList/AttachmentList";
import { useRestoreIssue, useSoftDeleteIssue } from "hooks";
import { useQueryClient } from "react-query";
import { ENDPOINTS } from "const";

interface IProps {
  details: string;
  origin: string;
  importance: string;
  id: string | undefined;
  profileImage: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt: string | undefined;
  rating: number;
  primaryClassification: Partial<IIssueRatingCategory> | undefined;
  secondaryClassification: Partial<IIssueRatingCategory> | undefined;
  innerRef?: (node?: Element | null | undefined) => void;
  onEdit: () => void;
  allowEdit: boolean;
  email: string | undefined;
  score: number | undefined;
  attachments: Partial<IAttachment<IAsset, any>>[] | undefined;
  tags?: string[] | undefined;
  issueId: string | undefined;
  isSoftDeleted: boolean;
}

interface IRatingBlockProps {
  rating: number;
  showRatingTooltip: boolean;
}

function RatingBlock({ rating, showRatingTooltip }: IRatingBlockProps) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (showRatingTooltip) {
          setOpenPopup(true);
        }
      }}
      onMouseOut={() => {
        setOpenPopup(false);
      }}
      className={classnames(
        " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start justify-center px-2 text-center  ",
        {
          " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
            openPopup,
        }
      )}
    >
      {getRating(rating) || 0}
      <RatingStar height={8} width={8} fill="black" />
      {showRatingTooltip && openPopup && (
        <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[150%] text-sm font-normal after:tooltiptoparrow">
          <div className="flex flex-col relative text-xs bg-white p-2">
            <p>How relevant is this issue? </p>
          </div>
          <span className="absolute border-[8px] top-[-17px] right-[9%] xx:right-[25%] lg:right-[31%] border-t-transparent border-b-[#8159D7] border-r-transparent border-l-transparent tooltiptoparrow" />
        </div>
      )}
    </span>
  );
}

function Item({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex items-center">
        {content}
      </span>
    </>
  );
}

function IssueDetailCard({
  details,
  origin,
  importance,
  profileImage,
  firstName = "",
  lastName = "",
  createdAt = "",
  rating,
  id,
  primaryClassification,
  secondaryClassification,
  onEdit,
  email,
  allowEdit,
  score,
  attachments,
  tags,
  issueId,
  isSoftDeleted,
}: IProps) {
  const date =
    createdAt &&
    new Date(createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);

  // Soft delete and restore
  const { mutateAsync: softDeleteIssue } = useSoftDeleteIssue();
  const { refetch: restoreIssue } = useRestoreIssue({ id: issueId });

  const queryClient = useQueryClient();

  const handleDeleteRestore = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (isSoftDeleted) {
          await restoreIssue();
        } else {
          await softDeleteIssue(issueId);
        }
        queryClient.invalidateQueries([ENDPOINTS.ISSUE, issueId]);
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [isSoftDeleted, issueId, queryClient, restoreIssue, softDeleteIssue]
  );

  return (
    <Card
      shouldHover={false}
      collapse={descriptionCollapsed}
      className={classnames("bg-white", {
        "bg-gray-100 pointer-events-none select-none": isSoftDeleted,
      })}
      header={
        <div
          // onTouchStart={() => {
          //   setDescriptionCollapsed((val) => !val);
          // }}
          // onTouchEnd={(e) => {
          //   e.preventDefault();
          // }}
          className="flex justify-between items-center pb-1"
        >
          <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
            <img
              onClick={() => {
                setDescriptionCollapsed((val) => !val);
              }}
              alt="chevron-down"
              className={classnames("cursor-pointer lg:hidden", {
                "rotate-180": descriptionCollapsed,
              })}
              src={ChevronDown}
            />
            <span className="font-bold leading-6 text-lg">Description</span>
          </div>

          {allowEdit && (
            <div className="flex gap-2">
              <Button
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={onEdit}
                disabled={!allowEdit}
              >
                Edit
              </Button>
              <Button
                onTouchStart={handleDeleteRestore}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={handleDeleteRestore}
                disabled={!allowEdit}
                className="!pointer-events-auto"
              >
                {isSoftDeleted ? "Restore" : "Delete"}
              </Button>
            </div>
          )}
        </div>
      }
      classNames={{
        header: " px-6 sm:px-16 ",
        content: " px-6 sm:px-16  flex-1 !justify-center ",
        contentInner: "flex ",
        footer: " px-6 sm:px-16 h-[60px]",
      }}
      content={
        <div className="flex flex-col w-full gap-4">
          <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 flex-1">
            <Item title="Details" content={details} />
            <Item title="Origin" content={origin} />
            <Item title="Importance" content={importance} />
            <Item
              title="Category"
              content={
                <div className="flex flex-wrap gap-4 py-2">
                  {!!primaryClassification && (
                    <BorderBadge
                      classificationName={primaryClassification.category}
                      classificationDescription={
                        primaryClassification.description
                      }
                      tooltipId={id}
                    >
                      {primaryClassification.title}
                    </BorderBadge>
                  )}
                  {!!secondaryClassification && (
                    <BorderBadge
                      classificationName={secondaryClassification.category}
                      classificationDescription={
                        secondaryClassification.description
                      }
                      tooltipId={id}
                    >
                      {secondaryClassification.title}
                    </BorderBadge>
                  )}
                </div>
              }
            />
            {tags && tags?.length > 0 && (
              <Item
                title="Labels"
                content={
                  <div className="flex items-center flex-wrap gap-2 py-1">
                    {tags?.map((tag) => (
                      <BorderBadge
                        key={tag}
                        classificationName={tag}
                        styleLess
                        classNames={{
                          text: "text-gray-500 text-xs underline",
                          contend: "flex",
                        }}
                      >
                        {tag}
                      </BorderBadge>
                    ))}
                  </div>
                }
              />
            )}
          </div>
          <AttachmentList
            //showAttachmentsLinkLabel={false}
            attachments={attachments}
            readOnly
            classNames={{
              attachmentsContainer: "grid-cols-8 lg:grid-cols-12 ",
            }}
            directory="issues"
          />
        </div>
      }
      footer={
        <>
          <ProfileBlock
            classNames={{
              name: "!justify-start",
            }}
            userId={id}
            firstName={firstName}
            lastName={lastName}
            createdAt={date}
            showName
            isModalVariant
            profileImage={profileImage}
            email={email}
            score={score}
          />
          <div className="flex flex-row">
            {!!rating && <RatingBlock rating={rating} showRatingTooltip />}
          </div>
        </>
      }
    />
  );
}

export default memo(IssueDetailCard);
