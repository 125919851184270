import { memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BorderBadge,
  BorderBadgeRound,
  Button,
  Loader,
  StarRatingItem,
} from "components";
import { APP_ROUTES, ENDPOINTS } from "const";
import { ProfileBlock, Card } from "components";
import { showErrorMessage, showSuccessMessage } from "utils";
import classnames from "classnames";
import {
  useMe,
  useSolution,
  useSolutionRatings,
  useWindowDimensions,
} from "hooks";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { useQueryClient } from "react-query";
import {
  useCreateSolutionRating,
  useUpdateSolutionRating,
} from "modules/Solution/SolutionsCard/SolutionsCard";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";

interface ICard {
  id: string | undefined;

  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
}

function Item({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <>
      <span className=" font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className=" font-normal leading-[30px] text-[#00000099] flex align-center">
        {content}
      </span>
    </>
  );
}

function SolutionCardModal({ id, className, setShow }: ICard, ref) {
  const navigate = useNavigate();
  const { data, isLoading } = useSolution({
    id: id,
    suspense: false,
  });

  const { data: meData } = useMe();
  const queryClient = useQueryClient();

  const [showRateSolutionPopup, setShowRateSolutionPopup] = useState(false);
  const { data: solutionRatingData } = useSolutionRatings({
    solutionId: id,
    userId: meData?.data.id,
    enabled: !!meData?.data.id && showRateSolutionPopup,
  });

  const [, setShowTooltip] = useState(false);
  const [ratingValue, setRatingValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      solutionRatingData &&
      solutionRatingData?.data.items.length > 0 &&
      solutionRatingData?.data.items[0].rate
    ) {
      setRatingValue(solutionRatingData?.data.items[0].rate);
    }
  }, [solutionRatingData]);

  const {
    mutateAsync: mutateCreateIssueRatingAsync,
    isLoading: loadingCreate,
  } = useCreateSolutionRating({
    solutionId: id,
  });
  const {
    mutateAsync: mutateUpdateIssueRatingAsync,
    isLoading: loadingUpdate,
  } = useUpdateSolutionRating({
    solutionRatingId:
      solutionRatingData && solutionRatingData?.data.items.length > 0
        ? solutionRatingData?.data.items[0].id
        : undefined,
  });

  const createRate = useCallback(
    (ratingValue: number) => {
      mutateCreateIssueRatingAsync({
        solution: id,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
          showSuccessMessage("Solution rating has been created!");
          setShowRateSolutionPopup(false);
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
          setShowRateSolutionPopup(false);
        });
    },
    [id, queryClient, mutateCreateIssueRatingAsync]
  );

  const updateRate = useCallback(
    (ratingValue: number) => {
      mutateUpdateIssueRatingAsync({
        solution: id,
        rate: ratingValue,
      })
        .then(() => {
          queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.resetQueries([ENDPOINTS.SOLUTIONS]);
          queryClient.invalidateQueries([ENDPOINTS.SOLUTION_RATING]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
          queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
          showSuccessMessage("Solution rating has been updated!");
          setShowRateSolutionPopup(false);
        })
        .catch(() => {
          showErrorMessage("Error while updating rating!");
          setShowRateSolutionPopup(false);
        });
    },
    [id, queryClient, mutateUpdateIssueRatingAsync]
  );

  const date =
    data?.data.createdAt &&
    new Date(data?.data.createdAt).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  const dimensions = useWindowDimensions();

  const formattedAddress = data?.data?.issue?.location?.formattedAddress;

  const ifSoftDeleted = !!data?.data.deletedAt;

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classnames(
        className,
        "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
      )}
    >
      {isLoading ? (
        <Loader type="rings" />
      ) : (
        <Card
          shouldHover={false}
          className={classnames(
            "flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40",

            { "bg-gray-100 pointer-events-none select-none": ifSoftDeleted }
          )}
          header={
            <div className="flex justify-between items-center pb-1">
              <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                <span className="font-bold leading-6 text-lg line-clamp-2">
                  {data?.data.title}
                </span>
                <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                  {formattedAddress}
                </span>
              </div>
              <XIcon
                onClick={() => {
                  setShow(false);
                }}
                className="!pointer-events-auto h-6 w-6 shrink-0 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          }
          classNames={{
            header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            content: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
            footer: "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
          }}
          content={
            <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6">
              <Item title="Details" content={data?.data.description} />
              <Item title="Effectiveness" content={data?.data.effectiveness} />
              <Item
                title="Skills Required"
                content={
                  <div
                    className={classnames(
                      "flex items-start flex-wrap  gap-4 ",
                      {
                        " pt-1 ": !(
                          (data?.data.primaryActionGroup?.skills &&
                            data?.data.primaryActionGroup?.skills.length < 1) ||
                          !data?.data.primaryActionGroup?.skills
                        ),
                      }
                    )}
                  >
                    {data?.data.primaryActionGroup?.skills?.map((skill) => {
                      return (
                        <BorderBadgeRound
                          key={skill.id}
                          className="flex"
                          withNotification
                          notificationContent={`${skill.timeRequired || 0} ${
                            Number(skill.timeRequired) === 1 ? "hour" : "hours"
                          } per week`}
                          classificationName={skill?.skill?.name}
                        >
                          {skill?.skill?.name}
                        </BorderBadgeRound>
                      );
                    })}
                    {((data?.data.primaryActionGroup?.skills &&
                      data?.data.primaryActionGroup?.skills.length < 1) ||
                      !data?.data.primaryActionGroup?.skills) && (
                      <div>No skills assigned</div>
                    )}
                  </div>
                }
              />
              {data?.data?.tags && data?.data?.tags?.length > 0 && (
                <Item
                  title="Labels"
                  content={
                    <div className="flex items-center flex-wrap gap-2 py-1">
                      {data?.data?.tags?.map((tag) => (
                        <BorderBadge
                          key={tag}
                          classificationName={tag}
                          styleLess
                          classNames={{
                            text: "text-gray-500 text-xs underline",
                            contend: "flex",
                          }}
                        >
                          {tag}
                        </BorderBadge>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          }
          footer={
            <>
              <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                <ProfileBlock
                  userId={data?.data?.user?.id}
                  isModalVariant
                  firstName={data?.data?.user?.firstName}
                  lastName={data?.data?.user?.lastName}
                  createdAt={date}
                  profileImage={data?.data?.user?.avatar}
                  email={data?.data?.user?.email}
                  score={data?.data?.user?.reputationScore}
                />
                <div className="flex flex-row gap-1">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseOver={() => {
                      setShowTooltip(true);
                    }}
                    onMouseOut={() => {
                      setShowTooltip(false);
                    }}
                    className="relative flex flex-row min-w-[36px]"
                  >
                    {!!data?.data.impactRanking ? (
                      <span
                        onClick={() => {
                          if (!!meData?.data.id) {
                            setShowRateSolutionPopup((val) => !val);
                          }
                        }}
                        className={classnames(
                          "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                          {
                            " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                              !!meData?.data.id,
                            " outline outline-[1px] outline-[#8159D7]":
                              showRateSolutionPopup,
                            " cursor-default ": !meData?.data.id,
                          }
                        )}
                      >
                        {data?.data.impactRanking}
                        <RatingStar height={8} width={8} fill="black" />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          if (!!meData?.data.id) {
                            setShowRateSolutionPopup((val) => !val);
                          }
                        }}
                        className={classnames(
                          "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                          {
                            " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                              !!meData?.data.id,
                            " cursor-default ": !meData?.data.id,
                          }
                        )}
                      >
                        {0}
                        <RatingStar height={8} width={8} fill="black" />
                      </span>
                    )}
                    {/* {!ratingValue && (
              <CustomToolTip
                className="!bg-white !border-black !border !text-black !min-w-[110px] !bottom-[140%]"
                position="top"
                showTooltip={showTooltip}
                tooltipText={"Click to rate!"}
              />
            )} */}
                    {showRateSolutionPopup && (
                      <div className="absolute  text-black gradient-outline-forced-roundless text-center    z-10 left-[-420%] xx:left-[-420%] lg:left-[-440%] top-[-600%] text-sm font-normal after:tooltipbottomarrow">
                        <div className="flex flex-row relative  bg-white p-2">
                          {loadingCreate || loadingUpdate ? (
                            <div className="  w-[175px] h-[84px]">
                              <Loader
                                type="rings"
                                globalLoader={false}
                                size="tiny"
                              />
                            </div>
                          ) : (
                            <div className=" py-5 px-1">
                              <StarRatingItem
                                starSize="small"
                                classNames={{
                                  label: "font-bold !text-xs",
                                  input: "!gap-2 pt-2",
                                }}
                                title="How effective is this solution?"
                                ratingValue={ratingValue || 0}
                                onClick={(ratingValue) => {
                                  if (
                                    ratingValue &&
                                    solutionRatingData &&
                                    solutionRatingData?.data.items.length === 0
                                  ) {
                                    createRate(ratingValue);
                                  }
                                  if (
                                    ratingValue &&
                                    solutionRatingData &&
                                    solutionRatingData?.data.items.length > 0
                                  ) {
                                    updateRate(ratingValue);
                                  }
                                }}
                                setRatingValue={setRatingValue}
                              />
                            </div>
                          )}
                          <XIcon
                            onClick={() => {
                              setShowRateSolutionPopup(false);
                            }}
                            className="!pointer-events-auto h-4 w-4 opacity-50  shrink-0 cursor-pointer"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="absolute border-[8px] right-[20%] xx:right-[21%] lg:right-[16%] border-b-transparent border-t-[#8159D7] border-r-transparent border-l-transparent tooltipbottomarrow" />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {dimensions.width < 641 && (
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="!pointer-events-auto seven-fifth-step solution-details-button-to-click sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    id && navigate(APP_ROUTES.SOLUTION + "/" + id);
                  }}
                >
                  Details
                </Button>
              )}
              {dimensions.width > 640 && (
                <Button
                  inGroupStyle={false}
                  isStyleless
                  isReverse
                  className="!pointer-events-auto seven-fifth-step solution-details-button-to-click  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                  onClick={() => {
                    id && navigate(APP_ROUTES.SOLUTION + "/" + id);
                  }}
                >
                  See full details
                </Button>
              )}
            </>
          }
        />
      )}
    </div>,
    document.body
  );
}

const IssueCardModalWithRef = React.forwardRef(SolutionCardModal);

export default memo(IssueCardModalWithRef);
