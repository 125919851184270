import { useField } from "formik";
import classnames from "classnames";
import { IInputProps } from "../InputElement";
import { CheckboxElement, Label } from "components";
import { twMerge } from "tailwind-merge";

interface IProps extends IInputProps {
  classNames?: {
    mainContainer?: string;
    inputContainer?: string;
    input?: string;
    error?: string;
    icon?: string;
    label?: string;
  };
  className?: string;

  name: string;
  label?: string;

  message?: React.ReactNode | string;
}

function CheckboxField({
  classNames,
  name,
  className,
  label,
  message,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const error = form.touched && form.error;
  const showError = !!error;

  return (
    <div className={twMerge(classnames(classNames?.mainContainer, "relative"))}>
      {label && (
        <Label className={rest.readOnly ? "font-bold" : ""} name={label} />
      )}
      <div className={classnames("mt-1")}>
        <CheckboxElement
          isError={showError}
          className={className}
          {...field}
          {...rest}
        />
      </div>
      {(showError || message) && (
        <div
          className={classnames(classNames?.error, "  font-light text-sm", {
            "text-red-400": showError,
          })}
        >
          {error || message}
        </div>
      )}
      {message && (
        <div className={classnames(classNames?.error, "  font-light text-sm")}>
          {message}
        </div>
      )}
    </div>
  );
}

export default CheckboxField;
