import { Formik } from "formik";
import { memo } from "react";

import { ValuesType } from "./LoginForm.types";

import validationSchema from "./LoginForm.schema";
import { useInitialState, useSubmitHandler } from "./LoginForm.hooks";
import LoginForm from "./LoginForm.component";

interface IProps {
  className?: string;
}

function Login({ className }: IProps) {
  return (
    <Formik<ValuesType>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler()}
    >
      {(props) => <LoginForm {...props} className={className} />}
    </Formik>
  );
}

export default memo(Login);
