import { Form } from "formik";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "const";
import { Button, FormikInput, AuthPanel } from "components";

import { FIELD_NAMES } from "./ForgetPasswordForm.constants";
import { ReactComponent as WeLogo } from "assets/images/we-logo.svg";

function ResetPasswordForm() {
  return (
    <AuthPanel>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <WeLogo />
        </div>
        <h2 className="mt-8 text-3xl font-bold text-gray-900 text-center">
          Reset password
        </h2>

        <div className=" flex flex-row justify-center text-center items-center  w-full h-full text-base font-normal mt-4">
          <p className="text-sm text-gray-600 mr-1 text-center">Or</p>
          <Link to={APP_ROUTES.SIGN_IN}>
            <p className="opacity-100 font-semibold text-black text-gradient ">
              Sign In
            </p>
          </Link>
        </div>
      </div>

      <div className="mt-[6.65rem]">
        <Form className={classnames("")}>
          <div className="mt-7">
            <FormikInput
              autoComplete="nope"
              label="Email address"
              name={FIELD_NAMES.EMAIL}
              placeholder="E-Mail Address"
            />
          </div>

          <div className="mt-[6.65rem]">
            <Button className="w-full justify-center" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </AuthPanel>
  );
}

export default ResetPasswordForm;
