import { Formik } from "formik";
import { memo } from "react";

import validationSchema from "./IssueForm.schema";
import { useInitialState, useSubmitHandler } from "./IssueForm.hooks";
import IssueForm, { IIssueFormProps } from "./IssueForm.component";
import { IValues } from "./IssueForm.types";
import { useIssueRatingCategories } from "hooks";

interface IProps extends Partial<IIssueFormProps> {
  onSuccess: (id?: string) => void;
}

function IssueFormContainer({
  className,
  onSuccess,
  onClose,
  showIssueFields = true,
  showRatings = true,
}: IProps) {
  const { data: categoriesData } = useIssueRatingCategories();
  const categories = categoriesData?.data?.map((i) => i.category);

  return (
    <Formik<IValues>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema(
        { showIssueFields, showRatings },
        categories
      )}
      onSubmit={useSubmitHandler(onSuccess, { showIssueFields, showRatings })}
    >
      <IssueForm
        className={className}
        onClose={onClose}
        showIssueFields={showIssueFields}
        showRatings={showRatings}
        categories={categoriesData?.data}
      />
    </Formik>
  );
}

export default memo(IssueFormContainer);
