import { useInfinityActionGroups, useQueryOptionsSetter } from "hooks";

import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions } from "react-query";
import { useSearchParams } from "react-router-dom";
import { IActionGroupsProps } from "services";
import { FullActionGroupType } from "types";
import { IAxiosResponseWithPagination } from "types/requests";

import { getRange, getSearchParams } from "utils";

type ActionGroupParamsTypes = Partial<IActionGroupsProps["params"]>;
interface IInfiniteQueryActionGroupsProps
  extends Partial<
      UseInfiniteQueryOptions<IAxiosResponseWithPagination<FullActionGroupType>>
    >,
    ActionGroupParamsTypes {}

export function useActionGroupsList(
  params: Partial<
    ReturnType<typeof getSearchParams> & IInfiniteQueryActionGroupsProps
  >,
  mapWidth?: number | undefined,
  disabled = false
) {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);
  const { ref, inView } = useInView();

  const { data, fetchNextPage, ...rest } = useInfinityActionGroups({
    getNextPageParam: (lastPage) => {
      if (lastPage.data.meta.currentPage < lastPage.data.meta.totalPages) {
        return lastPage.data.meta.currentPage + 1;
      }
    },

    range: getRange(params?.zoom, Number(params.lat), mapWidth),
    keepPreviousData: true,
    suspense: !isMount,
    refetchOnWindowFocus: false,
    enabled: !disabled,
    withDeleted: !!params.user,
    ...params,
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return { data, inViewRef: ref, fetchNextPage, ...rest };
}

export function useGroupListFilterParams(mapWidth: number, disabled = false) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, inViewRef } = useActionGroupsList(
    useMemo(() => getSearchParams(searchParams), [searchParams]),
    mapWidth,
    disabled
  );
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  return { data, inViewRef, setQueryOptions, searchParams };
}
